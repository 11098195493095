import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { axiosInstance } from "../../utility/api";
import Auth from "../../utility/auth";
import { useDispatch, useSelector } from "react-redux";
import { SAVE_TOKEN } from "../../redux/actionTypes";
import Logo from "../../assets/images/logo/PATRONOFART_LOGO.png";
import "./Login.css";
import axios from "axios";

import API from '../../APIActions/api'
import { formatAPIError } from "../../utility/helper";

export default function LoginStep2({ loginStepInfo }) {
  let history = useHistory();
  const [loginDetails, setLoginDetails] = useState({
    otp: "",
    email: "",
  });
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.adminReducer.userToken);

  useEffect(() => {
    if (userToken) {
      Auth.login(() => {
        history.push("/welcome/:layout");
      });
    }
  }, [userToken]);

  // const handleChange = (e) => {
  //   if (e.target.name !== "username") {
  //     setLogin({
  //       ...login,
  //       [e.target.name]: e.target.value,
  //     });
  //   } else if (e.target.name === "username") {
  //     setLogin({
  //       ...login,
  //       [e.target.name]: e.target.value.toLowerCase(),
  //     });
  //   }
  // };

  const submitHandler = (e) => {
    e.preventDefault();
    if (loginDetails?.otp) {
      setShowLoader(true);
      axiosInstance
        .post("/v1/admin/auth/2FA/verify", loginDetails)
        .then((res) => {
          localStorage.clear();
          console.log("qwe Show : ", res.data.accessToken);
          setShowLoader(false);
          Swal.fire("Welcome to Patron of Art", res.data.message, "success");

          console.log("rrr", res);
          if (res) {
            localStorage.setItem("token",JSON.stringify(res?.data?.accessToken));
            API.initHeaders(res?.data?.accessToken)
            dispatch({
              type: SAVE_TOKEN,
              payload: JSON.stringify(res?.data?.accessToken),
            });
          } else {
            Swal.fire("Error", "Error while login.", "error");
          }
          // history.push("/products/products/:layout");
          //   localStorage.setItem("isAuth", res.data.success);
          //   localStorage.setItem("token", JSON.stringify(res.data.result.token));
          //   localStorage.setItem("user", JSON.stringify(res.data.result));
          //   localStorage.setItem("role", JSON.stringify(res.data.result.role));
          //   localStorage.setItem("userID", JSON.stringify(res.data.result._id));
          // //   localStorage.setItem("email", JSON.stringify(res.data.result.email));
          // Auth.login(() => {
          //   history.push("/products/products/:layout");
          // });
        })
        .catch((err) => {
          setShowLoader(false);
          let errMsg = formatAPIError(err)
          Swal.fire("Alert!", errMsg, "error");
        });
      // }
    } else {
      Swal.fire("Alert!", "Please enter verification code.", "error");
    }
  };

  return (
    <>
      <div>
        <fieldset>
          <div className="twoFactorAuthenticate">
            <form
            // onSubmit={submitHandler}
            >
              <div className="logoWrapperLogin">
                <img src={Logo} alt="logo" className="logoImageLogin" />
              </div>
              <div className="loginCenter">
                <h3>2FA</h3>

                {loginStepInfo?.qr && (
                  <>
                    <div className="googleAuthenticatorInfo">
                      Scan this with Google Authentication App
                    </div>
                    <img src={loginStepInfo?.qr} alt="logo" height="210px" />
                  </>
                )}
              </div>

              <div className="form-group googleVerificationCode">
                {/* <label>Verification Code.</label> */}
                <input
                  type="text"
                  name="verificationCode"
                  className="form-control"
                  placeholder="Enter Verification code"
                  value={loginDetails?.otp}
                  onChange={(e) =>
                    setLoginDetails({
                      otp: e?.target?.value,
                      email: loginStepInfo?.email,
                    })
                  }
                />
              </div>

              <button
                type="submit"
                className="btn btn-dark btn-lg btn-block"
                onClick={submitHandler}
              >
                Submit
              </button>
              {/* <p className="forgot-password text-right">
              Forgot <a href="#">password?</a>
            </p> */}
            </form>
          </div>
        </fieldset>
      </div>
    </>
  );
}
