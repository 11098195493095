import React, { useState } from "react";

import EyeOpen from "../../../assets/images/icons/eye-open.svg";
import EyeClosed from "../../../assets/images/icons/eye-close.svg";

const PasswordInput = (props) => {

    const { label, name, placeholder, className, inputClass, value, onChange } = props

    const [passwordType, setPasswordType] = useState('password')

    return (
        <div className={className || ''}>
            {label && <label>{label}</label>}
            <div className="password-input-wrapper">
                <input
                    type={passwordType}
                    name={name}
                    className={`form-control ${inputClass || ''}`}
                    placeholder={placeholder || ''}
                    value={value}
                    onChange={onChange}
                    onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    required
                />
                <a onClick={() => setPasswordType(passwordType === 'text' ? 'password' : 'text')}>
                    <img src={passwordType === 'text' ? EyeOpen : EyeClosed} alt="eye" />
                </a>
            </div>
        </div>
    )
}

export default PasswordInput