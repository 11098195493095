module.exports = {
    API_URL: process.env.REACT_APP_API_URL + 'v1',

    API_ENDPOINT: {
        GET_PROFILE: '/admin/fetchAdminProfile',
        ADMIN_PERMISSION: '/admin/base/permission',
        UPLOAD_IMAGE: '/admin/upload/bucket',
        COLLECTION: '/admin/collection',
        INSTITUTION: '/admin/institution',
        ARTIST: '/admin/artist',
        EDIT_USER: '/admin/user',
        AIR_DROP: {
            GET: '/admin/airdropAsset',
            TRANSFER: '/admin/airdrop/'
        },
        ANALYTICS: {
            ASSET_CHART: '/admin/dashboard/analytics/asset',
            ASSET_DOWNLOAD: '/admin/asset/download',
            ORDER_CHART: '/admin/dashboard/analytics/order',
            ORDER_DOWNLOAD: '/admin/order/download',
            USER_CHART: '/admin/dashboard/analytics/user',
            USER_DOWNLOAD: '/admin/user-data/download',
            VISITOR_CHART: '/admin/dashboard/analytics/vuser',
            VISITOR_DOWNLOAD: '/admin/visited-user/download',
        },
        RELEASE: '/admin/release',
        FORGOT_PASSWORD: '/user/forgot-password',
        RESET_PASSWORD: '/admin/auth/reset',
        COMMISSION_LIST: '/admin/commissions/order/list',
        ASSET: '/admin/asset',
        GET_RATE: '/admin/exchange/rate'
    }
}