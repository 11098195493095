/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from "../../components/common/Loader/Loader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SearchIcon from "@material-ui/icons/Search";
import Swal from "sweetalert2";
import moment from "moment";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// import "./OrderTable.css";
import { axiosInstance } from "../../utility/api";
import NoRecordsFound from "../common/Error/NoRecordsFound";
import Tooltip from "@material-ui/core/Tooltip";
import DataRangePopOver from "../common/DateRange/DateRangePopOver";
import { formatDateTime } from "../common/date";
import "./TransactionHistoryTable.css";
// import Preview from "../../../assets/images/icons/preview.svg";
// import Download from "../../../assets/images/icons/download.svg";
import {
  formatAPIError,
  formatETHPrice,
  formatEuroPrice,
} from "../../utility/helper";
import API from "../../APIActions/api";

const useRowStyles = makeStyles({
  root: {
    marginTop: "0px",
    border: "none",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5FC",
  },
  rowDatas: {
    "& td,th": {
      border: "none",
      height: "90px",
    },
  },
  border__right: {
    borderRight: "2px solid white",
    height: "70%",
  },
  cursorDefault: {
    cursor: "default",
  },
  height24: {
    height: "24px",
  },
});

//Each row of table
function Row(props) {
  const { data, tableColumnWidth, maticValue } = props;
  const [open, setOpen] = React.useState(false);
  const [openPreview, setOpenPreview] = useState({ open: false, id: data?.id });
  // const token = JSON.parse(localStorage.getItem("token"));
  const classes = useRowStyles();
  // //console.log("")
  return (
    <React.Fragment>
      <TableRow
        onClick={() => {
          setOpen(!open);
        }}
        className={classes.rowDatas}
        style={{ borderBottom: "12px solid #F5F5FC", cursor: "default" }}
      >
        <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
          <div className="columnStyles">{data?.id ? data?.id : "-"}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
          <div className="columnStyles">{data?.asset && data?.asset?.name}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div className="columnStyles">
            {data?.createdAt
              ? moment(data?.createdAt).format("MMMM Do YYYY, h:mm a")
              : "-"}
            {/* {data?.createdAt ? formatDateTime(data?.createdAt) : "-"} */}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div
            class={`columnStyles messageBoxStyle ${
              data?.status === "success"
                ? "success"
                : data?.status === "failed"
                ? "failed"
                : "pending"
            }`}
          >
            {data?.status}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <div className="columnStyles-sm columnStyles">
            {data?.asset?.networkType || "-"}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[5]}%` }}>
          <div className="columnStyles-sm columnStyles">{data?.from}</div>
        </TableCell>

        {/* <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <OrderDetailsModal orderId={data?.id} />
        </TableCell> */}
        <TableCell style={{ width: `${tableColumnWidth[6]}%` }}>
          <div className="columnStyles-sm columnStyles">
            {/* <a
              href={`https://polygonscan.com/tx/${data?.orderTx}`}
              target="_blank"
              rel="noreferrer"
            
            > */}
            {data?.to}
            {/* </a> */}
          </div>
        </TableCell>
        {/* <TableCell style={{ width: `${tableColumnWidth[5]}%` }}>
          <div className="columnStyles captitalize ">
            {data?.paymentType ? data?.paymentType : "-"}
          </div>
        </TableCell> */}
        <TableCell style={{ width: `${tableColumnWidth[7]}%` }}>
          <div className="columnStyles">
            {/* {data?.totalPrice} */}
            {`${formatEuroPrice(data?.totalPrice)} € (${
              data.ethPrice !== null
                ? `${formatETHPrice(data?.ethPrice)} ${
                    data?.networkType === "Polygon" ? "MATIC" : "ETH"
                  }`
                : "User skipped transaction"
            })`}
          </div>
        </TableCell>
        {/* <TableCell style={{ width: `${tableColumnWidth[7]}%` }}>
          <div className="columnStyles">{data?.asset && data?.type}</div>
        </TableCell> */}
      </TableRow>
      {/* {openPreview?.open ? (
        <OrderDetailsModal orderId={openPreview?.id} />
      ) : null} */}
    </React.Fragment>
  );
}

const RenderHeader = ({ columns, onSorting }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  const classes = useRowStyles();
  return (
    <TableHead className={classes.header}>
      <TableRow>
        {columns?.map(({ name, field, sortable, image }) => (
          <TableCell
            align={columns.align}
            key={name}
            className={classes.cursorDefault}
          >
            <div className="dividerFlex">
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
                {/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function TransactionHistoryTable({
  data,
  showLoader,
  setShowLoader,
}) {
  const classes = useRowStyles();
  const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();
  let day = today.getDate();
  let date1MonthBefore = new Date(year, month - 1, day);
  const [transactionHistory, setTransactionHistory] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectionRange, setSelectionRange] = useState({
    startDate: date1MonthBefore,
    endDate: new Date(),
    key: "selection",
  });
  const [searchText, setSearchText] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [statusFilterSelected, setStatusFilterSelected] = useState("");
  const [maticValue, setMaticValue] = useState(0);
  const token = JSON.parse(localStorage.getItem("token"));
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const tableColumnWidth = [5, 15, 16, 12, 12, 20, 20, 20, 15];
  const columns = [
    {
      name: "ID",
      field: "Id",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Asset Name",
      field: "asset",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Date",
      field: "date",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Status",
      field: "status",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Blockchain",
      field: "networkType",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "From Wallet Address",
      field: "from",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "To Wallet Address",
      field: "to",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    // {
    //   name: "Payment Method",
    //   field: "PaymentMethod",
    //   numeric: true,
    //   sortable: false,
    //   align: "left",
    // },
    {
      name: "Transaction Amount(€)",
      field: "transactionAmount",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    // {
    //   name: "Type",
    //   field: "type",
    //   // image: Divider,
    //   numeric: true,
    //   sortable: false,
    //   align: "left",
    // },
  ];

  useEffect(() => {
    getTransactionHistory();
  }, [statusFilterSelected, searchText, pageNo, selectionRange]);

  useEffect(() => {
    getRate();
  }, []);

  const getRate = async () => {
    API.getConversionRate()
      .then((res) => {
        if (res.data.result.exchangeRate) {
          setMaticValue(res.data.result.exchangeRateMatic);
        } else {
          setMaticValue(0);
        }
      })
      .catch((error) => {
        setMaticValue(0);
      });
  };

  const handleSelect = (ranges) => {
    setSelectionRange(ranges?.selection);
  };

  const handleChangePage = (event, nextPageNo) => {
    setPageNo(nextPageNo);
    window.scrollTo(0, 0);
  };

  const getTransactionHistory = async () => {
    let startDate = new Date(
      selectionRange?.startDate?.getTime() -
        selectionRange?.startDate?.getTimezoneOffset() * 60000
    ).toISOString();
    let endDate = new Date(
      selectionRange?.endDate?.getTime() -
        selectionRange?.endDate?.getTimezoneOffset() * 60000
    ).toISOString();
    let statusFilter = statusFilterSelected == -1 ? "" : statusFilterSelected;
    const result = await axiosInstance
      .get(
        `/v1/admin/transactions?page=${
          pageNo + 1
        }&limit=10&search=${searchText}&status=${statusFilter}&fromDate=${startDate}&toDate=${endDate}`,
        header
      )
      .then((result) => {
        setTransactionHistory(result?.data?.result?.transactions?.rows);
        setTotalCount(result?.data?.result?.transactions?.count);
      })
      .catch((err) => {
        let errMsg = formatAPIError(err);
        Swal.fire("Alert!", errMsg, "error").then(() => {
          if (
            err?.response?.status === 401 &&
            err?.response?.data?.message?.includes("authenticated")
          ) {
            console.log("err.response", err.response);
            window.location.href = "/welcome/:layout";
          }
        });
      });
  };

  return (
    <div className="table_div tableWidth100">
      {showLoader == true ? <Loader /> : null}
      <div className="searchBoxProduct">
        <Button
          variant="outlined"
          onClick={() => {
            setSelectionRange({
              startDate: date1MonthBefore,
              endDate: new Date(),
              key: "selection",
            });
            setStatusFilterSelected(-1);
            setSearchText("");
          }}
        >
          Reset Filter
        </Button>
        <DataRangePopOver
          selectionRange={selectionRange}
          setSelectionRange={setSelectionRange}
          handleSelect={handleSelect}
        />
        <FormControl variant="outlined" className={classes.height24}>
          <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
          <Select
            native
            value={statusFilterSelected}
            onChange={(e) => setStatusFilterSelected(e.target.value)}
            label="Filter"
            inputProps={{
              name: "Filter",
              id: "outlined-age-native-simple",
            }}
            IconComponent={FilterListIcon}
            displayEmpty={true}
          >
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                clipRule="evenodd"
              />
            </svg>
            <optgroup label="Status">
              <option value={-1}>All</option>
              <option value={"success"}>Success</option>
              <option value={"pending"}>Pending</option>
              <option value={"failed"}>Failed</option>
              <option value={"completed"}>Completed</option>
            </optgroup>
          </Select>
        </FormControl>
        <Autocomplete
          id="search-box-product"
          options={searchSuggestions}
          disableClearable={true}
          clearOnBlur={false}
          getOptionLabel={(option) => {
            return option.name || "";
          }}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setPageNo(0);
              }}
              // onKeyDown={(e) => handleSearchBoxKeyDown(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              label="Search Transaction"
              variant="outlined"
              placeholder="Search"
            />
          )}
        />
      </div>
      {transactionHistory?.length !== 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" className={classes.root}>
              <RenderHeader
                columns={columns}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <TableBody style={{ width: "100%" }}>
                {transactionHistory?.map((row, index) => (
                  <Row
                    aria-label="expand row"
                    size="small"
                    key={row?.id}
                    row={row}
                    setSelectedRow={(value) => setSelectedRow(value)}
                    selectedRow={selectedRow}
                    data={transactionHistory[index]}
                    tableColumnWidth={tableColumnWidth}
                    maticValue={maticValue}
                    // setOpenEdit={setOpenEdit}
                    // setShowLoader={setShowLoader}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            // backIconButtonProps={{ disabled: false }}
            component="div"
            count={totalCount}
            rowsPerPage={10}
            page={pageNo}
            onChangePage={handleChangePage}
            // onChange={(e) => handleChangePage(e)}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => (
              <>
                <span className="paginationLabel">Showing </span>
                {`${from}-${to}`}
                <span className="paginationLabel"> out of </span>
                {`${count}`}
              </>
            )}
          />
        </>
      ) : (
        <div className="noResultFound flexColumnCenter mt-4">
          <NoRecordsFound message="No records found." />
        </div>
      )}
    </div>
  );
}
