import React from "react";

import './NoRecordsFound.css'
import NoRecordsImg from "../../../assets/images/error/not-found.svg";

export default function NoRecordsFound({ message }) {
  return (
    <>
      <div className="noImgFoundWrapper flexColumnCenter">
        <img src={NoRecordsImg} alt="noRecords" />
        <h2>{message}</h2>
      </div>
    </>
  );
}
