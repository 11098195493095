import React, { Fragment, useState, useEffect } from "react";
import './analytics.css';
import Breadcrumb from '../../layout/breadcrumb';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import Chart from "react-apexcharts";
import PopoverPopupState from "../Orders/components/dateRanagePopOver";
import { Link, useHistory } from 'react-router-dom';
import { axiosInstance } from '../../utility/api';

import API from '../../APIActions/api'
import Swal from "sweetalert2";
import { formatAPIError } from "../../utility/helper";

const Analytics = (props) => {

  const history = useHistory();

  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const day = today.getDate();
  const remainingDays = today.getDay();
  const date1MonthBefore = new Date(year, month - 1, day);
  const weekBefore = new Date(year, month, day - 7);
  const weekToDate = new Date(year, month, day - remainingDays);
  const monthToDate = new Date(year, month, day - (day - 2));

  const [options, setOptions] = React.useState(
    {
      chart: {
        id: "basic-bar",
      },
      markers: {
        size: 5,
      },
      stroke: {
        curve: 'straight',
      },
      xaxis: {
        categories: []
      }
    },
  )

  const [series, setSeries] = useState(
    [
      {
        name: "series",
        data: []
      }
    ]
  )

  const [assetOptions, setAssetOptions] = React.useState(
    {
      chart: {
        id: "basic-bar",
      },
      markers: {
        size: 5,
      },
      stroke: {
        curve: 'straight',
      },
      xaxis: {
        categories: []
      }
    },
  )

  const [assetSeries, setAssetSeries] = useState(
    [
      {
        name: "series",
        data: []
      }
    ]
  )

  const [userOptions, setUserOptions] = React.useState(
    {
      chart: {
        id: "basic-bar",
      },
      markers: {
        size: 5,
      },
      stroke: {
        curve: 'straight',
      },
      xaxis: {
        categories: []
      }
    },
  )

  const [userSeries, setUserSeries] = useState(
    [
      {
        name: "series",
        data: []
      }
    ]
  )

  const [visitorOptions, setVisitorOptions] = React.useState(
    {
      chart: {
        id: "basic-bar",
      },
      markers: {
        size: 5,
      },
      stroke: {
        curve: 'straight',
      },
      xaxis: {
        categories: []
      }
    },
  )

  const [visitorSeries, setVisitorSeries] = useState(
    [
      {
        name: "series",
        data: []
      }
    ]
  )

  const [dates, setDates] = useState({ fromDate: weekBefore, toDate: today, period: "day" });
  const [totalSold, setTotalSold] = useState("");
  const [amt, setAmt] = useState("");
  const [totalUsers, setTotalUsers] = useState("");
  const [totalVisitors, setTotalVisitors] = useState("");
  const [chartType, setChartType] = useState("line");
  const [chartWidth, setChartWidth] = useState("700");

  const token = JSON.parse(localStorage.getItem("token"));
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getVisitorData = async () => {
    try {
      const visitorResult = await axiosInstance
        .get(`/v1/admin/dashboard/analytics/vuser?`, { ...header, params: { ...dates } })
      console.log('visitorResult', visitorResult)
      const userData = visitorResult.data?.result?.users;
      setTotalVisitors(visitorResult.data?.result?.totalUsers || '0');
      const time = userData.map(item => item.period + " " + item.month.substring(0, 3)).reverse();
      const data = userData.map(item => Number(item.visitinguser).toFixed(5)).reverse();
      setVisitorOptions({ ...options, xaxis: { categories: time } });
      setVisitorSeries([{ name: "series", data }]);
    }
    catch (err) {
      let errMsg = formatAPIError(err)
      Swal.fire("Alert!", errMsg, "error").then(() => {
        if (err?.response?.status === 401 && err?.response?.data?.message?.includes("authenticated")) {
          console.log('err.response', err.response)
          window.location.href = "/welcome/:layout"
        }
      })
    }

  }

  const getUserData = async () => {
    const userResult = await axiosInstance
      .get(`/v1/admin/dashboard/analytics/user?`, { ...header, params: { ...dates } })
    const userData = userResult.data?.result?.users;
    setTotalUsers(userResult.data?.result?.totalUsers || '0');
    const time = userData.map(item => item.period + " " + item.month.substring(0, 3)).reverse();
    const data = userData.map(item => Number(item.user).toFixed(5)).reverse();
    setUserOptions({ ...options, xaxis: { categories: time } });
    setUserSeries([{ name: "series", data }]);
  }

  const getOrderData = async () => {
    const result = await axiosInstance
      .get(`/v1/admin/dashboard/analytics/order`, { ...header, params: { ...dates } })
    const mapdata = result.data?.result?.orders;
    setTotalSold(result.data?.result?.totalOrders || '0');
    const time = mapdata.map(item => item.period + " " + item.month.substring(0, 3)).reverse();
    const data = mapdata.map(item => item.order).reverse();
    setOptions({ ...options, xaxis: { categories: time } });
    setSeries([{ name: "series", data }]);
  }

  const getAssetData = async () => {
    const ordersStatus = "success" // take data only from success orders jira task: https://veritic.atlassian.net/browse/VER-841?atlOrigin=eyJpIjoiNWQ3YzI0YzBkNWIyNGYyZGI2OTY2ZGRjNmY2OWE2MDEiLCJwIjoiaiJ9
    const assetResult = await axiosInstance
      .get(`/v1/admin/dashboard/analytics/asset?`, { ...header, params: { ...dates, status: ordersStatus } })
    const assetData = assetResult.data?.result?.orders;
    setAmt(assetResult.data?.result?.totalSales || '0');
    const time = assetData.map(item => item.period + " " + item.month.substring(0, 3)).reverse();
    const data = assetData.map(item => item.totalSale.toFixed(5)).reverse();
    setAssetOptions({ ...options, xaxis: { categories: time } });
    setAssetSeries([{ name: "series", data }]);
  }

  React.useEffect(() => {
    getVisitorData()
    getUserData()
    getOrderData();
    getAssetData();
  }, [dates])

  const handleRange = (e) => {
    const value = e.target.value;
    if (value === "lastWeek") {
      setDates({ ...dates, fromDate: weekBefore });
    }
    if (value === "lastMonth") {
      setDates({ ...dates, fromDate: date1MonthBefore });
    }
    if (value === "weekly") {
      setDates({ ...dates, fromDate: weekToDate });
    }
    if (value === "monthly") {
      setDates({ ...dates, fromDate: monthToDate });
    }
  }

  const downloadVisitorData = () => {
    API.downloadVisitorAnalytics()
      .then(res => {
        if (res?.data) {
          downloadFile(res?.data, 'visitor_analytics')
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const downloadUserData = () => {
    API.downloadUserAnalytics()
      .then(res => {
        if (res?.data) {
          downloadFile(res?.data, 'user_analytics')
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const downloadOrderData = () => {
    API.downloadOrderAnalytics()
      .then(res => {
        if (res?.data) {
          downloadFile(res?.data, 'order_analytics')
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const downloadProductData = () => {

  }

  const downloadFile = (data, fileName) => {
    const csv = data
    var fileDownloadBtn = document.createElement('a');
    fileDownloadBtn.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    fileDownloadBtn.download = `${fileName}.csv`;
    fileDownloadBtn.click();
  }

  return (
    <div>
      <Breadcrumb parent="Dashboard" title="analytics" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  Performance
                </h5>
                <div style={{ width: "100%", margin: "auto", marginTop: 30, fontSize: 22 }} >
                  {/* <PopoverPopupState
                    selectionRange={selectionRange}
                    handleSelect={handleSelect}
                  /> */}
                  <span style={{ fontSize: 23, marginRight: 20 }}> Select Range</span>
                  <select onChange={handleRange}>
                    <option value="lastWeek"> Last Week </option>
                    <option value="lastMonth"> Last Month </option>
                    <option value="monthly"> Month to Date </option>
                    <option value="weekly"> Week to Date </option>
                  </select>
                </div>
              </CardHeader>
              <CardBody>
                <div className="catagories">
                  {/* <div className="single-detail"
                    onClick={() => history.push(`${process.env.PUBLIC_URL}/analytics/dashboard/totalAssets/:layout`)}>
                    <p>Product Analytics</p>
                    <p>Total Items Sold: {totalSold}</p>
                  </div> */}
                  <div className="single-detail"
                    onClick={() => history.push(`${process.env.PUBLIC_URL}/analytics/dashboard/totalSiteVisitors/:layout`)}>
                    <p>Site Visitor Analytics</p>
                    <p>Total Site Visitor: {totalVisitors || 0}</p>
                  </div>
                  <div className="single-detail"
                    onClick={() => history.push(`${process.env.PUBLIC_URL}/analytics/dashboard/totalUsers/:layout`)}>
                    <p>Registered Customer Analytics</p>
                    <p>Total Users : {totalUsers || 0}</p>
                  </div>
                  <div className="single-detail"
                    onClick={() => history.push(`${process.env.PUBLIC_URL}/analytics/dashboard/totalOrders/:layout`)}>
                    <p>Purchase Order Analytics</p>
                    <p>Total Sales : {amt || 0}</p>
                  </div>
                </div>

                <div className="chart-container">
                  {/* <div className="single-chart">
                    <div className="chart-header">
                      <h4> Product Analytics</h4>
                      <div>
                        <a onClick={downloadProductData}>Download</a>
                      </div>
                    </div>
                    <Chart options={options} series={series} type={chartType} width={chartWidth} />
                  </div> */}
                  <div className="single-chart">
                    <div className="chart-header">
                      <h4>Site Visitor Analytics</h4>
                      <div>
                        <a onClick={downloadVisitorData}>Download</a>
                      </div>
                    </div>
                    <Chart options={visitorOptions} series={visitorSeries} type={chartType} width={chartWidth} />
                  </div>
                  <div className="single-chart">
                    <div className="chart-header">
                      <h4>Registered Customer Analytics</h4>
                      <div>
                        <a onClick={downloadUserData}>Download</a>
                      </div>
                    </div>
                    <Chart options={userOptions} series={userSeries} type={chartType} width={chartWidth}
                    />
                  </div>
                  <div className="single-chart">
                    <div className="chart-header">
                      <h4>Purchase Order Analytics</h4>
                      <div>
                        <a onClick={downloadOrderData}>Download</a>
                      </div>
                    </div>
                    <Chart options={assetOptions} series={assetSeries} type={chartType} width={chartWidth} />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Analytics;
