import React, { useEffect } from "react";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import FilledInput from "@material-ui/core/FilledInput";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ChangePassword.module.scss";
import { axiosInstance } from "../../../utility/api";
import Swal from "sweetalert2";
import { history } from "../../../utility/history";
import { SAVE_TOKEN } from "../../../redux/actionTypes";
import { formatAPIError } from "../../../utility/helper";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const [values, setValues] = React.useState({
    oldPassword: "",
    newPassword: "",
    retypePassword: "",
    showOldPassword: false,
    showNewPassword: false,
    showConfirmNewPassword: false,
  });
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };

  const adminId = useSelector((state) => state.adminReducer.adminId);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleClickShowPassword = (passwordType) => {
    let x = {
      [passwordType]: !values[passwordType],
    };
    setValues({ ...values, ...x });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const passwordChangeHandler = () => {
    setIsSubmitted(true);
    if (
      values.oldPassword &&
      values.newPassword &&
      values.retypePassword &&
      values.newPassword === values.retypePassword &&
      values.oldPassword !== values.newPassword
    ) {
      axiosInstance
        .patch(`/v1/admin/auth/changeAdminPassword`, values, config)
        .then((res) => {
          Swal.fire("Success!", res?.data?.message, "success");
          setIsSubmitted(false);
          setValues({
            ...values,
            oldPassword: "",
            newPassword: "",
            retypePassword: "",
          });
          logout();
        })
        .catch((err) => {
          let errMsg = formatAPIError(err);
          Swal.fire("Alert!", errMsg, "error");
        });
    }
  };
  const logout = () => {
    axiosInstance
      .get(`/v1/admin/auth/logout`, config)
      .then((result) => {
        console.log("logout");
        dispatch({
          type: SAVE_TOKEN,
          payload: null,
        });
        localStorage.removeItem("token");
        window.location.href = `${process.env.PUBLIC_URL}/sign-in`;
      })
      .catch((err) => {
        console.log("logout error", err);
      });
  };

  return (
    <div style={{ marginTop: "26px" }}>
      <div className={styles.changePassword_container}>
        <h3 className={styles.title}>Change Password</h3>
        <div className={styles.change_password_form_wrap}>
          <FormControl
            variant="outlined"
            className={styles.form_control}
            style={{ width: "33%", marginRight: "20px" }}
          >
            <InputLabel
              htmlFor="oldP"
              style={{ backgroundColor: "white" }}
              required
            >
              Current Password
            </InputLabel>
            <OutlinedInput
              id="oldP"
              type={values.showOldPassword ? "text" : "password"}
              value={values.oldPassword}
              name="oldPassword"
              onChange={handleChange}
              onPaste={(e) => {
                e.preventDefault();
                return;
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("showOldPassword")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showOldPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
            {isSubmitted && values.oldPassword.length === 0 ? (
              <FormHelperText error>Enter Current Password</FormHelperText>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl
            variant="outlined"
            className={styles.form_control}
            style={{ width: "33%", marginRight: "20px" }}
          >
            <InputLabel
              htmlFor="newP"
              style={{ backgroundColor: "white" }}
              required
            >
              New Password
            </InputLabel>
            <OutlinedInput
              id="newP"
              type={values.showNewPassword ? "text" : "password"}
              value={values.newPassword}
              name="newPassword"
              onChange={handleChange}
              onPaste={(e) => {
                e.preventDefault();
                return;
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("showNewPassword")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showNewPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
            {isSubmitted ? (
              values.newPassword.length === 0 ? (
                <FormHelperText error>Enter New Password</FormHelperText>
              ) : values.newPassword === values.oldPassword &&
                values.newPassword.length !== 0 ? (
                <FormHelperText error>
                  Current and new password cannot be same
                </FormHelperText>
              ) : null
            ) : null}
          </FormControl>
          <FormControl
            variant="outlined"
            className={styles.form_control}
            style={{ width: "33%" }}
          >
            <InputLabel
              htmlFor="cNewP"
              style={{ backgroundColor: "white" }}
              required
            >
              Confirm New Password
            </InputLabel>
            <OutlinedInput
              id="cNewP"
              onPaste={(e) => {
                e.preventDefault();
                return;
              }}
              type={values.showConfirmNewPassword ? "text" : "password"}
              value={values.retypePassword}
              name="retypePassword"
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      handleClickShowPassword("showConfirmNewPassword")
                    }
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showConfirmNewPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
            {isSubmitted ? (
              values.retypePassword.length === 0 ? (
                <FormHelperText error>Enter Confirm Password</FormHelperText>
              ) : values.newPassword !== values.retypePassword ? (
                <FormHelperText error>Password doesn't match</FormHelperText>
              ) : null
            ) : null}
          </FormControl>
        </div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <button className="btn btn-primary" onClick={passwordChangeHandler}>
            Change Password
          </button>{" "}
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
