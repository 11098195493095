import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SimpleReactValidator from "simple-react-validator"
import Web3 from 'web3';
import ImageUpload from "../../../assets/images/other-images/imageUpload.svg";
import PlusCirle from '../../../assets/images/icons/plus-circle.svg'
import DeleteSvg from '../../../assets/images/icons/delete.svg'
import { Tooltip } from "@material-ui/core";
import Swal from "sweetalert2";
import { validateFacebookUrl, validateInstagramUrl, validateTwitterUrl } from "../../../utility/helper";

const useStyles = makeStyles({
  radioGroupRoot: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },

  formControlRoot: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
});

export default function AddInstitution(props) {
  const {
    style,
    state,
    handleChange,
    handleSubmitForm,
    uploadFile,
    handleSocialChange,
    handleImageUpload,
    isEdit,
    addNewInput,
    removeInput,
  } = props;

  const [forceUpdate, setForceUpdate] = useState(0);
  const [validator] = useState(useRef(new SimpleReactValidator({
    element: (message, className) => <p className="field_error">{message}</p>,
    validators: {
      walletAddress: {  // name the rule
        message: 'The :attribute must be a valid wallet address.',
        rule: (val) => {
          return Web3.utils.isAddress(val)
        },
        messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
        required: true  // optional
      },
      facebook: {
        message: 'The :attribute must be a valid facebook url.',
        rule: (val) => {
          return validateFacebookUrl(val)
        },
      },
      instagram: {
        message: 'The :attribute must be a valid instagram url.',
        rule: (val) => {
          return validateInstagramUrl(val)
        },
      },
      twitter: {
        message: 'The :attribute must be a valid twitter url.',
        rule: (val) => {
          return validateTwitterUrl(val)
        },
      }
    }
  }
  )))

  const handleSubmit = (e) => {
    e.preventDefault();
    const result = validator.current.allValid()
    if (result) {
      handleSubmitForm(e)
    } else {
      validator.current.showMessages()
      setForceUpdate(forceUpdate + 1)
    }
  }

  useEffect(() => {
    setForceUpdate(forceUpdate + 1)
  }, [state.socialMediaLinks])

  return (
    <>
      <div className="container tm-mt-big tm-mb-big">
        <div className="row">
          <div className="col-sm-12 mx-auto py-4 px-5">
            <div className="tm-bg-primary-dark tm-block tm-block-h-auto">
              <div className="row">
                <div className="col-12">
                  <h2 className="tm-block-title d-inline-block">
                    {isEdit ? "Edit" : "Add"} Institution
                  </h2>
                </div>
              </div>
              <div className="row tm-edit-product-row">
                <div className="col-xl-12 col-lg-6 col-md-12">
                  <form action="" className="tm-edit-product-form">
                    <div className="form-group mb-3">
                      <label for="name">Institution Name<span className="colorRed">*</span></label>
                      <input type="text" name="name" className="form-control validate"
                        defaultValue={state.name}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        onChange={handleChange} />
                      {validator.current.message('institutionName', state.name, 'required|alpha_num_dash_space')}
                    </div>
                    {/* <div className="form-group mb-3">
                      <label for="walletAddress">Wallet Address<span className="colorRed">*</span></label>
                      <input type="text" name="walletAddress" className="form-control validate"
                        defaultValue={state.walletAddress}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        onChange={handleChange} />
                      {validator.current.message('walletAddress', state.walletAddress, 'required|alpha_num|min:42|max:42|walletAddress')}
                    </div> */}
                    <div className="form-group mb-3">
                      <label for="InstitutionDescription">Institution Description<span className="colorRed">*</span></label>
                      <textarea class="form-control validate" rows="3" spellcheck="false" name="InstitutionDescription"
                        value={state.InstitutionDescription}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        onChange={(e) => handleChange(e)} />
                        <div className="decsriptionValidation">
                          {validator.current.message('Description', state.InstitutionDescription, 'required')}
                        </div>
                    </div>
                    <div className="form-group mb-3">
                      <div className="d-flex-aic-jcsb">
                        <label for="walletAddress">Social Media Link </label>
                        <div>
                          {state.socialMediaLinks.length !== 3
                            ? <Tooltip title="Add Social Media Link">
                              <a className="enum-icons" onClick={() => addNewInput()}>
                                <img src={PlusCirle} alt="PlusCirle" />
                              </a>
                            </Tooltip>
                            : null}
                        </div>
                      </div>
                      {state.socialMediaLinks.map((el, index) => {
                        return (
                          <div key={index} className="mb-10">
                            <div className="d-flex-aic">
                              <input type="text"
                                name={el?.type}
                                className="form-control validate"
                                defaultValue={el?.link}
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                placeholder={`Enter ${el?.type} link`}
                                onChange={handleSocialChange} />
                              {state.socialMediaLinks?.length !== 1
                                ? <Tooltip title="Delete">
                                  <a className="enum-icons" onClick={() => removeInput(el?.type)}>
                                    <img src={DeleteSvg} alt="DeleteSvg" />
                                  </a>
                                </Tooltip>
                                : null}
                            </div>
                            {validator.current.message(`${el?.type}` + ' link', state?.socialMediaLinks?.[index]?.link, `url|${el?.type}`)}
                          </div>
                        )
                      })}
                      {/* {validator.current.message('socialMediaLinks', state.socialMediaLinks, 'required|url')} */}
                    </div>
                    <div className="form-group mb-3">
                      <label for="walletAddress">Website Link<span className="colorRed">*</span></label>
                      <input type="text" name="websiteLink" className="form-control validate"
                        defaultValue={state.websiteLink}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        onChange={handleChange} />
                      {validator.current.message('websiteLink', state.websiteLink, 'required|url')}
                    </div>
                    <div className="form-group mb-3">
                      <div className="uploadPic">
                        {state?.logoUrl
                          ? <div className="profileImg" onClick={(e) => handleImageUpload(e, 'logoUrl')}
                            style={{ backgroundImage: `url(${state?.logoUrl})` }}>
                          </div>
                          : <img className="placeholderImg" onClick={(e) => handleImageUpload(e, 'logoUrl')} src={ImageUpload} alt="placeholderImg" />}
                        <input type="file" id="logoUrl" name="logoUrl" style={{ display: "none" }} accept="image/*"
                          onChange={uploadFile}
                        />
                        <button onClick={(e) => handleImageUpload(e, 'logoUrl')} className={"button-small"}>
                          Upload Institution Logo
                        </button>
                      </div>
                      <span className="mt-1 d-block">Supported File formats: (jpg, png)<span className="colorRed">*</span></span>
                      {validator.current.message('InstitutionLogo', state.logoUrl, 'required')}
                    </div>
                    <div className="form-group mb-3">
                      <div className="uploadPic">
                        {state?.bannerImage
                          ? <div className="profileImg" onClick={(e) => handleImageUpload(e, 'bannerImage')}
                            style={{ backgroundImage: `url(${state?.bannerImage})` }}>
                          </div>
                          : <img className="placeholderImg" onClick={(e) => handleImageUpload(e, 'bannerImage')} src={ImageUpload} alt="placeholderImg" />}
                        <input type="file" id="bannerImage" name="bannerImage" style={{ display: "none" }} accept="image/*"
                          onChange={uploadFile}
                        />
                        <button onClick={(e) => handleImageUpload(e, 'bannerImage')} className={"button-small"}>
                          Upload Banner Image
                        </button>
                      </div>
                      <span className="mt-1 d-block">Supported File formats: (jpg, png)<span className="colorRed">*</span></span>
                      {validator.current.message('bannerImage', state.bannerImage, 'required')}
                    </div>
                    <div className="form-group mb-3">
                      <label for="category">
                        Youtube embed video link (optional) (example: https://www.youtube.com/embed/tgbNymZ7vqY) {" "}
                      </label>
                      <input
                        type="text"
                        name="youtubeEmbedVideoLink"
                        className="form-control validate"
                        value={state?.youtubeEmbedVideoLink}
                        onChange={handleChange}
                      />

                      {state?.youtubeEmbedVideoLink && <iframe width="420" height="315" style={{ marginTop: "20px" }}
                        src={state?.youtubeEmbedVideoLink}>
                      </iframe>}
                    </div>
                  </form>
                </div>
                {state.error.status == true ? (
                  <div className="errorLabel col-6 col-md-12">
                    {state?.error?.message}
                  </div>
                ) : null}
                <div className="col-12">
                  <button className="btn btn-primary btn-block text-uppercase" onClick={handleSubmit}>
                    Save Institution now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
