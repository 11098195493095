import React from "react";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";
import { axiosInstance } from "../../utility/api";

const PrivacyPolicy = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };
  const [title, setTitle] = React.useState("");
  const [content, setContent] = React.useState("");
  React.useEffect(() => {
    axiosInstance
      .get(`/v1/admin/webcontent?key=privacy_policy`, config)
      .then((res) => {
        setContent(res?.data?.result[0]?.content);
        setTitle(res?.data?.result[0]?.name);
      })
      .catch((err) => {
        //console.log("error", err);
      });
  }, []);
  const contentHandler = (event) => {
    setContent(event);
  };
  const titleHandler = (event) => {
    setTitle(event.target.value);
  };
  const saveContent = () => {
    axiosInstance
      .post(
        `/v1/admin/webcontent/create`,
        { content: content, name: title,key:'privacy_policy' },
        config
      )
      .then((res) => {
        Swal.fire("Success","Content Updated");
      });
  };

  return (
    <div className="dynamic-page-container">
      <h3 className="heading">Privacy Policy</h3>
      <div className="section-title-wrap">
        <h5 className="sub-heading">Title</h5>
        <input
          className="title-field w100"
          value={title}
          onChange={titleHandler}
        />
      </div>
      <div className="section-content-wrap">
        <h5 className="sub-heading">Content</h5>
        <ReactQuill value={content} onChange={contentHandler}></ReactQuill>
      </div>
      <div className="save-btn-wrap">
        <button onClick={saveContent} className="btn btn-primary">
          Save
        </button>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
