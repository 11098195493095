import {
  Box, BookOpen, Anchor, User, Headphones, Send, Users, Activity, ShoppingBag,
  Globe, Database, Layers, ArrowRight, HelpCircle
} from "react-feather";
import DynamicPageIcons from "./DynamicPageIcons";

export const MENUITEMS = [
  // {
  //     menutitle:"General",
  //     menucontent:"Dashboards,Widgets",
  //     Items:[
  //         {
  //             title: 'Dashboard', icon: Home, type: 'sub', active: false, children: [
  //                 { path: `${process.env.PUBLIC_URL}/dashboard/default`, title: 'Default', type: 'link' },
  //                 { path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`, title: 'Ecommerce', type: 'link' },
  //             ]
  //         }
  //     ]

  // },
  {
    Items: [
      {
        title: "Assets",
        icon: 'Box',
        type: "sub",
        active: false,
        path: `${process.env.PUBLIC_URL}/products/products`,
        type: "link",
      },
      {
        title: "Artist",
        icon: 'User',
        type: "sub",
        active: false,
        path: `${process.env.PUBLIC_URL}/artists`,
        type: "link",
      },
      {
        title: "Collection",
        icon: 'Layers',
        type: "sub",
        active: false,
        path: `${process.env.PUBLIC_URL}/collection`,
        type: "link",
      },
      {
        title: "Institutions",
        icon: 'BookOpen',
        type: "sub",
        active: false,
        path: `${process.env.PUBLIC_URL}/institutions`,
        type: "link",
      },
      {
        title: "Users",
        icon: 'Users',
        type: "sub",
        active: false,
        path: `${process.env.PUBLIC_URL}/users/users`,
        type: "link",
      },
      {
        title: "Releases",
        icon: 'Releases',
        type: "sub",
        active: false,
        path: `${process.env.PUBLIC_URL}/releases`,
        type: "link",
      },
      // {
      //   title: "Creators",
      //   icon: 'PenTool',
      //   type: "sub",
      //   active: false,
      //   path: `${process.env.PUBLIC_URL}/creator`,
      //   type: "link",
      // },
      {
        title: "Auction",
        path: `${process.env.PUBLIC_URL}/auction/auction`,
        icon: 'Activity',
        type: "sub",
        active: false,
        type: "link",
      },
      // {
      //   title: "News",
      //   path: `${process.env.PUBLIC_URL}/product/news`,
      //   icon: 'Send',
      //   type: "sub",
      //   active: false,
      //   type: "link",
      // },
      {
        title: "Orders",
        path: `${process.env.PUBLIC_URL}/orders/orders`,
        icon: 'ShoppingBag',
        type: "sub",
        active: false,
        type: "link",
      },
      {
        title: "Transaction History",
        path: `${process.env.PUBLIC_URL}/orders/transactionhistory`,
        icon: 'Database',
        type: "sub",
        active: false,
        type: "link",
      },
      // {
      //   title: "Air Drops",
      //   path: `${process.env.PUBLIC_URL}/air-drops`,
      //   icon: 'Layers',
      //   type: "sub",
      //   active: false,
      //   type: "link",
      // },
      {
        title: "Sub Admin",
        path: `${process.env.PUBLIC_URL}/manage-subadmin/subadmin`,
        icon: 'User',
        type: "link",
        active: false,
        permission: {
          view: true,
          add: true,
          edit: true,
          delete: true
        }
      },
      {
        title: "Commission",
        path: `${process.env.PUBLIC_URL}/commission/commission`,
        icon: 'Globe',
        type: "sub",
        active: false,
        type: "link",
      },
      {
        title: "Analytic Dashboard",
        path: `${process.env.PUBLIC_URL}/analytics/dashboard`,
        icon: 'Anchor',
        type: "sub",
        active: false,
        type: "link",
      },
      {
        title: "Customer Queries",
        path: `${process.env.PUBLIC_URL}/user-queries`,
        icon: 'HelpCircle',
        type: "sub",
        active: false,
        type: "link",
      },
      // {
      //   title:'Dynamic Pages',
      //   type:'sub',
      //   menucontent: "",
      //   icon:'DynamicPageIcons',
      //       children:[
      //         {
      //           title: "About Us",
      //           path: `${process.env.PUBLIC_URL}/dynamic-page/about-us`,
      //           icon: 'ArrowRight',
      //           type: "link",
      //         },
      //         {
      //           title: "Terms and Conditions",
      //           path: `${process.env.PUBLIC_URL}/dynamic-page/terms-condition`,
      //           icon: 'ArrowRight',
      //           type: "link",
      //         },
      //         {
      //           title: "Privacy Policy",
      //           path: `${process.env.PUBLIC_URL}/dynamic-page/privacy-policy`,
      //           icon: 'ArrowRight',
      //           type: "link",
      //         },
      //       ]
      // },
      // {
      //   title: "Landing Page",
      //   path: `${process.env.PUBLIC_URL}/dynamic-page/landing-page`,
      //   icon: 'ArrowRight',
      //   type: "sub",
      //   active: false,
      //   type: "link",
      // },


    ],
  }

  // {
  //   menutitle: "Assets",
  //   menucontent: "Assets ,Category",
  //   Items: [
  //     {
  //       title: "Assets",
  //       icon: Home,
  //       type: "sub",
  //       active: false,
  //       children: [
  //         {
  //           path: `${process.env.PUBLIC_URL}/products/products`,
  //           title: "Assets",
  //           type: "link",
  //         },
  //         {
  //           path: `${process.env.PUBLIC_URL}/products/categories`,
  //           title: "Categories",
  //           type: "link",
  //         },
  //         {
  //           path: `${process.env.PUBLIC_URL}/users/users`,
  //           title: "Users",
  //           type: "link",
  //         },
  //         {
  //           path: `${process.env.PUBLIC_URL}/auction/auction`,
  //           title: "Auction",
  //           type: "link",
  //         },
  //         // {
  //         //   path: `${process.env.PUBLIC_URL}/auction/auction`,
  //         //   title: "Auction",
  //         //   type: "link",
  //         // },
  //         {
  //           path: `${process.env.PUBLIC_URL}/product/news`,
  //           title: "News",
  //           type: "link",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   menutitle: "Order",
  //   menucontent: "Manage Orders",
  //   Items: [
  //     {
  //       title: "Orders",
  //       icon: 'Anchor',
  //       type: "sub",
  //       active: false,
  //       children: [
  //         {
  //           path: `${process.env.PUBLIC_URL}/orders/orders`,
  //           title: "Orders",
  //           type: "link",
  //         },
  //         {
  //           path: `${process.env.PUBLIC_URL}/orders/transactionhistory`,
  //           title: "Transaction History",
  //           type: "link",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   menutitle: "Commission",
  //   menucontent: "",
  //   Items: [
  //     {
  //       title: "Commission",
  //       icon: 'Anchor',
  //       type: "sub",
  //       active: false,
  //       children: [
  //         {
  //           path: `${process.env.PUBLIC_URL}/commission/commission`,
  //           title: "Commission",
  //           type: "link",
  //         },
  //         // {
  //         //   path: `${process.env.PUBLIC_URL}/products/categories`,
  //         //   title: "Categories",
  //         //   type: "link",
  //         // },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   menutitle: "",
  //   menucontent: "",
  //   Items: [
  //     {
  //       title: "Dashboard",
  //       icon: 'Anchor',
  //       type: "sub",
  //       active: false,
  //       children: [
  //         {
  //           path: `${process.env.PUBLIC_URL}/analytics/dashboard`,
  //           title: "Analytic Dashboard",
  //           type: "link",
  //         },
  //         // {
  //         //   path: `${process.env.PUBLIC_URL}/products/categories`,
  //         //   title: "Categories",
  //         //   type: "link",
  //         // },
  //       ],
  //     },
  //   ],
  // },
];
