import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import SimpleReactValidator from "simple-react-validator"

import { axiosInstance } from "../../utility/api";
import "./EditUserForm.css";

import API from '../../APIActions/api'
import { formatAPIError } from "../../utility/helper";

export default function EditUserForm({ selectedRow }) {
  const [status, setStatus] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [userName, setUserName] = useState("");
  const statusList = ["verified", "unverified"];

  const [, forceUpdateForForm] = useState();
  const [validator] = useState(useRef(new SimpleReactValidator({
    element: (message, className) => <p className="field_error">{message}</p>}
  )))

  useEffect(() => {
    if (selectedRow) {
      setStatus(selectedRow?.status);
      setFirstName(selectedRow?.firstName);
      setLastName(selectedRow?.lastName);
      setUserName(selectedRow?.userName);
    }
  }, [selectedRow]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const result = validator.current.allValid()
    if(result) {
      handleSubmitForm(e)
    } else {
      validator.current.showMessages()
      forceUpdateForForm(1)
    }
  }

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    // this.setState({
    //   error: { status: false, message: "" },
    // });

    // this.setShowLoader(true, "Please Wait.");
    if(!firstName || !lastName || !userName){
      Swal.fire("Oops!", "Please enter the mandatory fields", "error");
      return
    }
    let payload = {
      firstName: firstName,
      lastName: lastName,
      userName: userName,
      status: status,
    };
    API.editUser(selectedRow?.id, payload)
      .then((res) => {
        //console.log("posted");
        // this.setState({
        //   ...this.state,
        //   showLoader: false,
        // });
        // this.setShowLoader(false, "");
        // this.props.onClose();
        Swal.fire("Success!", res?.data?.message, "success");
        window.location.reload(true)
      })
      .catch((err) => {
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error");
        // this.setShowLoader(false, "");
      });
  };

  const handleChange = (event) => {
    switch (event.target.name) {
      case "firstName":
        setFirstName(event.target.value);
        break;

      case "lastName":
        setLastName(event.target.value);
        break;
      case "userName":
        setUserName(event.target.value);
        break;
      case "status":
        setStatus(event.target.value);
        break;

      // case "userName":
      //   setUserName(event.target.value);
      //   break;￼

      // case "status":
      //   setStatus(event.target.value);
      //   break;
    }
  };

  return (
    <div className="py-4 px-5 mx-auto">
      <div className="row">
        <div className="col-sm-12">
          <h2 className="tm-block-title d-inline-block">Edit Users</h2>
        </div>
        {/* <div className="row tm-edit-product-row"> */}
        <div className="col-md-12">
          <form action="" className="">
            <div className="form-group">
              <label for="name">First Name</label>
              <span className="colorRed">*</span>
              <input
                type="text"
                name="firstName"
                className="form-control validate"
                value={firstName}
                onChange={(e) => handleChange(e)}
                required
              />
              {validator.current.message('firstName', firstName, 'required|alpha')}
            </div>
            <div className="form-group mb-12">
              <label for="name">Last Name</label>
              <span className="colorRed">*</span>
              <input
                type="text"
                name="lastName"
                className="form-control validate"
                value={lastName}
                onChange={(e) => handleChange(e)}
                required
              />
              {validator.current.message('lastName', lastName, 'required|alpha')}
            </div>
            <div className="form-group mb-12">
              <label for="name">User Name</label>
              <span className="colorRed">*</span>
              <input
                type="text"
                name="userName"
                className="form-control validate"
                value={userName}
                onChange={(e) => handleChange(e)}
                required
              />
              {validator.current.message('userName', userName, 'required')}
            </div>
            {/* <div className="form-group mb-12">
              <label for="name">Status</label>
              <select
                className="custom-select tm-select-accounts"
                name="status"
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                required
              >
                <option value="" selected>
                  Select
                </option>
                {statusList?.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div> */}
            <div className="form-group mb-12">
              <button
                type="submit"
                className="btn btn-primary btn-block text-uppercase"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
