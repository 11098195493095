import { combineReducers } from "redux";
import Customizer from "./customizer/reducer";
import adminReducer from "./customizer/adminReducer";
import appReducer from "./appReducer";
const reducers = combineReducers({
  Customizer,
  adminReducer,
  appReducer,
});

export default reducers;
