import React, { useEffect, useState } from "react";

import API from '../../APIActions/api'

export default function AirDropsPage() {

    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
  
    const getIsEdit = () => {
      setIsEdit(true);
    };

    const getAirDropsData = () => {
        API.getAirDrops()
        .then(res => {
            console.log('getAirDropsData',res)
        })
        .catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        getAirDropsData()
    },[])

    return (
        <div>
            <div className="collectionContent">
                <div className="boleroTabsSection">
                    <div className={`UsersTabOption userTabSelected`}>Air Drops</div>
                </div>
                <div class="products-wrapper">
                    <div class="container-fluide">
                        <div class="page-title">
                            <div class="row">
                                <div class="col-6">
                                    <h3>Air Drops</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="products-content">
                        {/* <CollectionTable /> */}
                    </div>
                </div>
                {/* <UiDrawer
                    open={open}
                    onClose={() => setOpen(false)}
                    showLoader={showLoader}
                    setShowLoader={(e) => setShowLoader(e)}
                    isEdit={isEdit}
                /> */}
            </div>
        </div>
    )
}