/* eslint-disable react/prop-types */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../auth";
import Loader from "../../layout/loader";
import Header from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import Footer from "../../layout/footer";
import { ToastContainer } from "react-toastify";

 const ProtectedRoute = ({ Component: Component, role, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.isAuthenticated()) {
          return (
            <>
            <Loader />
                <div className="page-wrapper compact-wrapper" id="pageWrapper">
                  <Header />
                  <div className="page-body-wrapper sidebar-icon">
                    <Sidebar />
                    <div className="page-body">
                      <Component {...props} />
                    </div>
                    <Footer />
                    {/* <ThemeCustomize /> */}
                  </div>
                </div>
                <ToastContainer />
            </>
            );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;