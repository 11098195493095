import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { axiosInstance } from "../../utility/api";
import Auth from "../../utility/auth";
import { useDispatch, useSelector } from "react-redux";
import { SAVE_TOKEN } from "../../redux/actionTypes";
import TwoFactorAuth from "./TwoFactorScreen";
import ForgotPassword from "./ForgotPassword";
import Logo from "../../assets/images/logo/site-logo.svg";
import "./Login.css";
import axios from "axios";
import { capitalizeFirstLetter, validateEmail } from "../../utility/helper";

import API from '../../APIActions/api'

export default function Login() {
  let history = useHistory();
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const [loginStepInfo, setLoginStepInfo] = useState({
    qr: null,
    email: null,
  });
  const [showLoader, setShowLoader] = useState(false);
  const [step1Complete, setStep1Complete] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.adminReducer.userToken);

  useEffect(() => {
    if (userToken) {
      Auth.login(() => {
        history.push("/welcome/:layout");
      });
    }
  }, [userToken]);

  useEffect(() => {
    if (localStorage.getItem("token") == undefined) {
      localStorage.clear();
    }
  }, []);

  const handleChange = (e) => {
    if (e.target.name !== "email") {
      setLogin({
        ...login,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "email") {
      setLogin({
        ...login,
        [e.target.name]: e.target.value.toLowerCase(),
      });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let result = validateEmail(login?.email)
    if (login.email === "" && login.password === "") {
      Swal.fire("Alert!", "Please Enter Your Email & Password", "error");
    } else if (login.email !== "" && login.password === "") {
      Swal.fire("Alert!", "Please Enter Your Password", "error");
    } else if (login.email === "" && login.password !== "") {
      Swal.fire("Alert!", "Please Enter Your Email", "error");
    } else if (!result) {
      Swal.fire("Alert!", "Please Enter Valid Email", "error");
    } else if (login.email !== "" && login.password !== "") {
      setShowLoader(true);
      axiosInstance
        .post("/v1/admin/auth", login)
        .then((res) => {
          localStorage.clear();
          setShowLoader(false);
          if (res?.data?.result?.accessToken) {
            Swal.fire("Welcome to Patron of Art", res.data.message, "success");
            localStorage.setItem("token", JSON.stringify(res?.data?.result?.accessToken));
            API.initHeaders(res?.data?.result?.accessToken)
            dispatch({
              type: SAVE_TOKEN,
              payload: JSON.stringify(res?.data?.result?.accessToken),
            });
          } else if (res?.data?.result?.email) {
            setStep1Complete(true);
            setLoginStepInfo({
              qr: res?.data?.result?.qr,
              email: res?.data?.result?.email,
            });
          } else {
            setStep1Complete(false);
            Swal.fire("Error", "Error while login.", "error");
          }
        })
        .catch((err) =>
          Swal.fire("Alert", capitalizeFirstLetter(err.response.data.message), "error")
        );

      // if (res?.data?.result?.accessToken != null) {
      //   localStorage.setItem(
      //     "token",
      //     JSON.stringify(res?.data?.result?.accessToken)
      //   );
      //   dispatch({
      //     type: SAVE_TOKEN,
      //     payload: res?.data?.result?.accessToken,
      //   });
      // } else {
      //   Swal.fire("Error", "Error while login.", "error");
      // }
      // localStorage.setItem(
      //   "token",
      //   JSON.stringify(
      //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pblVzZXIiOnsiaWQiOjEsImVtYWlsIjoiYWRtaW5AZXhhbXBsZS5jb20iLCJyb2xlIjoiYWRtaW4ifSwiaWF0IjoxNjMzMDc3Njc1LCJleHAiOjE2NjQ2MzUyNzV9.EY08CeKf97zrwrN1w51KMmY8JMbT_H7hj-pbtjt4MIs"
      //   )
      // );
      // dispatch({
      //   type: SAVE_TOKEN,
      //   payload:
      //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pblVzZXIiOnsiaWQiOjEsImVtYWlsIjoiYWRtaW5AZXhhbXBsZS5jb20iLCJyb2xlIjoiYWRtaW4ifSwiaWF0IjoxNjMzMDc3Njc1LCJleHAiOjE2NjQ2MzUyNzV9.EY08CeKf97zrwrN1w51KMmY8JMbT_H7hj-pbtjt4MIs",
      // });
      // Auth.login(() => {
      //   history.push("/welcome/:layout");
      // });
      // axiosInstance
      //   .post("/v1/admin/auth", login)
      //   .then((res) => {
      //     console.log("qwe Show : ", res?.data?.result?.accessToken);
      //     setShowLoader(false);
      //     Swal.fire("Welcome!", res.data.message, "success");
      //     localStorage.setItem(
      //       "token",
      //       JSON.stringify(res?.data?.result?.accessToken)
      //     );
      //     dispatch({
      //       type: SAVE_TOKEN,
      //       payload: JSON.stringify(res?.data?.result?.accessToken),
      //     });
      //     Auth.login(() => {
      //       history.push("/welcome/:layout");
      //     });
      //   })
      // .catch((err) => {
      //   setShowLoader(false);
      //   if (
      //     err?.response &&
      //     err?.response?.data?.errors &&
      //     err?.response?.data?.errors?.length !== 0 &&
      //     err?.response?.data?.errors?.length !== undefined
      //   ) {
      //     Swal.fire("Oops!", err?.response?.data?.errors, "error");
      //   } else {
      //     Swal.fire("Oops!", err.response?.data?.message, "error");
      //   }
      // });
      // }
    } 
  };

  return (
    <>
      {!step1Complete ? (
        !forgotPassword ? (
          <div className="loginWrapper">
            <form>
              <div className="logoWrapperLogin">
                <img src={Logo} alt="logo" className="logoImageLogin" />
              </div>
              <h3>Login</h3>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Enter email"
                  value={login.email}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="Enter password"
                  onChange={handleChange}
                />
              </div>

              {/* <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck1"
                  />
                </div>
              </div> */}

              <button onClick={submitHandler} className="btn btn-dark btn-lg btn-block mt-3">
                Sign in
              </button>
              <div className="text-right mt-2">
                <a onClick={() => setForgotPassword(true)} className="forgot-password cursorPointer">
                  Forgot password?
                </a>
              </div>
            </form>
          </div>
        ) : (
          <ForgotPassword />
        )
      ) : (
        <TwoFactorAuth loginStepInfo={loginStepInfo} />
      )}
    </>
  );
}
