import React, { useState } from "react";
import { Drawer } from "@material-ui/core";
import Loader from "../../common/Loader/Loader";
import { axiosInstance } from "../../../utility/api";
import AddNews from "../AddNews";
import Swal from "sweetalert2";
import moment from "moment"
const styles = {
  sideNav: {
    marginTop: "-60px",
    zIndex: 3,
    marginLeft: "0px",
    position: "fixed",
  },
  link: {
    color: "black",
    textDecoration: "none",
  },
  title: {
    width: "90%",
    marginBottom: "20px",
  },
  productCard: {
    boxShadow: "0px 0px 2px #bbb",
    padding: "10px",
    marginBottom: "20px",
  },
};

// const useStyles = makeStyles((theme) => ({
//   rootSnackBar: {
//     width: "100%",
//     "& > * + *": {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));


export default class UiDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpened: false,
      title: "",
      description: "",
      url: "",
      urlError: "",
      publishedDate: new Date(),
      error: {
        status: false,
        message: "",
      },
      showLoader: {
        open: false,
        message: "",
      },
      isEdit: false,
    };
  }


  config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };

  // classes = useStyles();

  componentDidMount() {
    if (this?.props?.selectedRow) {
      this.setState({
        ...this.props.selectedRow,
        isEdit: true,
      });
    } else {
      this.setState({
        ...this.state,
        isEdit: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.selectedRow?.id !== this?.props?.selectedRow?.id) {
      this.setState({
        ...this.props.selectedRow,
        isEdit: true,
      });
    } else {
      // this.setState({
      //   ...this.state,
      //   isEdit: false,
      // });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "title" && value.length <= 255) {
      this.setState((preVal) => ({
        ...preVal, [name]: value,
      }));
    } else if (name !== "title") {
      this.setState((preVal) => ({
        ...preVal, [name]: value,
      }));
    }
  };

  toggleDrawerStatus = () => {
    this.setState({
      isDrawerOpened: true,
    });
  };

  closeDrawer = () => {
    this.setState({
      isDrawerOpened: false,
    });
  };

  setShowLoader = (value, message) => {
    this.setState({
      ...this.state,
      showLoader: { open: value, message: message },
    });
  };

  handleSubmitForm = (e) => {
    e.preventDefault();
    function isUrlValid(userInput) {
      var regexQuery = "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
      var url = new RegExp(regexQuery, "i");
      return url.test(userInput);
    }
    this.setState({
      error: { status: false, message: "" },
    });
    if (
      this.state.title === "" ||
      this.state.title === undefined ||
      this.state.title === null
    ) {
      Swal.fire("Oops!", "Please enter title.", "error");
      return false;
    }
    if (
      this.state.description === "" ||
      this.state.description === undefined ||
      this.state.description === null
    ) {
      Swal.fire("Oops!", "Please enter description.", "error");
      return false;
    }
    if (
      this.state.url === "" ||
      this.state.url === undefined ||
      this.state.url === null
    ) {
      Swal.fire("Oops!", "Please enter url.", "error");
      return false;
    }
    if (isUrlValid(this.state.url) === false) {
      Swal.fire("Oops!", "Please enter valid url.", "error");
      return false;
    }
    if (
      this.state.publishedDate === "" ||
      this.state.publishedDate === undefined ||
      this.state.publishedDate === null
    ) {
      Swal.fire("Oops!", "Please enter date.", "error");
      return false;
    }

    this.setShowLoader(true, "Please Wait.");

    const data = { ...this.state };
    delete data.isDrawerOpened;
    delete data.showLoader;
    delete data.assetCount;
    // delete data.description;
    delete data.subCategories;
    // delete data.catagories;
    // delete data.pactive;
    // delete data.value;
    delete data.error;
    delete data.setOpenSnackBar;

    delete data.imageUrl;

    const req = {
      title: this.state.title,
      description: this.state.description,
      url: this.state.url,
      publishedDate: new Date(this.state.publishedDate)
    }
    if (!this.state.isEdit) {
      delete data.isEdit;
      axiosInstance
        .post("/v1/admin/news/create", req, this.config)
        .then((res) => {
          // this.setState({
          //   ...this.state,
          //   showLoader: false,
          // });
          this.setShowLoader(false, "");
          this.setState({
            showLoader: false,
            isDrawerOpened: false,
            title: "",
            description: "",
            url: "",
            publishedDate: "",
            error: {
              status: false,
              message: "",
            },
          });
          this.props.onClose();
          // window.location.reload(true);
          Swal.fire("Success!", res?.data?.message, "success");
        })
        .then(() => {
          window.location.reload(true);
        })
        .catch((err) => {
          this.setShowLoader(false, "");
          if (
            err.response &&
            err.response.data.errors &&
            err.response.data.errors.length > 1
          ) {
            Swal.fire({
              title: "Oops!",
              text: err?.response?.data?.errors,
              icon: "error",
            });
          } else {
            Swal.fire("Oops!", err?.response?.data?.message, "error");
          }
        });
    } else {
      this.handleEditForm(e);
      this.setShowLoader(false, "");
    }
  };

  handleEditForm = (e) => {
    e.preventDefault();
    function isUrlValid(userInput) {
      var regexQuery = "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
      var url = new RegExp(regexQuery, "i");
      return url.test(userInput);
    }
    if (isUrlValid(this.state.url) === false) {
      Swal.fire("Oops!", "Please enter valid url.", "error");
      return false;
    }
    this.setState({
      error: { status: false, message: "" },
    });
    this.setShowLoader(true, "Please Wait");

    const data = { ...this.state };
    delete data.isDrawerOpened;
    delete data.catagories;
    // if (data.pactive === "yes") data.status = "active";
    // else data.status = "not active";
    delete data.pactive;
    delete data.value;
    delete data.error;
    delete data.showLoader;
    delete data.setOpenSnackBar;
    delete data.createdAt;
    delete data.counts;
    delete data.id;
    delete data.isDeleted;
    delete data.updatedAt;
    delete data.isEdit;
    delete data.imageUrl;
    delete data.assetCount;
    // delete data.description;SSS
    delete data.subCategories;
    
    axiosInstance
      .patch(`/v1/admin/news/update/${this.state.id}`, data, this.config)
      .then((res) => {
        // window.location.reload(true);
        this.props.onClose();
        this.setShowLoader(false, "");
        Swal.fire("Success!", res?.data?.message, "success");
      })
      // .then(() => {
      //   // window.location.reload(true);
      // })
      .catch((err) => {
        this.setShowLoader(false, "");
      });
  };

  render() {
    const isDrawerOpened = this.props.open;
    return (
      <div>
        <Drawer
          anchor="right"
          variant="temporary"
          open={isDrawerOpened}
          onClose={this.props.onClose}
        >
          {this.state.showLoader.open == true && (
            <Loader loaderInfo={this.state.showLoader.message} />
          )}
          <AddNews
            style={{ ...styles.title, marginBottom: "30px" }}
            state={this.state}
            onChangeDescription={(data) => {
              this.setState((preVal) => ({ ...preVal, description: data }));
            }}

            handleChange={this.handleChange}
            // handleImageUpload={this.handleImageUpload}
            // loadfile={this.loadfile}
            // categoryList={this.state.catagories}
            handleSubmitForm={this.handleSubmitForm}
            isEdit={this.state.isEdit}
          />
        </Drawer>
      </div>
    );
  }
}
