import axios from 'axios'
import Swal from "sweetalert2";

import { API_URL, API_ENDPOINT } from '../constant/apiConstant'

let headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
}

let token = JSON.parse(localStorage.getItem("token"))
if (token) {
    headers['Authorization'] = `Bearer ${token}`;
}

const Axois = axios.create({
    baseURL: API_URL,
    timeout: 20000,
    headers: headers
});
Axois.interceptors.request.use(request => {
    // console.log('request :>> ', request);
    return request
})
Axois.interceptors.response.use(response => {
    // console.log('response :>> ', response);
    return response
},
    (error) => {
        console.log('error.response', error.response)
        if (error?.response && error?.response?.status === 401) {
            setTimeout(() => {
                Swal.fire("Alert!", "Session expired! Please login again.", "error").then(() => {
                    window.location.href = "/";
                    localStorage.removeItem("token");
                });
            }, 0);
        }
        return Promise.reject(error)

        // console.log('error?.response', error?.response)
        // if(error?.response && error.response?.config?.url === "/admin/fetchAdminProfile" && error?.response?.status === 401){
        //     window.location.href = '/'
        //     localStorage.removeItem("token");
        //     return Promise.reject(error)
        // }
        // if(error?.response && error?.response?.status === 401) {
        //     window.location.href = '/welcome/:layout'
        // }
        // return Promise.reject(error)
    }
)

export default {

    // set token here after login
    initHeaders(token) {
        let header = Axois.defaults.headers
        if (token) {
            header['Authorization'] = `Bearer ${token}`;
        } else {
            if (header.hasOwnProperty('Authorization')) {
                delete header['Authorization']
            }
        }
    },

    // get admin profile data
    getAdminProfile() {
        return Axois.get(API_ENDPOINT.GET_PROFILE);
    },

    // upload Image to s3 bucket
    uploadImageToBucket(form_data) {
        return Axois.post(API_ENDPOINT.UPLOAD_IMAGE, form_data);
    },

    // admin base permission
    getAdminPermission() {
        return Axois.get(API_ENDPOINT.ADMIN_PERMISSION)
    },

    // get rate conversion data
    getConversionRate() {
        return Axois.get(API_ENDPOINT.GET_RATE)
    },

    // Collection
    getCollectionList(params) {
        return Axois.get(API_ENDPOINT.COLLECTION + params)
    },
    deleteCollectionById(id) {
        return Axois.delete(API_ENDPOINT.COLLECTION + '/' + id)
    },
    addCollection(payload) {
        return Axois.post(API_ENDPOINT.COLLECTION, payload);
    },
    updateCollection(id, payload) {
        return Axois.patch(API_ENDPOINT.COLLECTION + '/' + id, payload);
    },

    // Institution
    getInstitutions(params) {
        return Axois.get(API_ENDPOINT.INSTITUTION + params)
    },
    getInstitutionById(id) {
        return Axois.get(API_ENDPOINT.INSTITUTION + '/' + id)
    },
    deleteInstitutionById(id) {
        return Axois.delete(API_ENDPOINT.INSTITUTION + '/' + id)
    },
    addInstitution(payload) {
        return Axois.post(API_ENDPOINT.INSTITUTION, payload);
    },
    updateInstitution(id, payload) {
        return Axois.patch(API_ENDPOINT.INSTITUTION + '/' + id, payload);
    },

    //Artist
    getArtists(params) {
        return Axois.get(API_ENDPOINT.ARTIST + params)
    },
    getArtistById(id) {
        return Axois.get(API_ENDPOINT.ARTIST + '/' + id)
    },
    deleteArtistById(id) {
        return Axois.delete(API_ENDPOINT.ARTIST + '/' + id)
    },
    addArtist(payload) {
        return Axois.post(API_ENDPOINT.ARTIST, payload);
    },
    updateArtist(id, payload) {
        return Axois.patch(API_ENDPOINT.ARTIST + '/' + id, payload);
    },

    // edit User
    editUser(id, payload) {
        return Axois.patch(API_ENDPOINT.EDIT_USER + '/' + id, payload);
    },

    // Air Drops
    getAirDrops() {
        return Axois.get(API_ENDPOINT.AIR_DROP.GET)
    },
    transferAirDrops(payload) {
        return Axois.post(API_ENDPOINT.AIR_DROP.TRANSFER, payload);
    },

    // Analytics
    getOrderAnalytics(params) {
        return Axois.get(API_ENDPOINT.ANALYTICS.ORDER_CHART + params)
    },
    downloadOrderAnalytics() {
        return Axois.get(API_ENDPOINT.ANALYTICS.ORDER_DOWNLOAD)
    },
    getUserAnalytics(params) {
        return Axois.get(API_ENDPOINT.ANALYTICS.USER_CHART + params)
    },
    downloadUserAnalytics() {
        return Axois.get(API_ENDPOINT.ANALYTICS.USER_DOWNLOAD)
    },
    getVisitorAnalytics(params) {
        return Axois.get(API_ENDPOINT.ANALYTICS.VISITOR_CHART + params)
    },
    downloadVisitorAnalytics() {
        return Axois.get(API_ENDPOINT.ANALYTICS.VISITOR_DOWNLOAD)
    },

    // commission
    getCommissionData(query) {
        return Axois.get(API_ENDPOINT.COMMISSION_LIST + query)
    },

    //  Asset
    getAssets(params) {
        return Axois.get(API_ENDPOINT.ASSET, { params: params })
    },

    // Release
    getReleases(params) {
        return Axois.get(API_ENDPOINT.RELEASE + params)
    },
    getReleaseById(id) {
        return Axois.get(API_ENDPOINT.RELEASE + '/' + id)
    },
    deleteReleaseById(id) {
        return Axois.delete(API_ENDPOINT.RELEASE + '/' + id)
    },
    addRelease(payload) {
        return Axois.post(API_ENDPOINT.RELEASE, payload);
    },
    updateRelease(id, payload) {
        return Axois.patch(API_ENDPOINT.RELEASE + '/' + id, payload);
    },
}