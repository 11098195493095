import { SAVE_TOKEN, SAVE_ADMIN_PROFILE_DETAILS } from "../actionTypes";

const initial_state = {
    userToken: "",
    adminUser: null,
    adminId: "",
    profilePic:"",
    firstName:'',
    lastName:''
};

export default (state = initial_state, action) => {
    switch (action.type) {

        case SAVE_TOKEN:
            const token = action.payload;
            state.userToken = token;
            return { ...state };

        case SAVE_ADMIN_PROFILE_DETAILS:
            const user = action.payload;
            state.adminUser = user;
            return { ...state };

        default:
        return { ...state };
    }
};
