import React, { useEffect, useState, useRef } from "react";
import { Drawer } from "@material-ui/core";
// import Loader from "../../common/Loader/Loader";
// import { axiosInstance } from "../../../utility/api";
// import AddSubAdmin from "../AddSubAdmin";
import Swal from "sweetalert2";
// import "react-checkbox-tree/lib/react-checkbox-tree.css";
import SimpleReactValidator from "simple-react-validator"

import { axiosInstance, baseURL } from "../../utility/api";
import { formatAPIError } from "../../utility/helper";

import Checkbox from "./checkbox";
import PasswordInput from "../common/FormInput/passwordInput";

const UiDrawer = (props) => {
  const { permission, setPermission } = props;
  const [newsTgle, setNewstgle] = useState(false);
  const [aucTgle, setAucTgle] = useState(false);
  const [catTgle, setCatTgle] = useState(false);
  const [userTgle, setUserTgle] = useState(false);
  const [assetsTgle, setAssetsTgle] = useState(false);
  const [comTgle, setComTgle] = useState(false);
  const [transTgle, setTransTgle] = useState(false);
  const [analyTgle, setAnalyTgle] = useState(false);
  const [ordersTgle, setOrdersTgle] = useState(false);
  const [errors, setErrors] = useState(null);
  const [showError, setShowError] = useState(false);

  const [, forceUpdateForForm] = useState();
  let validator = new SimpleReactValidator
  // const [validator] = useState(useRef(new SimpleReactValidator({
  //   element: (message, className) => <p className="field_error">{message}</p>}
  // )))
  const {
    state,
    setState,
    selectHandler,
    selectAction,
    setSelectAction,
    isEdit,
    checked,
    expanded,
    setChecked,
    setExpanded,
  } = props;
  console.log('state', state)
  console.log('props', props)
  const [erorr, setError] = useState({
    status: false,
    message: "",
  });

  const [showLoader, setShowLoader] = useState({
    open: false,
    message: "",
  });

  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };

  const handleChange = (event) => {
    const { name, value, checked } = event?.target
    if (name === 'is2FA') {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleOnChange = (e) => {
    const { name, checked, value } = e.target;
    if (name === "all") {
      setPermission((pre) => ({
        ...pre,
        [value]: {
          ...pre[value],
          add: checked,
          delete: checked,
          edit: checked,
          view: checked,
          // all: checked
        },
      }));
    } else {
      setPermission((pre) => {
        return {
          ...pre,
          [value]: { ...pre[value], [name]: checked },
        };
      });
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    let result = {};
    props.setShowLoader(true);
    if (state.password !== state.confirmPassword) {
      Swal.fire("Alert", 'Confirm Password did not matched Password', "error");
      return;
    }
    if (Object.entries(permission).length > 0) {
      const newDat = Object.entries(permission).map((e) => ({
        [e[0]]: [e[1]],
      }));

      for (var i = 0; i < newDat.length; i++) {
        result = { ...result, ...newDat[i] };
      }
    }

    const data = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      password: state.password,
      confirmpassword: state.confirmPassword,
      is2FA: state.is2FA,
      permission: result,
    };

    const upDatedata = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      is2FA: state.is2FA,
      // password: state.password,
      permissionId: state.permissionId,
      permission: result,
    };


    let permission_data = Object.values(result);
    let onlytrue = permission_data.map(item => Object.values(item[0]).find(i => i === true))
    if (!onlytrue.includes(true)) {
      Swal.fire('Info', 'You must give at least one permission', 'info');
      return;
    }
    console.log(data)
    if (isEdit === false) {
      axiosInstance
        .post(`${baseURL}v1/sub-admin/signUp`, data, config)
        .then((res) => {
          setShowLoader(false);
          setError({
            status: false,
            message: "",
          });
          props.onClose();
          Swal.fire("Success!", res?.data?.message, "success").then(res => window.location.reload())
        })
        .catch((err) => {
          setShowLoader(false);
          if (
            err.response &&
            err.response.data.errors &&
            err.response.data.errors.length > 1
          ) {
            Swal.fire({
              title: "Alert",
              text: err?.response?.data?.errors,
              icon: "error",
            });
          } else {
            Swal.fire("Alert", err?.response?.data?.message || err.response?.data?.msg, "error");
          }
        });
    } else {
      axiosInstance
        .patch(`${baseURL}v1/sub-admin/profile/${state.id}`, upDatedata, config)
        .then((res) => {
          setShowLoader(false);
          setError({
            status: false,
            message: "",
          });
          props.onClose();
          Swal.fire("Success!", res?.data?.message, "success")
            .then(
              res => props.onSuccess()
            );;
        })
        .catch((err) => {
          setShowLoader(false);
          if (
            err.response &&
            err.response.data.errors &&
            err.response.data.errors.length > 1
          ) {
            Swal.fire({
              title: "Alert",
              text: err?.response?.data?.errors,
              icon: "error",
            });
          } else {
            Swal.fire("Alert", err?.response?.data?.message, "error");
          }
        });
      // handleEditForm(e);
      setShowLoader(false);
    }
  };

  const handleClose = () => {
    props.onClose();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowError(true)
    let finalResult = validateForm(e)
    if (finalResult) {
      handleSubmitForm(e)
    }
  }

  const validateForm = (e) => {
    const result = validator.allValid()
    if (result) {
      forceUpdateForForm(1)
      setShowError(false)
      setErrors(null)
      return true
    } else {
      validator.showMessages()
      setErrors(validator.errorMessages)
      forceUpdateForForm(2)
      return false
    }
  }

  useEffect(() => {
    validateForm()
  }, [state])

  return (
    <div>
      <Drawer
        anchor="right"
        variant="temporary"
        open={props.open}
        onClose={handleClose}
      >
        {showLoader.open == true && (
          // <Loader loaderInfo={showLoader.message} />
          <p></p>
        )}

        <div className="container tm-mt-big tm-mb-big">
          <div className="row">
            <div className="col-sm-12 mx-auto py-4 px-5">
              <div className="tm-bg-primary-dark tm-block tm-block-h-auto">
                <div className="row">
                  <div className="col-12">
                    <h2 className="tm-block-title d-inline-block">
                      {props.isEdit === true ? "Edit" : "Add"} Sub Admin
                    </h2>
                  </div>
                </div>
                <div className="row tm-edit-product-row">
                  <div className="col-xl-12 col-lg-6 col-md-12">
                    <form action="" className="tm-edit-product-form">
                      <div className="input-wrapper">
                        <label for="firstName">First Name <span className="colorRed">*</span></label>
                        <input
                          type="text"
                          name="firstName"
                          className="form-control validate"
                          value={state.firstName}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                        {showError && errors?.firstName && <p className="field_error">{errors?.firstName}</p>}
                        {validator.message('firstName', state.firstName, 'required|alpha')}
                      </div>
                      <div className="input-wrapper">
                        <label for="lastName">Last Name <span className="colorRed">*</span></label>
                        <input
                          type="text"
                          name="lastName"
                          className="form-control validate"
                          value={state.lastName}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                        {showError && errors?.lastName && <p className="field_error">{errors?.lastName}</p>}
                        {validator.message('lastName', state.lastName, 'required|alpha')}
                      </div>
                      <div className="input-wrapper">
                        <label for="email">Email <span className="colorRed">*</span></label>
                        <input
                          type="text"
                          name="email"
                          className="form-control validate"
                          value={state.email}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                        {showError && errors?.email && <p className="field_error">{errors?.email}</p>}
                        {validator.message('email', state.email, 'required|email')}
                      </div>
                      {!props.isEdit
                        ? <>
                          <div className="input-wrapper">
                            <label for="password ">Password <span className="colorRed">*</span></label>
                            <PasswordInput
                              label={``}
                              name={`password`}
                              inputClass={`validate`}
                              value={state.password}
                              onChange={(e) => handleChange(e)}
                            />
                            {/* <input
                            type="password"
                            name="password"
                            className="form-control validate"
                            value={state.password}
                            onChange={(e) => handleChange(e)}
                          /> */}
                            {showError && errors?.password && <p className="field_error">{errors?.password}</p>}
                            {validator.message('password', state.password, 'required')}
                          </div>
                          <div className="input-wrapper">
                            <label for="password">Confirm Password <span className="colorRed">*</span></label>
                            <PasswordInput
                              label={``}
                              name={`confirmPassword`}
                              inputClass={`validate`}
                              value={state.confirmPassword}
                              onChange={(e) => handleChange(e)}
                            />
                            {/* <input
                            id="passwoManage Sub Adminrd"
                            type="password"
                            name="confirmPassword"
                            className="form-control validate"
                            value={state.confirmPassword}
                            onChange={(e) => handleChange(e)}
                          /> */}
                            {showError && errors?.confirmPassword && <p className="field_error">{errors?.confirmPassword}</p>}
                            {validator.message('confirmPassword', state.confirmPassword, 'required')}
                          </div>
                        </>
                        : null}
                      <div className="input-wrapper form-group twoFA-checkbox">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            name="is2FA"
                            checked={state?.is2FA}
                            className="custom-control-input"
                            id="customCheck1"
                            onChange={handleChange}
                          />
                          <label for="customCheck1"></label>
                          <span>2FA</span>
                        </div>
                      </div>
                      <div className="permission_wrapper">
                        <label className="permission_title">Permissions <span className="colorRed">*</span></label>
                        {Object.entries((permission || {}))?.length > 0
                          ? <div className="permission-checklist">
                            {/* <Checkbox
                              title={"News"}
                              objName={"news"}
                              handleOnChange={handleOnChange}
                              permission={permission}
                              toggle={newsTgle}
                              setToggle={setNewstgle}
                            // val={"news"}
                            /> */}
                            <Checkbox
                              title={"Auction"}
                              objName={"auction"}
                              handleOnChange={handleOnChange}
                              permission={permission}
                              toggle={aucTgle}
                              setToggle={setAucTgle}
                            />
                            <Checkbox
                              title={"Collection Management"}
                              objName={"collection"}
                              permission={permission}
                              handleOnChange={handleOnChange}
                              toggle={catTgle}
                              setToggle={setCatTgle}
                            />
                            <Checkbox
                              title={"Institution Management"}
                              objName={"institution"}
                              permission={permission}
                              handleOnChange={handleOnChange}
                              toggle={catTgle}
                              setToggle={setCatTgle}
                            />
                            <Checkbox
                              title={"Artists Management"}
                              objName={"artist"}
                              permission={permission}
                              handleOnChange={handleOnChange}
                              toggle={catTgle}
                              setToggle={setCatTgle}
                            />
                            <Checkbox
                              title={"User Management"}
                              objName={"user"}
                              permission={permission}
                              handleOnChange={handleOnChange}
                              toggle={userTgle}
                              setToggle={setUserTgle}
                            />
                            <Checkbox
                              title={"Commission"}
                              objName={"commission"}
                              permission={permission}
                              handleOnChange={handleOnChange}
                              toggle={comTgle}
                              setToggle={setComTgle}
                            />
                            <Checkbox
                              title={"Orders"}
                              objName={"order"}
                              permission={permission}
                              handleOnChange={handleOnChange}
                              toggle={ordersTgle}
                              setToggle={setOrdersTgle}
                            />
                            <Checkbox
                              title={"Assets"}
                              objName={"asset"}
                              permission={permission}
                              handleOnChange={handleOnChange}
                              toggle={assetsTgle}
                              setToggle={setAssetsTgle}
                            />
                            <Checkbox
                              title={"Releases"}
                              objName={"release"}
                              permission={permission}
                              handleOnChange={handleOnChange}
                              toggle={assetsTgle}
                              setToggle={setAssetsTgle}
                            />
                            <Checkbox
                              title={"Transaction"}
                              objName={"transaction-history"}
                              permission={permission}
                              handleOnChange={handleOnChange}
                              toggle={transTgle}
                              setToggle={setTransTgle}
                            />
                            <Checkbox
                              title={"Analytics"}
                              objName={"analytic-dashboard"}
                              permission={permission}
                              handleOnChange={handleOnChange}
                              toggle={analyTgle}
                              setToggle={setAnalyTgle}
                            />
                          </div>
                          : <div>
                            <p>No Data Found!!!</p>
                          </div>}
                      </div>
                    </form>
                  </div>

                  {/* {state.error.status == true ? (
                  <div className="errorLabel col-6 col-md-12">
                    {state?.error?.message}
                  </div>
                ) : null} */}
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block text-uppercase"
                      onClick={handleSubmit}
                      disabled={
                        state.firstName ||
                          state.lastName ||
                          state.email ||
                          selectAction
                          ? false
                          : true
                      }
                    >
                      Save Sub Admin
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default UiDrawer;
