import React, { useState } from "react";
import Swal from "sweetalert2";
import { Drawer } from "@material-ui/core";

import AddInstitution from "./AddInstitution";
import Loader from "../../common/Loader/Loader";

import { axiosInstance } from "../../../utility/api";
import { capitalizeFirstLetter, formatAPIError, objectDeepClone, validateFacebookUrl, validateTwitterUrl } from "../../../utility/helper";

import API from '../../../APIActions/api'

const styles = {
  sideNav: {
    marginTop: "-60px",
    zIndex: 3,
    marginLeft: "0px",
    position: "fixed",
  },
  link: {
    color: "black",
    textDecoration: "none",
  },
  title: {
    width: "90%",
    marginBottom: "20px",
  },
  productCard: {
    boxShadow: "0px 0px 2px #bbb",
    padding: "10px",
    marginBottom: "20px",
  },
};

const socialMediaLinksArr = [
  {
    "link": "",
    "type": "twitter"
  },
  {
    "link": "",
    "type": "instagram"
  },
  {
    "link": "",
    "type": "facebook"
  },
]

export default class InstitutionUiDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpened: false,
      name: "",
      walletAddress: '',
      InstitutionDescription: '',
      socialMediaLinks: [socialMediaLinksArr[0]],
      websiteLink: '',
      logoUrl: '',
      bannerImage: '',
      error: {
        status: false,
        message: "",
      },
      showLoader: {
        open: false,
        message: "",
      },
      isEdit: false,
    };
  }

  componentDidMount() {
    if (this?.props?.selectedRow) {
      this.setState({
        ...this.props.selectedRow,
        isEdit: true,
      });
    } else {
      this.setState({
        ...this.state,
        isEdit: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedRow && this.state.id !== this.props.selectedRow.id) {
      if (this.props.selectedRow?.socialMediaLinks === null) {
        this.props.selectedRow.socialMediaLinks = [socialMediaLinksArr[0]]
      }
      this.setState({
        ...this.props.selectedRow,
        isEdit: true,
      });
    } else {
      // this.setState({
      //   ...this.state,
      //   isEdit: false,
      // });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleSocialChange = (event) => {
    const { name, value } = event.target
    let found = this.state.socialMediaLinks.findIndex(el => el.type === name)
    let newState = objectDeepClone(this.state)
    newState.socialMediaLinks[found].link = value
    this.setState(newState);
  }

  addNewInput = () => {
    let newState = objectDeepClone(this.state)
    if (newState.socialMediaLinks?.length < socialMediaLinksArr?.length) {
      const results = socialMediaLinksArr.filter(({ type: id1 }) => !newState.socialMediaLinks.some(({ type: id2 }) => id2 === id1));
      newState.socialMediaLinks.push(results[0])
      this.setState(newState);
    } else {
      Swal.fire("Alert!", 'Only 3 Social Media Links can be added.', "error");
    }
  }

  removeInput = (type) => {
    let newState = objectDeepClone(this.state)
    if (newState.socialMediaLinks?.length === 1) {
      Swal.fire("Alert!", 'Cannot delete input field', "error");
    } else {
      let arr = newState.socialMediaLinks.filter(el => el?.type !== type)
      newState.socialMediaLinks = objectDeepClone(arr)
      this.setState(newState);
    }
  }

  handleImageUpload = (e, fileId) => {
    const { name, value, id } = e.target
    e.preventDefault()
    document.getElementById(fileId).click();
  };

  uploadFile = (event) => {
    const { name, value, id } = event.target
    event.preventDefault()
    if (event.target.files[0]) {
      const fileType = ["image/png", "image/jpeg", "image/jpg"];
      if (![...fileType].includes(event.target.files[0].type) &&
        event.target.files[0].name.substr(event.target.files[0].name.length - 3) != "fbx") {
        Swal.fire("Alert", 'Please upload a valid file.', "error");
        return;
      }
    } else {
      return
    }
    this.setShowLoader(true, "Please Wait. Uploading Image.");
    let form_data = new FormData();
    form_data.append("file", event.target.files[0]);
    API.uploadImageToBucket(form_data)
      .then(res => {
        let previewUrl = res?.data?.result?.previewUrl
        if (previewUrl) {
          this.setState({ [name]: previewUrl });
        }
        this.setShowLoader(false, "");
      })
      .catch(err => {
        this.setShowLoader(false, "");
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error");
      })
  }

  toggleDrawerStatus = () => {
    this.setState({
      isDrawerOpened: true,
    });
  };

  closeDrawer = () => {
    this.setState({
      isDrawerOpened: false,
    });
  };

  setShowLoader = (value, message) => {
    this.setState({
      ...this.state,
      showLoader: { open: value, message: message },
    });
  };

  handleSubmitForm = (e) => {
    e.preventDefault();
    this.setState({
      error: { status: false, message: "" },
    });

    this.setShowLoader(true, "Please Wait.");
    const payload = {
      name: this.state.name,
      // walletAddress: this.state.walletAddress,
      InstitutionDescription: this.state.InstitutionDescription,
      socialMediaLinks: this.state.socialMediaLinks,
      websiteLink: this.state.websiteLink,
      logoUrl: this.state.logoUrl,
      bannerImage: this.state.bannerImage,
      youtubeEmbedVideoLink: this.state.youtubeEmbedVideoLink,
    };
    payload.socialMediaLinks.sort((a, b) => {
      if (a?.type > b?.type) return -1
      if (b?.type < a?.type) return -1
      return 0
    })
    if (!this.state.isEdit) {
      API.addInstitution(payload)
        .then((res) => {
          this.setShowLoader(false, "");
          this.setState({
            showLoader: false,
            isDrawerOpened: false,
            name: "",
            walletAddress: '',
            InstitutionDescription: '',
            socialMediaLinks: socialMediaLinksArr,
            websiteLink: '',
            logoUrl: '',
            bannerImage: '',
            error: {
              status: false,
              message: "",
            },
          });
          this.props.onClose();
          Swal.fire("Success!", capitalizeFirstLetter(res?.data?.message) || 'Institution successfully created', "success");
          window.location.reload(true);
        })
        .catch((err) => {
          this.setShowLoader(false, "");
          let errMsg = formatAPIError(err)
          Swal.fire("Alert!", errMsg, "error");
        });

    } else {
      API.updateInstitution(this.state.id, payload)
        .then((res) => {
          this.props.onClose();
          this.setShowLoader(false, "");
          Swal.fire("Success!", capitalizeFirstLetter(res?.data?.message) || 'Institution successfully updated', "success");
          window.location.reload(true);
        })
        .catch((err) => {
          this.setShowLoader(false, "");
          let errMsg = formatAPIError(err)
          Swal.fire("Alert!", errMsg, "error");
        })
    }
  };


  render() {
    const isDrawerOpened = this.props.open;
    return (
      <div>
        <Drawer
          anchor="right"
          variant="temporary"
          open={isDrawerOpened}
          onClose={this.props.onClose}
        >
          {this.state.showLoader.open == true && (
            <Loader loaderInfo={this.state.showLoader.message} />
          )}
          <AddInstitution
            style={{ ...styles.title, marginBottom: "30px" }}
            state={this.state}
            handleChange={this.handleChange}
            handleSubmitForm={this.handleSubmitForm}
            uploadFile={this.uploadFile}
            handleImageUpload={this.handleImageUpload}
            isEdit={this.state.isEdit}
            handleSocialChange={this.handleSocialChange}
            addNewInput={this.addNewInput}
            removeInput={this.removeInput}
          />
        </Drawer>
      </div>
    );
  }
}
