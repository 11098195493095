import React from "react";
import Swal from "sweetalert2";
import { Drawer } from "@material-ui/core";

import AddCollection from "../addCollection";
import Loader from "../../common/Loader/Loader";

import { axiosInstance } from "../../../utility/api";
import { capitalizeFirstLetter, formatAPIError } from "../../../utility/helper";

const styles = {
  sideNav: {
    marginTop: "-60px",
    zIndex: 3,
    marginLeft: "0px",
    position: "fixed",
  },
  link: {
    color: "black",
    textDecoration: "none",
  },
  title: {
    width: "90%",
    marginBottom: "20px",
  },
  productCard: {
    boxShadow: "0px 0px 2px #bbb",
    padding: "10px",
    marginBottom: "20px",
  },
};

export default class UiDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpened: false,
      name: "",
      slug: "",
      description: '',
      error: {
        status: false,
        message: "",
      },
      showLoader: {
        open: false,
        message: "",
      },
      isEdit: false,
    };
  }

  config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };

  // classes = useStyles();

  componentDidMount() {
    if (this?.props?.selectedRow) {
      this.setState({
        ...this.props.selectedRow,
        isEdit: true,
      });
    } else {
      this.setState({
        ...this.state,
        isEdit: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedRow &&
      this.state.slug !== this.props.selectedRow.slug
    ) {
      this.setState({
        ...this.props.selectedRow,
        isEdit: true,
      });
    } else {
      // this.setState({
      //   ...this.state,
      //   isEdit: false,
      // });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({
      ...this.state,
      [name]: value,
    });    
  };

  toggleDrawerStatus = () => {
    this.setState({
      isDrawerOpened: true,
    });
  };

  closeDrawer = () => {
    this.setState({
      isDrawerOpened: false,
    });
  };

  setShowLoader = (value, message) => {
    this.setState({
      ...this.state,
      showLoader: { open: value, message: message },
    });
  };

  handleSubmitForm = (e) => {
    e.preventDefault();
    this.setState({
      error: { status: false, message: "" },
    });
    if (
      this.state.name == "" ||
      this.state.name == undefined ||
      this.state.name == null
    ) {
      Swal.fire("Oops!", "Please enter name.", "error");
      // this.setState({
      //   error: { status: true, message: "Please enter a name." },
      // });
      return false;
    }
    // if (
    //   this.state.slug == "" ||
    //   this.state.slug == undefined ||
    //   this.state.slug == null
    // ) {
    //   Swal.fire("Oops!", "Please enter label.", "error");
    //   return false;
    // }

    this.setShowLoader(true, "Please Wait.");
    const payload = { 
      name: this.state.name,
      description: this.state.description
    };
    if (!this.state.isEdit) {
      axiosInstance
        .post("/v1/admin/collection", payload, this.config)
        .then((res) => {
          console.log('res of add collection', res)
          // this.setState({
          //   ...this.state,
          //   showLoader: false,
          // });
          this.setShowLoader(false, "");
          this.setState({
            showLoader: false,
            isDrawerOpened: false,
            name: "",
            // catagories: [],
            description: "",
            slug: "",
            // imageUrl: "",
            error: {
              status: false,
              message: "",
            },
          });
          this.props.onClose();
          Swal.fire("Success!", capitalizeFirstLetter(res?.data?.message) || 'Collection successfully created', "success");
          window.location.reload(true);
        })
        .catch((err) => {
          this.setShowLoader(false, "");
          let errMsg = formatAPIError(err)
          Swal.fire("Alert!", errMsg, "error");
        });
    } else {
      this.handleEditForm(e);
      this.setShowLoader(false, "");
    }
  };

  handleEditForm = (e) => {
    e.preventDefault();
    this.setState({
      error: { status: false, message: "" },
    });
    this.setShowLoader(true, "Please Wait");

    const payload = { 
      name: this.state.name,
      description: this.state.description
    };
    axiosInstance
      .patch(`/v1/admin/collection/${this.state.id}`, payload, this.config)
      .then((res) => {
        console.log('res of update collection', res)
        this.props.onClose();
        this.setShowLoader(false, "");
        Swal.fire("Success!",capitalizeFirstLetter(res?.data?.message) || 'Collection successfully updated', "success");
        window.location.reload(true);
      })
      .catch((err) => {
        this.setShowLoader(false, "");
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error");
      });
  };

  render() {
    const isDrawerOpened = this.props.open;
    return (
      <div>
        <Drawer
          anchor="right"
          variant="temporary"
          open={isDrawerOpened}
          onClose={this.props.onClose}
        >
          {this.state.showLoader.open == true && (
            <Loader loaderInfo={this.state.showLoader.message} />
          )}
          <AddCollection
            style={{ ...styles.title, marginBottom: "30px" }}
            state={this.state}
            handleChange={this.handleChange}
            handleSubmitForm={this.handleSubmitForm}
            isEdit={this.state.isEdit}
          />
        </Drawer>
      </div>
    );
  }
}
