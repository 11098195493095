import React, { Fragment } from "react";
import Loader from "../layout/loader";
import Header from "../layout/header";
import Sidebar from "../layout/sidebar";
import Footer from "../layout/footer";
import ThemeCustomize from "../layout/theme-customizer";
import { ToastContainer } from "react-toastify";
import SignUp from "./signup/Signup";
import Login from "./Login/Login";
import Dashbboard from "./dashboard/default/index";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { routes } from "./../route/index";
import ProtectedRoute from '../utility/protected-routes/protectedRoutes'
import {
  Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ConfigDB from "../data/customizer/config";
import { classes } from "../data/layouts";
import Wrapper from "../layout/unAuthWrappers/unAuthWrappers";
import { history } from "../utility/history";
import ResetPassword from "./Login/Resetpassword";

const App = ({ children }) => {
  console.warn = () => {};
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();
  const [anim, setAnim] = React.useState("");
  const animation =
    localStorage.getItem("animation") ||
    ConfigDB.data.router_animation ||
    "fade";
  const abortController = new AbortController();

  React.useEffect(() => {
    setAnim(animation);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Router history={history}>
        <Switch>
          {/* <TransitionGroup> */}
            {/* <div className="outer">
              <div className="inner"> */}
            <Route exact path="/" component={Wrapper(Login)} />
            <Route exact path="/sign-in" component={Wrapper(Login)} />
            <Route exact path="/sign-up" component={Wrapper(SignUp)} />
            <Route exact path="/forgot-password" component={Wrapper(ResetPassword)} />
            {/* </div>
            </div> */}
            {/* <Route exact path={`${process.env.PUBLIC_URL}/dashboard/default/${layout}`} render={() => {
              return (<Redirect to={``} />) 
            }} /> */}
            {routes.map(({ path, Component }) => (
              <ProtectedRoute key={path} exact path={`${process.env.PUBLIC_URL}${path}`} Component={Component}>
                {/* {({ match }) => (
                  <CSSTransition
                    in={match != null}
                    timeout={100}
                    // classNames={anim}
                    unmountOnExit
                  >
                    <div>
                      <Component />
                    </div>
                  </CSSTransition>
                )} */}
              
              </ProtectedRoute>
            ))}
          {/* </TransitionGroup> */}
        </Switch>
      </Router>
    </Fragment>
  );
};

export default App;
