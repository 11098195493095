import React, { useEffect, useState, useRef } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./addProduct.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import ImageUpload from "../../assets/images/other-images/imageUpload.svg";
// import UploadMediaModal from "../products/components/uploadFileScreen";
import UploadFileModal from "../products/components/UploadFileModal";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReactPlayer from "react-player";
import decryptFunction from "../common/decryptURL";
import { maxLengthCheck } from "../common/inputUtilities";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { axiosInstance } from "../../utility/api";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import { formatETHPrice } from "../../utility/helper";
// import Tooltip from "@material-ui/core/Tooltip";
// import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  radioGroupRoot: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },

  formControlRoot: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

// const styles = {
//   rootDrawer: {
//     zIndex: "200 !important",
//   },
// };

function AddProduct(props) {
  const {
    style,
    state,
    institutions,
    collections,
    artists,
    onChangeDescription,
    handleChange,
    handleSelectChange,
    // handleImageUpload,
    handleMultiFileUpload,
    loadfile,
    categoryList,
    // teamList,
    // leagueList,
    handleSubmitForm,
    setSelectedVideoFileType,
    setSelectedAudioType,
    isEdit,
    setError,
    isMainFileVideo,
    isMainFileAudio,
    isMainFile3D,
    disableSupportingDocs,
    isSuplimentaryFile1Video,
    isSuplimentaryFile2Video,
    isSuplimentaryFile3Video,
    isSuplimentaryFile4Video,
    handleRemoveFile,
    handleDeleteAll,
    setShowLoader,
  } = props;

  // console.log('add product state', state)
  // console.log('add product props', props)
  console.log("state :>> ", state);
  const classes = useStyles();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [leagueList, setLeagueList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [previewVideoNeeded, setpreviewVideoNeeded] = useState(false);
  const [preview3DNeeded, setpreview3DNeeded] = useState(false);
  const [previewImgNeeded, setpreviewImgNeeded] = useState(false);
  const [open, setOpen] = React.useState(false);
  const productConditionList = ["new", "used"];
  const auctionTypeList = ["normal", "reverse"];
  const auctionStatusList = ["active", "inactive"];
  const [ethValue, setEthValue] = useState(0);
  const [maticValue, setMaticValue] = useState(0);
  const reactQuillRef = React.useRef();
  const quilEditorText = reactQuillRef?.current?.getEditor();
  const descriptionLength = quilEditorText?.getLength() - 1;
  const [countryError, setCountryError] = useState(false);
  const [sportsError, setSportsError] = useState(false);
  const [leagueError, setLeagueError] = useState(false);
  const restrictEditDescription = false;

  const [forceUpdate, setForceUpdate] = useState(0);
  const [validator, setValidator] = useState(
    useRef(
      new SimpleReactValidator({
        element: (message, className) => (
          <p className="field_error">{message}</p>
        ),
        validators: {
          // price: {
          //   message: 'The price field should be greater than zero.',
          //   rule: (val) => {
          //     if(parseFloat(val)) {
          //       return Number(parseFloat(val)) > 0 ? true : false
          //     } else {
          //       return false
          //     }
          //   },
          //   required: true
          // },
          imgVal: {
            message: "The preview and raw image file field is required.",
            rule: (val) => {
              return val ? true : false;
            },
            required: true,
          },
        },
      })
    )
  );

  useEffect(() => {
    setForceUpdate(forceUpdate + 1);
  }, [state?.saleType]);

  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };
  var descLength = 0;
  let cameraPosition = {
    x: 150,
    y: 300,
    z: 350,
  };
  const rarityOptions = [
    { id: "open", label: "Open" },
    { id: "gold", label: "Gold" },
    { id: "diamond", label: "Diamond " },
    { id: "platinum", label: "Platinum" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const exchangeVal =
      state?.networkType === "Ethereum" ? ethValue : maticValue;
    setForceUpdate(forceUpdate + 1);
    const result = validator.current.allValid();
    console.log("validator.current", validator.current);
    console.log("validator.current state", state);
    console.log("result :>> ", result);
    if (result) {
      handleSubmitForm(e, exchangeVal);
    } else {
      console.log("else block");
      validator.current.showMessages();
      setForceUpdate(forceUpdate + 1);
      setTimeout(() => {
        let elements = document.getElementsByClassName("field_error");
        if (elements?.length) {
          let element = elements[0];
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 500);
    }
  };

  const onLoad = (e) => {
    //console.log(e);
  };

  const onError = (e) => {
    //console.log(e);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(false, "", "");
  };

  const handleStartDateChange = (date) => {
    let event = { target: { name: "", value: "" } };
    event["target"]["name"] = "startDate";
    event["target"]["value"] = date;
    handleChange(event);
  };

  const handleEndingDateChange = (date) => {
    let event = { target: { name: "", value: "" } };
    event["target"]["name"] = "endingDate";
    event["target"]["value"] = date;
    handleChange(event);
  };

  // useEffect(() => {
  //   if (state.shortVideoUrl && state.longVideoUrl) {
  //     setOpen(false);
  //   }
  //   if (state.audioUrl && state.audioThumbnail) {
  //     setOpen(false);
  //   }
  //   if (state.imageUrl && state.ipfsImageUrlPath) {
  //     setOpen(false);
  //   }
  // }, [state]);

  useEffect(() => {
    setpreviewVideoNeeded(isMainFileVideo);
  }, [isMainFileVideo]);

  useEffect(() => {
    setpreview3DNeeded(isMainFile3D);
  }, [isMainFile3D]);

  useEffect(() => {
    setpreviewImgNeeded(isMainFileAudio);
  }, [isMainFileAudio]);

  useEffect(async () => {
    if (
      (state?.regularPrice && state.regularPrice != "") ||
      (state?.auction?.openingPrice && state?.auction?.openingPrice != "")
    ) {
      await axiosInstance
        .get(`/v1/admin/exchange/rate`, config)
        .then((res) => {
          if (
            res.data.result?.exchangeRateMatic &&
            res.data.result?.exchangeRate
          ) {
            setMaticValue(res.data.result.exchangeRateMatic);
            setEthValue(res.data.result.exchangeRate);
          } else {
            setEthValue(0);
            setMaticValue(0);
          }
        })
        .catch((error) => {
          setEthValue(0);
          setMaticValue(0);
          //console.log("Error while getting subcategories :", error);
        });
    } else {
      setEthValue(0);
      setMaticValue(0);
    }
  }, [state?.regularPrice, state?.auction?.openingPrice]);

  useEffect(() => {
    setSubCategoryList([]);
    setLeagueList([]);
    setTeamList([]);
    getSubCategories(state?.categoryId);
  }, [state?.categoryId]);
  useEffect(() => {
    setLeagueList([]);
    setTeamList([]);
    getLeague(state?.subCategoryId);
  }, [state?.subCategoryId]);
  useEffect(() => {
    setTeamList([]);
    getTeam(state?.leagueId);
  }, [state?.leagueId]);

  const formatInput = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum = e.key === "e" || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      checkIfNum = e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const formatInputDot = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum = e.key === "e" || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 187 ||
        e.keyCode === 189 ||
        e.keyCode === 190 ||
        e.keyCode === 110;
    }
    return checkIfNum && e.preventDefault();
  };

  const getSubCategories = async (id) => {
    if (id) {
      await axiosInstance
        .get(`/v1/admin/category/${id}`, config)
        .then((res) => {
          setSubCategoryList(res?.data?.result?.subCategories);
          setCountryError(false);
        })
        .catch((error) => {
          //console.log("Error while getting subcategories :", error);
          setCountryError(true);
        });
    } else {
      setSubCategoryList([]);
    }
  };
  const getLeague = async (id) => {
    if (id) {
      await axiosInstance
        .get(`/v1/admin/league/${id}`, config)
        .then((res) => {
          setLeagueList(res?.data?.result?.league);
          if (res.data.result.league.length == 0) {
            setSportsError(true);
          } else {
            setSportsError(false);
          }
        })
        .catch((error) => {
          //console.log("Error while getting league :", error);
        });
    } else {
      setLeagueList([]);
    }
  };
  const getTeam = async (id) => {
    if (id) {
      await axiosInstance
        .get(`/v1/admin/team/${id}`, config)
        .then((res) => {
          setTeamList(res?.data?.result?.teams);
          if (res.data.result.teams.length == 0) {
            setLeagueError(true);
          } else {
            setLeagueError(false);
          }
        })
        .catch((error) => {
          //console.log("Error while getting team :", error);
          setLeagueError(true);
        });
    } else {
      setTeamList([]);
    }
  };

  const checkCharacterCount = (event) => {
    const descriptionLength = reactQuillRef.current.getEditor().getLength();
    if (descriptionLength > 1500 && event.key !== "Backspace")
      event.preventDefault();
  };

  return (
    <>
      { }
      <div className="container tm-mt-big tm-mb-big">
        <div className="row">
          <div className="col-sm-12 mx-auto py-4 px-5">
            <form
              action=""
              className="tm-bg-primary-dark tm-block tm-block-h-auto"
            >
              <div className="container tm-mt-big tm-mb-big">
                <div className="row">
                  <div className="col-12" style={{ paddingLeft: 0 }}>
                    <h2 className="tm-block-title d-inline-block">
                      {isEdit == true ? "Edit" : "Add"} Asset
                    </h2>
                  </div>
                </div>
              </div>
              {/* <div className="container tm-mt-big tm-mb-big"> */}
              <div className="row tm-edit-product-row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="tm-edit-product-form">
                    {isEdit == true ? (
                      <div className="form-group mb-3">
                        <label for="name">Asset ID</label>
                        <span className="colorRed">*</span>
                        <input
                          type="text"
                          name="assetID"
                          className="form-control validate"
                          value={state?.id}
                          disabled="true"
                        />
                      </div>
                    ) : null}
                    <div className="form-group mb-3">
                      <label for="category">Network Type</label>
                      <span className="colorRed">*</span>
                      <FormControl
                        component="fieldset"
                        classes={{
                          root: classes.formControlRoot,
                        }}
                        disabled={isEdit ? true : false}
                      >
                        <RadioGroup
                          classes={{
                            root: classes.radioGroupRoot, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                          }}
                          name="networkType"
                          value={state.networkType}
                          onChange={(e) => handleChange(e)}
                        >
                          <FormControlLabel
                            value="Ethereum"
                            control={<Radio />}
                            label="Ethereum"
                          />
                          <FormControlLabel
                            value="Polygon"
                            control={<Radio />}
                            label="Polygon"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="form-group mb-3">
                      <label for="category">Sale Type</label>
                      <span className="colorRed">*</span>
                      <FormControl
                        component="fieldset"
                        classes={{
                          root: classes.formControlRoot,
                        }}
                        disabled={isEdit ? true : false}
                      >
                        <RadioGroup
                          classes={{
                            root: classes.radioGroupRoot, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                          }}
                          name="saleType"
                          value={state.saleType}
                          onChange={(e) => handleChange(e)}
                        >
                          <FormControlLabel
                            value="fixed"
                            control={<Radio />}
                            label="Fixed Price"
                          />
                          <FormControlLabel
                            value="auction"
                            control={<Radio />}
                            label="Auction"
                          />
                          <FormControlLabel
                            value="free"
                            control={<Radio />}
                            label="Free"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="form-group mb-3">
                      <label for="name">
                        Asset Name<span className="colorRed">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control validate"
                        value={state.name}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        required
                        disabled={isEdit ? true : false}
                      />
                      {validator.current.message(
                        "Name",
                        state.name,
                        "required"
                      )}
                      <div className="charactersRemaining">
                        <span>
                          ({state?.name ? 90 - state?.name?.length : "90"}{" "}
                          characters remaining)
                        </span>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label for="description">Description</label>
                      <span className="colorRed">*</span>
                      <ReactQuill
                        className={`react-quill-description ${
                          restrictEditDescription ? "read-only" : ""
                        }`}
                        readOnly={restrictEditDescription ? true : false}
                        // onKeyDown={checkCharacterCount}
                        ref={reactQuillRef}
                        value={state.description}
                        onChange={(e) => {
                          const quilEditor =
                            reactQuillRef?.current?.getEditor();
                          const descriptionLength = quilEditor?.getLength();
                          if (reactQuillRef.current) {
                            descLength = descriptionLength;
                            // if (descriptionLength < 1500) {
                            onChangeDescription(e);
                            // } else {
                            //   quilEditor.deleteText(
                            //     1500,
                            //     quilEditor.getLength()
                            //   );
                            //   onChangeDescription(
                            //     reactQuillRef.current.getEditorContents()
                            //   );
                            // }
                          } else {
                            onChangeDescription(e);
                          }
                        }}
                      />
                      {validator.current.message(
                        "description",
                        state.description,
                        "required"
                      )}
                      {/* <CKEditor
                        style={style}
                        // config={{
                        //   toolbar: [
                        //     "bold",
                        //     "italic",
                        //     "NumberedList",
                        //     "BulletedList",
                        //     "|",
                        //     "undo",
                        //     "redo",
                        //   ],
                        // }}
                        // enforceFocus={false}
                        data={state.description}
                        editor={ClassicEditor}
                        onChange={onChangeDescription}
                      /> */}
                      {/* <div className="charactersRemaining">
                        <span>
                          (
                          {state?.description
                            ? 1500 - descriptionLength
                            : "1500"}{" "}
                          characters remaining)
                        </span>
                      </div> */}
                    </div>

                    <div className="form-group mb-3">
                      <label for="description">Description for metadata</label>
                      <span className="colorRed">*</span>
                      {/* <ReactQuill
                        className={`react-quill-description ${
                          isEdit ? "read-only" : ""
                        }`}
                        readOnly={isEdit ? true : false}
                        // onKeyDown={checkCharacterCount}
                        ref={reactQuillRef}
                        value={state.description}
                        onChange={(e) => {
                          const quilEditor =
                            reactQuillRef?.current?.getEditor();
                          const descriptionLength = quilEditor?.getLength();
                          if (reactQuillRef.current) {
                            descLength = descriptionLength;
                            if (descriptionLength < 1500) {
                              onChangeDescription(e);
                            } else {
                              quilEditor.deleteText(
                                1500,
                                quilEditor.getLength()
                              );
                              onChangeDescription(
                                reactQuillRef.current.getEditorContents()
                              );
                            }
                          } else {
                            onChangeDescription(e);
                          }
                        }}                       
                      /> */}
                      <textarea
                        class="form-control validate"
                        rows="3"
                        required=""
                        spellcheck="false"
                        name="descriptionMint"
                        value={state.descriptionMint}
                        onChange={handleChange}
                        disabled={isEdit ? true : false}
                      ></textarea>
                      {validator.current.message(
                        "description metadata",
                        state.descriptionMint,
                        "required"
                      )}
                      {/* <div className="charactersRemaining">
                        <span>
                          (
                          {state?.descriptionMint
                            ? 1000 - state?.descriptionMint?.length
                            : "1000"}{" "}
                          characters remaining)
                        </span>
                      </div> */}
                    </div>

                    {/* <div className="form-group mb-3">
                      <label for="creator">Creator Name</label>
                      <span className="colorRed">*</span>
                      <input
                        type="text"
                        name="creatorName"
                        className="form-control validate"
                        value={state?.creatorName}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        // onWheel={(event) => event.currentTarget.blur()}
                        disabled={isEdit ? true : false}
                        required
                      />
                      <select
                          className="custom-select tm-select-accounts"
                          name="creatorName"
                          id="creator"
                          value={state.creatorName}
                          onChange={(e) => handleChange(e)}
                          // disabled={isEdit ? true : false}
                          required
                        >
                          <option value="" selected>
                            Select
                          </option>
                          <option value="Alex Hall" selected>
                          Alex Hall
                          </option>
                          <option value="Ian Smith" selected>
                          Ian Smith
                          </option>
                          <option value="Maggie Fang" selected>
                          Maggie Fang
                          </option>
                          <option value="Matt Hawkins" selected>
                          Matt Hawkins
                          </option>
                          {subCategoryList?.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </select>
                    </div> */}
                    {/* <div className="form-group mb-3">
                      <label for="creator">Creator Name</label>
                      <span className="colorRed">*</span>
                      <input
                        type="text"
                        name="creatorName"
                        className="form-control validate"
                        value={state.creatorName}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                      <div className="charactersRemaining">
                        <span>
                          (
                          {state?.creatorName
                            ? 90 - state?.creatorName?.length
                            : "90"}{" "}
                          characters remaining)
                        </span>
                      </div>
                    </div> */}
                    {/* <div className="form-group mb-3">
                      <label for="description">Creator Description</label>
                      <span className="colorRed">*</span>
                      <textarea
                        className="form-control validate"
                        rows="3"
                        required
                        spellcheck="false"
                        maxLength="1500"
                        onInput={maxLengthCheck}
                        name="creatorDescription"
                        value={state.creatorDescription}
                        onChange={(e) => handleChange(e)}
                      ></textarea>
                      <div className="charactersRemaining">
                        <span>
                          (
                          {state?.creatorDescription
                            ? 1500 - state?.creatorDescription?.length
                            : "1500"}{" "}
                          characters remaining)
                        </span>
                      </div>
                    </div> */}
                    {/* <div className="form-group mb-3">
                      <label for="description">Collection Description</label>
                      <textarea
                        className="form-control validate"
                        rows="3"
                        required=""
                        spellcheck="false"
                        maxLength="1000"
                        onInput={maxLengthCheck}
                        name="descriptionMint"
                        value={state?.descriptionMint}
                        onChange={(e) => handleChange(e)}
                      ></textarea>
                      <div className="charactersRemaining">
                        <span>
                          (
                          {state?.descriptionMint
                            ? 1000 - state?.descriptionMint?.length
                            : "1000"}{" "}
                          characters remaining)
                        </span>
                      </div>
                    </div> */}
                    <div className="form-group mb-3">
                      <label for="description">
                        Artist<span className="colorRed">*</span>
                      </label>
                      <Select
                        options={artists}
                        className="react-select"
                        classNamePrefix="react-select"
                        closeMenuOnSelect={true}
                        value={
                          state.artistId
                            ? {
                              label: artists.find(
                                (el) => el?.id === state.artistId
                              )?.artistName,
                              value: state.artistId,
                            }
                            : ""
                        }
                        placeholder={`Select Artist`}
                        isMulti={false}
                        name="artistId"
                        isDisabled={isEdit ? true : false}
                        onChange={(e) => handleSelectChange(e, "artistId")}
                      />
                      {!isEdit &&
                        validator.current.message(
                          "artist",
                          state.artistId,
                          "required"
                        )}
                    </div>
                    <div className="form-group mb-3">
                      <label for="description">
                        Institution<span className="colorRed">*</span>
                      </label>
                      <Select
                        options={institutions}
                        className="react-select"
                        classNamePrefix="react-select"
                        closeMenuOnSelect={true}
                        value={
                          state.institutionId
                            ? {
                              label: institutions.find(
                                (el) => el?.id === state.institutionId
                              )?.name,
                              value: state.institutionId,
                            }
                            : ""
                        }
                        placeholder={`Select Institution`}
                        isMulti={false}
                        name="id"
                        isDisabled={isEdit ? true : false}
                        onChange={(e) => handleSelectChange(e, "institutionId")}
                      />
                      {!isEdit &&
                        validator.current.message(
                          "institution",
                          state.institutionId,
                          "required"
                        )}
                      {state.institutionId ? (
                        <span>
                          <small style={{ wordBreak: "break-all" }}>
                            Institution description -{" "}
                            {
                              institutions?.find(
                                (el) => el?.id === state.institutionId
                              )?.InstitutionDescription
                            }
                          </small>
                        </span>
                      ) : null}
                    </div>
                    <div className="form-group mb-3">
                      <label for="description">
                        Collection<span className="colorRed">*</span>
                      </label>
                      <Select
                        options={collections}
                        className="react-select"
                        classNamePrefix="react-select"
                        closeMenuOnSelect={true}
                        value={
                          state.collectionId
                            ? {
                              label: collections.find(
                                (el) => el?.id === state.collectionId
                              )?.name,
                              value: state.collectionId,
                            }
                            : ""
                        }
                        placeholder={`Select Collection`}
                        isMulti={false}
                        name="collectionId"
                        isDisabled={isEdit ? true : false}
                        onChange={(e) => handleSelectChange(e, "collectionId")}
                      />
                      {!isEdit &&
                        validator.current.message(
                          "collection",
                          state.collectionId,
                          "required"
                        )}
                    </div>
                    <div className="form-group mb-3">
                      <label for="creator">
                        Year of Physical creation
                        <span className="colorRed">*</span>
                      </label>
                      <input
                        type="text"
                        name="physicalCreationYear"
                        className="form-control validate"
                        value={state?.physicalCreationYear}
                        placeholder={`Enter year (YYYY)`}
                        onChange={handleChange}
                        disabled={isEdit ? true : false}
                      />
                      {!isEdit &&
                        validator.current.message(
                          "physicalCreationYear",
                          state.physicalCreationYear,
                          "required"
                        )}
                    </div>
                    <div className="form-group mb-3">
                      <label for="creator">
                        Year of Digital Reproduction
                        <span className="colorRed">*</span>
                      </label>
                      <input
                        type="text"
                        name="digitalReProdYear"
                        className="form-control validate"
                        value={state?.digitalReProdYear}
                        placeholder={`Enter year (YYYY)`}
                        onChange={handleChange}
                        disabled={isEdit ? true : false}
                      />
                      {!isEdit &&
                        validator.current.message(
                          "YearOfDigitalReproduction",
                          state.digitalReProdYear,
                          "required"
                        )}
                    </div>
                    {/* <div className="form-group mb-3">
                      <label for="creator">Year available for minting<span className="colorRed">*</span></label>
                      <input
                        type="text"
                        name="mintingYear"
                        className="form-control validate"
                        value={state?.mintingYear}
                        placeholder={`Enter year (YYYY)`}
                        onChange={handleChange}
                        disabled={isEdit ? true : false}
                      />
                      {!isEdit && validator.current.message('mintingYear', state.mintingYear, 'required|min:4|max:4|numeric')}
                    </div> */}
                    <div className="form-group mb-3">
                      <label for="creator">
                        Image Copyright<span className="colorRed">*</span>
                      </label>
                      <input
                        type="text"
                        name="imageCopyright"
                        className="form-control validate"
                        value={state?.imageCopyright}
                        onChange={handleChange}
                        disabled={isEdit ? true : false}
                      />
                      {!isEdit &&
                        validator.current.message(
                          "imageCopyright",
                          state.imageCopyright,
                          "required"
                        )}
                    </div>
                    <div className="form-group mb-3">
                      <label for="creator">
                        Physical Owner<span className="colorRed">*</span>
                      </label>
                      <input
                        type="text"
                        name="physicalOwner"
                        className="form-control validate"
                        value={state?.physicalOwner}
                        onChange={handleChange}
                        disabled={isEdit ? true : false}
                      />
                      {!isEdit &&
                        validator.current.message(
                          "physicalOwner",
                          state.physicalOwner,
                          "required"
                        )}
                    </div>
                    <div className="form-group mb-3">
                      <label for="creator">
                        Physical Provenance<span className="colorRed">*</span>
                      </label>
                      <input
                        type="text"
                        name="physicalProvenence"
                        className="form-control validate"
                        value={state?.physicalProvenence}
                        onChange={handleChange}
                        disabled={isEdit ? true : false}
                      />
                      {!isEdit &&
                        validator.current.message(
                          "physicalProvenence",
                          state.physicalProvenence,
                          "required"
                        )}
                    </div>
                    <div className="form-group mb-3">
                      <label for="creator">Additional Information </label>
                      <input
                        type="text"
                        name="additionalInfo"
                        className="form-control validate"
                        value={state?.additionalInfo}
                        onChange={handleChange}
                        disabled={isEdit ? true : false}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label for="stock">Royalty Commission (optional) </label>
                      {/* <Tooltip title="Enter value between 5% to 20%">
                          <IconButton aria-label="Enter value between 5% to 20%">
                            <HelpIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip> */}

                      {/* <span className="colorRed">*</span> */}
                      <div className="displayFlexBaseline">
                        <span className="currencyInput royalty-currency">
                          <input
                            className="royalty-commission-field"
                            type="number"
                            name="royaltyCommission"
                            min="0"
                            // value={state?.royaltyCommission}
                            value={0}
                            onWheel={(event) => event.currentTarget.blur()}
                            onKeyDown={formatInput}
                            onChange={(e) => {
                              if (e.target.value >= 0) {
                                handleChange(e);
                              }
                            }}
                            // disabled={isEdit ? true : false}
                            disabled
                          // required
                          />
                          %
                        </span>
                        {/* <input
                          type="number"
                          name="royaltyCommission"
                          min="0"
                          className="form-control validate"
                          value={state?.royaltyCommission}
                          onKeyDown={formatInput}
                          onChange={(e) => {
                            if (e.target.value >= 0) {
                              handleChange(e);
                            }
                          }}
                          disabled={isEdit ? true : false}
                          required
                        /> */}
                        {/* <span className="percentageImgContainer">%</span> */}
                      </div>
                    </div>
                    <div className="row">
                      {state.saleType == "auction" ? (
                        <div className="form-group mb-3 col-xs-12 col-sm-6">
                          <label for="stock">Current Edition</label>
                          {/* <Tooltip title="Enter value between 5% to 20%">
                          <IconButton aria-label="Enter value between 5% to 20%">
                            <HelpIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip> */}

                          <span className="colorRed">*</span>
                          <div className="displayFlexBaseline">
                            {" "}
                            <input
                              type="number"
                              name="currentEdition"
                              min="0"
                              className="form-control validate"
                              value={state?.currentEdition}
                              onKeyDown={formatInputDot}
                              onChange={(e) => {
                                if (e.target.value >= 0) {
                                  handleChange(e);
                                }
                              }}
                              onWheel={(event) => event.currentTarget.blur()}
                              disabled={(state.saleType != "fixed" && state.saleType != "free")}
                              required
                            />
                          </div>
                          {!isEdit &&
                            validator.current.message(
                              "currentEdition",
                              state?.currentEdition,
                              "required"
                            )}
                        </div>
                      ) : null}
                      {/* <Tooltip title="Enter value between 5% to 20%">
                          <IconButton aria-label="Enter value between 5% to 20%">
                            <HelpIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip> */}
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label for="stock">
                          Total Edition<span className="colorRed">*</span>
                        </label>
                        <input
                          type="number"
                          name="totalEdition"
                          min="0"
                          max="100"
                          maxLength="3"
                          className="form-control validate"
                          value={state?.totalEdition}
                          onWheel={(event) => event.currentTarget.blur()}
                          onKeyDown={formatInputDot}
                          onChange={(e) => {
                            if (
                              e.target.value >= 0 &&
                              e.target.value.length < 7
                            ) {
                              handleChange(e);
                            }
                          }}
                          disabled={
                            (state.saleType != "fixed" && state.saleType != "free") || isEdit ? true : false
                          }
                          required
                        />
                        {!isEdit &&
                          validator.current.message(
                            "totalEdition",
                            state?.totalEdition,
                            "required"
                          )}
                        {/* <span className="percentageImgContainer">%</span> 
                         </div> 
                      </div> */}
                      </div>
                    </div>
                    {/* {state.saleType != "fixed" ? (
                      <div className="form-group mb-3">
                        <label for="category">Mint Admin Edition</label>
                        <span className="colorRed">*</span>
                        <FormControl
                          component="fieldset"
                          size="small"
                          classes={{
                            root: classes.formControlRoot,
                          }}
                        >
                          <RadioGroup
                            classes={{
                              root: classes.radioGroupRoot,
                              label: classes.label,
                            }}
                            name="isEditionMintToAdmin"
                            value={state?.isEditionMintToAdmin}
                            onChange={(e) => handleChange(e)}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="Yes"
                              disabled={isEdit ? true : false}
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="No"
                              disabled={isEdit ? true : false}
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    ) : null} */}
                    <div className="row">
                      {state.saleType == "fixed" || state.saleType == "free" ? (
                        <>
                          <div className="form-group mb-3 col-xs-12 col-sm-6">
                            <label for="stock">Unit Available For Sale</label>
                            <div className="displayFlexBaseline">
                              {" "}
                              <input
                                type="number"
                                name="unitAvailableSale"
                                min="0"
                                className="form-control validate"
                                value={
                                  isEdit
                                    ? state?.totalEdition - state?.soldEdition
                                    : state?.totalEdition
                                }
                                onKeyDown={formatInput}
                                onChange={(e) => {
                                  if (e.target.value >= 0) {
                                    handleChange(e);
                                  }
                                }}
                                disabled={true}
                                required
                              />
                            </div>
                          </div>

                          <div className="form-group mb-3 col-xs-12 col-sm-6">
                            <label for="stock">
                              Units In Stock<span className="colorRed">*</span>
                            </label>
                            <input
                              type="number"
                              name="quantity"
                              min="0"
                              className="form-control validate"
                              maxLength="12"
                              onInput={maxLengthCheck}
                              onWheel={(event) => event.currentTarget.blur()}
                              value={state.quantity}
                              onKeyDown={formatInputDot}
                              onChange={(e) => handleChange(e)}
                              required
                            />
                            {validator.current.message(
                              "UnitsInStock",
                              state.quantity,
                              "required"
                            )}
                            {parseInt(state.quantity) >
                              parseInt(state.totalEdition) ? (
                              <p className="field_error">
                                It should not be greater than total edition.{" "}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="form-group mb-3">
                      <label for="category">Sale Status</label>
                      <span className="colorRed">*</span>
                      <FormControl
                        component="fieldset"
                        size="small"
                        classes={{
                          root: classes.formControlRoot,
                        }}
                      >
                        <RadioGroup
                          classes={{
                            root: classes.radioGroupRoot,
                            label: classes.label,
                          }}
                          name="status"
                          // value="inactive"
                          value={state.status}
                          onChange={(e) => handleChange(e)}
                        >
                          <FormControlLabel
                            value="active"
                            control={<Radio />}
                            label="Active"
                            disabled={
                              state.saleType === "auction" ? true : false
                            }
                          />
                          <FormControlLabel
                            value="inactive"
                            control={<Radio />}
                            label="Inactive"
                            disabled={
                              state.saleType === "auction" ? true : false
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {/* {state.saleType !== "auction" ? ( */}
                    {/* // <div className="row"> */}
                    <div
                      className={`form-group mb-3 ${state.saleType === "auction" || state.saleType === "free" ? "d-none" : ""
                        }`}
                    >
                      <label for="expire_date">
                        Price<span className="colorRed">*</span>
                      </label>
                      <div className="addProductFlexRow">
                        <span className="currencyInput">
                          €
                          <input
                            className="currencyInput33"
                            type="number"
                            id="regularPrice"
                            name="regularPrice"
                            min="0"
                            placeholder="Enter Price in Euro"
                            onKeyDown={formatInputDot}
                            value={state.regularPrice}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onWheel={(event) => event.currentTarget.blur()}
                            required
                          />
                        </span>

                        <div className="ethValueDisplayer ethValueDisplayer123">
                          {/* {console.log((state?.regularPrice / (ethValue||1)).toFixed(4),"okok")} */}
                          {(
                            state?.regularPrice *
                            (state?.networkType === "Ethereum"
                              ? ethValue
                              : maticValue)
                          ).toFixed(5) === NaN
                            ? "0"
                            : `${formatETHPrice(
                              state?.regularPrice *
                              (state?.networkType === "Ethereum"
                                ? ethValue
                                : maticValue)
                            )} ${state?.networkType === "Ethereum"
                              ? "ETH"
                              : "MATIC"
                            }`}
                        </div>
                      </div>
                      {validator.current.message(
                        "Price",
                        state.regularPrice,
                        "required"
                      )}
                      {/* </div> */}
                      {/* <div className="form-group mb-3 col-xs-12 col-sm-6">
                          <label for="expire_date">Sale Price</label>
                          <div className="addProductFlexRow">
                            <input
                              type="n1umber"
                              name="salePrice"
                              min="0"
                              className="form-control validate"
                              onKeyDown={formatInput}
                              value={state.salePrice}
                              onChange={(e) => {
                                // if (e.target.value != 0 || e.target.value >= -0 || e.target.value == "") {
                                handleChange(e);
                                // }
                              }}
                              required
                            />
                            <span className="percentageImgContainer">$</span>
                          </div>
                        </div> */}
                      {/* </div> */}
                    </div>
                    {/* ) : null} */}
                    {/* {state.saleType == "fixed" ? (
                      <div className="form-group mb-3">
                        <label for="stock">Units In Stock</label>
                        <span className="colorRed">*</span>
                        <input
                          type="number"
                          name="quantity"
                          min="0"
                          className="form-control validate"
                          value={state.quantity}
                          onKeyDown={formatInputDot}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </div>
                    ) : null} */}
                    {/* <div className="row">
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label for="category">Category</label>
                        <span className="colorRed">*</span>
                        <select
                          className="custom-select tm-select-accounts"
                          name="categoryId"
                          id="categoryId"
                          value={`${state.categoryId}`}
                          onChange={(e) => handleChange(e)}
                          disabled={isEdit ? true : false}
                          required
                        >
                          <option value="" selected>
                            Select
                          </option>
                          {categoryList?.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </select>
                        {countryError && <p className="empty-drop-error-msg">No Subcategory with this Team</p>}
                      </div>
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label for="category">Subcategory</label>
                        <span className="colorRed">*</span>
                        <select
                          className="custom-select tm-select-accounts"
                          name="subCategoryId"
                          id="subCategoryId"
                          value={`${state.subCategoryId}`}
                          onChange={(e) => handleChange(e)}
                          disabled={isEdit ? true : false}
                          required
                        >
                          <option value="" selected>
                            Select
                          </option>
                          {subCategoryList?.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </select>
                        {/* {sportsError && <p className="empty-drop-error-msg">No League with this Sports</p>}

                      </div>
                    </div> */}
                    {/* <div className="row">
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label for="category">League</label>
                        <span className="colorRed">*</span>
                        <select
                          className="custom-select tm-select-accounts"
                          name="leagueId"
                          id="leagueId"
                          value={`${state.leagueId}`}
                          onChange={(e) => handleChange(e)}
                          disabled={isEdit ? true : false}
                          required
                        >
                          <option value="" selected>
                            Select
                          </option>
                          {leagueList?.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </select>
                        {leagueError && <p className="empty-drop-error-msg">No Team with this League</p>}

                      </div>
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label for="category">Team</label>
                        <span className="colorRed">*</span>
                        <select
                          className="custom-select tm-select-accounts"
                          name="teamId"
                          id="teamId"
                          value={`${state.teamId}`}
                          onChange={(e) => handleChange(e)}
                          disabled={isEdit ? true : false}
                          required
                        >
                          <option value="" selected>
                            Select
                          </option>
                          {teamList?.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                    </div> */}
                    <div className="form-group mb-3">
                      <label for="description">
                        Short Description (optional){" "}
                      </label>
                      <textarea
                        class="form-control validate"
                        rows="3"
                        required=""
                        spellcheck="false"
                        maxLength="1500"
                        onInput={maxLengthCheck}
                        name="shortDescription"
                        value={state.shortDescription}
                        onChange={(e) => handleChange(e)}
                      ></textarea>{" "}
                      <div className="charactersRemaining">
                        <span>
                          (
                          {state?.shortDescription
                            ? 1500 - state?.shortDescription?.length
                            : "1500"}{" "}
                          characters remaining)
                        </span>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label for="description">
                        Rarity Level<span className="colorRed">*</span>
                      </label>
                      <Select
                        options={rarityOptions}
                        className="react-select"
                        classNamePrefix="react-select"
                        closeMenuOnSelect={true}
                        value={
                          state.rarityLevel
                            ? {
                              label: rarityOptions.find(
                                (el) => el?.id === state.rarityLevel
                              )?.label,
                              value: state.rarityLevel,
                            }
                            : ""
                        }
                        placeholder={`Select Rarity`}
                        isMulti={false}
                        name="rarityLevel"
                        isDisabled={isEdit ? true : false}
                        onChange={(e) => handleSelectChange(e, "rarityLevel")}
                      />
                      {!isEdit &&
                        validator.current.message(
                          "rarityLevel",
                          state.rarityLevel,
                          "required"
                        )}
                    </div>
                    <div className="form-group mb-3">
                      <label for="category">
                        Show on Carousel<span className="colorRed">*</span>
                      </label>
                      <FormControl
                        component="fieldset"
                        classes={{
                          root: classes.formControlRoot,
                        }}
                      >
                        <RadioGroup
                          classes={{
                            root: classes.radioGroupRoot, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                          }}
                          name="isCarrosel"
                          value={
                            typeof state?.isCarrosel === "boolean"
                              ? state?.isCarrosel
                              : state?.isCarrosel === "true"
                                ? true
                                : false
                          }
                          onChange={(e) => handleChange(e)}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                      {!isEdit &&
                        validator.current.message(
                          "Carrosel",
                          state?.isCarrosel,
                          "required"
                        )}
                    </div>
                    <div className="form-group mb-3">
                      <label for="category">
                        Youtube embed video link (optional) (example: https://www.youtube.com/embed/tgbNymZ7vqY) {" "}
                      </label>
                      <input
                        type="text"
                        name="youtubeEmbedVideoLink"
                        className="form-control validate"
                        value={state?.youtubeEmbedVideoLink}
                        onChange={handleChange}
                      />
                      {/* <div className="form-group mb-3">
                      <label for="category">Air Drop <span className="colorRed">*</span></label>
                      <FormControl
                        component="fieldset"
                        classes={{
                          root: classes.formControlRoot,
                        }}
                      >
                        <RadioGroup
                          classes={{
                            root: classes.radioGroupRoot, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                          }}
                          name="isAirDrop"
                          value={state?.isAirDrop}
                          onChange={(e) => handleChange(e)}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                      {validator.current.message('AirDrop', state?.isAirDrop, 'required')}
                    </div> */}
                      {state?.youtubeEmbedVideoLink && <iframe width="420" height="315" style={{ marginTop: "20px" }}
                        src={state?.youtubeEmbedVideoLink}>
                      </iframe>}
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12 mx-auto mb-4">
                  {state?.ipfsAudioThumbnailPath && (
                    <div
                      className={`tm-product-img-dummy mx-auto addProductFlexCenter height41p imgGreyBg cursorPointer`}
                    >
                      <img
                        src={
                          state?.ipfsAudioThumbnailPath
                            ? state.ipfsAudioThumbnailPath
                            : ImageUpload
                        }
                        className="previewImg"
                        nb8h
                      />
                    </div>
                  )}
                  {/* {state?.ipfs3dUrl && (
                    <ReactThreeFbxViewer
                      cameraPosition={cameraPosition}
                      url={state?.ipfs3dUrl}
                      onLoading={onLoad}
                      onError={onError}
                      width={480}
                      height={260}
                    />
                  )} */}
                  {(state?.ipfsLongVideoUrlPath && !state?.ipfs3dUrl) ||
                    (state?.ipfsAudioUrlPath && !state?.ipfs3dUrl) ? (
                    <ReactPlayer
                      url={
                        state.ipfsLongVideoUrlPath
                          ? !isEdit
                            ? decryptFunction(state.ipfsLongVideoUrlPath)
                            : state.ipfsLongVideoUrlPath
                          : !isEdit
                            ? decryptFunction(state?.ipfsAudioUrlPath)
                            : state?.ipfsAudioUrlPath
                      }
                      width="400px"
                      height={state?.ipfsAudioUrlPath ? "86px" : "300px"}
                      controls={true}
                      config={{
                        file: {
                          attributes: {
                            controlsList: "nodownload",
                            ImageUpload,
                          },
                        },
                      }}
                    // playIcon="true"
                    />
                  ) : (
                    (!state?.ipfs3dUrl || state?.ipfs3dUrl == undefined) && (
                      <div
                        className={`tm-product-img-dummy mx-auto addProductFlexCenter height41p imgGreyBg cursorPointer ${state?.ipfsImageUrlPath ? "" : "addProduct-bg-grey"
                          }`}
                        onClick={handleClickOpen}
                        style={{
                          display: state?.ipfsAudioThumbnailPath
                            ? "none"
                            : "block",
                        }}
                      >
                        <img
                          src={
                            state?.ipfsImageUrlPath
                              ? !isEdit
                                ? decryptFunction(state.ipfsImageUrlPath)
                                : state.ipfsImageUrlPath
                              : ImageUpload
                          }
                          onLoad={() => setShowLoader(false, "")}
                          onError={() => setShowLoader(false, "")}
                          className="previewImg"
                        />
                      </div>
                    )
                  )}
                  <div class="custom-file mt-3 mb-3">
                    <input
                      type="file"
                      id="main_file"
                      style={{ display: "none" }}
                      onClick={() => {
                        document.getElementById(`main_file`).value = null;
                      }}
                      onChange={loadfile}
                    // accept="image/*"
                    />
                    <input
                      type="file"
                      id="supporting_file1"
                      style={{ display: "none" }}
                      onClick={() => {
                        document.getElementById(`supporting_file1`).value =
                          null;
                      }}
                      onChange={loadfile}
                    // accept="audio/*"
                    />
                    <input
                      type="file"
                      id="supporting_file2"
                      style={{ display: "none" }}
                      onClick={() => {
                        document.getElementById(`supporting_file2`).value =
                          null;
                      }}
                      onChange={loadfile}
                    // accept="video/*"
                    />
                    <input
                      type="file"
                      id="supporting_file3"
                      style={{ display: "none" }}
                      onClick={() => {
                        document.getElementById(`supporting_file3`).value =
                          null;
                      }}
                      onChange={loadfile}
                    // accept="video/*"
                    />
                    <input
                      type="file"
                      id="supporting_file4"
                      style={{ display: "none" }}
                      onClick={() => {
                        document.getElementById(`supporting_file4`).value =
                          null;
                      }}
                      onChange={loadfile}
                    // accept="video/*"
                    />

                    <div>
                      Supported File formats: (jpg, png)
                      {/* gif, wav, ogg, mp3, webm, glb, gltf */}
                      <span className="colorRed">*</span>
                      {validator.current.message(
                        "MainImageFile",
                        state.ipfsImageUrlPath &&
                        state?.multiFileStore?.supplimentary1[0],

                        "imgVal"
                      )}
                      {/* {state.ipfsImageUrlPath && validator.current.message('CertificateImage', state.certificateImageUrl, 'required')} */}
                    </div>
                    <input
                      type="button"
                      class="btn btn-primary mx-auto mt-3"
                      value="UPLOAD"
                      // onClick={handleImageUpload}
                      onClick={handleClickOpen}
                    />
                  </div>

                  <div className="row disableAndHideDiv">
                    <div className="form-group mb-3 col-xs-12 col-sm-6">
                      <label for="expire_date">Reserve Price</label>
                      <span className="colorRed">*</span>
                      <div className="addProductFlexRow mb-3">
                        <span
                          className="currencyInput"
                          style={{ width: "60%" }}
                        >
                          €
                          <input
                            type="number"
                            name="openingPrice11"
                            min="0"
                            className="form-control validate reservePricefield"
                            onKeyDown={formatInputDot}
                            value={50}
                            placeholder={0}
                            disabled={isEdit ? true : false}
                            onChange={(e) => {
                              if (e.target.value > 0 || e.target.value == "") {
                                handleChange(e);
                              }
                            }}
                            onWheel={(event) => event.currentTarget.blur()}
                            required
                          />
                        </span>
                        {/* <input
                          type="number"
                          name="openingPrice11"
                          min="0"
                          className="form-control validate"
                          onKeyDown={formatInputDot}
                          value={50}
                          onChange={(e) => {
                            if (e.target.value > 0 || e.target.value == "") {
                              handleChange(e);
                            }
                          }}
                          required
                        /> */}
                        {/* <span className="percentageImgContainer">$</span> */}
                      </div>
                    </div>
                    <div className="form-group mb-3 col-xs-12 col-sm-6">
                      <label for="expire_date">Lowest Price to accept</label>
                      {/* <span className="colorRed">*</span> */}
                      <div className="addProductFlexRow">
                        <input
                          type="number"
                          name="lowestPriceToAccept"
                          id="lowestPriceToAccept11"
                          min="0"
                          className="form-control validate"
                          onKeyDown={formatInput}
                          value={50}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          required
                        />
                        <span className="percentageImgContainer">$</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="form-group mb-3 col-xs-12 col-sm-6">
                      <label for="category">Downloadable</label>
                      <FormControl
                        component="fieldset"
                        size="small"
                        classes={{
                          root: classes.formControlRoot,
                        }}
                      >
                        <RadioGroup
                          classes={{
                            root: classes.radioGroupRoot,
                            label: classes.label,
                          }}
                          name="isDownloadable"
                          value={state?.isDownloadable}
                          onChange={(e) => handleChange(e)}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="No"
                            checked={
                              state?.isDownloadable == "true" ? false : true
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </div> */}
                  </div>
                  {/* {state.saleType != "auction" ? ( */}

                  {/* ) : null} */}

                  {/* <div className="row">
                    <div className="form-group mb-3 col-xs-12 col-sm-6"></div>
                    <div className="form-group mb-3 col-xs-12 col-sm-6"></div>
                    {state.saleType == "auction" ? (
                      <>
                        <div className="form-group mb-3 col-xs-12 col-sm-6">
                          <label for="category">Product Condition</label>
                          <span className="colorRed">*</span>
                          <select
                            className="custom-select tm-select-accounts"
                            name="productCondition"
                            id="productCondition"
                            value={`${state.productCondition}`}
                            onChange={(e) => handleChange(e)}
                            required
                          >
                            <option value="" selected>
                              Select
                            </option>
                            {productConditionList?.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group mb-3 col-xs-12 col-sm-6">
                          <label for="category">Auction Type</label>
                          <span className="colorRed">*</span>
                          <select
                            className="custom-select tm-select-accounts"
                            name="auctionType"
                            id="auctionType"
                            value={`${state.auctionType}`}
                            onChange={(e) => handleChange(e)}
                            required
                          >
                            <option value="" selected>
                              Select
                            </option>
                            {auctionTypeList?.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>
                        </div>
                      </>
                    ) : null}
                  </div> */}

                  {state.saleType == "auction" ? (
                    <div className="row">
                      <div className="form-group mb-3 paddingLeft14px col-12">
                        <label for="expire_date">
                          Reserve Price<span className="colorRed">*</span>
                        </label>
                        <div className="addProductFlexRow">
                          <span className="currencyInput">
                            €
                            <input
                              type="number"
                              id="openingPrice"
                              name="openingPrice"
                              min="0"
                              placeholder={0}
                              onKeyDown={formatInputDot}
                              value={state?.auction?.openingPrice}
                              onChange={(e) => {
                                // if (e.target.value > 0 || e.target.value == "") {
                                handleChange(e);
                                // }
                              }}
                              required
                            // disabled={isEdit ? true : false}
                            // disabled={
                            //   state?.purchasedBy == null ? false : true
                            // }
                            />
                          </span>
                          <div className="ethValueDisplayer">{`${formatETHPrice(
                            state?.auction?.openingPrice *
                            (state?.networkType === "Ethereum"
                              ? ethValue
                              : maticValue)
                          )} ${state?.networkType === "Ethereum" ? "ETH" : "MATIC"
                            }`}</div>
                        </div>
                        {!isEdit &&
                          validator.current.message(
                            "ReservePrice",
                            state?.auction?.openingPrice,
                            "required"
                          )}
                      </div>
                      {/* <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label for="expire_date">Lowest Price to accept</label>
                        <div className="addProductFlexRow">
                          <input
                            type="number"
                            name="lowestPriceToAccept"
                            id="lowestPriceToAccept"
                            min="0"
                            className="form-control validate"
                            onKeyDown={formatInput}
                            value={state?.auction?.lowestPriceToAccept}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            required
                          />
                          <span className="percentageImgContainer">$</span>
                        </div>
                      </div> */}
                    </div>
                  ) : null}
                  {/* {state.saleType == "auction" ? (
                    <div className="row">
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label for="expire_date">Bid Increment</label>
                        <div className="addProductFlexRow">
                          <input
                            type="number"
                            name="bidIncrement"
                            min="0"
                            className="form-control validate"
                            onKeyDown={formatInput}
                            value={state?.auction?.bidIncrement}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            required
                          />
                          <span className="percentageImgContainer">$</span>
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  {/* {state.saleType == "auction" ? (
                    <div className="row">
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label for="expire_date">Buy Now Price </label>
                    
                        <div className="addProductFlexRow">
                          <input
                            type="number"
                            name="auctionBuyNowPrice"
                            min="0"
                            className="form-control validate"
                            onKeyDown={formatInput}
                            value={state.auctionBuyNowPrice}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            required
                          />
                          <span className="percentageImgContainer">$</span>
                        </div>
                      </div>
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label for="category">No of Bids</label>
                        <input
                          type="number"
                          name="numberOfBid"
                          min="0"
                          className="form-control validate"
                          onKeyDown={formatInput}
                          value={state?.auction?.numberOfBid}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          required
                        />
                      </div>
                    </div>
                  ) : null} */}
                  {state.saleType == "auction" ? (
                    <div className="row">
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label for="expire_date">Start Date </label>
                        <span className="colorRed">*</span>
                        {/* <TextField
                          id="datetime-local"
                          name="startDate"
                          label="Start Date"
                          type="datetime-local"
                          value={state.startDate}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        /> */}
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DateTimePicker
                            // variant="inline"
                            value={state?.auction?.startDate}
                            disablePast={true}
                            format="dd/MM/yyyy hh:mm a"
                            // onChange={handleDateChange}
                            disabled={isEdit ? true : false}
                            onChange={handleStartDateChange}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                        <label for="category">End Date</label>
                        <span className="colorRed">*</span>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DateTimePicker
                            // variant="inline"
                            value={state?.auction?.endingDate}
                            disablePast={true}
                            format="dd/MM/yyyy hh:mm a"
                            disabled={isEdit ? true : false}
                            // onChange={handleDateChange}
                            onChange={handleEndingDateChange}
                          />
                        </MuiPickersUtilsProvider>
                        {/* <TextField
                          id="datetime-local"
                          name="endingDate"
                          label="End Date"
                          type="datetime-local"
                          value={state.endingDate}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        /> */}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/* </div> */}

              <div className="col-12" style={{ padding: "15px 0px" }}>
                <button
                  type="submit"
                  className="btn btn-primary text-uppercase"
                  onClick={handleSubmit}
                // disabled={
                //     state.name ||
                //     state.description ||
                //     state.salePrice ||
                //     state.quantity ||
                //     state.imageUrl
                //     ? false
                //     : true
                // }
                >
                  {isEdit == true ? "Save" : "Add"} Asset Now
                </button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  maxWidth="md"
                // style={{
                //   position: "fixed",
                //   zIndex: 300,
                //   right: "0px",
                //   bottom: "0px",
                //   top: "0px",
                //   left: "0px",
                // }}
                >
                  <DialogTitle>Upload file</DialogTitle>
                  <DialogContent>
                    {/* <DialogContentText>
                        To subscribe to this website, please enter your email
                        address here. We will send updates occasionally.
                      </DialogContentText> */}
                    <UploadFileModal
                      handleMultiFileUpload={handleMultiFileUpload}
                      setSelectedVideoFileType={setSelectedVideoFileType}
                      setSelectedAudioType={setSelectedAudioType}
                      state={state}
                      handleClose={handleClose}
                      previewVideoNeeded={previewVideoNeeded}
                      previewImgNeeded={previewImgNeeded}
                      preview3DNeeded={preview3DNeeded}
                      disableSupportingDocs={disableSupportingDocs}
                      isSuplimentaryFile1Video={isSuplimentaryFile1Video}
                      isSuplimentaryFile2Video={isSuplimentaryFile2Video}
                      isSuplimentaryFile3Video={isSuplimentaryFile3Video}
                      isSuplimentaryFile4Video={isSuplimentaryFile4Video}
                      handleRemoveFile={handleRemoveFile}
                      handleDeleteAll={handleDeleteAll}
                    />
                    {/* <UploadMediaModal
                      handleImageUpload={handleImageUpload}
                      setSelectedVideoFileType={setSelectedVideoFileType}
                      setSelectedAudioType={setSelectedAudioType}
                      state={state}
                    /> */}
                  </DialogContent>
                  {/* <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                  </DialogActions> */}
                </Dialog>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddProduct;
