import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import OrderDetails from "./OrderDetails";
import Preview from "../../../assets/images/icons/preview.svg";
import Tooltip from "@material-ui/core/Tooltip";
import Loader from "../../common/Loader/Loader";
import { formatAPIError } from "../../../utility/helper";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { axiosInstance } from "../../../utility/api";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({ orderId }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    getOrderDetails()
  };
  const handleClose = () => {
    setOpen(false);
  };

  const token = JSON.parse(localStorage.getItem("token"));
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);

  const createData = (product, quantity, total, ethPrice) => {
    return { product, quantity, total, ethPrice };
  };

  const getOrderDetails = async () => {
    if (orderId) {
      setLoading(true)
      const result = await axiosInstance
        .get(`/v1/admin/order/${orderId}`, header)
        .then((result) => {
          setRows([
            createData(
              result?.data?.result?.asset?.name,
              result?.data?.result?.asset?.quantity,
              result?.data?.result?.totalPrice,
              result?.data?.result?.ethPrice
            ),
          ]);
          setOrderDetails(result?.data?.result);
          setLoading(false)
          setOpen(true);
        })
        .catch((err) => {
          let errMsg = formatAPIError(err)
          Swal.fire("Alert!", errMsg, "error");
          setLoading(false)
        });
    }
  };

  return (
    <div style={{ display: "inline-block" }}>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Preview
      </Button> */}
      <Tooltip title="Preview">
        <img
          src={Preview}
          className="cursorPointer"
          alt="preview"
          onClick={handleClickOpen}
        />
      </Tooltip>
      {loading 
      ? <Loader loaderInfo={`Please Wait. Loading Data`} />
      : <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Order Details
          </DialogTitle>
          <DialogContent dividers>
            <OrderDetails rows={rows} orderDetails={orderDetails} />
          </DialogContent>
          {/* <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Ok
            </Button>
          </DialogActions> */}
        </Dialog>}
    </div>
  );
}
