import React from "react";

export default function Wrapper(Component) {
  return () => (
    <div className="outer">
      <div className="inner">
        <Component />
      </div>
    </div>
  );
}
