/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from "../../common/Loader/Loader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SearchIcon from "@material-ui/icons/Search";
import Swal from "sweetalert2";
import moment from "moment";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";

import "./OrderTable.css";
import { axiosInstance, baseURL } from "../../../utility/api";
import NoRecordsFound from "../../common/Error/NoRecordsFound";
import OrderDetailsModal from "../OrderDetails/OrderDetailsModal";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ReplayIcon from '@material-ui/icons/Replay';
import Tooltip from "@material-ui/core/Tooltip";
import DataRangePopOver from "./dateRanagePopOver";
import { formatDateTime } from "../../common/date";
import Preview from "../../../assets/images/icons/preview.svg";
import Download from "../../../assets/images/icons/download.svg";
import { formatAPIError, formatETHPrice, formatEuroPrice } from '../../../utility/helper'
import axios from "axios";

const useRowStyles = makeStyles({
  root: {
    marginTop: "0px",
    border: "none",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5FC",
  },
  rowDatas: {
    "& td,th": {
      border: "none",
      height: "90px",
    },
  },
  border__right: {
    borderRight: "2px solid white",
    height: "70%",
  },
  cursorDefault: {
    cursor: "default",
  },
  height24: {
    height: "24px",
  },
});

//Each row of table
function Row(props) {
  const { data, tableColumnWidth, maticValue, getOrderList } = props;
  const [open, setOpen] = React.useState(false);
  const [openPreview, setOpenPreview] = useState({ open: false, id: data?.id });
  // const token = JSON.parse(localStorage.getItem("token"));
  const classes = useRowStyles();
  const token = JSON.parse(localStorage.getItem("token"));
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  
  function convertMsToHM(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
  
    seconds = seconds % 60;
    // 👇️ if seconds are greater than 30, round minutes up (optional)
    minutes = seconds >= 30 ? minutes + 1 : minutes;
  
    minutes = minutes % 60;
  
    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    // hours = hours % 24;
    // return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
    return isNaN(hours) ? 0 : hours
  }

  const diff = new Date() - new Date(data?.updatedAt)

  const handleRetry = (e) => {
    e.stopPropagation();
    axiosInstance.put(`/v1/admin/order/retry/${data.id}`,{},header).then((res) => {
      setTimeout(() => {
        getOrderList()
      },3000)
      Swal.fire({
        title: "Success",
        html: res?.data?.message,
        icon: "success",
      })
    })
    .catch(err => {
      Swal.fire({
        title: "Error",
        html: err?.response?.data?.message || "Error in minting",
        icon: "error",
      })
    })
  }

  return (
    <React.Fragment>
      <TableRow
        onClick={() => {
          setOpen(!open);
        }}
        className={classes.rowDatas}
        style={{ borderBottom: "12px solid #F5F5FC", cursor: "pointer" }}
      >
        <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
          <div className="columnStyles">{data?.id}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
          <div className="columnStyles ws-nowrap">
          {data?.createdAt ? moment(data?.createdAt).format('MMMM Do YYYY, h:mm a') : "-"}
            {/* {data?.createdAt ? formatDateTime(data?.createdAt) : "-"} */}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div
            class={`columnStyles messageBoxStyle ${
              data?.status === "success" ? "success" : data?.status === "failed" ? 'failed' : "pending"
            }`}
          >
            {data?.status === "queue" && convertMsToHM(diff) > 0 ? <>{data?.status}<ReplayIcon onClick={handleRetry}/></> : data?.status}
          </div>
        </TableCell>
        {/* <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <OrderDetailsModal orderId={data?.id} />
        </TableCell> */}
        <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div className="columnStyles">{data?.from && data?.from}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <div className="columnStyles ws-nowrap">
            {data?.asset?.name && data?.asset?.name}
          </div>
        </TableCell>
        <TableCell
          style={{
            width: `${tableColumnWidth[5]}%`,
            textTransform: "capitalize",
          }}
        >
          <div className="columnStyles">
            {data?.paymentType ? data?.paymentType : "-"}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[6]}%` }}>
          <div className="columnStyles ws-nowrap">
            {`${formatEuroPrice(data?.unitPrice)} € (${  
              data?.ethPrice != null 
                ? `${formatETHPrice(data?.ethPrice)} ${data?.networkType === "Polygon" ? "MATIC":"ETH"}`
                : "User skipped transaction"
            })`}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[7]}%` }}>
          <OrderDetailsModal orderId={openPreview?.id} />
          {/* <Tooltip title="Download">
            <img
              src={Download}
              className="marginLeft20 cursorPointer"
              alt="download"
            />
          </Tooltip> */}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            borderBottom: "12px solid rgb(245, 245, 252)",
          }}
          colSpan={8}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {/* <ExpandedRow
                rowData={data}
                setShowLoader={(e) => setShowLoader(e)}
                showLoader={showLoader}
              /> */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {/* {openPreview?.open ? (
        <OrderDetailsModal orderId={openPreview?.id} />
      ) : null} */} 
    </React.Fragment>
  );
}

const RenderHeader = ({ columns, onSorting }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  const classes = useRowStyles();
  return (
    <TableHead className={`thead-white-space-nowrap ${classes.header}`}>
      <TableRow>
        {columns?.map(({ name, field, sortable, image }) => (
          <TableCell
            align={columns.align}
            key={name}
            className={classes.cursorDefault}
          >
            <div className="dividerFlex">
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
                {/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function OrderTable({ data, showLoader, setShowLoader }) {
  const classes = useRowStyles();
  const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();
  let day = today.getDate();
  let date1MonthBefore = new Date(year, month - 1, day);
  const [orderList, setOrderList] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectionRange, setSelectionRange] = useState({
    startDate: date1MonthBefore,
    endDate: new Date(),
    key: "selection",
  });
  const [searchText, setSearchText] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [statusFilterSelected, setStatusFilterSelected] = useState("");
  const [maticValue, setMaticValue] = useState(0)
  const token = JSON.parse(localStorage.getItem("token"));
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const tableColumnWidth = [10, 15, 10, 20, 20, 15, 15, 7];
  const columns = [
    {
      name: "Order ID",
      field: "order",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Date",
      field: "date",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Status",
      field: "status",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    // {
    //   name: "View",
    //   field: "view",
    //   // image: Divider,
    //   numeric: true,
    //   sortable: false,
    //   align: "left",
    // },
    {
      name: "Wallet Address",
      field: "address",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Asset",
      field: "asset",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Payment Method",
      field: "paymentMethod",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Net Sales(€)",
      field: "newsale",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Action",
      field: "action",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
  ];

  useEffect(() => {
    getOrderList();
  }, [selectionRange, statusFilterSelected, searchText, pageNo]);

  const getpricefeed = async () => {
    const url = process.env.REACT_APP_API_URL + "v1";
    await axios.get(url + "/admin/exchange/rate").then((res) => {
      setMaticValue(res.data.result.exchangeRateMatic);
    });
  };

  useEffect(() => {
    getpricefeed();
  }, []);

  const handleSelect = (ranges) => {
    setSelectionRange(ranges?.selection);
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  };

  const handleChangePage = (event, nextPageNo) => {
    setPageNo(nextPageNo);
    window.scrollTo(0, 0);
  };

  const downloadReport = () => {
    const url = `${baseURL}v1/admin/order/download`;
    window.open(url, "_blank");
  };

  const getOrderList = async () => {
    let startDate = new Date(
      selectionRange?.startDate?.getTime() -
        selectionRange?.startDate?.getTimezoneOffset() * 60000
    ).toISOString();
    let endDate = new Date(
      selectionRange?.endDate?.getTime() -
        selectionRange?.endDate?.getTimezoneOffset() * 60000
    ).toISOString();
    let statusFilter = statusFilterSelected == -1 ? "" : statusFilterSelected;
    const result = await axiosInstance
      .get(
        `/v1/admin/order?page=${
          pageNo + 1
        }&fromDate=${startDate}&toDate=${endDate}&search=${encodeURIComponent(
          searchText
        )}&status=${statusFilter}`,
        header
      )
      .then((result) => {
        setOrderList(result?.data?.result?.orders);
        setTotalCount(result?.data?.result?.totalItems);
      })
      .catch((err) => {
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error").then(() => {
          if(err?.response?.status === 401 && err?.response?.data?.message?.includes("authenticated")){
            console.log('err.response', err.response)
            window.location.href = "/welcome/:layout"
          }
        })
      });
      //   if (
      //     err?.response &&
      //     err?.response?.data?.errors &&
      //     err?.response?.data?.errors?.length > 1
      //   ) {
      //     Swal.fire({
      //       title: "Oops!",
      //       text: err?.response?.data?.errors.map((err) => err.msg),
      //       icon: "error",
      //     });
      //   } else {
      //     Swal.fire("Oops!", err?.response?.data?.message, "error");
      //   }
      // });
  };

  return (
    <div className="table_div tableWidth100">
      {showLoader == true ? <Loader /> : null}
      <div className="searchBoxProduct">
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<CloudDownloadIcon>Report</CloudDownloadIcon>}
          onClick={() => downloadReport()}
        >
          Report
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setSelectionRange({
              startDate: date1MonthBefore,
              endDate: new Date(),
              key: "selection",
            });
            setStatusFilterSelected(-1);
            setSearchText("");
          }}
        >
          Reset Filter
        </Button>
        <DataRangePopOver
          selectionRange={selectionRange}
          setSelectionRange={setSelectionRange}
          handleSelect={handleSelect}
        />
        {/* <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} /> */}
        <FormControl variant="outlined" className={classes.height24}>
          <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
          <Select
            native
            value={statusFilterSelected}
            onChange={(e) => setStatusFilterSelected(e.target.value)}
            label="Filter"
            inputProps={{
              name: "Filter",
              id: "outlined-age-native-simple",
            }}
            IconComponent={FilterListIcon}
            displayEmpty={true}
          >
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                clipRule="evenodd"
              />
            </svg>
            <optgroup label="Status">
              <option value={-1}>All</option>
              <option value={"success"}>Success</option>
              <option value={"pending"}>Pending</option>
              <option value={"failed"}>Failed</option>
            </optgroup>
            {/* <optgroup label="Category"> */}
            {/* {categoryList?.map((option) => (
                <option value={option.id}>{option.name}</option>
              ))} */}
            {/* </optgroup> */}
          </Select>
        </FormControl>
        <Autocomplete
          id="search-box-product"
          options={searchSuggestions}
          disableClearable={true}
          clearOnBlur={false}
          getOptionLabel={(option) => {
            return option.name || "";
          }}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setPageNo(0);
              }}
              // onKeyDown={(e) => handleSearchBoxKeyDown(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              label="Search Order"
              variant="outlined"
              placeholder="Search"
            />
          )}
        />
      </div>
      {orderList?.length !== 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" className={classes.root}>
              <RenderHeader
                columns={columns}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <TableBody style={{ width: "100%" }}>
                {orderList?.map((row, index) => (
                  <Row
                    aria-label="expand row"
                    size="small"
                    key={row?.id}
                    row={row}
                    setSelectedRow={(value) => setSelectedRow(value)}
                    selectedRow={selectedRow}
                    data={orderList[index]}
                    tableColumnWidth={tableColumnWidth}
                    maticValue={maticValue}
                    getOrderList={getOrderList}
                    // setOpenEdit={setOpenEdit}
                    // setShowLoader={setShowLoader}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            // backIconButtonProps={{ disabled: false }}
            component="div"
            count={totalCount}
            rowsPerPage={10}
            page={pageNo}
            onChangePage={handleChangePage}
            // onChange={(e) => handleChangePage(e)}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => (
              <>
                <span className="paginationLabel">Showing </span>
                {`${from}-${to}`}
                <span className="paginationLabel"> out of </span>
                {`${count}`}
              </>
            )}
          />
        </>
      ) : (
        <div className="noResultFound flexColumnCenter">
          <NoRecordsFound message="No records found." />
        </div>
      )}
    </div>
  );
}
