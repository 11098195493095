import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import { confirmAlert } from "react-confirm-alert";
import Button from "@material-ui/core/Button";
import "./tableExpandedRow-MaterialUI.css";
import EditIcon from "../../../../assets/images/icons/edit.svg";
import PreviewIcon from "../../../../assets/images/icons/preview.svg";
import DeleteIcon from "../../../../assets/images/icons/delete.svg";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import EuroIcon from "@material-ui/icons/Euro";

import { axiosInstance, customerBaseURL,customerFrontEndURL} from "../../../../utility/api";
import UiDrawer from "../../../Sidedrawer/Uidrawer";
import Swal from "sweetalert2";
import { formatAPIError } from "../../../../utility/helper";
import decryptURl from "../../decryptURL";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },

  inputWidthHeight: {
    maxHeight: "40px",
    maxWidth: "64%",
  },
}));

// const inputProps = {
//   width: "50%",
//   height: "10px",
// };

export default function ExpandedRow(props) {
  const { rowData, setShowLoader, showLoader, institutions, collections, artists } = props;
  const classes = useStyles();

  // const [checked, setChecked] = useState({
  //   unlimited: false,
  //   outofstock: false,
  // });

  const [salePrice, setSalePrice] = useState(null);
  const [regularPrice, setRegularPrice] = useState(null);
  const [stock, setStock] = useState(null);
  // const [deleteProductID, setDeleteProductID] = useState(null);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setRegularPrice(rowData?.regularPrice);
    setSalePrice(rowData?.salePrice);
    setStock(rowData?.quantity);
  }, [rowData?.regularPrice, rowData?.salePrice, rowData?.quantity]);

  const deleteProduct = (id) => {
    if (id) {
      confirmAlert({
        title: "Delete Asset?",
        message: "Are you sure to delete.",
        buttons: [
          {
            label: "Yes",
            onClick: () => handleDelete(id),
          },
          {
            label: "No",
            onClick: () => console.log(""),
          },
        ],
      });
    }
  };
  const handleDelete = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };
    axiosInstance
      .delete(`/v1/admin/asset/${id}`, config)
      .then((res) => {
        window.location.reload(true);
        Swal.fire("Success!", res?.data?.message, "success");
      })
      .catch((err) => {
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error");
      });
  };

  const handlePriceStockUpdate = ({ id }) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };

    let body = {
      salePrice: salePrice,
      regularPrice: regularPrice,
      quantity: stock,
    };
    axiosInstance
      .put(`/v1/admin/asset/${id}`, body, config)
      .then((res) => {
        Swal.fire("Success!", res?.data?.message, "success");
        window.location.reload(true);
      })
      .catch((err) => {
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error");
      });
  };

  const formatInputDot = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum =
        e.key === "e" || e.key === "+" || e.key === "-" || e.key === ".";
    } else if (e.keyCode !== undefined) {
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 187 ||
        e.keyCode === 189 ||
        e.keyCode === 190 ||
        e.keyCode === 110;
    }
    return checkIfNum && e.preventDefault();
  };

  const formatInput = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum = e.key === "e" || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      checkIfNum = e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const handleInputChange = (event) => {
    let value = "";
    if (event.target.value != "") {
      if (
        event.target.value.startsWith(".0") ||
        event.target.value.startsWith("0")
      ) {
        value = event.target.value;
      } else {
        value =
          event.target.value < 0.0000000000000001 ? "" : event.target.value;
      }
    }
    switch (event.target.name) {
      case "regularPrice":
        setRegularPrice(value);
        break;
      case "salePrice":
        setSalePrice(value);
        break;

      default:
        break;
    }
  };

  const handleStockInputChange = (event) => {
    let value = "";
    if (event.target.value != "") {
      value = event.target.value < 0 ? "" : event.target.value;
    }
    setStock(value);
  };
console.log('decryptURl(rowData?.ipfsMetaUrl)', decryptURl(rowData?.ipfsMetaUrl))
  return (
    <>
      <div className="tableExpandWrapper">
        <div className="tableExpandHeader">
          <div className="section-three">
            {(rowData?.saleType == "auction" &&
              !rowData?.auctions?.[0]?.isExpired) ||
            !rowData?.purchasedBy ? (
              <Tooltip title="Edit Product">
                <img
                  src={EditIcon}
                  alt="edit"
                  className="marginLeft14 cursorPointer"
                  height="24px"
                  onClick={() => setOpen(true)}
                />
              </Tooltip>
            ) : null}
            <a href={`${customerFrontEndURL}product-details/${rowData?.id}`} target="_blank">
              <Tooltip title="Preview">
                <img
                  src={PreviewIcon}
                  alt="preview"
                  className="marginLeft14 cursorPointer"
                  height="28px"
                />
              </Tooltip>
            </a>
            {(rowData?.saleType == "auction" &&
              !rowData?.auctions?.[0]?.isExpired) ||
            !rowData?.purchasedBy ? (
              <Tooltip
                title="Delete Asset"
                onClick={() => {
                  // setDeleteProductID(rowData?.id);
                  deleteProduct(rowData?.id);
                }}
              >
                <img
                  src={DeleteIcon}
                  alt="delete"
                  className="marginLeft14 cursorPointer"
                  height="24px"
                />
              </Tooltip>
            ) : null}
                        {
              rowData?.ipfsMetaUrl ? rowData?.saleType === "auction" ? (
                <a href={`https://gateway.pinata.cloud/ipfs/${decryptURl(rowData?.ipfsMetaUrl)}`} style={{marginLeft:"10px"}} target="_blank">
                  Ipfs metadata
              {/* <Tooltip title="Preview">
                <img
                  src={PreviewIcon}
                  alt="preview"
                  className="marginLeft14 cursorPointer"
                  height="28px"
                />
                IPFS
              </Tooltip> */}
            </a>
              )
              : rowData?.purchasedBy && (
                <a href={`https://gateway.pinata.cloud/ipfs/${decryptURl(rowData?.ipfsMetaUrl)}`} style={{marginLeft:"10px"}} target="_blank">
                  Ipfs metadata</a>
              )
              : null
            }
          </div>
        </div>
        <UiDrawer
          open={open}
          institutions={institutions}
          collections={collections}
          artists={artists}
          onClose={() => setOpen(false)}
          data={rowData}
          setShowLoader={() => setShowLoader(!showLoader)}
          isEdit={true}
        />
      </div>
    </>
  );
}

ExpandedRow.propTypes = {
  graphType: PropTypes.string,
  apigraphData: PropTypes.array,
  onChangeGraph: PropTypes.func,
};
