import React from 'react'
import ChangePassword from './Change-Password/ChangePassword'
import EditProfile from './EditProfile/EditProfile'

const AccountSetting = () => {
  return (
    <div style={{paddingTop:'23px'}}>
        <EditProfile/>
        <ChangePassword/>
    </div>
  )
}

export default AccountSetting