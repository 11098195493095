import React, { useState, useEffect } from "react";
import "./OrderDetails.css";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Swal from "sweetalert2";
import moment from "moment";
import Loader from "../../common/Loader/Loader";
import { axiosInstance } from "../../../utility/api";
import { formatDateTime } from "../../common/date";
import { formatETHPrice } from "../../../utility/helper";

const useStyles = makeStyles({
  table: {
    minWidth: 500,
    maxWidth: 500,
  },

  tableCellStyle: {
    display: "table-cell",
    padding: "16px",
    fontSize: "0.875rem",
    textAlign: "left",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.43,
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    letterSpacing: "0.01071em",
    verticalAlign: "inherit",
    opacity: "70%",
    wordBreak: "break-all",
  },
});

export default function OrderDetails({ rows, orderDetails }) {

  const classes = useStyles();

  return (
    <div style={{ width: "500px" }}>
      <div className="order-details-billing flexColumnWrap">
        <h4>Billing details</h4>
        {/* <div className="order-details-content">final data</div> */}
        <div className="marginTop1p">
          <div className="billing-details-label">
            <strong>Order ID</strong>
          </div>
          <div className="order-details-content">
            {orderDetails?.id ? orderDetails?.id : ""}
          </div>
        </div>
        <div className="marginTop1p">
          <div className="billing-details-label">
            <strong>Email</strong>
          </div>

          <div className="order-details-content">
            <a href="mailto:final@mailinator.com">
              {orderDetails?.user?.email ? orderDetails?.user?.email : "-"}
            </a>
          </div>
        </div>
        <div className="marginTop1p">
          <div className="billing-details-label">
            <strong>Date</strong>
          </div>

          <div className="order-details-content">
            {orderDetails?.createdAt
              ? formatDateTime(orderDetails?.createdAt)
              : "-"}
          </div>
        </div>
        <div className="marginTop1p">
          <div className="billing-details-label">
            <strong>Payment via</strong>
          </div>
          <div className="order-details-content">
            {orderDetails?.paymentType == null
              ? "Metamask"
              : orderDetails?.paymentType}
          </div>
        </div>
        <div className="marginTop1p">
          <div className="billing-details-label">
            <strong>Purchased by</strong>
          </div>
          <div className="order-details-content">
            {orderDetails?.user?.email
              ? orderDetails?.user?.email
              : orderDetails?.user?.publicAddress}
          </div>
        </div>
        <div className="marginTop1p">
          <div className="billing-details-label">
            <strong>Sold by</strong>
          </div>
          <div className="order-details-content">
            {orderDetails?.to && orderDetails?.to}
          </div>
        </div>
      </div>
      <div className="order-details-products marginTop1p">
        <div className="billing-details-label">
          <strong>Asset details</strong>
        </div>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  classes={{ root: classes.tableCellStyle }}
                >
                  Asset
                </TableCell>
                <TableCell
                  align="left"
                  classes={{ root: classes.tableCellStyle }}
                >
                  Quantity
                </TableCell>
                {/* <TableCell
                  align="left"
                  classes={{ root: classes.tableCellStyle }}
                >
                  Tax
                </TableCell> */}
                <TableCell
                  align="left"
                  classes={{ root: classes.tableCellStyle }}
                >
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell
                    align="left"
                    classes={{ root: classes.tableCellStyle }}
                    style={{ width: "40%" }}
                  >
                    <div>
                      <div>{row?.product}</div>
                      {/* <div>
                        <strong>Purchased by :</strong>
                        {orderDetails?.user?.email
                          ? orderDetails?.user?.email
                          : orderDetails?.user?.publicAddress}
                      </div> */}
                    </div>
                  </TableCell>
                  <TableCell
                    align="left"
                    classes={{ root: classes.tableCellStyle }}
                    style={{ width: "20%" }}
                  >
                    {row?.quantity}
                  </TableCell>
                  {/* <TableCell
                    align="left"
                    classes={{ root: classes.tableCellStyle }}
                    style={{ width: "20%" }}
                  >
                    {row?.tax}
                  </TableCell> */}
                  <TableCell
                    align="left"
                    classes={{ root: classes.tableCellStyle }}
                    style={{ width: "40%" }}
                  >
                    {`${row?.total} € (${formatETHPrice(row?.ethPrice)} ${orderDetails?.networkType === "Polygon" ? "MATIC":"ETH"})`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
