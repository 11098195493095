/* eslint-disable react/prop-types */
import React from "react";
import "./Loader.css";

//Loader function
export default function MiniLoader({ loaderInfo, customize }) {
  return (
    <div
      className={
        customize ? "customizeStyle loaderContianer" : "loaderContianer"
      }
      style={{
        zIndex: 2000,
      }}
    >
      <div className={customize ? "overlayLoaderMini" : "overlayLoader"}></div>
      <div className="loaderContent">
        <div className="loader"></div>
        <div className="loaderInfo">{loaderInfo}</div>
      </div>
    </div>
  );
}
