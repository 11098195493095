import React from "react";
import { Drawer } from "@material-ui/core";
import Loader from "../../common/Loader/Loader";
import { axiosInstance } from "../../../utility/api";
import Swal from "sweetalert2";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
// import AddAuctionForm from "./AddAuctionForm";

const useStyles = makeStyles((theme) => ({
  drawerRoot: {
    marginTop: "750px",
    border: "none",
    width: "100%",
  },
}));

class UiDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpened: false,
      name: "",
      categoryId: "",
      error: {
        status: false,
        message: "",
      },
      showLoader: {
        open: false,
        message: "",
      },
      isEdit: false,
    };
  }

  config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };

  // classes = useStyles();

  componentDidMount() {
    if (this?.props?.selectedRow) {
      this.setState({
        ...this.props.selectedRow,
        isEdit: true,
      });
    } else {
      this.setState({
        ...this.state,
        isEdit: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedRow &&
      this.state.name !== this.props.selectedRow.name
    ) {
      this.setState({
        ...this.props.selectedRow,
        isEdit: true,
      });
    } else {
      // this.setState({
      //   ...this.state,
      //   isEdit: false,
      // });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  toggleDrawerStatus = () => {
    this.setState({
      isDrawerOpened: true,
    });
  };

  closeDrawer = () => {
    this.setState({
      isDrawerOpened: false,
    });
  };

  setShowLoader = (value, message) => {
    this.setState({
      ...this.state,
      showLoader: { open: value, message: message },
    });
  };

  handleSubmitForm = (e) => {
    e.preventDefault();
    this.setState({
      error: { status: false, message: "" },
    });

    this.setShowLoader(true, "Please Wait.");

    const data = { ...this.state };
    delete data.isDrawerOpened;
    delete data.showLoader;
    // delete data.catagories;
    // delete data.pactive;
    // delete data.value;
    delete data.error;
    delete data.setOpenSnackBar;

    delete data.imageUrl;

    if (!this.state.isEdit) {
      delete data.isEdit;
      axiosInstance
        .post("/v1/admin/category/sub-category", data, this.config)
        .then((res) => {
          // this.setState({
          //   ...this.state,
          //   showLoader: false,
          // });
          this.setShowLoader(false, "");
          this.setState({
            showLoader: false,
            isDrawerOpened: false,
            name: "",
            // catagories: [],
            categoryId: "",
            error: {
              status: false,
              message: "",
            },
          });
          this.props.onClose();
          window.location.reload(true);
          Swal.fire("Success!", res?.data?.message, "success");
        })
        .catch((err) => {
          this.setShowLoader(false, "");
        });
    } else {
      this.handleEditForm(e);
      this.setShowLoader(false, "");
    }
  };

  handleEditForm = (e) => {
    e.preventDefault();
    this.setState({
      error: { status: false, message: "" },
    });
    this.setShowLoader(true, "Please Wait");

    const data = { ...this.state };
    delete data.isDrawerOpened;
    delete data.catagories;
    // if (data.pactive === "yes") data.status = "active";
    // else data.status = "not active";
    delete data.pactive;
    delete data.value;
    delete data.error;
    delete data.showLoader;
    delete data.setOpenSnackBar;
    delete data.createdAt;
    delete data.counts;
    delete data.id;
    delete data.isDeleted;
    delete data.updatedAt;
    delete data.isEdit;
    delete data.imageUrl;

    axiosInstance
      .patch(
        `/v1/admin/category/subCategory/${this.state.id}`,
        data,
        this.config
      )
      .then((res) => {
        window.location.reload(true);
        this.props.onClose();
        this.setShowLoader(false, "");
        Swal.fire("Success!", res?.data?.message, "success");
      })
      .catch((err) => {
        this.setShowLoader(false, "");
      });
  };

  render() {
    const isDrawerOpened = this.props.open;
    const { classes } = this.props;
    return (
      <div>
        <Drawer
          anchor="right"
          variant="temporary"
          className={classes.drawerRoot}
          open={isDrawerOpened}
          onClose={this.props.onClose}
        >
          {/* {this.state.showLoader.open == true && (
            <Loader loaderInfo={this.state.showLoader.message} />
          )} */}
          {/* <AddAuctionForm
            style={{ ...styles.title, marginBottom: "30px" }}
            state={this.state}
            // onChangeDescription={(event, editor) => {
            //   const data = editor.getData();
            //   this.setState({ description: data });
            // }}

            handleChange={this.handleChange}
            // handleImageUpload={this.handleImageUpload}
            // loadfile={this.loadfile}
            // categoryList={this.state.catagories}
            handleSubmitForm={this.handleSubmitForm}
            isEdit={this.state.isEdit}
          /> */}
        </Drawer>
      </div>
    );
  }
}

export default withStyles(useStyles)(UiDrawer);
