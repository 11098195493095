import React, { useEffect, useState } from "react";
// import ProductTable from "../common/tableExpandable.jsx";
import Swal from "sweetalert2";
import CreatorTable from './CreatorTable/CreatorTable'

import { axiosInstance, baseURL } from "../../utility/api";
import CategoryTable from "../categories/components/CategoryTable";
import CreatorDrawer from "./UiDrawer";

export default function CreatorModule() {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const token = JSON.parse(localStorage.getItem("token"));

  const getIsEdit = () => {
    setIsEdit(true);
  };

  return (
    <>
      <div class="products-wrapper">
        <div class="container-fluide">
          <div class="page-title">
            <div class="row">
              <div class="col-6">
                <h3>Creator</h3>
              </div>
              <div class="col-12 mt-4" style={{ justifySelf: "flexgit -end" }}>
                <button
                  className={"btn btn-primary"}
                  onClick={() => setOpen(true)}
                >
                  + Add Creator
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="products-content">
          <CreatorTable />
        </div>
      </div>

      <CreatorDrawer
        open={open}
        onClose={() => setOpen(false)}
        showLoader={showLoader}
        setShowLoader={(e) => setShowLoader(e)}
        isEdit={isEdit}
      />
    </>
  );
}






