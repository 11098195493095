import React, { useState } from "react";
import { axiosInstance } from "../../utility/api";
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useHistory } from "react-router-dom";
import PasswordInput from "../common/FormInput/passwordInput";

export default function ResetPassword() {
    const [password, setPassword] = useState("");
    const [cpassword, setCpassword] = useState("");
    const history = useHistory();
    const { search } = useLocation();
    const { token } = queryString.parse(search);

    const submitHandler = (e) => {
        e.preventDefault();
        if (password !== cpassword) {
            Swal.fire('Info', 'Confirm password does not match password', 'info');
            return;
        }
        e.preventDefault();
        const payload = {
            password,
            token
        };
        if (password !== "") {
            axiosInstance.post("/v1/admin/auth/reset", payload)
            .then((res) => {
                // localStorage.clear();
                //   setShowLoader(false);

                // if (res?.data?.result?.email) {
                //   setStep1Complete(true);
                //   setLoginStepInfo({
                //     qr: res?.data?.result?.qr,
                //     email: res?.data?.result?.email,
                //   });
                // } else {
                //   setStep1Complete(false);
                // }
                Swal.fire("Great", "Password Reset Successfully", "success")
                .then(res => history.push("/"));
            })
            .catch(err => {
                Swal.fire("Alert!", err.response.data.message || 'Invalid Token', 'error')
            })
        }
    };

    return (
        <div>
            <h3>Forgot your password?</h3>
            <form className="form-group" onSubmit={submitHandler}>
                <br/>
                {/* <label>New Password</label>
                <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => setPassword(e?.target?.value)}
                    required
                /> */}
                <PasswordInput 
                    label={`New Password`}
                    name={`password`}
                    placeholder={`Enter Password`}
                    value={password}
                    onChange={(e) => setPassword(e?.target?.value)}
                />
                <br/>
                {/* <label>Confirm password</label>
                <input
                    type="password"
                    name="cpassword"
                    className="form-control"
                    placeholder="Enter Confirm Password"
                    value={cpassword}
                    onChange={(e) => setCpassword(e?.target?.value)}
                    required
                /> */}
                <PasswordInput 
                    label={`Confirm password`}
                    name={`cpassword`}
                    placeholder={`Enter Confirm Password`}
                    value={cpassword}
                    onChange={(e) => setCpassword(e?.target?.value)}
                />
                <br />
                <button type="submit" className="btn btn-dark btn-lg btn-block">
                    Submit
                </button>
            </form>
        </div>
    );
}
