import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swal from "sweetalert2";

import API from '../../APIActions/api'
import { formatAPIError } from "../../utility/helper";

export default function ReleasesDetailsPage() {

    const history = useHistory();
    const location = useLocation()

    const [details, setDetails] = useState(null);

    const getReleaseDataById = (id) => {
        API.getReleaseById(id)
            .then(res => {
                if (res?.data?.result) {
                    setDetails(res?.data?.result)
                }
            })
            .catch(err => {
                console.log(err)
                let errMsg = formatAPIError(err)
                Swal.fire("Alert!", errMsg, "error");
                history.push('/releases/list')
            })
    }

    useEffect(() => {
        let splitArr = location?.pathname?.split('/')
        let id = splitArr[splitArr?.length - 1]
        if (Number(id)) {
            getReleaseDataById(id)
        } else {
            history.push('/releases/list')
        }
    }, [location])

    return (
        <div>
            <div className="collectionContent pt-0">
                <div className="products-wrapper">
                    <div className="container-fluide">
                        <div className="page-title">
                            <div className="row">
                                <div className="col-6">
                                    <h3>Release Details</h3>
                                </div>
                                <div className="col-6 d-flex">
                                    <Link className="details-back-btn" to={`/releases/list`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7366ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                                        Back to Releases List
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="products-content">
                        <div className="grid-table">
                            <div className="table-row">
                                <span className="th">Release Name</span>
                                <span className="td">{details?.name || 'NA'}</span>
                            </div>
                            <div className="table-row">
                                <span className="th">Release Description</span>
                                <span className="td">{details?.releaseDescription || 'NA'}</span>
                            </div>
                            <div className="table-row">
                                <span className="th">Institution</span>
                                <span className="td">{details?.institutionName || 'NA'}</span>
                            </div>
                            <div className="table-row">
                                <span className="th">Banner Image</span>
                                <span className="td">
                                    {details?.bannerImage ? <img src={details?.bannerImage} alt={details?.name} /> : 'NA'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
