import React, { useState } from "react";
import "./index.css";
import Settings from "./commissionSetting";
import CommissionListing from "./commissionListing";

export default function CommissionIndex() {
  const [selectedTab, setSelectedTab] = useState("commission");
  return (
    <div>
      <div className="commissionContent">
        <div className="boleroTabsSection">
          <div
            className={
              "UsersTabOption " +
              (selectedTab == "commission" ? "userTabSelected" : "")
            }
            onClick={() => {
              setSelectedTab("commission");
            }}
          >
            Commission
          </div>
          <div
            className={
              "boleroTabCurve" +
              (selectedTab == "commission" ? "userTabSelected" : "")
            }
            onClick={() => {
              setSelectedTab("commission");
            }}
          ></div>

          <div
            className={
              "UsersTabOption " +
              (selectedTab == "settings" ? "userTabSelected" : "")
            }
            onClick={() => {
              setSelectedTab("settings");
            }}
          >
            Setting
          </div>
          <div
            className={
              "boleroTabCurve " +
              (selectedTab == "settings" ? "userTabSelected" : "")
            }
            onClick={() => {
              setSelectedTab("settings");
            }}
          ></div>
        </div>
        {selectedTab == "settings" && <Settings />}
        {selectedTab == "commission" && <CommissionListing />}
      </div>
    </div>
  );
}
