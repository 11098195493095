import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SimpleReactValidator from "simple-react-validator"

const useStyles = makeStyles({
  radioGroupRoot: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },

  formControlRoot: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
});

export default function AddArtist(props) {
  const {
    style,
    state,
    handleChange,
    handleSubmitForm,
    isEdit,
  } = props;

  const [, forceUpdateForForm] = useState();
  const [validator] = useState(useRef(new SimpleReactValidator({
    element: (message, className) => <p className="field_error">{message}</p>}
  )))
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    const result = validator.current.allValid()
    console.log('handleSubmit', result, state , validator.current)
    if(result) {
      handleSubmitForm(e)
    } else {
      validator.current.showMessages()
      forceUpdateForForm(1)
    }
  }

  return (
    <>
      <div className="container tm-mt-big tm-mb-big">
        <div className="row">
          <div className="col-sm-12 mx-auto py-4 px-5">
            <div className="tm-bg-primary-dark tm-block tm-block-h-auto">
              <div className="row">
                <div className="col-12">
                  <h2 className="tm-block-title d-inline-block">
                    {isEdit ? "Edit" : "Add"} Artist
                  </h2>
                </div>
              </div>
              <div className="row tm-edit-product-row">
                <div className="col-xl-12 col-lg-6 col-md-12">
                  <form action="" className="tm-edit-product-form">
                    <div className="form-group mb-3">
                      <label for="artistName">Artist Name</label>
                      <span className="colorRed">*</span>
                      <input
                        type="text"
                        name="artistName"
                        className="form-control validate"
                        value={state.artistName}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        required
                      />
                      {validator.current.message('artistName', state.artistName, 'required|alpha_num_dash_space')}
                    </div>
                    {/* <div className="form-group mb-3">
                      <label for="name">Label</label>
                      <span className="colorRed">*</span>
                      <input
                        type="text"
                        name="slug"
                        className="form-control validate"
                        value={state.name}
                        onChange={(e) => handleChange(e)}
                        required
                        disabled={true}
                      />
                    </div> */}
                    <div className="form-group mb-3">
                      <label for="description">Description</label>
                      <textarea
                        class="form-control validate"
                        rows="3"
                        required=""
                        spellcheck="false"
                        name="description"
                        value={state.description}
                        onChange={(e) => handleChange(e)}
                      ></textarea>
                    </div>
                  </form>
                </div>

                {state.error.status == true ? (
                  <div className="errorLabel col-6 col-md-12">
                    {state?.error?.message}
                  </div>
                ) : null}
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block text-uppercase"
                    onClick={handleSubmit}
                  >
                    Save Artist now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
