/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import crypto from "crypto";

// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import ReactPlayer from "react-player";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loader from "../common/Loader/Loader";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import VideoThumbnail from "react-video-thumbnail";
import Swal from "sweetalert2";
import axios from "axios";
import { Switch } from "@material-ui/core";
import { axiosInstance } from "../../utility/api";
import SearchIcon from "@material-ui/icons/Search";
import AudioImg from "../../assets/images/other-images/audio.png";
import FilterListIcon from "@material-ui/icons/FilterList";
import decryptFunction from "../common/decryptURL";

import "./tableExpandable.css";
import ExpandedRow from "./Table/Components/tableExpandedRow-MaterialUI";
import NoRecordsFound from "../common/Error/NoRecordsFound";
import { formatAPIError, formatETHPrice, formatEuroPrice } from "../../utility/helper";
import API from '../../APIActions/api'

const useRowStyles = makeStyles({
  root: {
    marginTop: "0px",
    border: "none",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5FC",
  },
  rowDatas: {
    "& td,th": {
      border: "none",
      height: "90px",
    },
  },
  border__right: {
    borderRight: "2px solid white",
    height: "70%",
  },
  cursorDefault: {
    cursor: "default",
  },
  tableRoot: {
    overflowX: "hidden",
    overflowY: "hidden",
  },
});

//Each row of table
function Row(props) {
  const { data, tableColumnWidth, ethValue, maticValue, setShowLoader, showLoader, selectedTab, rowIndex, tableData, setTableData, institutions, collections, artists } = props;
  // //console.log("rowindex is"+ rowIndex)
  // //console.log(data)
  const [open, setOpen] = React.useState(false);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  // const token = JSON.parse(localStorage.getItem("token"));
  const classes = useRowStyles();
  let config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };
  function handleAssetStatusChange(e) {
    if (e.target.checked == true) {
      axiosInstance
        .patch(`/v1/admin/asset/${data.id}/active`, null, config)
        .then((res) => {
          let tData = [...tableData]
          tData[rowIndex].status = "active"
          setTableData([...tData])
          Swal.fire("Success", res?.data?.message, "success");
        }
        ).catch((err) => {
          let errMsg = formatAPIError(err)
          Swal.fire("Alert!", errMsg, "error");
        })

    } else {
      axiosInstance
        .patch(`/v1/admin/asset/${data.id}/inactive`, null, config)
        .then((res) => {
          let tData = [...tableData]
          tData[rowIndex].status = "inactive"
          setTableData([...tData])
          Swal.fire("Success", res?.data?.message, "success");
        }).catch((err) => {
          let errMsg = formatAPIError(err)
          Swal.fire("Alert!", errMsg, "error");
        })

    }
  }
  return (
    <React.Fragment>
      <TableRow
        className={classes.rowDatas}
        style={{ borderBottom: "3px solid #F5F5FC", cursor: "pointer" }}
      >
        <TableCell style={{ width: `${tableColumnWidth[0]}%` }} onClick={() => { setOpen(!open) }}>
          {/* <div className="tableCellContainer"> */}
          <div
            class="imgCard"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            {data?.ipfsAudioUrl && (
              <ReactPlayer
                url={data?.ipfsAudioUrl}
                width="120px"
                height="120px"
                controls={true}
                style={{
                  backgroundImage: "url(" + data?.audioThumbnail + ")",
                  backgroundSize: "cover",
                }}
                onReady={true}
                light={data?.audioThumbnail ? data?.audioThumbnail : true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
              // playIcon="true"
              />
            )}
            {data?.ipfsLongVideoUrl && !data?.ipfsAudioUrl && (
              <ReactPlayer
                url={data?.ipfsLongVideoUrl}
                width="120px"
                height="120px"
                controls={true}
                onReady={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
              // playIcon="true"
              />
            )}
            {/* <img src={videoThumbnail && videoThumbnail} alt="Image" /> */}
            {data?.ipfsImageUrl &&
              !data?.ipfsLongVideoUrl &&
              data?.ipfsImageUrl.split(".").pop() != "fbx" && (
                <img
                  src={data?.ipfsImageUrl ? `${data?.ipfsImageUrl}` : null}
                  alt="Image"
                />
              )}
            {data?.ipfsImageUrl &&
              !data?.ipfsLongVideoUrl &&
              data?.ipfsImageUrl.split(".").pop() == "fbx" && (
                <img src={data["3DURL"] ? data["3DURL"] : null} alt="Image" />
              )}
            {/* {data?.ipfsAudioUrl && <img src={AudioImg} alt="Image" />} */}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[1]}%` }} onClick={() => { setOpen(!open) }}>
          <div className="columnStyles">{data?.name}</div>
        </TableCell>
        {selectedTab === "resaleassets" && <TableCell style={{ width: `${tableColumnWidth[1]}%` }} onClick={() => { setOpen(!open) }}>
          <div className="columnStyles">{data?.purchasedBy}</div>
        </TableCell>}
        <TableCell style={{ width: `${tableColumnWidth[2]}%` }} onClick={() => { setOpen(!open) }}>
          <div className="columnStyles">
            {data?.saleType == "free" ? '-' : <>€ {data?.saleType == "fixed"
              ? data?.regularPrice
              : data?.auctions && data?.auctions[0]?.openingPrice}
              <br></br>
              {data?.networkType === 'Ethereum' ? 'ETH' : 'MATIC'}&nbsp;
              {data?.saleType == "fixed"
                ? formatETHPrice(data?.regularPrice * (data?.networkType === 'Ethereum' ? ethValue : maticValue))
                : data?.auctions && formatETHPrice(data?.auctions[0]?.openingPrice * (data?.networkType === 'Ethereum' ? ethValue : maticValue))} </>}

          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[3]}%` }} onClick={() => { setOpen(!open) }}>
          <div className="columnStyles">
            {data?.availableStock ? data?.availableStock : "No stock available"}
          </div>
        </TableCell>
        {/* <TableCell style={{ width: `${tableColumnWidth[4]}%` }} onClick={() => {setOpen(!open)}}>
          <div className="columnStyles">
            {data?.totalEdition - data?.soldEdition}
          </div>
        </TableCell> */}
        <TableCell style={{ width: `${tableColumnWidth[5]}%` }} onClick={() => { setOpen(!open) }}>
          <div className="columnStyles">
            {data?.totalEdition && data?.totalEdition}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[6]}%` }}>
          {/* <div className="columnStyles">
            {data?.availableStock && data?.availableStock}
            {data?.quantity && `/${data?.quantity}`}
          </div> */}
          <div className="columnStyles">
            {data?.networkType?.toUpperCase()}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[7]}%` }} onClick={() => { setOpen(!open) }}>
          <div className="columnStyles">
            <div
              className={
                data?.saleType == "fixed" ? "fixedLabel" : "auctionLabel"
              }
            >
              {data?.saleType}
            </div>
            {/* {data?.regularPrice ? data?.regularPrice : "0"}{" "} */}
          </div>
        </TableCell>
        {/* <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <div className="positionToggle width64">
            <Switch
              checked={true}
              onChange={(e) => //console.log(e)}
              name="activate"
              inputProps={{ "aria-label": "checkbox" }}
              disabled={true}
            />
          </div>
        </TableCell> */}
        {/* <TableCell style={{ width: `${tableColumnWidth[7]}%` }} onClick={() => {setOpen(!open)}}>
          <div className="columnStyles category-wrapper">
            {data?.category?.name}
          </div>
        </TableCell> */}

        {selectedTab === "assets" && <TableCell >
          <Switch
            checked={data?.status == "active" ? true : false}
            onChange={handleAssetStatusChange}
            color="primary"
            name="saleType"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </TableCell>}
      </TableRow>

      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            borderBottom: "12px solid rgb(245, 245, 252)",
          }}
          colSpan={7}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <ExpandedRow
                institutions={institutions}
                collections={collections}
                artists={artists}
                rowData={data}
                setShowLoader={(e) => setShowLoader(e)}
                showLoader={showLoader}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const RenderHeader = ({ columns, onSorting, resaleColumns, soldColumns, selectedTab }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  const classes = useRowStyles();
  return (
    <TableHead className={classes.header}>
      <TableRow>
        {selectedTab !== "resaleassets" ? columns.map(({ name, field, sortable, image }, index) => (
          <TableCell
            align={columns.align}
            key={name + index}
            className={classes.cursorDefault}
          >
            <div className="dividerFlex">
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
                {/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
              </div>
            </div>
          </TableCell>
        )) : resaleColumns.map(({ name, field, sortable, image }, index) => (
          <TableCell
            align={columns.align}
            key={index + name}
            className={classes.cursorDefault}
          >
            <div className="dividerFlex">
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
                {/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function TableExpandable({
  data,
  columns,
  resaleColumns,
  soldColumns,
  tableColumnWidth,
  totalDoc,
  pageNo,
  setPageNo,
  showLoader,
  setShowLoader,
  searchText,
  setSearchText,
  filterSelected,
  setFilterSelected,
  networkType,
  setNetworkType,
  selectedTab,
  setSelectedTab,
  institutions,
  collections,
  artists
}) {
  const classes = useRowStyles();
  const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
  const [ThreeDTempURL, setThreeDTempURL] = useState("");
  const [tableData, setTableData] = useState([]);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [categoryList, setCategoryList] = useState(null);
  const token = JSON.parse(localStorage.getItem("token"));
  const [ethValue, setEthValue] = useState([]);
  const [maticValue, setMaticValue] = useState(null);
  // const [selectedTab, setSelectedTab] = useState("assets");
  // //console.log("token", token);
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const getPreviewImg = async (urltoget) => {
    //console.log("3d dd", urltoget);
    try {
      const axiosInstanceForPreview = axios.create();
      const response = await axiosInstanceForPreview.get(urltoget);
      return response?.data?.thumbnails?.images[0]?.url
        ? response?.data?.thumbnails?.images[0]?.url
        : "";
    } catch (error) {
      //console.log("3d catch", urltoget);
      //console.log("3d error", error);
    }
    // const axiosInstanceForPreview = axios.create();
    // const response = await axiosInstanceForPreview.get(urltoget);
    // return response?.data?.thumbnails?.images[0]?.url
    //   ? response?.data?.thumbnails?.images[0]?.url
    //   : "";
  };

  const decryptURl = (string) => {
    const CRYPTO_KEY = "ArtentikFirst@6684";
    const decipher = crypto.createDecipher("aes-128-cbc", CRYPTO_KEY);
    let decrypted = decipher.update(string, "hex", "utf8");
    decrypted += decipher.final("utf8");
    return decrypted;
  };

  useEffect(async () => {
    if (data.length > 0) {
      //console.log("3d data", data);
      // setTableData(data);
      const decryptData = data.map((obj) => {
        if (obj?.ipfsImageUrl && obj?.ipfsImageUrl.length > 0) {
          obj.ipfsImageUrl = decryptFunction(obj?.ipfsImageUrl);
        }
        if (obj?.ipfsLongVideoUrl && obj?.ipfsLongVideoUrl.length > 0) {
          obj.ipfsLongVideoUrl = decryptFunction(obj?.ipfsLongVideoUrl);
        }
        if (obj?.ipfsAudioUrl && obj?.ipfsAudioUrl.length > 0) {
          obj.ipfsAudioUrl = decryptFunction(obj?.ipfsAudioUrl);
        }

        return obj;
      });
      const add3DURL = await Promise.all(
        decryptData.map(async (obj) => {
          if (
            obj?.ipfsImageUrl &&
            obj?.ipfsImageUrl.split(".").pop() == "fbx"
          ) {
            obj["3DURL"] = await getPreviewImg(obj?.mediaPreviewUrl);
          }
          return obj;
        })
      );
      setTableData(decryptData);
      if (data.length > 0) {
        let searchSuggestionTitle = data.map(function (item) {
          return {
            name: item.name,
          };
        });
        setSearchSuggestions(searchSuggestionTitle);
      }
    }
  }, [data]);

  useEffect(() => {
    getRate()
  }, []);

  const handleChangePage = (event, nextPageNo) => {
    setPageNo(nextPageNo);
    window.scrollTo(0, 0);
  };

  const handleSearchBoxKeyDown = (e) => {
    if (e.keyCode == 13) {
      setSearchText(e.target.value);
    }
  };

  const getRate = async () => {
    API.getConversionRate()
      .then((res) => {
        if (res.data.result.exchangeRate) {
          setEthValue(res.data.result.exchangeRate);
          setMaticValue(res.data.result.exchangeRateMatic);
        } else {
          setEthValue(0)
          setMaticValue(0)
        }
      })
      .catch((error) => {
        setMaticValue(0)
        setEthValue(0)
        setMaticValue(0)
      });
  };

  return (
    <div className="table_div tableWidth100">
      {showLoader == true ? <Loader /> : null}
      <div className="productTableHeaderWrapper">
        <div className="tabSectionProducts">
          <div className="boleroTabsSection">
            <div
              className={
                "UsersTabOption " +
                (selectedTab == "assets" ? "userTabSelected" : "")
              }
              onClick={() => {
                setSelectedTab("assets");
                setPageNo(0);
              }}
            >
              Assets
            </div>
            <div
              className={
                "boleroTabCurve" +
                (selectedTab == "assets" ? "userTabSelected" : "")
              }
              onClick={() => {
                setSelectedTab("assets");
                setPageNo(0);
              }}
            ></div>

            <div
              className={
                "UsersTabOption " +
                (selectedTab == "soldAssets" ? "userTabSelected" : "")
              }
              onClick={() => {
                setSelectedTab("soldAssets");
              }}
            >
              Sold Assets
            </div>
            <div
              className={
                "boleroTabCurve " +
                (selectedTab == "soldAssets" ? "userTabSelected" : "")
              }
              onClick={() => {
                setSelectedTab("soldAssets");
              }}
            ></div>

            {/* <div
              className={
                "UsersTabOption " +
                (selectedTab == "resaleassets" ? "userTabSelected" : "")
              }
              onClick={() => {
                setSelectedTab("resaleassets");
                setPageNo(0);
              }}
            >
              Resale Assets
            </div> */}
            {/* <div
              className={
                "boleroTabCurve" +
                (selectedTab == "resaleassets" ? "userTabSelected" : "")
              }
              onClick={() => {
                setSelectedTab("resaleassets");
                setPageNo(0);
              }}
            ></div> */}
          </div>
        </div>
        <div className="searchBoxProduct">
          <Button
            variant="outlined"
            onClick={() => {
              setFilterSelected('0');
              setSearchText("");
              setNetworkType("0")
            }}
          >
            Reset Filter
          </Button>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">Network Type</InputLabel>
            <Select
              native
              value={networkType}
              onChange={(e) => setNetworkType(e.target.value)}
              label="Network Type"
              inputProps={{
                name: "Network Type",
                id: "outlined-age-native-simple",
              }}
              IconComponent={FilterListIcon}
              displayEmpty={true}
            >
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                  clipRule="evenodd"
                />
              </svg>
              <option value={"0"}>All</option>
              <option value={"Ethereum"}>ETHEREUM</option>
              <option value={"Polygon"}>POLYGON</option>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
            <Select
              native
              value={filterSelected}
              onChange={(e) => setFilterSelected(e.target.value)}
              label="Filter"
              inputProps={{
                name: "Filter",
                id: "outlined-age-native-simple",
              }}
              IconComponent={FilterListIcon}
              displayEmpty={true}
            >
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                  clipRule="evenodd"
                />
              </svg>
              {/* <optgroup label="Category 1">
              <option value={"0"}>All</option>
            </optgroup> */}
              {/* <optgroup label="Category"> */}
              <option value={"0"}>All</option>
              <option value={"fixed"}>Fixed</option>
              <option value={"auction"}>Auction</option>
              {/* {categoryList?.map((option) => (
                <option value={option.id}>{option.name}</option>
              ))} */}
              {/* </optgroup> */}
            </Select>
          </FormControl>
          <TextField
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              setPageNo(0);
            }}
            onKeyDown={(e) => handleSearchBoxKeyDown(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            label="Search Asset"
            variant="outlined"
            placeholder="Search"
          />
          {/* <Autocomplete
            id="search-box-product"
            options={searchSuggestions}
            disableClearable={true}
            clearOnBlur={false}
            getOptionLabel={(option) => {
              return option.name || "";
            }}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setPageNo(0);
                }}
                onKeyDown={(e) => handleSearchBoxKeyDown(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                label="Search Asset"
                variant="outlined"
                placeholder="Search"
              />
            )}
          /> */}
        </div>
      </div>
      {totalDoc > 0 ? (
        <>
          <TableContainer component={Paper} className={classes.tableRoot}>
            <Table aria-label="collapsible table" className={classes.root}>
              <RenderHeader
                columns={selectedTab == "assets" ? columns : soldColumns}
                resaleColumns={resaleColumns}
                // soldColumns={soldColumns}
                selectedTab={selectedTab}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <TableBody style={{ width: "100%" }}>
                {tableData?.map((row, index) => (
                  <Row
                    aria-label="expand row"
                    size="small"
                    tableData={tableData}
                    key={`${row?.id}key${index}`}
                    row={row}
                    ethValue={ethValue}
                    maticValue={maticValue}
                    rowIndex={index}
                    data={data[index]}
                    tableColumnWidth={tableColumnWidth}
                    setShowLoader={setShowLoader}
                    selectedTab={selectedTab}
                    setTableData={setTableData}
                    institutions={institutions}
                    collections={collections}
                    artists={artists}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            // backIconButtonProps={{ disabled: false }}
            component="div"
            count={totalDoc}
            rowsPerPage={10}
            page={pageNo}
            onChangePage={handleChangePage}
            // onChange={(e) => handleChangePage(e, page)}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => (
              <>
                <span className="paginationLabel">Showing </span>
                {`${from}-${to}`}
                <span className="paginationLabel"> out of </span>
                {`${count}`}
              </>
            )}
          />
        </>
      ) : (
        <div className="noResultFound flexColumnCenter">
          <NoRecordsFound message="No records found." />
        </div>
      )}
    </div>
  );
}
