import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import ReactQuill from "react-quill";
const useStyles = makeStyles({
  radioGroupRoot: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },

  formControlRoot: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  // label: {
  //   textTransform: "capitalize",
  // },
});

export default function AddCategory(props) {
  const {
    style,
    state,
    onChangeDescription,
    handleChange,
    // handleImageUpload,
    // loadfile,
    newsList,
    handleSubmitForm,
    isEdit,
  } = props;

  const classes = useStyles();
  const reactQuillRef = React.useRef();
  const quilEditorText = reactQuillRef?.current?.getEditor();
  const descriptionLength = quilEditorText?.getLength() - 1;
  return (
    <>
      <div className="container tm-mt-big tm-mb-big">
        <div className="row">
          <div className="col-xl-9 col-lg-10 col-md-12 col-sm-12 mx-auto">
            <div className="tm-bg-primary-dark tm-block tm-block-h-auto">
              <div className="row">
                <div className="col-12">
                  <h2 className="tm-block-title d-inline-block">
                    {isEdit ? "Edit" : "Add"} News
                  </h2>
                </div>
              </div>
              <div className="row tm-edit-product-row">
                <div className="col-xl-12 col-lg-6 col-md-12">
                  <form action="" className="tm-edit-product-form">
                    <div className="form-group mb-3">
                      <label for="name">Title</label>
                      <span className="colorRed">*</span>
                      <input
                        type="text"
                        name="title"
                        className="form-control validate"
                        value={state.title}
                        onChange={(e) => {
                          // //console.log("category", e.target);
                          let event={...e}
                          if(e.target.value.length>255){
                          event.target.value=event.target.value.slice(0,255)
                          handleChange(event);
                          }else{
                          handleChange(event);
                          }
                        }}
                        required
                      />
                      <div className="charactersRemaining">
                        <span>
                          (
                          {state?.title
                            ? 255 - state?.title?.length
                            : "255"}{" "}
                          characters remaining)
                        </span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label for="description">Description</label>
                      <ReactQuill
                        // onKeyDown={checkCharacterCount}
                        ref={reactQuillRef}
                        value={state?.description}
                        onChange={(e) => {
                          const quilEditor =
                            reactQuillRef?.current?.getEditor();
                          const descriptionLength = quilEditor?.getLength();
                          if (reactQuillRef?.current) {
                            if (descriptionLength < 1500) {
                              onChangeDescription(e);
                            } else {
                              quilEditor.deleteText(
                                1500,
                                quilEditor.getLength()
                              );
                              onChangeDescription(
                                reactQuillRef.current.getEditorContents()
                              );
                            }
                          } else {
                            onChangeDescription(e);
                          }
                        }}
                      />
                      <div className="charactersRemaining">
                        <span>
                          (
                          {state?.description
                            ? 1500 - descriptionLength
                            : "1500"}{" "}
                          characters remaining)
                        </span>
                      </div>
                    </div>
                    {/* <div className="form-group mb-3">
                      <label for="name">PNG UPLOAD</label>
                      <span className="colorRed">*</span>
                      <input
                        type="file"
                        // name="url"
                        className="form-control validate"
                        // defaultValue={state.url}
                        // onChange={(e) => {
                        //   // //console.log("url", e.target);
                        //   handleChange(e);
                        // }}
                        required
                      />
                    </div> */}
                    <div className="form-group mb-3">
                      <label for="name">URL</label>
                      <span className="colorRed">*</span>
                      <input
                        type="text"
                        name="url"
                        className="form-control validate"
                        value={state.url}
                        onChange={(e) => {
                          // //console.log("url", e.target);
                          handleChange(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label for="name">Published Date</label>
                      <span className="colorRed">*</span>
                      <input
                        type="date"
                        name="publishedDate"
                        className="form-control validate"
                        defaultValue={moment(state.publishedDate).format(
                          "yyyy-MM-DD"
                        )}
                        max={moment().format(
                          "yyyy-MM-DD"
                        )}
                        onChange={(e) => {
                          // //console.log("publishDate", e.target);
                          handleChange(e);
                        }}
                        required
                      />
                    </div>
                  </form>
                </div>

                {state.error.status == true ? (
                  <div className="errorLabel col-6 col-md-12">
                    {state?.error?.message}
                  </div>
                ) : null}
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block text-uppercase"
                    onClick={handleSubmitForm}
                    disabled={
                      state.title ||
                        state.description ||
                        state.url ||
                        state.publishedDate
                        ? false
                        : true
                    }
                  >
                    Save news now.
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
