import React from "react";
import Logo from "../assets/images/logo/PATRONOFART_LOGO.png";
import QRCode from "../assets/images/QRCode.png";
import "./test.css";

export default function test() {
  return (
    <div>
      <fieldset>
        <div className="loginWrapper">
          <form
          // onSubmit={submitHandler}
          >
            <div className="logoWrapperLogin">
              <img src={Logo} alt="logo" className="logoImageLogin" />
            </div>
            <div className="loginCenter">
              <h3>Authenticate</h3>
              <img src={QRCode} alt="logo" height="180px" />
            </div>

            <div className="form-group">
              <label>Referral Code.</label>
              <input
                type="text"
                name="referralCode"
                className="form-control"
                placeholder="Enter Referral code"
                value={""}
                // onChange={handleChange}
              />
            </div>
            {/* 
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  Remember me
                </label>
              </div>
            </div> */}

            <button type="submit" className="btn btn-dark btn-lg btn-block">
              Submit
            </button>
            {/* <p className="forgot-password text-right">
              Forgot <a href="#">password?</a>
            </p> */}
          </form>
        </div>
      </fieldset>
    </div>
  );
}
