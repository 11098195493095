/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import Box from "@material-ui/core/Box";
// import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from "../common/Loader/Loader";
import "./commissionListing.css";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

import { axiosInstance } from "../../utility/api";
import NoRecordsFound from "../common/Error/NoRecordsFound";
import {
  formatAPIError,
  formatETHPrice,
  formatEuroPrice,
} from "../../utility/helper";

import API from "../../APIActions/api";
import axios from "axios";

const useRowStyles = makeStyles({
  root: {
    marginTop: "0px",
    border: "none",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5FC",
  },
  rowDatas: {
    "& td,th": {
      border: "none",
      height: "90px",
    },
  },
  border__right: {
    borderRight: "2px solid white",
    height: "70%",
  },
  cursorDefault: {
    cursor: "default",
  },
});

//Each row of table
function Row(props) {
  const { data, tableColumnWidth, euroValue, maticValue } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const handleDelete = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };
    axiosInstance
      .delete(`/v1/admin/category/${id}`, config)
      .then((res) => {
        Swal.fire("Success!", res?.data?.message, "success");
      })
      .then(() => {
        // window.location.reload(true);
      })
      .catch((err) => {
        let errMsg = formatAPIError(err);

        Swal.fire("Alert!", errMsg, "error");
      });
  };

  return (
    <React.Fragment>
      <TableRow
        onClick={() => {
          setOpen(!open);
        }}
        className={classes.rowDatas}
        style={{ borderBottom: "12px solid #F5F5FC", cursor: "default" }}
      >
        <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
          <div className="columnStyles">{data?.asset?.name}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
          <div className="columnStyles">{`${formatEuroPrice(
            data?.unitPrice
          )} €`}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div className="columnStyles">
            {`${
              data?.balanceAfterCommission
                ? `${data?.balanceAfterCommission?.toFixed(3)}`
                : "0"
            } €`}{" "}
            {/* {euroValue && data?.balanceAfterCommission
              ? `${`(${formatETHPrice(
                  parseFloat(
                    (
                      data?.balanceAfterCommission *
                      (data?.asset?.networkType === "Polygon"
                        ? maticValue
                        : euroValue)
                    )?.toFixed(4)
                  )
                )} ${
                  data?.asset?.networkType === "Polygon" ? "MATIC" : "ETH"
                })`}`
              : ""} */}{" "}
          </div>
        </TableCell>
        {/* <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div className="columnStyles">{data?.royaltyCommission ? `${data?.royaltyCommission} $` : "0"}</div>
        </TableCell> */}
        <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div className="columnStyles">
            {data?.platformCommission
              ? data?.platformCommission % 1 !== 0
                ? data?.platformCommission?.toFixed(3)
                : data?.platformCommission
              : "0"}{" "}
            €{" "}
            {/* {euroValue && data?.platformCommission
              ? `${`(${parseFloat(
                  (
                    data?.platformCommission *
                    (data?.asset?.networkType === "Polygon"
                      ? maticValue
                      : euroValue)
                  )?.toFixed(7)
                )} ${
                  data?.asset?.networkType === "Polygon" ? "MATIC" : "ETH"
                })`}`
              : ""} */}
          </div>
        </TableCell>
      </TableRow>
      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <ExpandedRow
                rowData={data}
                setShowLoader={(e) => setShowLoader(e)}
                showLoader={showLoader}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </React.Fragment>
  );
}

const RenderHeader = ({ columns, onSorting }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  const classes = useRowStyles();

  return (
    <TableHead className={classes.header}>
      <TableRow>
        {columns?.map(({ name, field, sortable, image }) => (
          <TableCell
            align={columns.align}
            key={name}
            className={classes.cursorDefault}
          >
            <div className="dividerFlex">
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
                {/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function CategoryTable({ showLoader }) {
  const classes = useRowStyles();
  const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
  const [tableData, setTableData] = useState([]);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [commissionList, setCommissionList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [searchParam, setSearchParam] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [euroValue, setEuroValue] = useState(null);
  const [maticValue, setMaticValue] = useState(0);
  const token = JSON.parse(localStorage.getItem("token"));
  // const header = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const tableColumnWidth = [25, 25, 25, 25];
  const columns = [
    {
      name: "Asset",
      field: "name",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Unit Price (€)",
      field: "unitPrice",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Balance After Commission",
      field: "balanceAfterCommission",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    // {
    //   name: "Royality Commission",
    //   field: "royalityCommission",
    //   // image: Divider,
    //   numeric: false,
    //   sortable: false,
    //   align: "left",
    // },
    {
      name: "Platform Commission",
      field: "platformCommission",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
  ];

  const getpricefeed = async () => {
    const url = process.env.REACT_APP_API_URL + "v1";
    await axios.get(url + "/admin/exchange/rate").then((res) => {
      setEuroValue(res?.data?.result?.exchangeRate);
      setMaticValue(res.data.result.exchangeRateMatic);
    });
  };

  useEffect(() => {
    getpricefeed();
  }, []);

  useEffect(() => {
    getCommissionList();
  }, [pageNo, searchParam]);

  const handleChangePage = (event, nextPageNo) => {
    setPageNo(nextPageNo);
    window.scrollTo(0, 0);
  };

  const getCommissionList = async () => {
    let urlParam = {
      params: {
        page: pageNo + 1,
        sortBy: sorting.field,
        sorting: sorting.order,
        search: searchParam,
      },
    };
    let query = `?page=${pageNo + 1}&search=${searchParam}&status=success`;
    API.getCommissionData(query)
      .then((result) => {
        setCommissionList(result?.data?.result?.orders);
        setTotalCount(result?.data?.result?.totalItems);
      })
      .catch((err) => {
        let errMsg = formatAPIError(err);
        Swal.fire("Alert!", errMsg, "error").then(() => {
          if (
            err?.response?.status === 401 &&
            err?.response?.data?.message?.includes("authenticated")
          ) {
            console.log("err.response", err.response);
            window.location.href = "/welcome/:layout";
          }
        });
      });
  };

  return (
    <div className="table_div tableWidth100 paddingBottom66px">
      {showLoader == true ? <Loader /> : null}
      <div className="searchBoxProduct">
        <TextField
          className="cat-search-box-align"
          value={searchParam}
          onChange={(e) => {
            setPageNo(0);
            setSearchParam(e?.target?.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          label="Search Commission"
          variant="outlined"
          placeholder="Search"
        />
      </div>
      {commissionList?.length !== 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" className={classes.root}>
              <RenderHeader
                columns={columns}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <TableBody style={{ width: "100%" }}>
                {commissionList?.map((row, index) => (
                  <Row
                    aria-label="expand row"
                    size="small"
                    key={row?.id}
                    row={row}
                    setSelectedRow={(value) => setSelectedRow(value)}
                    selectedRow={selectedRow}
                    data={commissionList[index]}
                    tableColumnWidth={tableColumnWidth}
                    setOpenEdit={setOpenEdit}
                    euroValue={euroValue}
                    maticValue={maticValue}
                    // setShowLoader={setShowLoader}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            // backIconButtonProps={{ disabled: false }}
            component="div"
            count={totalCount}
            rowsPerPage={10}
            page={pageNo}
            onChangePage={handleChangePage}
            // onChange={(e) => handleChangePage(e, page)}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => (
              <>
                <span className="paginationLabel">Showing </span>
                {`${from}-${to}`}
                <span className="paginationLabel"> out of </span>
                {`${count}`}
              </>
            )}
          />
        </>
      ) : (
        <div className="noResultFound flexColumnCenter">
          <NoRecordsFound message="No records found." />
        </div>
      )}
    </div>
  );
}
