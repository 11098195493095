import React from 'react';
import {
    Box, BookOpen, Anchor, Headphones, Send, User, Users, Activity, ShoppingBag,
    Globe, Database, Layers, ArrowRight, HelpCircle, Feather
} from "react-feather";
import DynamicPageIcons from '../../../layout/sidebar/DynamicPageIcons';

const Icon = ({ iconName }) => {

    const displayIcon = () => {
        switch (iconName) {
            case 'Box':
                return <Box />
            case 'BookOpen':
                return <BookOpen />
            case 'Anchor':
                return <Anchor />
            case 'Headphones':
                return <Headphones />
            case 'Send':
                return <Send />
            case 'User':
                return <User />
            case 'Users':
                return <Users />
            case 'Releases':
                return <Feather />
            case 'Activity':
                return <Activity />
            case 'ShoppingBag':
                return <ShoppingBag />
            case 'Globe':
                return <Globe />
            case 'Database':
                return <Database />
            case 'Layers':
                return <Layers />
            case 'ArrowRight':
                return <ArrowRight />
            case 'HelpCircle':
                return <HelpCircle />
            case 'DynamicPageIcons':
                return <DynamicPageIcons />
            default:
                return null
        }
    }

    return displayIcon()
}

export default Icon