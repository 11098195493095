import React, { useEffect, useState, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { FormHelperText } from "@material-ui/core";
import { Edit } from "react-feather";
import SimpleReactValidator from "simple-react-validator"

import { SAVE_TOKEN, SAVE_ADMIN_PROFILE_DETAILS } from "../../../redux/actionTypes";
import { history } from "../../../utility/history";
import styles from "./EditProfile.module.scss";
import { axiosInstance } from "../../../utility/api";
import { formatAPIError } from "../../../utility/helper";

const EditProfile = () => {
  const dispatch = useDispatch();
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [editEmail, setEditEmail] = React.useState(false);
  const adminUser = useSelector((state) => state.adminReducer.adminUser);
  const [formValue, setFormValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    is2FA: false,
    thumbnailImageUrl:""
  });
  const [profilePic, setProfilePic] = React.useState(null);
  const [fa, setFa] = useState(false)

  const [, forceUpdateForForm] = useState();
  const [validator] = useState(useRef(new SimpleReactValidator({
    element: (message, className) => <p className="field_error">{message}</p>}
  )))

  const handleSubmit = (e) => {
    e.preventDefault();
    const result = validator.current.allValid()
    if(result) {
      saveProfileChanges()
    } else {
      validator.current.showMessages()
      forceUpdateForForm(1)
    }
  }

  useEffect(() => {
    if(adminUser?.id) {
      setFa(adminUser.is2FA)
      setFormValue({
        ...adminUser,
        currentEmail: adminUser?.email
      })
    }
  }, [adminUser]);

  const uploadFile = (e) => {
    // const config = getConfig();
    const pic = document.getElementById("profile_pic");
    let files = e.target.files[0];
    if (files) {
      const fileType = ["image/png", "image/jpeg" , "image/jpg"];
      if (![...fileType].includes(files.type) &&
          files.name.substr(files.name.length - 3) != "fbx") {
          Swal.fire("Alert", 'Please upload a valid file.', "error");
          return;
      }
    } else {
      return;
    }
    let formData = new FormData();
    formData.append("image", files);
    axiosInstance
      .post(`/v1/admin/updateProfilePic`, formData, config)
      .then((res) => {
        console.log('updateProfilePic', res)
        Swal.fire("Great!", "Profile Pic successfully uploaded", "success");
        setFormValue({...formValue,thumbnailImageUrl:res?.data?.result?.imageUrl})
        dispatch({
          type: SAVE_ADMIN_PROFILE_DETAILS,
          payload: { ...formValue, thumbnailImageUrl : res?.data?.result?.imageUrl},
        });
        console.log('updateProfilePic', { ...formValue, thumbnailImageUrl : res?.data?.result?.imageUrl})
      })
      .catch((err) => {
        Swal.fire("Oops!", err?.response?.data?.message, "error");
      });
  };
  const handleImageUpload = (e) => {
    document.getElementById("my_file").click();
  };

  const handleProfileFormChange = (event) => {
    const { name, value, checked } = event?.target
    if(name === 'is2FA') {
      setFormValue({ 
        ...formValue, 
        [name]: checked 
      });
    } else {
      setFormValue({ 
        ...formValue, 
        [name]: value 
      });
    } 
    console.log('asdasda', name, value, checked )
    
  };

  const saveProfileChanges = () => {
    setIsSubmitted(true);
    if (
      (formValue.firstName !== "" ||
        formValue.firstName !== undefined ||
        formValue.firstName !== null) &&
      (formValue.lastName !== "" ||
        formValue.lastName !== undefined ||
        formValue.lastName !== null) &&
      (formValue.email !== "" ||
        formValue.email !== undefined ||
        formValue.email !== null)
    ) {
      axiosInstance
        .patch(`/v1/admin/editAdminProfile`, formValue, config)
        .then((res) => {
          console.log('editAdminProfile', res)
          dispatch({
            type: SAVE_ADMIN_PROFILE_DETAILS,
            payload: formValue,
          });
          Swal.fire("Success!", res?.data?.message, "success")
          setIsSubmitted(false);
          if (formValue.is2FA && fa !== formValue.is2FA){
            logout()
          }
          if (formValue.email !== formValue.currentEmail) {
            logout();
          }
        })
        .catch((err) => {
          let errMsg = formatAPIError(err)
          Swal.fire("Alert!", errMsg, "error");
        });
    }
  };
  const handleEmailEdit = (e) => {
    e.preventDefault()
    setEditEmail(!editEmail);
  };
  const logout = () => {
    axiosInstance.get(`/v1/admin/auth/logout`, config)
    .then((result) => {
     console.log("logout")
     dispatch({
      type: SAVE_TOKEN,
      payload: null,
    });
    localStorage.removeItem("token");
    window.location.href = `${process.env.PUBLIC_URL}/sign-in`
    })
    .catch((err) => {
      console.log("logout error",err)
    });
  };
  return (
    <div className={styles.edit_profile_container}>
      <h3>Edit Profile</h3>
      <div className={styles.edit_profile_form_wrap}>
        <form>
          <div className="input-wrapper">
            <TextField
              className="text_field"
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              name="firstName"
              type="text"
              // style={
              //   isSubmitted && formValue.firstName.length === 0
              //     ? { marginBottom: "0px" }
              //     : { marginBottom: "10px" }
              // }
              value={formValue.firstName}
              onChange={handleProfileFormChange}
              required
            />
            {validator.current.message('firstName', formValue.firstName, 'required|alpha')}
            {/* {isSubmitted && formValue.firstName.length === 0 ? <FormHelperText error>Enter a valid First name</FormHelperText> : null} */}
          </div>
          <div className="input-wrapper">
            <TextField
              label="Last Name"
              variant="outlined"
              name="lastName"
              // style={
              //   isSubmitted && formValue.firstName.length === 0
              //     ? { marginBottom: "0px" }
              //     : { marginBottom: "10px" }
              // }
              value={formValue.lastName}
              onChange={handleProfileFormChange}
              required
            />
            {validator.current.message('lastName', formValue.lastName, 'required|alpha')}
            {/* {isSubmitted && formValue.lastName.length === 0 ? <FormHelperText error>Enter Last name</FormHelperText> : null} */}
          </div>
          <div className="w100 input-wrapper" style={{ position: "relative" }}>
            <TextField
              label="E-mail"
              variant="outlined"
              name="email"
              value={formValue.email}
              onChange={handleProfileFormChange}
              required
              disabled={!editEmail}
              className="w100"
            />
            <button className={`btn btn-primary ${styles.edit_icon_btn}`} onClick={handleEmailEdit} ><Edit className={styles.edit_icon} /></button>
            {validator.current.message('email', formValue.email, 'required|email')}
            {/* {isSubmitted && formValue.email.length === 0 ? <FormHelperText error>Enter valid email</FormHelperText> : null} */}
          </div>
          <div className="form-group twoFA-checkbox">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                name="is2FA"
                checked={formValue?.is2FA}
                className="custom-control-input"
                id="customCheck1"
                onChange={handleProfileFormChange}
              />
              <label for="customCheck1"></label>
              <span>2FA</span>
            </div>
          </div>
        </form>
        <div className={styles.uploadPic}>
          <div className={styles.profileImg} 
            onClick={handleImageUpload}
            id="profile_pic"
            style={{ backgroundImage: `url(${formValue?.thumbnailImageUrl ||
              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"})` }}>
          </div>
          {/* <img
            id="profile_pic"
            alt="profile.png"
            src={
              formValue.thumbnailImageUrl ||
              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
            }
            onClick={handleImageUpload}
          /> */}
          <input
            type="file"
            id="my_file"
            style={{ display: "none" }}
            onChange={uploadFile}
            accept="image/*"
          />
          <button onClick={handleImageUpload} className={"button-small"}>
            Upload Image
          </button>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}
      >
        <button
          className="btn btn-primary"
          style={{ width: "213px" }}
          onClick={handleSubmit}
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
