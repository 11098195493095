import { Drawer } from "@material-ui/core";
import React, { Fragment ,useState, useEffect} from "react"
import styles from './AirDropDrawer.module.css'
import moment from 'moment'
import AirDropTable from "./airdropfolder/AirDropTable";
const AirDropDrawer = ({data,setAirDropOpen}) => {
    const [isopen, setOpen] = useState(true)
    // console.log(data)
    useEffect(()=>{
        if(isopen==false){
        
        
            setAirDropOpen(false)
        }
    },[isopen])
    return ( 
        <Drawer
          anchor="top"
          variant="temporary"
          open={isopen}
        //   open={isDrawerOpened}
          onClose={(e)=>{setAirDropOpen(false)}}
          style={{ height: "100%", backgroundColor:"white" }}
          >
        <div className={styles.container}>
            <div className={styles.subContainer}>

                <div className={styles.Heading}>
                    <h3>AirDrop</h3>
                    <button className={styles.closeButton} onClick={()=>{setOpen(false)}}>X</button>

                </div>
               <div className={styles.msg}>
               <p><b>Selected user is</b></p>
               </div>
                <div className={styles.userInfo}>
                    <img
                    className={styles.profileimg}
                     src={data?.profilePicUrl ? data?.profilePicUrl : ""}
                     ></img>
                    <div className={styles.profileDetails}>
                        <p>{data?.firstName} {data?.lastName}</p>
                        {/* <p> {moment(data?.createdAt).format('MMMM Do YYYY, h:mm:ss ')}</p> */}
                        <p>{data?.email}</p>
                       <p><b>Wallet Address:</b>{data?.publicAddress}</p>
                       <p><b>Username:</b>{data?.userName}</p>
                    </div>

                </div>
        
               <div className={styles.droptable}>
               <div className={styles.msg}>
               <p><b>Choose an asset to airdrop:</b></p>
               </div>
               <AirDropTable userData={data}></AirDropTable>
               </div>
            </div>
        </div>
        </Drawer>
    );
}
 
export default AirDropDrawer;