/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

import { axiosInstance } from "../../../utility/api";

import Loader from "../../common/Loader/Loader";
import UiDrawer from "./ReleaseUiDrawer";
import NoRecordsFound from "../../common/Error/NoRecordsFound";

import Edit from "../../../assets/images/icons/edit.svg";
import deleteIcon from "../../../assets/images/icons/delete.svg";
import PreviewIcon from "../../../assets/images/icons/preview.svg";
import optionsDots from "../../../assets/images/optionsDots.svg";
import filePlaceholder from '../../../assets/images/no-data.png'

import { capitalizeFirstLetter, formatAPIError } from "../../../utility/helper";

import API from '../../../APIActions/api'
import { Link } from "react-router-dom";

import { Switch } from "@material-ui/core";
import moment from "moment";

const useRowStyles = makeStyles({
  root: {
    marginTop: "0px",
    border: "none",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5FC",
  },
  rowDatas: {
    "& td,th": {
      border: "none",
      height: "90px",
    },
  },
  border__right: {
    borderRight: "2px solid white",
    height: "70%",
  },
  cursorDefault: {
    cursor: "default",
  },
});

//Each row of table
function Row(props) {
  const {
    row,
    data,
    index,
    selectedRowIndex,
    setselectedRowIndex,
    tableColumnWidth,
    setShowLoader,
    showLoader,
    setOpenEdit,
    selectedRow,
    setSelectedRow,
    onStatusChange
  } = props;
  const [open, setOpen] = useState(false);
  const [releaseId, setReleaseId] = useState(false);
  const classes = useRowStyles();

  const deleteRelease = (id) => {
    console.log("deleted 2", id);
    if (id) {
      confirmAlert({
        title: "Delete Release?",
        message: "Are you sure to delete.",
        buttons: [
          {
            label: "Yes",
            onClick: () => handleDelete(id),
          },
          {
            label: "No",
            onClick: () => console.log("Clicked No"),
          },
        ],
      });
    }
  };

  const handleDelete = (id) => {
    API.deleteReleaseById(id)
      .then(res => {
        Swal.fire("Success!", capitalizeFirstLetter(res?.data?.message) || 'Release successfully deleted', "success");
        window.location.reload(true);
      })
      .catch(err => {
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error");
      })
  };

  const handleChangeForDots = (index) => {
    setselectedRowIndex(index);
  };

  const HandleOutSideClickClose = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setselectedRowIndex("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const BlockDisableContainerRef = React.useRef(null);
  HandleOutSideClickClose(BlockDisableContainerRef);

  return (
    <React.Fragment>
      <TableRow
        onClick={() => {
          setOpen(!open);
        }}
        className={classes.rowDatas}
        style={{ borderBottom: "12px solid #F5F5FC", cursor: "default" }}
      >
        <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
          <div className="columnStyles">{data?.name ? data?.name : "-"}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
          <div className="columnStyles">{data?.releaseDescription ? data?.releaseDescription : "-"}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div className="columnStyles">{data?.institutionName ? data?.institutionName : "-"}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div className="columnStyles">{data?.publishedDate ? moment(data?.publishedDate).format(
            "yyyy-MM-DD HH:mm:ss"
          ) : "-"}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <div className="columnStyles">
            <img src={data?.bannerImage} alt={`${data?.name}-bannerImage`} style={{ width: "300px" }} />
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[5]}%` }}>
          <Switch
            checked={data?.status == "active" ? true : false}
            onChange={(e) => { onStatusChange(e, index) }}
            color="primary"
            name="saleType"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[6]}%` }}>
          <div className={"voiletTickImage alignItemStart hcpLastColumn relative"}>
            {selectedRowIndex === index && (
              <div className="userTableActions threeDotContainer threeDotContainerAlignment" ref={BlockDisableContainerRef}>
                <Link className="editTableRow userTableActionBtn" to={`/releases-details/${data?.id}`}>
                  <img src={PreviewIcon} alt="edit" className="paddingRight4px" /> View
                </Link>
                <div className="editTableRow userTableActionBtn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedRow(data);
                    setOpenEdit(true);
                  }}>
                  <img src={Edit} alt="edit" className="paddingRight4px" /> Edit
                </div>
                <div className="editTableRow userTableActionBtn"
                  onClick={async () => {
                    deleteRelease(data?.id);
                  }}>
                  <img src={deleteIcon} alt="edit" className="paddingRight4px" /> Delete
                </div>
              </div>
            )}
          </div>
          <img
            className="optionsDotsCss cursorPointer"
            src={optionsDots}
            onClick={(e) => {
              e.stopPropagation();
              handleChangeForDots(index);
            }}
            alt="dots"
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const RenderHeader = ({ columns, onSorting }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  const classes = useRowStyles();
  return (
    <TableHead className={classes.header}>
      <TableRow>
        {columns?.map(({ name, field, sortable, image }) => (
          <TableCell
            align={columns.align}
            key={name}
            className={classes.cursorDefault}
          >
            <div className="dividerFlex">
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
                {/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function ReleaseTable({ data, showLoader, setShowLoader }) {
  const classes = useRowStyles();
  // const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
  const [totalCount, setTotalCount] = useState(0);
  const [releasesList, setReleasesList] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const [searchParam, setSearchParam] = React.useState("");
  const token = JSON.parse(localStorage.getItem("token"));
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const tableColumnWidth = [15, 20, 15, 10, 20, 10, 10];
  const columns = [
    {
      name: "Name",
      field: "name",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    // {
    //   name: "Wallet Address",
    //   field: "walletAddress",
    //   // image: Divider,
    //   numeric: true,
    //   sortable: false,
    //   align: "left",
    // },
    {
      name: "Description",
      field: "releaseDescription",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Institution",
      field: "institutionName",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Published date",
      field: "publishedDate",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Banner Image",
      field: "bannerImage",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Status (Inactive/Active)",
      field: "status",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "right",
    },
    {
      name: "Action",
      field: "action",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
  ];

  const handleChangePage = (event, nextPageNo) => {
    setPageNo(nextPageNo);
    window.scrollTo(0, 0);
  };
  const handleSearchParam = (e) => {
    setSearchParam(e.target.value);
    getReleasesList(e.target.value)
  };
  const getReleasesList = async (searchStr) => {
    let query = `?type=release&page=${pageNo + 1}&limit=10${searchStr ? `&search=${searchStr}` : ''}`
    API.getReleases(query)
      .then(res => {
        setReleasesList(res?.data?.result?.releases);
        setTotalCount(res?.data?.result?.totalItems);
      })
      .catch(err => {
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error").then(() => {
          if (err?.response?.status === 401 && err?.response?.data?.message?.includes("authenticated")) {
            console.log('err.response', err.response)
            window.location.href = "/welcome/:layout"
          }
        })
      })
  };

  function handleReleaseStatusChange(e, rowIndex) {
    const data = releasesList[rowIndex];
    if (e.target.checked == true) {
      axiosInstance
        .patch(`/v1/admin/release/${data.id}/active`, null, header)
        .then((res) => {
          let tData = [...releasesList]
          tData[rowIndex].status = "active"
          setReleasesList([...tData])
          Swal.fire("Success", res?.data?.message, "success");
        }
        ).catch((err) => {
          let errMsg = formatAPIError(err)
          Swal.fire("Alert!", errMsg, "error");
        })

    } else {
      axiosInstance
        .patch(`/v1/admin/release/${data.id}/inactive`, null, header)
        .then((res) => {
          let tData = [...releasesList]
          tData[rowIndex].status = "inactive"
          setReleasesList([...tData])
          Swal.fire("Success", res?.data?.message, "success");
        }).catch((err) => {
          let errMsg = formatAPIError(err)
          Swal.fire("Alert!", errMsg, "error");
        })
    }
  }

  useEffect(() => {
    getReleasesList();
  }, [pageNo]);

  return (
    <div className="table_div tableWidth100" style={{ position: "relative" }}>
      {showLoader == true ? <Loader /> : null}
      <TextField
        className="cat-search-box-align"
        value={searchParam}
        onChange={handleSearchParam}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        label="Search Release"
        variant="outlined"
        placeholder="Search"
      />

      {releasesList?.length !== 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" className={classes.root}>
              <RenderHeader
                columns={columns}
              // onSorting={(field, order) => setSorting({ field, order })}
              />
              <TableBody style={{ width: "100%" }}>
                {releasesList?.map((row, index) => (
                  <Row
                    aria-label="expand row"
                    size="small"
                    key={row?.id}
                    row={row}
                    index={index}
                    selectedRowIndex={selectedRowIndex}
                    setselectedRowIndex={setSelectedRowIndex}
                    setSelectedRow={(value) => setSelectedRow(value)}
                    selectedRow={selectedRow}
                    data={releasesList[index]}
                    tableColumnWidth={tableColumnWidth}
                    setOpenEdit={setOpenEdit}
                    onStatusChange={handleReleaseStatusChange}
                  // setShowLoader={setShowLoader}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            // backIconButtonProps={{ disabled: false }}
            component="div"
            count={totalCount}
            rowsPerPage={10}
            page={pageNo}
            onChangePage={handleChangePage}
            // onChange={(e) => handleChangePage(e, page)}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => (
              <>
                <span className="paginationLabel">Showing </span>
                {`${from}-${to}`}
                <span className="paginationLabel"> out of </span>
                {`${count}`}
              </>
            )}
          />
        </>
      ) : (
        <div className="noResultFound flexColumnCenter">
          <NoRecordsFound message="No records found." />
        </div>
      )}
      <UiDrawer
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        showLoader={showLoader}
        setShowLoader={(e) => setShowLoader(e)}
        isEdit={true}
        selectedRow={selectedRow}
      />
    </div>
  );
}
