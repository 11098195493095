import React, { useEffect, useState } from "react";

import InstitutionTable from "./components/InstitutionTable";
import InstitutionUiDrawer from "./components/InstitutionUiDrawer";

export default function InstitutionsPage() {

    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
  
    const getIsEdit = () => {
      setIsEdit(true);
    };

    return (
        <div>
            <div className="collectionContent pt-0">
                {/* <div className="boleroTabsSection">
                    <div className={`UsersTabOption userTabSelected`}>Institutions</div>
                </div> */}
                <div class="products-wrapper">
                    <div class="container-fluide">
                        <div class="page-title">
                            <div class="row">
                                <div class="col-6">
                                    <h3>Institutions</h3>
                                </div>
                                <div class="col-12 mt-4" style={{ justifySelf: "flexgit -end" }}>
                                    <button className={"btn btn-primary"} onClick={() => setOpen(true)}>
                                        + Add Institutions
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="products-content">
                        <InstitutionTable />
                    </div>
                </div>
                <InstitutionUiDrawer
                    open={open}
                    onClose={() => setOpen(false)}
                    showLoader={showLoader}
                    setShowLoader={(e) => setShowLoader(e)}
                    isEdit={isEdit}
                />
            </div>
        </div>
    );
}
