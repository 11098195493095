import axios from "axios";
import Swal from "sweetalert2";

// const token = JSON.parse(localStorage.getItem("token"));
export const baseURL = process.env.REACT_APP_API_URL;
export const customerBaseURL = process.env.REACT_APP_CUSTOMER_API_URL;
export const customerFrontEndURL = process.env.REACT_APP_CUSTOMER_FRONTEND_URL;

export const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // console.log("error", error);
    console.log("error.response", error.response);
    if (error?.response && error?.response?.status === 401) {
      setTimeout(() => {
        Swal.fire(
          "Alert!",
          "Session expired! Please login again.",
          "error"
        ).then(() => {
          window.location.href = "/";
          localStorage.removeItem("token");
        });
      }, 0);
    }
    return Promise.reject(error);
  }
);
