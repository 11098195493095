/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import Box from "@material-ui/core/Box";
// import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loader from "../common/Loader/Loader";
import Swal from "sweetalert2";
import DataRangePopOver from "../common/DateRange/DateRangePopOver";
import { axiosInstance } from "../../utility/api";
import moment from "moment";
import { formatDateTime } from "../common/date";
import NoRecordsFound from "../common/Error/NoRecordsFound";

import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { formatAPIError, formatEuroPrice } from "../../utility/helper";

const useRowStyles = makeStyles({
  root: {
    marginTop: "0px",
    border: "none",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5FC",
  },
  rowDatas: {
    "& td,th": {
      border: "none",
      height: "90px",
    },
  },
  border__right: {
    borderRight: "2px solid white",
    height: "70%",
  },
  cursorDefault: {
    cursor: "default",
  },
});

//Each row of table
function Row(props) {
  const { data, tableColumnWidth } = props;
  const [open, setOpen] = React.useState(false);
  const [deleteCategoryID, setDeleteCategoryID] = useState(null);
  // const token = JSON.parse(localStorage.getItem("token"));
  const classes = useRowStyles();

  const handleDelete = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };
    axiosInstance
      .delete(`/v1/admin/category/${id}`, config)
      .then((res) => {
        Swal.fire("Success!", res?.data?.message, "success");
      })
      .then(() => {
        window.location.reload(true);
      })
      .catch((err) => {
        let errMsg = formatAPIError(err);
        Swal.fire("Alert!", errMsg, "error");
      });
  };

  const setStatusColor = (status) => {
    console.log(status);
    if (status === "active") {
      return "activeLabel";
    } else if (status === "inactive") {
      return "inactiveLabel";
    } else if (status === "expired") {
      return "expiredLabel";
    } else {
      return "";
    }
  };

  return (
    <React.Fragment>
      <TableRow
        onClick={() => {
          setOpen(!open);
        }}
        className={classes.rowDatas}
        style={{ borderBottom: "12px solid #F5F5FC", cursor: "default" }}
      >
        <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
          <div className="columnStyles">{data?.id}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
          <div className="columnStyles">{data?.currentBidderName || "NIL"}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div className="columnStyles" style={{ wordBreak: "break-all" }}>
            {(data?.currentBidder && data?.currentBidder) || "NIL"}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div className="columnStyles">{data?.asset?.name}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <div className="columnStyles">
            {data?.currentBid && formatEuroPrice(data?.currentBid)} €
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[5]}%` }}>
          <div className="columnStyles">
            {data?.openingPrice && formatEuroPrice(data?.openingPrice)} €
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[6]}%` }}>
          <div className={`columnStyles ${setStatusColor(data?.status)}`}>
            {data?.status && data?.status}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[7]}%` }}>
          <div className="columnStyles">
            {data?.startDate && formatDateTime(data?.startDate)}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[8]}%` }}>
          <div className="columnStyles">
            {data?.endingDate && formatDateTime(data?.endingDate)}
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const RenderHeader = ({ columns, onSorting }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  const classes = useRowStyles();
  return (
    <TableHead className={classes.header}>
      <TableRow>
        {columns?.map(({ name, field, sortable, image }) => (
          <TableCell
            align={columns.align}
            key={name}
            className={classes.cursorDefault}
          >
            <div className="dividerFlex">
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
                {/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function AuctionList({
  data,
  showLoader,
  setShowLoader,
  //   searchText,
  //   setSearchText,
  //   filterSelected,
  //   setFilterSelected,
}) {
  const classes = useRowStyles();
  const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
  const [totalCount, setTotalCount] = useState(0);
  const [auctionList, setAuctionList] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const token = JSON.parse(localStorage.getItem("token"));
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();
  let day = today.getDate();
  let date1MonthBefore = new Date(year, month - 1, day);
  const [userList, setUserList] = useState(null);
  // const [pageNo, setPageNo] = useState(0);
  // const [totalCount, setTotalCount] = useState(0);
  // const [selectedRow, setSelectedRow] = useState(null);
  const [selectionRange, setSelectionRange] = useState({
    startDate: date1MonthBefore,
    endDate: new Date(),
    key: "selection",
  });
  const [searchText, setSearchText] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [statusFilterSelected, setStatusFilterSelected] = useState("all");
  const [selectedRowIndex, setSelectedRowIndex] = useState("");

  const tableColumnWidth = [5, 15, 15, 15, 12, 15, 15, 15, 15];
  const columns = [
    {
      name: "id",
      field: "id",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Bidder Name",
      field: "currentBidderName",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Bidder wallet address",
      field: "lastBider",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Asset Name",
      field: "assetName",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Highest Bid",
      field: "currentBid",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Reserve Price",
      field: "OpeningPrice",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Status",
      field: "status",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Start Date",
      field: "action",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "End Date",
      field: "action",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
  ];

  // useEffect(() => {
  //   getauctionList();
  // }, [pageNo]);

  const handleChangePage = (event, nextPageNo) => {
    setPageNo(nextPageNo);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getauctionList();
  }, [searchText, pageNo, selectionRange, statusFilterSelected]);

  const getauctionList = async () => {
    let startDate = new Date(
      selectionRange?.startDate?.getTime() -
        selectionRange?.startDate?.getTimezoneOffset() * 60000
    ).toISOString();
    let endDate = new Date(
      selectionRange?.endDate?.getTime() -
        selectionRange?.endDate?.getTimezoneOffset() * 60000
    ).toISOString();

    const result = await axiosInstance
      .get(
        `/v1/admin/asset/auctions?page=${
          pageNo + 1
        }&auctionStatus=${statusFilterSelected}&search=${searchText}&fromDate=${startDate}&toDate=${endDate}`,
        header
      )
      .then((result) => {
        setAuctionList(result?.data?.result?.auctions);
        setTotalCount(result?.data?.result?.totalItems);
      })
      .catch((err) => {
        let errMsg = formatAPIError(err);
        Swal.fire("Alert!", errMsg, "error").then(() => {
          if (
            err?.response?.status === 401 &&
            err?.response?.data?.message?.includes("authenticated")
          ) {
            console.log("err.response", err.response);
            window.location.href = "/welcome/:layout";
          }
        });
      });
    // .catch((err) => {
    //   if (
    //     err?.response &&
    //     err?.response?.data?.errors &&
    //     err?.response?.data?.errors?.length !== 0
    //   ) {
    //     Swal.fire({
    //       title: "Oops!",
    //       text: err?.response?.data?.errors?.map((err) => err?.msg),
    //       icon: "error",
    //     });
    //   } else {
    //     Swal.fire("Oops!", err?.response?.data?.message, "error");
    //   }
    // }
    // );
  };

  const handleDate = (ranges) => {
    setSelectionRange(ranges?.selection);
  };

  return (
    <div className="table_div tableWidth100">
      {showLoader == true ? <Loader /> : null}
      <div className="searchBoxProduct">
        <Button
          variant="outlined"
          onClick={() => {
            setSelectionRange({
              startDate: date1MonthBefore,
              endDate: new Date(),
              key: "selection",
            });
            setStatusFilterSelected("all");
            setSearchText("");
          }}
        >
          Reset Filter
        </Button>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
          <Select
            native
            value={statusFilterSelected}
            onChange={(e) => setStatusFilterSelected(e.target.value)}
            label="Filter"
            inputProps={{
              name: "Filter",
              id: "outlined-age-native-simple",
            }}
            IconComponent={FilterListIcon}
            displayEmpty={true}
          >
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                clipRule="evenodd"
              />
            </svg>
            <option value={"all"}>All</option>
            <option value={"live"}>Live</option>
            <option value={"expired"}>Expired</option>
          </Select>
        </FormControl>
        <DataRangePopOver
          selectionRange={selectionRange}
          setSelectionRange={setSelectionRange}
          handleSelect={handleDate}
        />
        <TextField
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            setPageNo(0);
          }}
          // onKeyDown={(e) => handleSearchBoxKeyDown(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          label="Search Auction"
          variant="outlined"
          placeholder="Search"
        />
        {/* <Autocomplete
          id="search-box-product"
          options={searchSuggestions}
          disableClearable={true}
          clearOnBlur={false}
          getOptionLabel={(option) => {
            return option.name || "";
          }}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setPageNo(0);
              }}
              // onKeyDown={(e) => handleSearchBoxKeyDown(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              label="Search Auction"
              variant="outlined"
            />
          )}
        /> */}
      </div>
      {auctionList?.length !== 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" className={classes.root}>
              <RenderHeader
                columns={columns}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <TableBody style={{ width: "100%" }}>
                {auctionList?.map((row, index) => (
                  <Row
                    aria-label="expand row"
                    size="small"
                    key={row?.id}
                    row={row}
                    setSelectedRow={(value) => setSelectedRow(value)}
                    selectedRow={selectedRow}
                    data={auctionList[index]}
                    tableColumnWidth={tableColumnWidth}
                    setOpenEdit={setOpenEdit}
                    // setShowLoader={setShowLoader}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            // backIconButtonProps={{ disabled: false }}
            component="div"
            count={totalCount}
            rowsPerPage={10}
            page={pageNo}
            onChangePage={handleChangePage}
            // onChange={(e) => handleChangePage(e, page)}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => (
              <>
                <span className="paginationLabel">Showing </span>
                {`${from}-${to}`}
                <span className="paginationLabel"> out of </span>
                {`${count}`}
              </>
            )}
          />
        </>
      ) : (
        <div className="noResultFound flexColumnCenter">
          <NoRecordsFound message="No records found." />
        </div>
      )}
    </div>
  );
}
