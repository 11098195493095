import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator"
import ImageUpload from "../../../assets/images/other-images/imageUpload.svg";
import Select from "react-select";
import moment from "moment";

export default function AddRelease(props) {
  const {
    style,
    state,
    handleChange,
    handleSubmitForm,
    uploadFile,
    handleSocialChange,
    handleImageUpload,
    isEdit,
    addNewInput,
    removeInput,
    handleSelectChange
  } = props;

  const [forceUpdate, setForceUpdate] = useState(0);
  const [validator] = useState(useRef(new SimpleReactValidator({
    element: (message, className) => <p className="field_error">{message}</p>,
    validators: {
      name: {  // name the rule
        required: true
      },
      releaseDescription: {  // name the rule
        required: true
      },
    }
  }
  )))

  const handleSubmit = (e) => {
    e.preventDefault();
    const result = validator.current.allValid()
    if (result) {
      handleSubmitForm(e)
    } else {
      validator.current.showMessages()
      setForceUpdate(forceUpdate + 1)
    }
  }

  useEffect(() => {
    setForceUpdate(forceUpdate + 1)
  }, [state.socialMediaLinks])

  return (
    <>
      <div className="container tm-mt-big tm-mb-big">
        <div className="row">
          <div className="col-sm-12 mx-auto py-4 px-5">
            <div className="tm-bg-primary-dark tm-block tm-block-h-auto">
              <div className="row">
                <div className="col-12">
                  <h2 className="tm-block-title d-inline-block">
                    {isEdit ? "Edit" : "Add"} Release
                  </h2>
                </div>
              </div>
              <div className="row tm-edit-product-row">
                <div className="col-xl-12 col-lg-6 col-md-12">
                  <form action="" className="tm-edit-product-form">
                    <div className="form-group mb-3">
                      <label for="name">Release Name<span className="colorRed">*</span></label>
                      <input type="text" name="name" className="form-control validate"
                        defaultValue={state.name}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        onChange={handleChange} />
                      {validator.current.message('releaseName', state.name, 'required|alpha_num_dash_space')}
                    </div>
                    <div className="form-group mb-3">
                      <label for="releaseDescription">Release Description<span className="colorRed">*</span></label>
                      <textarea class="form-control validate" rows="3" spellcheck="false" name="releaseDescription"
                        value={state.releaseDescription}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        onChange={(e) => handleChange(e)} />
                      <div className="decsriptionValidation">
                        {validator.current.message('Description', state.releaseDescription, 'required')}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label for="description">
                        Institution<span className="colorRed">*</span>
                      </label>
                      <Select
                        options={state.institutions}
                        className="react-select"
                        classNamePrefix="react-select"
                        closeMenuOnSelect={true}
                        value={
                          state.institutionId
                            ? {
                              label: state.institutions.find(
                                (el) => el?.id === state.institutionId
                              )?.name,
                              value: state.institutionId,
                            }
                            : ""
                        }
                        placeholder={`Select Institution`}
                        isMulti={false}
                        name="id"
                        isDisabled={isEdit ? true : false}
                        onChange={(e) => handleSelectChange(e, "institutionId")}
                      />
                      {!isEdit &&
                        validator.current.message(
                          "institution",
                          state.institutionId,
                          "required"
                        )}
                      {state.institutionId ? (
                        <span>
                          <small style={{ wordBreak: "break-all" }}>
                            Institution description -{" "}
                            {
                              state.institutions?.find(
                                (el) => el?.id === state.institutionId
                              )?.InstitutionDescription
                            }
                          </small>
                        </span>
                      ) : null}
                    </div>
                    <div className="form-group mb-3">
                      <label for="name">Published date</label>
                      <span className="colorRed">*</span>
                      <input
                        type="datetime-local"
                        name="publishedDate"
                        className="form-control validate"
                        defaultValue={moment(state.publishedDate).format(
                          "yyyy-MM-DD HH:mm:ss"
                        )}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <div className="uploadPic">
                        {state?.bannerImage
                          ? <div className="profileImg" onClick={(e) => handleImageUpload(e, 'bannerImage')}
                            style={{ backgroundImage: `url(${state?.bannerImage})` }}>
                          </div>
                          : <img className="placeholderImg" onClick={(e) => handleImageUpload(e, 'bannerImage')} src={ImageUpload} alt="placeholderImg" />}
                        <input type="file" id="bannerImage" name="bannerImage" style={{ display: "none" }} accept="image/*"
                          onChange={uploadFile}
                        />
                        <button onClick={(e) => handleImageUpload(e, 'bannerImage')} className={"button-small"}>
                          Upload Banner Image
                        </button>
                      </div>
                      <span className="mt-1 d-block">Supported File formats: (jpg, png)<span className="colorRed">*</span></span>
                      {validator.current.message('bannerImage', state.bannerImage, 'required')}
                    </div>
                  </form>
                </div>
                {state.error.status == true ? (
                  <div className="errorLabel col-6 col-md-12">
                    {state?.error?.message}
                  </div>
                ) : null}
                <div className="col-12">
                  <button className="btn btn-primary btn-block text-uppercase" onClick={handleSubmit}>
                    Save Release now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
