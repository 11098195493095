import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swal from "sweetalert2";

import API from '../../APIActions/api'
import { capitalizeFirstLetter, formatAPIError } from "../../utility/helper";

const ReadMoreDescription = ({description}) => {
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    const formatDesc = () => isReadMore ? description.slice(0, 150) : description

    return (
        <div className="text-description">
            {description.includes('</') 
                ? <div dangerouslySetInnerHTML={{__html: formatDesc()}}></div> 
                : formatDesc() || 'NA'}
            {description?.length >= 150 
            ? <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? "...show more" : " show less"}
            </span>
            : null}
        </div>
    );
}

export default function InstitutionsDetailsPage() {

    const history = useHistory();
    const location = useLocation()

    const [details, setDetails] = useState(null);

    const getInstitutionDataById = (id) => {
        API.getInstitutionById(id)
        .then(res => {
            if(res?.data?.result) {
                setDetails(res?.data?.result)
            }
        })
        .catch(err => {
            console.log(err)
            let errMsg = formatAPIError(err)
            Swal.fire("Alert!", errMsg, "error");
            history.push('/institutions/list')
        })
    }

    useEffect(() => {
        let splitArr = location?.pathname?.split('/')
        let id = splitArr[splitArr?.length-1]
        if(Number(id)) {
            getInstitutionDataById(id)
        } else {
            history.push('/institutions/list')
        }
    },[location])

    return (
        <div>
            <div className="collectionContent pt-0">
                {/* <div className="boleroTabsSection">
                    <div className={`UsersTabOption userTabSelected`}>Institution Details</div>
                </div> */}
                <div className="products-wrapper">
                    <div className="container-fluide">
                        <div className="page-title">
                            <div className="row">
                                <div className="col-6">
                                    <h3>Institution Details</h3>
                                </div>
                                <div className="col-6 d-flex">
                                    <Link className="details-back-btn" to={`/institutions/list`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7366ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                                        Back to Institution List
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="products-content">
                        <div className="grid-table">
                            <div className="table-row">
                                <span className="th">Institution Name</span>
                                <span className="td">{details?.name || 'NA'}</span>
                            </div>
                            <div className="table-row">
                                <span className="th">Institution Description</span>
                                <span className="td">{details?.InstitutionDescription || 'NA'}</span>
                            </div>
                            {/* <div className="table-row">
                                <span className="th">Wallet Address</span>
                                <span className="td">{details?.walletAddress || 'NA'}</span>
                            </div> */}
                            <div className="table-row">
                                <span className="th">Asset Count</span>
                                <span className="td">{details?.assetCount}</span>
                            </div>
                            <div className="table-row">
                                <span className="th">Social Media Links</span>
                                <span className="td">
                                    {details?.socialMediaLinks?.length
                                        ?  details?.socialMediaLinks.map(el => {
                                            return (
                                                <div>
                                                    {
                                                        el.link && (
                                                            <>
                                                            {el?.link} - <a key={el?.type} className="social-media-url" rel="noopener noreferrer" href={el?.link}  target="_blank">{capitalizeFirstLetter(el?.type)}</a>
                                                            </>
                                                        )
                                                    }
                                                    
                                                </div>
                                            )
                                        })
                                        : 'NA'}
                                </span>
                            </div>
                            <div className="table-row">
                                <span className="th">Website Link</span>
                                <span className="td">{details?.websiteLink ? <a href={details?.websiteLink} target="_blank">{details?.websiteLink}</a> : 'NA'}</span>
                            </div>
                            <div className="table-row">
                                <span className="th">Institution Image</span>
                                <span className="td">
                                    {details?.logoUrl ? <img src={details?.logoUrl} alt={details?.name} /> : 'NA'}
                                </span>
                            </div>
                            <div className="table-row">
                                <span className="th">Banner Image</span>
                                <span className="td">
                                    {details?.bannerImage ? <img src={details?.bannerImage} alt={details?.name} /> : 'NA'}
                                </span>
                            </div>
                        </div>
                        {details?.assets?.length 
                        ? <div className="institution-assets">
                            <h4>Institution Asset Details</h4>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ width: "15%" }}>Asset Name</th>
                                        <th style={{ width: "45%" }}>Asset Description</th>
                                        <th style={{ width: "15%" }}>Creator Name</th>
                                        <th style={{ width: "8%" }}>Currency</th>
                                        <th style={{ width: "16%" }}>Current Edition</th>
                                        <th style={{ width: "7%" }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {details?.assets.map(asset => {
                                        return (
                                            <tr key={asset?.id}>
                                                <td style={{ width: "15%" }}>{asset?.name || 'NA'}</td>
                                                <td style={{ width: "45%" }}>
                                                    {asset?.description 
                                                    ? <ReadMoreDescription description={asset?.description} />
                                                    : 'NA'}
                                                </td>
                                                <td style={{ width: "15%" }}>{asset?.creatorName || 'NA'}</td>
                                                <td style={{ width: "8%" }}>{asset?.currency || 'NA'}</td>
                                                <td style={{ width: "16%" }}>{asset?.currentEdition || 'NA'}</td>
                                                <td style={{ width: "7%" }}>
                                                    <span className={`columnStyles ${asset?.status === 'active' ? 'activeLabel' : 'inactiveLabel'}`}>{asset?.status || 'NA'}</span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        : null}
                    </div>
                </div>
            </div>
        </div>
    );
}
