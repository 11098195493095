import React, { useEffect, useState } from "react";
import UiDrawer from "./UiDrawer";
import DataTable from "./Table/Table."
import Swal from "sweetalert2";

import { formatAPIError } from '../../utility/helper'
import API from '../../APIActions/api'

const SubAdmin = () => {
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [checked, setChecked] = useState([])
  const [expanded, setExpanded] = useState([])
  const [permission, setPermission] = useState({});
  const [reload, setReload] = useState(false);

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    is2FA: false,
  })

  useEffect(() => {

  }, [reload])

  const [list] = useState([
    { slug: "assets", checked: false },
    { slug: "cate", checked: false }
  ])
  const [selectAction, setSelectAction] = useState([{}])

  const selectHandler = selectAction => {
    setSelectAction(selectAction)
  }

  const reloadTable = () => {
    console.log('okok')
    setReload(!reload);
  }

  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };

  const fetchpermissions = () => {
    API.getAdminPermission()
    .then((res) => {
      setShowLoader(false);
      setPermission(res.data.result);
    })
    .catch((err) => {
      setShowLoader(false);
      let errMsg = formatAPIError(err)
      Swal.fire("Alert!", errMsg, "error");
    });
  }

  useEffect(() => {
    fetchpermissions();
  }, []);

  return (
    <div>
      <div className="categoryContent">
        <>
          <div class="products-wrapper">
            <div class="container-fluide">
              <div class="page-title">
                <div class="row">
                  <div class="col-6">
                    <h3>Manage Sub Admin</h3>
                  </div>

                  <div class="col-12 mt-4" style={{ justifySelf: "flexgit -end" }}>
                    <button
                      className={"btn btn-primary"}
                      onClick={() => {
                        setOpen(true);
                        setIsEdit(false);
                        setState({
                          firstName: "",
                          lastName: "",
                          email: "",
                          password: "",
                          is2FA: false,
                        });
                        setSelectAction([])
                      }
                      }
                    >
                      + Add Sub Admin
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="products-content">
              <DataTable setEditOpen={setEditOpen} setIsEdit={setIsEdit} setState={setState} permission={permission}
                setPermission={setPermission} reload={reload} />
            </div>
          </div>
          {isEdit 
          ? <UiDrawer
            open={editOpen}
            onClose={() => setEditOpen(false)}
            showLoader={showLoader}
            setShowLoader={(e) => setShowLoader(e)}
            isEdit={isEdit}
            setState={setState}
            state={state}
            selectAction={selectAction}
            setSelectAction={setSelectAction}
            selectHandler={selectHandler}
            list={list}
            checked={checked}
            setChecked={setChecked}
            expanded={expanded}
            setExpanded={setExpanded}
            permission={permission}
            setPermission={setPermission}
            onSuccess={reloadTable}
          />
          : <UiDrawer
            open={open}
            onClose={() => setOpen(false)}
            showLoader={showLoader}
            setShowLoader={(e) => setShowLoader(e)}
            isEdit={isEdit}
            setState={setState}
            state={state}
            list={list}
            selectAction={selectAction}
            setSelectAction={setSelectAction}
            selectHandler={selectHandler}
            checked={checked}
            setChecked={setChecked}
            expanded={expanded}
            setExpanded={setExpanded}
            permission={permission}
            setPermission={setPermission}
          />}
        </>
      </div>
    </div>
  )

}
export default SubAdmin