import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { axiosInstance } from "../../utility/api";
import "./commissionSetting.css";
import Primary from "../../assets/images/icons/Primary.svg";
import Secondary from "../../assets/images/icons/Secondary.svg";
import { formatAPIError } from "../../utility/helper";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  radioGroupRoot: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },

  formControlRoot: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function CommissionSetting() {
  const classes = useStyles();
  const [commissionType, setCommissionType] = useState("secondary");
  const [commissionList, setCommissionList] = useState([]);
  // const [primaryPlatformCommission, setPrimaryPlatformCommission] = useState({
  //   id: "",
  //   platformCommission: "",
  // });
  // const [secondaryPlatformCommission, setSecondaryPlatformCommission] =
  //   useState({
  //     id: "",
  //     platformCommission: "",
  //   });
  const [primaryCommision, setPrimaryCommision] = useState(0);
  const [secondaryCommision, setSecondaryCommision] = useState(0);
  const [networkType, setNetworkType] = useState("ethereum")
  // const [royaltyCommission, setRoyaltyCommission] = useState(null);
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };

  useEffect(() => {
    getCommissionList();
  }, []);

  const commissionTypeOptions = [
    { label: "Primary Commission", value: "primary" },
    { label: "Secondary Commission", value: "secondary" },
  ];

  const getCommissionList = async (networkVal) => {
    const result = await axiosInstance
      .get(`/v1/admin/commissions/${networkVal || networkType}`, config)
      .then((result) => {
        if(result?.data?.result?.length!==0){
          setPrimaryCommision(result?.data?.result?.[0]?.platformCommission || 0)
          setSecondaryCommision(result?.data?.result?.[1]?.platformCommission || 0)
        }
        else{
          setPrimaryCommision(0)
          setSecondaryCommision(0)
        }
        // setCommissionList(result?.data?.result);
        // let primary = result?.data?.result?.find(
        //   (element) => element?.commissionType == "primary"
        // );
        // let secondary = result?.data?.result?.find(
        //   (element) => element?.commissionType == "secondary"
        // );
        // setPrimaryPlatformCommission(primary);
        // setSecondaryPlatformCommission(secondary); 
      } ) 
      .catch((err) => {
        console.log('err', err)
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error");
      });
  };

  const handleSubmit = async () => {

    // type was param
    // if(!primaryCommision && !secondaryCommision) {
    //   Swal.fire("Oops!", "Please enter platform commissions", "error");
    //   return false;
    // }
    // if(!primaryCommision) {
    //   Swal.fire("Oops!", "Please enter primary commision", "error");
    //   return false;
    // }
    // if(!secondaryCommision) {
    //   Swal.fire("Oops!", "Please enter secondary commision", "error");
    //   return false;
    // }
    if(!networkType){
      Swal.fire("Oops!", "Please select a network type", "error");
      return false;
    }
    if (
      // primaryPlatformCommission?.platformCommission < 0 ||
      // primaryPlatformCommission?.platformCommission > 20
      primaryCommision < 0 ||
      primaryCommision > 20 ||
      secondaryCommision < 0 ||
      secondaryCommision > 20
    ) {
      Swal.fire("Oops!", "Admin platform commission should between 0% to 20%.", "error");
      return false;
    }
    // let data = {
    //   commissionType: type,
    //   platformCommission:
    //     type == "primary"
    //       ? primaryPlatformCommission?.platformCommission
    //       : secondaryPlatformCommission?.platformCommission,
    // };
    await axiosInstance
      .post(
        `/v1/admin/addcommissions/${networkType}`,
        { primary: parseFloat(primaryCommision), secondary: parseFloat(secondaryCommision) },
        config
      )
      .then((res) => {
        //console.log("posted");
        // this.setShowLoader(false, "");
        Swal.fire("Success!", res?.data?.message, "success");
      })
      .catch((err) => {
        // this.setShowLoader(false, "");
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error");
      });
  };
  console.log('networkType :>> ', networkType);
  return (
    <div className="commissionSettingWrapper">
      <div class="containers">
        <div class="container cone">
          <div class="icon flexRowFlexEnd">
            <img src={Primary} alt="primary" height="34px" />
            <h4>
              <u>Platform Admin Commission.</u>
            </h4>
          </div>

          {/* <h4>Residential address</h4> */}

          <table>
            {/* <tr>
              <td className="commissionTableHeader">
                <label for="stock">Commission type</label>
              </td>
              <td className="commissionTableContent paddingLeft78">
                <select
                  className="custom-select tm-select-accounts"
                  name="commissionType"
                  id="commissionType"
                  value={commissionType}
                  onChange={(e) => setCommissionType(e.target.value)}
                  required
                  disabled
                >
                  <option value="" selected>
                    Primary Admin Commission
                  </option>
                  {commissionTypeOptions?.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
              </td>
            </tr> */}
            <tr>
              <td className="commissionTableHeader">
                <label for="stock">Primary Platform commission</label>
              </td>
              <td className="commissionTableContent paddingLeft78 displayFlexBaseline">
                <input
                  type="number"
                  name="adminCommission"
                  min="0"
                  className="form-control validate marginLeft10pxN"
                  value={primaryCommision}
                  // onKeyDown={formatInputDot}
                  onChange={(e) => {
                    if (e.target.value < 101) {
                      setPrimaryCommision(e.target.value);
                    }
                  }}
                  required
                />
                <span className="percentageImgContainer">%</span>
              </td>
            </tr>
            <tr>
              <td className="commissionTableHeader">
                <label for="stock">Secondary Platform commission</label>
              </td>
              <td className="commissionTableContent paddingLeft78 displayFlexBaseline">
                <input
                  type="number"
                  name="adminCommission"
                  min="0"
                  className="form-control validate marginLeft10pxN"
                  value={secondaryCommision}
                  // onKeyDown={formatInputDot}
                  onChange={(e) => {
                    if (e.target.value < 101) {
                      setSecondaryCommision(e.target.value);
                    }
                  }}
                  required
                />
                <span className="percentageImgContainer">%</span>
              </td>
            </tr>
            <tr>
              <td className="commissionTableHeader">
                <label for="stock">Network Type</label>
              </td>
              <td className="commissionTableContent paddingLeft78 displayFlexBaseline">
              {/* <label for="category">Network Type</label> */}
                      {/* <span className="colorRed">*</span> */}
                      <FormControl
                        component="fieldset"
                        classes={{
                          root: classes.formControlRoot,
                        }}
                      >
                        <RadioGroup
                          classes={{
                            root: classes.radioGroupRoot, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                          }}
                          name="networkType"
                          value={networkType}
                          onChange={(e) => {
                            getCommissionList(e.target.value)
                            setNetworkType(e.target.value)
                          } }
                        >
                          <FormControlLabel
                            value="ethereum"
                            control={<Radio />}
                            label="Ethereum"
                          />
                          <FormControlLabel
                            value="polygon"
                            control={<Radio />}
                            label="Polygon"
                          />
                        </RadioGroup>
                      </FormControl>
              </td>
            </tr>
          </table>
          <div className="flexCenter">
            <div class="btn" onClick={() => handleSubmit()}>
              Save Changes
            </div>
          </div>
        </div>
        {/* <div class="container cone">
          <div class="icon flexRowFlexEnd">
            <img src={Secondary} alt="Secondary" height="34px" />
            <h4>
              <u>Secondary Sale Commission.</u>
            </h4>
          </div>
          <table>
            <tr>
              <td className="commissionTableHeader">
                <label for="stock">Commission type</label>
              </td>
              <td className="commissionTableContent paddingLeft78">
                <select
                  className="custom-select tm-select-accounts"
                  name="commissionType"
                  id="commissionType"
                  value={commissionType}
                  onChange={(e) => setCommissionType(e.target.value)}
                  required
                  disabled
                >
                  <option value="" selected>
                    Secondary Sale Commission
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td className="commissionTableHeader">
                <label for="stock">Platform commission</label>
              </td>
              <td className="commissionTableContent paddingLeft78 displayFlexBaseline">
                <input
                  type="number"
                  name="adminCommission"
                  min="0"
                  className="form-control validate marginLeft10pxN"
                  value={secondaryPlatformCommission?.platformCommission}
                  // onKeyDown={formatInputDot}
                  onChange={(e) => {
                    if (e.target.value < 101) {
                      setSecondaryPlatformCommission({
                        ...secondaryPlatformCommission,
                        platformCommission: e.target.value,
                      });
                    }
                  }}
                  required
                />
                <span className="percentageImgContainer">%</span>
              </td>
            </tr>
          </table>
          <div className="flexCenter">
            <div class="btn" onClick={() => handleSubmit("secondary")}>
              Save Changes
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
