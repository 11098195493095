import React, { Fragment } from 'react';
import Breadcrumb from '../../layout/breadcrumb';
import { Container, Row, Col } from 'reactstrap';


const Welcome = (props) => {
    return (
        <Fragment>
            <div className='welcome-page-content'>
                <div className='welcome-img-wrapper'>
                    <img src={require("../../assets/images/logo/site-logo.svg")} alt="logo"
                        className='img-fluid' />
                </div>
                <h1>Welcome to Patron of Art.</h1>
                <p>Browse using avaliable options in the Sidebar Navigation.</p>
                {/* <Breadcrumb parent="Dashboard" title="Welcome to Patron of Art." />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <p style={{ fontSize: 17, fontWeight: "500" }}>
                                <br/>
                                Browse using avaliable options in the Sidebar Navigation.
                            </p>
                        </Col>
                    </Row>
                </Container> */}
            </div>
        </Fragment>
    );
}

export default Welcome;