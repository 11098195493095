import React, { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classes from "./Table.css"

const RenderHeader = ({ columns, onSorting }) => {
    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("asc");
  
    const onSortingChange = (field) => {
      const order =
        field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
      setSortingField(field);
      setSortingOrder(order);
      onSorting(field, order);
    };
    return (
      <TableHead className={classes.header}>
        <TableRow>
          {columns?.map(({ name, field, sortable }) => (
            <TableCell
              align={columns.align}
              key={name}
              className={classes.cursorDefault}
            >
              <div className="dividerFlex">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    fontWeight: "600",
                    fontSize: "12px",
                    cursor: sortable ? "pointer" : "default",
                  }}
                  onClick={() => (sortable ? onSortingChange(field) : null)}
                >
                  {name}              
                </div>
              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  export default RenderHeader