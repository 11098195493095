/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

import { axiosInstance } from "../../../utility/api";

import Loader from "../../common/Loader/Loader";
import UiDrawer from "./UiDrawer";
import NoRecordsFound from "../../common/Error/NoRecordsFound";

import editIcon from "../../../assets/images/icons/edit.svg";
import deleteIcon from "../../../assets/images/icons/delete.svg";

import "./CollectionTable.css";
import { capitalizeFirstLetter, formatAPIError } from "../../../utility/helper";

const useRowStyles = makeStyles({
  root: {
    marginTop: "0px",
    border: "none",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5FC",
  },
  rowDatas: {
    "& td,th": {
      border: "none",
      height: "90px",
    },
  },
  border__right: {
    borderRight: "2px solid white",
    height: "70%",
  },
  cursorDefault: {
    cursor: "default",
  },
});

//Each row of table
function Row(props) {
  const {
    row,
    data,
    tableColumnWidth,
    setShowLoader,
    showLoader,
    setOpenEdit,
    selectedRow,
    setSelectedRow,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [deleteCollectionID, setDeleteCollectionID] = useState(null);
  // const token = JSON.parse(localStorage.getItem("token"));
  const classes = useRowStyles();

  const deleteCollection = (id) => {
    console.log("deleted 2", id);
    if (id) {
      confirmAlert({
        title: "Delete collection?",
        message: "Are you sure to delete.",
        buttons: [
          {
            label: "Yes",
            onClick: () => handleDelete(id),
          },
          {
            label: "No",
            onClick: () => console.log("Clicked No"),
          },
        ],
      });
    }
  };

  const handleDelete = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };
    axiosInstance
      .delete(`/v1/admin/collection/${id}`, config)
      .then((res) => {
        Swal.fire("Success!", capitalizeFirstLetter(res?.data?.message) || 'Collection successfully deleted', "success");
        window.location.reload(true);
      })
      .catch((err) => {
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error");
      });
  };

  return (
    <React.Fragment>
      <TableRow
        onClick={() => {
          setOpen(!open);
        }}
        className={classes.rowDatas}
        style={{ borderBottom: "12px solid #F5F5FC", cursor: "default" }}
      >
        <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
          <div className="columnStyles">{data?.name ? data?.name : "-"}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
          <div className="columnStyles">{data?.slug ? data?.slug : "-"}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div className="columnStyles break-word">{data?.description ? data?.description : "-"}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div className="columnStyles">
            {data?.assetCount}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <Tooltip title="Edit collection">
            <img
              src={editIcon}
              className="cursorPointer"
              alt="Edit"
              onClick={() => {
                setSelectedRow(data);
                setOpenEdit(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete collection">
            <img
              src={deleteIcon}
              onClick={async () => {
                await setDeleteCollectionID(data?.id);
                deleteCollection(data?.id);
              }}
              className="marginLeft20 cursorPointer"
              alt="Delete"
            />
          </Tooltip>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const RenderHeader = ({ columns, onSorting }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  const classes = useRowStyles();
  return (
    <TableHead className={classes.header}>
      <TableRow>
        {columns?.map(({ name, field, sortable, image }) => (
          <TableCell
            align={columns.align}
            key={name}
            className={classes.cursorDefault}
          >
            <div className="dividerFlex">
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
                {/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function CollectionTable({ data, showLoader, setShowLoader }) {
  const classes = useRowStyles();
  // const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
  const [totalCount, setTotalCount] = useState(0);
  const [collectionList, setCollectionList] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchParam, setSearchParam] = React.useState("");
  const token = JSON.parse(localStorage.getItem("token"));
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const tableColumnWidth = [25, 20, 25, 15, 15];
  const columns = [
    {
      name: "Name",
      field: "name",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Label",
      field: "label",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Description",
      field: "description",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Asset Count",
      field: "count",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Action",
      field: "action",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
  ];

  useEffect(() => {
     getCollectionList();
  }, [pageNo]);

  const handleChangePage = (event, nextPageNo) => {
    setPageNo(nextPageNo);
    window.scrollTo(0, 0);
  };
  const handleSearchParam = (e) => {
    setSearchParam(e.target.value);
    axiosInstance
      .get(
        `/v1/admin/collection?type=collection&page=${pageNo + 1}&limit=10&search=${
          e.target.value
        }`,
        header
      )
      .then((result) => {
        setCollectionList(result?.data?.result?.collections);
        setTotalCount(result?.data?.result?.totalItems);
      })
      .catch((err) => {
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error");
      });
  };

  const getCollectionList = async () => {
    const result = await axiosInstance.get(`/v1/admin/collection?type=collection&page=${pageNo + 1}&limit=10&`,
        header
      )
      .then((result) => {
        setCollectionList(result?.data?.result?.collections);
        setTotalCount(result?.data?.result?.totalItems);
      })
      .catch((err) => {
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error").then(() => {
          if(err?.response?.status === 401 && err?.response?.data?.message?.includes("authenticated")){
            console.log('err.response', err.response)
            window.location.href = "/welcome/:layout"
          }
        })
      });
  };

  return (
    <div className="table_div tableWidth100" style={{ position: "relative" }}>
      {showLoader == true ? <Loader /> : null}
      {/* <TextField
        className="cat-search-box-align"
        id="sports-search"
        label="Search"
        variant="outlined"
        value={searchParam}
        onChange={handleSearchParam}
      /> */}
      <TextField
        className="cat-search-box-align"
          value={searchParam}
          onChange={handleSearchParam}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          label="Search Collection"
          variant="outlined"
          placeholder="Search"
        />

      {collectionList?.length !== 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" className={classes.root}>
              <RenderHeader
                columns={columns}
                // onSorting={(field, order) => setSorting({ field, order })}
              />
              <TableBody style={{ width: "100%" }}>
                {collectionList?.map((row, index) => (
                  <Row
                    aria-label="expand row"
                    size="small"
                    key={row?.id}
                    row={row}
                    setSelectedRow={(value) => setSelectedRow(value)}
                    selectedRow={selectedRow}
                    data={collectionList[index]}
                    tableColumnWidth={tableColumnWidth}
                    setOpenEdit={setOpenEdit}
                    // setShowLoader={setShowLoader}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            // backIconButtonProps={{ disabled: false }}
            component="div"
            count={totalCount}
            rowsPerPage={10}
            page={pageNo}
            onChangePage={handleChangePage}
            // onChange={(e) => handleChangePage(e, page)}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => (
              <>
                <span className="paginationLabel">Showing </span>
                {`${from}-${to}`}
                <span className="paginationLabel"> out of </span>
                {`${count}`}
              </>
            )}
          />
        </>
      ) : (
        <div className="noResultFound flexColumnCenter">
          <NoRecordsFound message="No records found." />
        </div>
      )}
      <UiDrawer
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        showLoader={showLoader}
        setShowLoader={(e) => setShowLoader(e)}
        isEdit={true}
        selectedRow={selectedRow}
      />
    </div>
  );
}
