export const formatEuroPrice = (price) => {
  if (price) {
    return price.toFixed(2);
  }
  return 0;
};

export const formatETHPrice = (price) => {
  if (price) {
    return price.toFixed(4);
  }
  return 0;
};

export function acceptOnlyNumbers(str) {
  if (str) {
    return str.replace(/[^0-9]/g, "").replace(/(\..*?)\..*/g, "$1");
  }
  return null;
}

export function acceptNumbersWithDecimal(str) {
  if (str) {
    var validNumber = new RegExp(/^\d*\.?\d*$/);
    if (validNumber.test(str)) {
      if (str) parseFloat(str);
      return parseFloat(str);
    } else {
      return null;
    }
  }
  return null;
}

export function formatNumbersWithDecimal(str) {
  if (str) {
    if (str?.startsWith(".")) {
      str = str.replace(".", "0.");
    }
    var validNumber = new RegExp(/^\d*\.?\d*$/);
    if (validNumber.test(str)) {
      return str;
    } else {
      return "";
    }
  }
  return null;
}

export function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return null;
}

export function validateFacebookUrl(string) {
  if (/^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i.test(string)) {
    return string;
  }
  return false;
}

export function validateTwitterUrl(string) {
  if (/^(https?:\/\/)?twitter.com\/.*/i.test(string)) {
    return string;
  }
  return false;
}

export function validateInstagramUrl(string) {
  if (/^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i.test(string)) {
    return string;
  }
  return false;
}

export function objectDeepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function validateEmail(email) {
  if (email?.length) {
    let output = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (output?.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

export function formatAPIError(error) {
  let msg = "Something went wrong !!!";
  if (error) {
    if (error?.response?.data?.message?.length)
      return error?.response?.data?.message?.toString();
    if (typeof error?.response?.data?.errors === "string")
      return error?.response?.data?.errors?.toString();
    if (
      typeof error?.response?.data?.errors === "object" &&
      error?.response?.data?.errors?.[0]
    )
      return error?.response?.data?.errors?.[0]?.toString();
    if (error?.message === "Network Error") {
      return "Network issue. Please try again.";
    } else if (error?.message) {
      return error?.message?.toString();
    } else {
      return msg;
    }
  }
  return msg;
}
