
import React, { useEffect, useState } from "react"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import { TableRow } from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import { Loader } from "react-feather";
import Swal from "sweetalert2";
import { axiosInstance, baseURL } from "../../../utility/api";
import NoRecordsFound from "../../common/Error/NoRecordsFound";
import classes from "./Table.css"
import RenderHeader from "./TableHeader";
import Row from "./Row"
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

import { formatAPIError } from "../../../utility/helper";

const DataTable = ({ data, showLoader, setShowLoader, setEditOpen, setIsEdit, setState, onClose, setPermission, permission, reload }) => {
  // const classes = useRowStyles(); 
  const [totalCount, setTotalCount] = useState(0);
  const [newsList, setNewsList] = useState([
    // { firstname: "imran", lastname: "basha", email: "imran@gmail.com", status: "Active" }
  ]);
  // const [openEdit, setOpenEdit] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [searchParam, setSearchParam] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    params: {
      page: pageNo+1,
      limit: 10
    },
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };
  const tableColumnWidth = [20, 20, 20, 20, 30];
  const columns = [
    {
      name: "First Name",
      field: "firstname",
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Last Name",
      field: "lastname",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Email",
      field: "email",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    // {
    //   name: "Status",
    //   field: "status",
    //   // image: Divider,
    //   numeric: true,
    //   sortable: false,
    //   align: "left",
    // },
    {
      name: "Action",
      field: "action",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },

  ];
  useEffect(() => {
    // getNewsList();
  }, []);

  const getData = () => {
    axiosInstance
      .get(`${baseURL}v1/sub-admin/list${searchParam ? `?search=${searchParam}` : ''}`, config)
      .then((res) => {
        setNewsList(res.data.result.data);
        setTotalCount(res.data.result.totalItems)
        // setPermission(res.data.result)
        // setError({
        //   status:false,
        //   message:""
        // })     
        // Swal.fire("Success!", res?.data?.message, "success");
      })
      .catch((err) => {
        let errMsg = formatAPIError(err)
        // Swal.fire("Alert!", errMsg, "error");
      });
  }
  useEffect(() => {
    getData()
  }, [pageNo, reload, searchParam]);

  const handleChangePage = (event, nextPageNo) => {
    setPageNo(nextPageNo);
    window.scrollTo(0, 0);
  };
  // const getNewsList = async () => {
  //   const result = await axiosInstance
  //     .get(`/v1/admin/news/fetch-all?page=${pageNo + 1}&limit=10`, header)
  //     .then((result) => {
  //       setNewsList(result?.data?.result?.data);
  //       setTotalCount(result?.data?.result?.totalItems);
  //     })
  //     .catch((err) => {
  //       if (
  //         err?.response &&
  //         err?.response?.data?.errors &&
  //         err?.response?.data?.errors?.length !== 0
  //       ) {
  //         Swal.fire({
  //           title: "Alert",
  //           text: err?.response?.data?.errors.map((err) => err.msg),
  //           icon: "error",
  //         });
  //       } else {
  //         Swal.fire("Alert", err?.response?.data?.message, "error");
  //       }
  //     });
  // };
  return (
    <div className="table_div tableWidth100">
      {showLoader == true ? <Loader /> : null}
      <TextField
        className="cat-search-box-align"
          value={searchParam}
          onChange={(e) => setSearchParam(e?.target?.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          label="Search Sub Admin"
          variant="outlined"
          placeholder="Search"
        />
      {newsList?.length !== 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" className={classes.root}>
              <RenderHeader
                columns={columns}
              />
              <TableBody style={{ width: "100%" }}>
                {newsList?.map((row, index) => (
                  <Row
                    aria-label="expand row"
                    size="small"
                    key={row?.id}
                    row={row}
                    setSelectedRow={(value) => setSelectedRow(value)}
                    selectedRow={selectedRow}
                    data={newsList[index]}
                    tableColumnWidth={tableColumnWidth}
                    setOpenEdit={setEditOpen}
                    setIsEdit={setIsEdit}
                    setState={setState}
                    setPermission={setPermission}
                  />
                ))}
              </TableBody>
              <TableFooter>

                <TablePagination
                  rowsPerPageOptions={[]}
                  // component="div"
                  count={totalCount}
                  rowsPerPage={10}
                  page={pageNo}
                  onChangePage={handleChangePage}
                  showFirstButton={true}
                  showLastButton={true}
                  labelDisplayedRows={({ from, to, count }) => (
                    <>
                      <span className="paginationLabel">Showing </span>
                      {`${from}-${to}`}
                      <span className="paginationLabel"> out of </span>
                      {`${count}`}
                    </>
                  )}
                />

              </TableFooter>
            </Table>
          </TableContainer>
        </>
      ) : (
        <div className="noResultFound flexColumnCenter">
          <NoRecordsFound message="No records found." />
        </div>
      )}
    </div>
  );
}
export default DataTable