const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength);
    }
  };
  
  const preventSpecialCharacters = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum = e.key === 'e' || e.key === '+' || e.key === '-';
    } else if (e.keyCode !== undefined) {
      checkIfNum = e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };
  
  export { maxLengthCheck, preventSpecialCharacters };