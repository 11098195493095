import React, { useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./addProduct.css";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
// import ImageUpload from "../../assets/images/other-images/imageUpload.svg";

const useStyles = makeStyles({
  radioGroupRoot: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },

  formControlRoot: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  // label: {
  //   textTransform: "capitalize",
  // },
});

export default function AddCreator(props) {
  const {
    style,
    state,
    // onChangeDescription,
    handleChange,
    // handleImageUpload,
    // loadfile,
    categoryList,
    handleSubmitForm,
    isEdit,
  } = props;

  const classes = useStyles();

  return (
    <>
      <div className="container tm-mt-big tm-mb-big">
        <div className="row">
          <div className="col-xl-9 col-lg-10 col-md-12 col-sm-12 mx-auto">
            <div className="tm-bg-primary-dark tm-block tm-block-h-auto">
              <div className="row">
                <div className="col-12">
                  <h2 className="tm-block-title d-inline-block">
                    {isEdit ? "Edit" : "Add"} Creator
                  </h2>
                </div>
              </div>
              <div className="row tm-edit-product-row">
                <div className="col-xl-12 col-lg-6 col-md-12">
                  <form action="" className="tm-edit-product-form">
                    <div className="form-group mb-3">
                      <label for="name">Creator Name</label>
                      <span className="colorRed">*</span>
                      <input
                        type="text"
                        name="name"
                        className="form-control validate"
                        defaultValue={state.name}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label for="name">Wallet Address</label>
                      <span className="colorRed">*</span>
                      <input
                        type="text"
                        name="slug"
                        className="form-control validate"
                        value={state.name}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </div>
                    {/* <div className="form-group mb-3">
                      <label for="description">Description</label>
                      <textarea
                        class="form-control validate"
                        rows="3"
                        required=""
                        spellcheck="false"
                        name="description"
                        value={state.description}
                        onChange={(e) => handleChange(e)}
                      ></textarea>
                    </div> */}
                  </form>
                </div>

                {state.error.status == true ? (
                  <div className="errorLabel col-6 col-md-12">
                    {state?.error?.message}
                  </div>
                ) : null}
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block text-uppercase"
                    onClick={handleSubmitForm}
                    disabled={
                      state.name || state.description || state.slug
                        ? false
                        : true
                    }
                  >
                    Save Creator Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
