import React from "react";
import { Drawer, Divider, IconButton, TextField } from "@material-ui/core";
// import ReorderIcon from "@material-ui/icons/Reorder";
// import { Link } from "react-router-dom";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loader from "../common/Loader/Loader";
import { axiosInstance } from "../../utility/api";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AddProductForm from "../products/addProduct";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import decryptFunction from "../common/decryptURL";
import "./Uidrawer.css";
// import { actionToggle } from "../../redux/actions/index";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
// import { Dispatch } from "redux";
import Swal from "sweetalert2";
import moment from "moment";

import API from '../../APIActions/api'
import { acceptNumbersWithDecimal, acceptOnlyNumbers, formatAPIError, formatETHPrice, formatNumbersWithDecimal } from "../../utility/helper";

const styles = {
  sideNav: {
    marginTop: "-60px",
    zIndex: 3,
    marginLeft: "0px",
    position: "fixed",
  },
  link: {
    color: "black",
    textDecoration: "none",
  },
  title: {
    width: "90%",
    marginBottom: "20px",
  },
  productCard: {
    boxShadow: "0px 0px 2px #bbb",
    padding: "10px",
    marginBottom: "20px",
  },
  rootDrawer: {
    zIndex: "200 !important",
  },
};

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  label: {
    textTransform: "capitalize",
  },
});

// const useStyles = makeStyles((theme) => ({
//   rootDrawer: {
//     zIndex: "200",
//   },
// }));

class UiDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpened: false,
      value: "",
      categoryId: "",
      subCategoryId: "",
      leagueId: "",
      teamId: "",
      networkType: 'Ethereum',
      saleType: "fixed",
      name: "",
      catagories: [],
      subCategoryList: [],
      leagueList: [],
      teamList: [],
      status: "active",
      description: "",
      currentEdition: 1,
      quantity: 1,
      totalEdition: 1,
      availableStock: 1,
      creatorDescription: "",
      creatorName: "",
      auctionBuyNowPrice: null,
      isBuyNowForAuction: false,
      saleType: "fixed",
      isDownloadable: "false",
      institutionId: '',
      collectionId: '',
      rarityLevel: '',
      physicalCreationYear: '',
      digitalReProdYear: '',
      mintingYear: '',
      imageCopyright: '',
      physicalOwner: '',
      physicalProvenence: '',
      additionalInfo: '',
      artistId: '',
      certificateImageUrl: '',
      // isAirDrop: false,
      auction: {
        auctionType: "normal",
        openingPrice: null,
        lowestPriceToAccept: null,
        bidIncrement: null,
        noOfBids: null,
        startDate: new Date(moment.utc().format()),
        endingDate: new Date(moment.utc().add(1, "days").format()),
        status: "active",
      },
      // imageUrl: "",
      regularPrice: '',
      // animationUrl: "",
      // shortVideoUrl: "",
      // longVideoUrl: "",
      // ipfsImageUrl: "",
      // audioUrl: "",
      isMediaChange: false,
      ipfsAudioUrlPath: "",
      ipfsShortVideoUrlPath: "",
      ipfsLongVideoUrlPath: "",
      ipfsImageUrlPath: "",
      ipfsAnimationUrlPath: "",
      isMediaChange: false,
      ipfsMetaUrl: "",
      royaltyCommission: 0,
      mediaType: "",
      multiFileStore: {
        mainFile: [],
        supplimentary1: [],
        supplimentary2: [],
        supplimentary3: [],
        supplimentary4: [],
      },
      isDownloadable: true,
      isCarrosel: false,
      // isEditionMintToAdmin: false,
      descriptionMint: "",

      error: {
        status: false,
        message: "",
        location: "",
      },
      showLoader: {
        open: false,
        message: "",
      },
      setOpenSnackBar: false,
      isEdit: props.isEdits,
      isMainFileVideo: false,
      isMainFileAudio: false,
      isMainFile3D: false,
      disableSupportingDocs: true,
      isSuplimentaryFile1Video: false,
      isSuplimentaryFile2Video: false,
      isSuplimentaryFile3Video: false,
      isSuplimentaryFile4Video: false,
      isMainFileVideo: false,
      is3dFile: false,
      priceCrypto: 0,
      descriptionMint: "",
    };
  }

  config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };

  // classes = useStyles();

  componentDidMount() {
    // axiosInstance
    //   .get("/v1/admin/category?type=category&page=1&limit=200", this.config)
    //   .then((res) => {
    //     this.setState({ catagories: res?.data?.result?.assets });
    //   })
    //   .catch(() => {});
    let tempMultiFiles = {};

    if (this.props.data) {
      if (this.props?.data?.files?.length > 0) {
        let fileIndex = 1;
        let result = this.props?.data?.files?.forEach((file) => {
          if (file.isIpfs === true && file?.position === 1) {
            tempMultiFiles.mainFile = [
              file?.id,
              file?.name,
              decryptFunction(file?.url),
              file?.type,
              "mainFile",
            ];
          } else {
            let position = Number(file?.position) - 1
            tempMultiFiles[`supplimentary${position}`] = [
              file?.id,
              file?.name,
              file.position === 2 || file.position === 3 ? decryptFunction(file?.url) : file?.url,
              file?.type,
              "supplimentaryFile",
            ];
            fileIndex++;
          }
        });
      }

      this.setState({
        ...this.props.data,
        categoryId: this.props.data?.category?.id,
        ipfsAudioUrlPath: this.props.data?.ipfsAudioUrl,
        audioUrl: this.props.data?.ipfsAudioUrl,
        ipfsShortVideoUrlPath: this.props.data?.ipfsShortVideoUrl,
        shortVideoUrl: this.props.data?.ipfsShortVideoUrl,
        ipfsLongVideoUrlPath: this.props.data?.ipfsLongVideoUrl,
        ipfsImageUrlPath: this.props.data?.ipfsImageUrl,
        imageUrl: this.props.data?.ipfsImageUrl,
        ipfsAnimationUrlPath: this.props.data?.ipfsAnimationUrl,
        ipfsAudioThumbnailPath: this.props?.data?.ipfsAudioThumbnail,
        ipfsMetaUrl: this.props.data?.ipfsMetaUrl,
        royaltyCommission: this.props.data?.royaltyCommission,
        isEdit: true,
        auction: this.props?.data?.auctions?.[0] || '',
        isCarrosel: this?.props?.data?.isCarrosel?.toString(),
        multiFileStore: tempMultiFiles,
        disableSupportingDocs: false,
        isMainFileVideo: this.props.data?.mediaType === "video" ? true : false,
        isMainFileAudio: this.props.data?.mediaType === "audio" ? true : false,
        // auction: { ...this.state.auction, id: this.props.data.auctions[0]?.id },
      });
    } else {
      this.setState({
        ...this.state,
        isEdit: false,
      });
    }
  }

  handleSelectChange = (e, name) => {
    this.setState({
      ...this.state,
      [name]: e?.id,
    });
  }

  handleChange = async (event) => {
    const { name, value } = event?.target
    const auctionParamaters = [
      "productCondition",
      "auctionType",
      "openingPrice",
      "lowestPriceToAccept",
      "bidIncrement",
      "startDate",
      "endingDate",
    ];
    if (auctionParamaters.find((item) => item == event.target.name)) {
      this.setState({
        ...this.state,
        auction: {
          ...this.state.auction,
          [event.target.name]: event.target.value,
        },
      });
    } else {
      if (event.target.name == "saleType" && event.target.value == "fixed") {
        this.setState({
          ...this.state,
          ["saleType"]: "fixed",
          ["currentEdition"]: null,
          ["auction"]: null,
        });
      } else if (
        event.target.name == "saleType" &&
        event.target.value == "auction"
      ) {
        this.setState({
          ...this.state,
          ["saleType"]: "auction",
          ["regularPrice"]: 0,
          ["currentEdition"]: 1,
          ["quantity"]: 1,
          ["totalEdition"]: 1,
        });
      }
      else if (
        event.target.name == "saleType" &&
        event.target.value == "free"
      ) {
        this.setState({
          ...this.state,
          ["saleType"]: "free",
          ["auction"]: null,
          ["regularPrice"]: 0,
        });
      }
      else if (event.target.name === "isEditionMintToAdmin") {
        //console.log("mint admin 12", event.target.name, event.target.value);
        this.setState({
          ...this.state,
          [event.target.name]: event.target.value === "true" ? true : false,
        });
      } else if (
        event.target.name === "name" ||
        event.target.name === "creatorName"
      ) {
        const { name, value } = event.target;
        if (value.length <= 90) {
          this.setState({
            ...this.state,
            [name]: value,
          });
        }
      }
      //  else if (event.target.name === "descriptionMint") {
      //   const { name, value } = event.target;
      //   if (value.length <= 1000) {
      //     this.setState({
      //       ...this.state,
      //       [name]: value,
      //     });
      //   }
      // }
      else if (event.target.name === "isAirDrop" || event.target.name === 'isCarrosel') {
        this.setState({
          ...this.state,
          [event.target.name]: event.target.value === 'true' ? true : false,
        });
      } else if (name === 'mintingYear' || name === 'totalEdition' || name === 'quantity' || name === 'soldEdition') {
        let val = acceptOnlyNumbers(value)
        this.setState({
          ...this.state,
          [name]: val,
        });
      } else {
        this.setState({
          ...this.state,
          [event.target.name]: event.target.value,
        });
      }
    }
  };

  handleDeleteAssetFile = async (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };
    await axiosInstance
      .delete(`/v1/admin/asset/files/remove/${id}`, config)
      .then((res) => {
        Swal.fire("Success!", res?.data?.message, "success");
      })
      .then(() => { })
      .catch((err) => {
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error");
      });
  };

  handleDeleteMultipleAssetFiles = (idList) => {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
      };
      axiosInstance
        .delete(`/v1/admin/asset/files/all?fileId=${idList.toString()}`, config)
        .then((res) => {
          resolve(res);
          // Swal.fire("Success!", res?.data?.message, "success");
        })
        .catch((err) => {
          reject(err);
          //console.log("rrr eror", err?.response.data?.message);
          // Swal.fire("Alert!", err?.response?.data?.message, "error");
        });
    });
  };

  assetIds = (obj) => {
    let filteredAssetID = [];
    let createdArr = Object.values(obj);
    for (let i = 0; i < createdArr.length; i++) {
      if (createdArr[i].length > 0) {
        filteredAssetID.push(createdArr[i][0]);
      }
    }
    return filteredAssetID;
  };

  assetIdsWithoutMainFileAndCertificateImg = (obj) => {
    let filteredAssetID = [];
    if (obj?.supplimentary1?.[0]) {
      filteredAssetID.push(obj?.supplimentary1?.[0])
    }
    if (obj?.supplimentary3?.[0]) {
      filteredAssetID.push(obj?.supplimentary3?.[0])
    }
    if (obj?.supplimentary4?.[0]) {
      filteredAssetID.push(obj?.supplimentary4?.[0])
    }
    return filteredAssetID;
  };

  handleDeleteAll = async () => {
    // this.state.isEdit
    let fileIds = [];
    if (this.state.isEdit == true) {
      fileIds = this.assetIdsWithoutMainFileAndCertificateImg(this.state.multiFileStore);
    } else {
      fileIds = this.assetIds(this.state.multiFileStore);
    }

    if (fileIds?.length > 0) {
      // await this.handleDeleteMultipleAssetFiles(fileIds);
      this.handleDeleteMultipleAssetFiles(fileIds)
        .then((res) => {
          let multiFileStore = {
            ...this.state.multiFileStore,
            mainFile: [],
            supplimentary1: [],
            supplimentary2: [],
            supplimentary3: [],
            supplimentary4: [],
          };
          if (this.state?.isEdit == true) {
            multiFileStore = {
              ...this.state.multiFileStore,
              supplimentary1: [],
              // supplimentary2: [], in edit when delete all dont delete certificate image as it is minted
              supplimentary3: [],
              supplimentary4: [],
            };
          }
          this.setState({
            ...this.state,
            certificateImageUrl: this.state?.isEdit === true ? this.state?.certificateImageUrl : '',
            multiFileStore: multiFileStore,
            isMainFileVideo: false,
            isMainFileAudio: false,
            isMainFile3D: false,
            ipfsImageUrlPath: this.state.isEdit
              ? this?.state?.ipfsImageUrlPath
              : "",
            ipfsShortVideoUrlPath: this.state.isEdit
              ? this?.state?.ipfsShortVideoUrlPath
              : "",
            ipfsAudioUrlPath: this.state.isEdit
              ? this?.state?.ipfsAudioUrlPath
              : "",
            ipfs3dUrl: this.state.isEdit ? this?.state?.ipfs3dUrl : "",
            is3dFile: this?.state?.isEdit ? this?.state?.is3dFile : "",
          });
          Swal.fire("Success!", res?.data?.message, "success");
        })
        .catch((err) => {
          //console.log("Promise Reject", err);
          Swal.fire("Success!", "Error while deleting files.", "error");
        });
    }
  };

  handleRemoveFile = (type) => {
    let id = null;
    switch (type) {
      case "mainFile":
        id = this?.state?.multiFileStore?.mainFile[0];
        if (id) {
          this.handleDeleteAssetFile(id);
        }
        this.setState({
          ...this.state,
          multiFileStore: {
            ...this.state.multiFileStore,
            mainFile: [],
          },
          isMainFileVideo: false,
          isMainFileAudio: false,
          isMainFile3D: false,
          ipfsImageUrlPath: "",
          ipfsShortVideoUrlPath: "",
          ipfsAudioUrlPath: "",
          ipfs3dUrl: "",
          is3dFile: "",
        });
        break;
      case "supplimentary1":
        id = this?.state?.multiFileStore?.supplimentary1[0];
        if (id) {
          this.handleDeleteAssetFile(id);
        }
        this.setState({
          ...this.state,
          isSuplimentaryFile1Video: false,
          multiFileStore: {
            ...this.state.multiFileStore,
            supplimentary1: [],
          },
        });
        break;
      case "supplimentary2":
        id = this?.state?.multiFileStore?.supplimentary2[0];
        if (id) {
          this.handleDeleteAssetFile(id);
        }
        this.setState({
          ...this.state,
          isSuplimentaryFile2Video: false,
          certificateImageUrl: '',
          multiFileStore: {
            ...this.state.multiFileStore,
            supplimentary2: [],
          },
        });
        break;
      case "supplimentary3":
        id = this?.state?.multiFileStore?.supplimentary3[0];
        if (id) {
          this.handleDeleteAssetFile(id);
        }
        this.setState({
          ...this.state,
          isSuplimentaryFile3Video: false,
          multiFileStore: {
            ...this.state.multiFileStore,
            supplimentary3: [],
          },
        });
        break;
      case "supplimentary4":
        id = this?.state?.multiFileStore?.supplimentary4[0];
        if (id) {
          this.handleDeleteAssetFile(id);
        }
        this.setState({
          ...this.state,
          isSuplimentaryFile4Video: false,
          multiFileStore: {
            ...this.state.multiFileStore,
            supplimentary4: [],
          },
        });
        break;

      default:
        break;
    }
  };

  toggleDrawerStatus = () => {
    this.setState({
      isDrawerOpened: true,
    });
  };

  closeDrawer = () => {
    this.setState({
      isDrawerOpened: false,
    });
  };

  setShowLoader = (value, message) => {
    this.setState({
      ...this.state,
      showLoader: { open: value, message: message },
    });
  };

  handleSubmitForm = (e, exchangeVal) => {
    // console.log('handleSubmitForm state', this.state, formatNumbersWithDecimal(this.state.regularPrice))
    // console.log('handleSubmitForm props', this.props, formatNumbersWithDecimal(this.state?.auction?.openingPrice))

    e.preventDefault();
    this.setState({
      error: { status: false, message: "" },
    });
    console.log('this.state', this.state)
    if (this.state.saleType == "auction") {
      if (parseFloat(this.state?.auction?.openingPrice.toString()) <= 0) {
        Swal.fire("Alert!", "Reserve price should be greater than 0", "error");
        return false
      }
    } else if (this.state.saleType == "fixed") {
      if (parseFloat(this.state.regularPrice.toString()) <= 0) {
        Swal.fire("Alert!", "Price should be greater than 0", "error");
        return false
      }
    }
    if (
      this.state?.isMainFileAudio &&
      this.state?.multiFileStore?.supplimentary1?.length == 0
    ) {
      Swal.fire("Alert!", "Thumbnail for the main audio is needed", "error");
      return false;
    }
    // if(this.state.currentEdition > 100){
    //   Swal.fire("Alert!", "Total Edition should be less than 100", "error");
    //   return false
    // }
    // if(this.state.totalEdition > 100){
    //   Swal.fire("Alert!", "Total Edition should be less than 100", "error");
    //   return false
    // }
    if (
      this.state.saleType == "auction" &&
      parseInt(this.state?.currentEdition) > parseInt(this.state?.totalEdition)
    ) {
      Swal.fire(
        "Alert!",
        "Current edition should be lessthan total edition.",
        "error"
      );
      return false;
    }
    // if(this.state.creatorName == null || this.state.creatorName == undefined || this.state.creatorName.length === 0){
    //   Swal.fire("Alert!", "Creator name is mandatory.", "error");
    //   return false
    // }

    if (
      (this.state.royaltyCommission < 0 &&
        this.state.royaltyCommission != "" &&
        this.state.royaltyCommission != null) ||
      (this.state.royaltyCommission > 20 &&
        this.state.royaltyCommission != "" &&
        this.state.royaltyCommission != null)
    ) {
      Swal.fire(
        "Alert!",
        "Royality commission should be between 0% and 20%.",
        "error"
      );
      return false;
    }
    Object.filter = (obj, predicate) =>
      Object.keys(obj)
        .filter((key) => predicate(obj[key]))
        .reduce((res, key) => ((res[key] = obj[key]), res), {});

    let tempFileIds = [];
    let tempMulti = this.state.multiFileStore;

    //console.log("temp", tempMulti);

    for (const [key, value] of Object.entries(tempMulti)) {
      //console.log(`${value[0]}`);
      if (value[0]) {
        tempFileIds.push(value[0]);
      }
    }

    this.setShowLoader(true, "Please Wait.");

    const data = { ...this.state };
    data.fileId = tempFileIds;
    data.priceCrypto = formatETHPrice((this.state.auction?.openingPrice || this.state?.regularPrice) * exchangeVal)
    delete data.isDrawerOpened;
    delete data.showLoader;
    delete data.catagories;
    delete data.subCategoryList;
    delete data.leagueList;
    delete data.teamList;
    // if (data.status === "active") data.status = "active";
    // else data.status = "inactive";
    // if (data.pactive === "yes") data.status = "active";
    // else data.status = "inactive";
    delete data.pactive;
    delete data.value;
    delete data.error;
    delete data.setOpenSnackBar;
    delete data.isEdit;
    delete data.ipfsImageUrl;
    delete data.ipfsAudioUrlPath;
    delete data.ipfsLongUrlPath;
    delete data.ipfsShortVideoUrlPath;
    delete data.ipfsLongVideoUrlPath;
    delete data.ipfsAnimationUrlPath;
    delete data.ipfsAudioThumbnailPath;
    delete data.ipfsImageUrlPath;
    delete data.selectedVideoFileType;
    delete data.selectedAudioType;
    delete data.isMediaChange;
    delete data.multiFileStore;
    delete data.subCategory;
    delete data.ipfsMetaUrl;
    delete data.shortVideoUrl;
    delete data.longVideoUrl;
    delete data.longVideoUrl;
    delete data.audioUrl;
    delete data.audioThumbnail;
    delete data.ipfsVideoVideoUrlPath;
    delete data.isSuplimentaryFile1Video;
    delete data.isSuplimentaryFile2Video;
    delete data.isSuplimentaryFile3Video;
    delete data.isSuplimentaryFile4Video;
    delete data.isMainFileVideo;
    delete data.isMainFileAudio;
    delete data.isMainFile3D;
    delete data.is3dFile;
    delete data.disableSupportingDocs;
    delete data.currency;
    delete data.animationUrl;
    delete data.soldEdition;
    delete data.assetState;
    delete data.parentId;
    delete data.mediaPreviewUrl;
    delete data.mediaType;
    delete data.creatorName;
    delete data.creatorDescription;
    if (this.state.audioUrl) {
      delete data.ipfsImageUrl;
      delete data.imageUrl;
    }
    if (data?.audioUrl?.includes("https")) {
      delete data.audioUrl;
    }
    // if (this?.data?.saleType == "auction") {
    //   delete data.isCarrosel;
    // }
    delete data.categoryId;
    delete data.subCategoryId;
    delete data.teamList;
    console.log('this.props.data', this.props.data)
    if (!this.props.data) {
      axiosInstance
        .post("/v1/admin/asset", data, this.config)
        .then((res) => {
          this.setShowLoader(false, "");
          this.setState({
            showLoader: false,
            isDrawerOpened: false,
            value: "",
            categoryId: "",
            subCategoryId: "",
            saleType: "",
            name: "",
            // animationUrl: "",
            // shortVideoUrl: "",
            // ipfsLongVideoUrl: "",
            // audioUrl: "",
            // catagories: [],
            description: "",
            quantity: 1,
            imageUrl: "",
            ipfsAudioUrlPath: "",
            ipfsShortVideoUrlPath: "",
            ipfsLongVideoUrlPath: "",
            ipfsImageUrlPath: "",
            ipfsAudioThumbnailPath: "",
            ipfsAnimationUrlPath: "",
            regularPrice: 0,
            creatorName: "",
            creatorDescription: "",
            royaltyCommission: 0,
            multiFileStore: {
              main: [],
              supplimentary1: [],
              supplimentary2: [],
              supplimentary3: [],
              supplimentary4: [],
            },
            error: {
              status: false,
              message: "",
            },
            selectedVideoFileType: null,
            selectedAudioType: null,
          });
          this.props.onClose();
          // this.props.toggleAction();
          Swal.fire("Success!", res?.data?.message || 'Asset added successfully', "success");
          window.location.reload(true)
          // setTimeout(() => window.location.reload(true), 6000)  // added as facing issue in asset get call
        })
        .catch((err) => {
          this.setShowLoader(false, "");
          let errMsg = formatAPIError(err)
          Swal.fire("Alert!", errMsg, "error");
        });
    } else {
      console.log("handleEditForm");
      this.handleEditForm(e);
    }
  };

  handleEditForm = (e) => {
    e.preventDefault();
    this.setState({
      error: { status: false, message: "" },
    });

    // if (this.state.imageUrl == "") {
    //   this.setState({
    //     error: { status: true, message: "Please select a image." },
    //   });
    // }

    const data = { ...this.state };
    let tempFileIds = [];
    let tempMulti = this.state.multiFileStore;

    for (const [key, value] of Object.entries(tempMulti)) {
      //console.log(`${value[0]}`);
      if (value[0]) {
        tempFileIds.push(value[0]);
      }
    }

    data.fileId = tempFileIds;

    if (data.shortVideoUrl) {
      data.imageUrl = null;
    }
    if (data?.audioUrl?.includes("https")) {
      delete data.audioUrl;
    }
    this.setShowLoader(true, "Please Wait.");

    // if(this.props.data){

    // }

    // if(data.img){
    //   data.imageUrl = null;
    // }
    // if(data.shortVideoUrl){
    //   data.imageUrl = null;
    // }
    delete data.isDrawerOpened;
    delete data.catagories;
    delete data.subCategoryList;
    delete data.leagueList;
    delete data.teamList;
    // if (data.pactive === "yes") data.status = "active";
    // else data.status = "not active";
    delete data.pactive;
    delete data.value;
    delete data.error;
    delete data.showLoader;
    delete data.setOpenSnackBar;
    delete data.isEdit;
    delete data.selectedVideoFileType;
    delete data.selectedAudioType;
    delete data.ipfsAudioUrlPath;
    delete data.ipfsShortVideoUrlPath;
    delete data.ipfsImageUrl;
    delete data.ipfsLongVideoUrl;
    delete data.ipfsAudioUrl;
    delete data.ipfsShortVideoUrl;
    delete data.ipfsAnimationUrl;
    if (!data.mediaType) {
      delete data.mediaType;
    }
    if (data.saleType == "fixed") {
      delete data.auction;
    }
    // if (data?.saleType == "auction") {
    //   delete data.isCarrosel;
    // }
    delete data.updatedAt;
    delete data.tokenId;
    delete data.isDeleted;
    delete data.ipfsLongVideoUrlPath;
    delete data.ipfsVideoVideoUrlPath;
    delete data.ipfsAnimationUrlPath;
    delete data.ipfsImageUrlPath;
    delete data.ipfsAudioThumbnailPath;
    delete data.id;
    delete data.createdAt;
    delete data.category;
    delete data.auctions;
    delete data.attributes;
    delete data.multiFileStore;
    delete data.subCategory;
    delete data.shortVideoUrl;
    delete data.longVideoUrl;
    delete data.longVideoUrl;
    delete data.audioUrl;
    delete data.audioThumbnail;
    delete data.ipfsVideoVideoUrlPath;
    delete data.currentEdition;
    delete data.files;
    delete data.imageUrl;
    delete data.ipfsPreviewImageUrl;
    delete data.purchasedBy;
    delete data.purchaseCount;
    delete data.isOnSell;
    delete data.isOnSell;
    delete data.unitAvailableSale;
    delete data.isSuplimentaryFile1Video;
    delete data.isSuplimentaryFile2Video;
    delete data.isSuplimentaryFile3Video;
    delete data.isSuplimentaryFile4Video;
    delete data.isMainFileVideo;
    delete data.isMainFileAudio;
    delete data.isMainFile3D;
    delete data.is3dFile;
    delete data.disableSupportingDocs;
    delete data.currency;
    delete data.animationUrl;
    delete data.soldEdition;
    delete data.assetState;
    delete data.parentId;
    delete data.mediaPreviewUrl;
    delete data.creatorName;
    delete data.creatorDescription;
    // delete data.purchasedBy;
    // delete data.files;
    // delete data.ipfsPreviewImageUrl;
    delete data.isBuyNowForAuction;
    // delete data.isOnSell;
    // delete data.longVideoUrl;
    // delete data.shortVideoUrl;
    // delete data.imageUrl;

    axiosInstance
      .patch(`/v1/admin/asset/${this.state.id}`, data, this.config)
      .then((res) => {
        this.setShowLoader(false, "");
        this.setState({
          ...this.state,
          isMediaChange: false,
          mainFile: {
            main: [],
            supplimentary1: [],
            supplimentary2: [],
            supplimentary3: [],
            supplimentary4: [],
          },
        });
        Swal.fire("Success!", res?.data?.message || 'Asset updated successfully', "success");
        this.props.onClose();
        window.location.reload(true)
        // setTimeout(() => window.location.reload(true), 6000)  // added as facing issue in asset get call
      })
      .catch((err) => {
        this.setShowLoader(false, "");
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error");
      });
  };

  handleMultiFileUpload = (type) => {
    let clickArea = "";
    if (type == "main_file") {
      clickArea = document.getElementById("main_file");
    } else if (type == "supporting_file1") {
      clickArea = document.getElementById("supporting_file1");
    } else if (type == "supporting_file2") {
      clickArea = document.getElementById("supporting_file2");
    } else if (type == "supporting_file3") {
      clickArea = document.getElementById("supporting_file3");
    } else if (type == "supporting_file4") {
      clickArea = document.getElementById("supporting_file4");
    }
    if (clickArea) {
      clickArea.click();
    }
  };

  loadfile = (e) => {
    if (e.target.files[0]) {
      const fileType = ["image/png", "image/jpeg", "image/jpg"];
      const imageMimeType = ["image/png", "image/jpeg", "image/gif"];
      const videoMimeType = ["video/mp4", "video/mpeg", "video/x-msvideo"];
      const audioMimeType = [
        "audio/aac",
        "audio/mpeg",
        "audio/ogg",
        "audio/wav",
        "audio/x-flac",
        "audio/flac",
      ];
      if (![...fileType].includes(e.target.files[0].type)
        // ![...imageMimeType, ...videoMimeType, ...audioMimeType].includes(e.target.files[0].type) 
        &&
        e.target.files[0].name.substr(e.target.files[0].name.length - 3) != "fbx") {
        this.setState({
          error: {
            status: true,
            message: "Please upload a valid file.",
            location: "uploadFilePage",
          },
        });
        return false;
      }

      if (
        (e.target.id == "supporting_file1" ||
          e.target.id == "supporting_file2" ||
          e.target.id == "supporting_file3" ||
          e.target.id == "supporting_file4") &&
        e.target.files[0].name.split(".").pop() == "fbx"
      ) {
        Swal.fire(
          "Alert!",
          "Invalid format,only main file can be uploaded with the 3D file",
          "error"
        );
        return false;
      }

      // if(e.target.id == "main_file" && e.target.files[0].size>50000000){
      //   Swal.fire(
      //     "Alert!",
      //     "File size exceeds,Maximum file size for main file should 50MB",
      //     "error"
      //   );
      //   return false;
      // }

      this.setShowLoader(true, "Please wait while uploading your files.");
      try {
        var binaryData = [];
        var image = document.getElementById("output");
        binaryData.push(e.target.files[0]);
        try {
          image.src = window.URL.createObjectURL(
            new Blob(binaryData, { type: "application/zip" })
          );
        } catch (error) {
          //console.log("error", error);
        }

        let position = 1;
        if (e.target.id == "supporting_file1") {
          position = 2;
        } else if (e.target.id == "supporting_file2") {
          position = 3;
        } else if (e.target.id == "supporting_file3") {
          position = 4;
        } else if (e.target.id == "supporting_file4") {
          position = 5;
        }

        // var image = document.getElementById("output");
        // image.src = URL.createObjectURL(e.target.files[0]);
        let form_data = new FormData();
        form_data.append("file", e.target.files[0]);
        form_data.append("position", position);
        if (e.target.id == "main_file") {
          axiosInstance
            .post("/v1/admin/upload/pinning", form_data, this.config)
            .then((res) => {
              if ([...videoMimeType].includes(e.target.files[0].type)) {
                this.setState(
                  {
                    ...this.state,
                    isMainFileVideo: true,
                    isMainFileAudio: false,
                    isMainFile3D: false,
                  }
                  // () => {
                  //   if (!this.state.isSuplimentaryFile1Video) {
                  //     this.setState({
                  //       ...this.state,
                  //       multiFileStore: {
                  //         ...this.state.multiFileStore,
                  //         supplimentary1: [],
                  //       },
                  //       isMainFileVideo: true,
                  //       isMainFileAudio: false,
                  //       isMainFile3D: false,
                  //     });
                  //   }
                  // }
                );
              }
              // else {
              //   this.setState({ ...this.state, isMainFileVideo: false });
              // }
              if ([...audioMimeType].includes(e.target.files[0].type)) {
                this.setState(
                  {
                    ...this.state,
                    isMainFileVideo: false,
                    isMainFileAudio: true,
                    isMainFile3D: false,
                  }
                  // (cstate) => {
                  //   if (this.state.isSuplimentaryFile1Video) {
                  //     this.setState({
                  //       ...this.state,
                  //       multiFileStore: {
                  //         ...this.state.multiFileStore,
                  //         supplimentary1: [],
                  //       },
                  //       // ipfsImageUrlPath: res.data.result.path,
                  //       // mediaType: res.data.result.mediaType,
                  //       audioThumbnail: "",
                  //       animationUrl: "",
                  //       shortVideoUrl: "",
                  //       longVideoUrl: "",
                  //       audioUrl: "",
                  //       audioThumbnail: "",
                  //       ipfsShortVideoUrlPath: "",
                  //       ipfsVideoVideoUrlPath: "",
                  //       ipfsAnimationUrlPath: "",
                  //       ipfsAudioUrlPath: "",
                  //       isSuplimentaryFile1Video: false,
                  //     });
                  //   }
                  // }
                );
              }
              // else {
              //   this.setState({ ...this.state, isMainFileAudio: false });
              // }
              if (e.target.files[0].name.split(".").pop() == "fbx") {
                this.setState({
                  ...this.state,
                  isMainFileVideo: false,
                  isMainFileAudio: false,
                  isMainFile3D: true,
                });
              }
              // else {
              //   this.setState({ ...this.state, isMainFile3D: false });
              // }
              this.setState({
                ...this.state,
                imageUrl: res.data.result.filePath,
                multiFileStore: {
                  ...this.state.multiFileStore,
                  mainFile: [
                    res.data.result.id,
                    res.data.result.name,
                    decryptFunction(res.data.result.url),
                    res.data.result.type,
                  ],
                },
                // ipfsImageUrlPath: res.data.result.path,
                // mediaType: res.data.result.mediaType,
                audioThumbnail: "",
                animationUrl: "",
                shortVideoUrl: "",
                longVideoUrl: "",
                audioUrl: "",
                audioThumbnail: "",
                ipfsShortVideoUrlPath: this.state.isMainFileVideo
                  ? res.data.result.url
                  : "",
                ipfsVideoVideoUrlPath: "",
                ipfsAnimationUrlPath: "",
                ipfsAudioUrlPath: this.state.isMainFileAudio
                  ? this.state.ipfsAudioUrlPath
                  : "",
                ipfsAudioThumbnailPath: "",
                ipfsImageUrlPath: [...imageMimeType].includes(
                  e.target.files[0].type
                )
                  ? res.data.result.url
                  : "",
                isMainFileVideo: this.state.isMainFileVideo ? true : false,
                isMainFile3D:
                  e.target.files[0].name.split(".").pop() == "fbx"
                    ? true
                    : false,
                is3dFile:
                  e.target.files[0].name.split(".").pop() == "fbx"
                    ? true
                    : false,
                ipfs3dUrl:
                  e.target.files[0].name.split(".").pop() == "fbx"
                    ? res.data.result.url
                    : "",
                ipfs3dId:
                  e.target.files[0].name.split(".").pop() == "fbx"
                    ? res.data.result.previewUrl
                    : "",
                disableSupportingDocs: false,
              });
              if ([...audioMimeType].includes(e.target.files[0].type)) {
                this.setState({
                  ...this.state,
                  ipfsAudioUrlPath: res.data.result.url,
                });
              }
              if (this.props.data) {
                this.setState({
                  ...this.state,
                  isMediaChange: true,
                });
              }
              this.setShowLoader(false, "");
            })
            .catch((err) => {
              this.setShowLoader(false, "")
              let errMsg = formatAPIError(err)
              Swal.fire("Alert!", errMsg, "error");
            });
        } else {
          if (e.target.id == "supporting_file1") {
            if (this.state.isMainFileAudio) {
              if (![...imageMimeType].includes(e.target.files[0].type)) {
                this.setShowLoader(false, "");
                this.setState({
                  error: {
                    status: true,
                    message: "Please upload the image format file.",
                    location: "uploadFilePage",
                  },
                });
                return false;
              }
            }
          }
          console.log('e.target.id :>> ', e.target.id);
          console.log('position', position)
          if (this.state.isMainFileVideo) {
            // if (
            //   ![...videoMimeType].includes(e.target.files[0].type) &&
            //   e.target.id == "supporting_file1"
            // ) {
            //   this.setShowLoader(false, "");
            //   this.setState({
            //     error: {
            //       status: true,
            //       message: "Please upload the video format file.",
            //       location: "uploadFilePage",
            //     },
            //   });
            //   return false;
            // }
          }
          if (this.state.isMainFileAudio) {
            form_data.append("isThumbnail", true);
            // form_data.append("isShortVideo", false);
          }
          if (this.state.isMainFileVideo) {
            // form_data.append("isShortVideo", true);
            form_data.append("isThumbnail", false);
          }
          axiosInstance
            .post(`/v1/admin/upload/${position === 3 || position === 2 ? "pinning" : "bucket"}`, form_data, this.config)
            .then((res) => {
              // if(res?.data?.result?.position === 3) {
              //   this.setState({
              //     ...this.state,
              //     certificateImageUrl: res?.data?.result?.previewUrl,
              //   });
              // }
              // console.log('res.data.result', res.data.result)
              // console.log('position :>> ', position);
              console.log('this.state :>> ', this.state);
              if (position === 3) {
                this.setState({
                  ...this.state,
                  certificateImageUrl: res?.data?.result?.previewUrl,
                });
              }
              if (e.target.id == "supporting_file1") {
                if ([...videoMimeType].includes(e.target.files[0].type)) {
                  this.setState({
                    ...this.state,
                    // isSuplimentaryFile1Video: true,
                  });
                } else {
                  this.setState({
                    ...this.state,
                    // isSuplimentaryFile1Video: false,
                  });
                }
              }

              if (e.target.id == "supporting_file2") {
                if ([...videoMimeType].includes(e.target.files[0].type)) {
                  this.setState({
                    ...this.state,
                    isSuplimentaryFile2Video: true,
                  });
                } else {
                  this.setState({
                    ...this.state,
                    isSuplimentaryFile2Video: false,
                  });
                }
              }
              if (e.target.id == "supporting_file3") {
                if ([...videoMimeType].includes(e.target.files[0].type)) {
                  this.setState({
                    ...this.state,
                    isSuplimentaryFile3Video: true,
                  });
                } else {
                  this.setState({
                    ...this.state,
                    isSuplimentaryFile3Video: false,
                  });
                }
              }
              if (e.target.id == "supporting_file4") {
                if ([...videoMimeType].includes(e.target.files[0].type)) {
                  this.setState({
                    ...this.state,
                    isSuplimentaryFile4Video: true,
                  });
                } else {
                  this.setState({
                    ...this.state,
                    isSuplimentaryFile4Video: false,
                  });
                }
              }
              if (e.target.id == "supporting_file1") {
                //console.log("show", res.data[0]);
                this.setState({
                  ...this.state,
                  multiFileStore: {
                    ...this.state.multiFileStore,
                    supplimentary1: [
                      res.data?.result?.id,
                      res.data?.result?.name,
                      decryptFunction(res.data.result.url),
                      res.data.result.type,
                    ],
                  },
                  // ipfsImageUrlPath: res.data.result.path,
                  // mediaType: res.data.result.mediaType,
                  audioThumbnail: "",
                  animationUrl: "",
                  shortVideoUrl: "",
                  longVideoUrl: "",
                  audioUrl: "",
                  audioThumbnail: "",
                  ipfsVideoVideoUrlPath: "",
                  ipfsAnimationUrlPath: "",
                  ipfsAudioUrlPath: this.state.isMainFileAudio
                    ? this.state.ipfsAudioUrlPath
                    : "",
                  ipfsAudioThumbnailPath: this.state.isMainFileAudio
                    ? res.data.result.url
                    : "",
                });
              } else if (e.target.id == "supporting_file2") {
                this.setState({
                  ...this.state,
                  multiFileStore: {
                    ...this.state.multiFileStore,
                    supplimentary2: [
                      res.data?.result?.id,
                      res.data?.result?.name,
                      decryptFunction(res.data.result.url),
                      res.data.result.type,
                    ],
                  },
                  // ipfsImageUrlPath: res.data.result.path,
                  // mediaType: res.data.result.mediaType,
                  audioThumbnail: "",
                  animationUrl: "",
                  shortVideoUrl: "",
                  longVideoUrl: "",
                  audioUrl: "",
                  audioThumbnail: "",
                  ipfsVideoVideoUrlPath: "",
                  ipfsAnimationUrlPath: "",
                  ipfsAudioUrlPath: this.state.isMainFileAudio
                    ? this.state.ipfsAudioUrlPath
                    : "",
                  ipfsAudioThumbnailPath: this.state.isMainFileAudio
                    ? this.state.ipfsAudioThumbnailPath
                    : "",
                });
              } else if (e.target.id == "supporting_file3") {
                this.setState({
                  ...this.state,
                  multiFileStore: {
                    ...this.state.multiFileStore,
                    supplimentary3: [
                      res.data?.result?.id,
                      res.data?.result?.name,
                      res.data?.result?.url,
                      res.data.result.type,
                    ],
                  },
                  // ipfsImageUrlPath: res.data.result.path,
                  // mediaType: res.data.result.mediaType,
                  audioThumbnail: "",
                  animationUrl: "",
                  shortVideoUrl: "",
                  longVideoUrl: "",
                  audioUrl: "",
                  audioThumbnail: "",
                  ipfsVideoVideoUrlPath: "",
                  ipfsAnimationUrlPath: "",
                  ipfsAudioUrlPath: this.state.isMainFileAudio
                    ? this.state.ipfsAudioUrlPath
                    : "",
                  ipfsAudioThumbnailPath: this.state.isMainFileAudio
                    ? this.state.ipfsAudioThumbnailPath
                    : "",
                });
              } else if (e.target.id == "supporting_file4") {
                this.setState({
                  ...this.state,
                  multiFileStore: {
                    ...this.state.multiFileStore,
                    supplimentary4: [
                      res.data?.result?.id,
                      res.data?.result?.name,
                      res.data?.result?.url,
                      res.data.result.type,
                    ],
                  },
                  // ipfsImageUrlPath: res.data.result.path,
                  // mediaType: res.data.result.mediaType,
                  audioThumbnail: "",
                  animationUrl: "",
                  shortVideoUrl: "",
                  longVideoUrl: "",
                  audioUrl: "",
                  audioThumbnail: "",
                  ipfsVideoVideoUrlPath: "",
                  ipfsAnimationUrlPath: "",
                  ipfsAudioUrlPath: this.state.isMainFileAudio
                    ? this.state.ipfsAudioUrlPath
                    : "",
                  ipfsAudioThumbnailPath: this.state.isMainFileAudio
                    ? this.state.ipfsAudioThumbnailPath
                    : "",
                });
              }
              this.setShowLoader(false, "");
            })
            .catch((err) => {
              this.setShowLoader(false, "");
              let errMsg = formatAPIError(err)
              Swal.fire("Alert!", errMsg, "error");
            });
        }
      } catch (error) {
        this.setShowLoader(false, "");
      }
    }
  };

  handleClickSnackBar = () => {
    this.setState({ ...this.state, setOpenSnackBar: true });
  };

  handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ ...this.state, setOpenSnackBar: false });
  };

  // const classes = useStyles();


  render() {
    const isDrawerOpened = this.props.open;
    return (
      <div>
        {this.state.showLoader.open == true && (
          <Loader loaderInfo={this.state.showLoader.message} />
        )}
        <Drawer
          anchor="right"
          variant="temporary"
          open={isDrawerOpened}
          onClose={this.props.onClose}
          className={this.props.classes.rootDrawer}
        >
          <AddProductForm
            style={{ ...styles.title, marginBottom: "30px" }}
            state={this.state}
            onChangeDescription={(data) => {
              // const data = editor.getData()
              this.setState({ description: data });
            }}
            handleChange={this.handleChange}
            handleSelectChange={this.handleSelectChange}
            // handleImageUpload={(type) => this.handleImageUpload(type)}
            handleMultiFileUpload={(type) => this.handleMultiFileUpload(type)}
            loadfile={this.loadfile}
            institutions={this.props.institutions}
            collections={this.props.collections}
            artists={this.props.artists}
            categoryList={this.state.catagories}
            subCategoryList={this.state.subCategoryList}
            teamList={this.teamList}
            leagueList={this.teamList}
            isMainFileVideo={this.state.isMainFileVideo}
            isMainFileAudio={this.state.isMainFileAudio}
            isMainFile3D={this.state.isMainFile3D}
            disableSupportingDocs={this.state.disableSupportingDocs}
            isSuplimentaryFile1Video={this.state.isSuplimentaryFile1Video}
            isSuplimentaryFile2Video={this.state.isSuplimentaryFile2Video}
            isSuplimentaryFile3Video={this.state.isSuplimentaryFile3Video}
            isSuplimentaryFile4Video={this.state.isSuplimentaryFile4Video}
            is3dFile={this.state.is3dFile}
            ipfs3dUrl={this.state.ipfs3dUrl}
            handleSubmitForm={this.handleSubmitForm}
            isEdit={this.state.isEdit}
            setShowLoader={this.setShowLoader}
            setSelectedVideoFileType={(value) =>
              this.setState({ ...this.state, selectedVideoFileType: value })
            }
            setSelectedAudioType={(value) =>
              this.setState({ ...this.state, selectedAudioType: value })
            }
            setSaleType={(name, value) => {
              this.setState({ ...this.state, name: value });
            }}
            setError={(status, message, page) => {
              this.setState({
                error: {
                  status: status,
                  message: message,
                  location: page,
                },
              });
            }}
            handleRemoveFile={this.handleRemoveFile}
            handleDeleteAll={this.handleDeleteAll}
          // setShowLoader={(value) => setShowLoader(value)}
          />
        </Drawer>
      </div>
    );
  }
}

const mapDispatchtoprops = {
  toggleAction: (currentState) => {
    // Dispatch(actionToggle(currentState));
  },
};

export default withStyles(styles)(connect(null, mapDispatchtoprops)(UiDrawer));
