/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import Box from "@material-ui/core/Box";
// import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
// import TextField from "@material-ui/core/TextField";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import Loader from "../../common/Loader/Loader";
import editIcon from "../../../assets/images/icons/edit.svg";
import deleteIcon from "../../../assets/images/icons/delete.svg";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import Swal from "sweetalert2";

import { axiosInstance } from "../../../utility/api";
// import SearchIcon from "@material-ui/icons/Search";
// import FilterListIcon from "@material-ui/icons/FilterList";
import UiDrawer from "./UiDrawer";
import "./NewsTable.css";
// import ExpandedRow from "./Table/Components/tableExpandedRow-MaterialUI";
import NoRecordsFound from "../../common/Error/NoRecordsFound";
import moment from "moment"
import { formatDateTime } from "../../common/date";
import { formatAPIError } from "../../../utility/helper";
const useRowStyles = makeStyles({
  root: {
    marginTop: "0px",
    border: "none",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5FC",
  },
  rowDatas: {
    "& td,th": {
      border: "none",
      height: "90px",
    },
  },
  border__right: {
    borderRight: "2px solid white",
    height: "70%",
  },
  cursorDefault: {
    cursor: "default",
  },
});

//Each row of table
function Row(props) {
  const {
    row,
    data,
    tableColumnWidth,
    setShowLoader,
    showLoader,
    setOpenEdit,
    selectedRow,
    setSelectedRow,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [deleteCategoryID, setDeleteCategoryID] = useState(null);
  // const token = JSON.parse(localStorage.getItem("token"));
  const classes = useRowStyles();

  const deleteCateogry = (id) => {
    if (id) {
      confirmAlert({
        title: "Delete category?",
        message: "Are you sure to delete.",
        buttons: [
          {
            label: "Yes",
            onClick: () => handleDelete(id),
          },
          {
            label: "No",
            onClick: () => console.log("Clicked No"),
          },
        ],
      });
    }
  };

  const handleDelete = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };
    axiosInstance
      .delete(`/v1/admin/news/delete/${id}`, config)
      .then((res) => {
        Swal.fire("Success!", res?.data?.message, "success");
      })
      .then(() => {
        window.location.reload(true);
      })
      .catch((err) => {
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error");
      });
  };

  return (
    <React.Fragment>
      <TableRow
        onClick={() => {
          setOpen(!open);
        }}
        className={classes.rowDatas}
        style={{ borderBottom: "12px solid #F5F5FC", cursor: "default" }}
      >
        <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
          <div className="columnStyles">{data?.title ? data?.title : "-"}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
          <div className="columnStyles">{data?.publishedDate ? formatDateTime(data?.publishedDate) : "-"}</div>
        </TableCell>
        {/* <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div className="columnStyles">
            {data?.assetCount ? data?.assetCount : "-"}
          </div>
        </TableCell> */}
        <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <Tooltip title="Edit category">
            <img
              src={editIcon}
              className="cursorPointer"
              alt="Edit"
              onClick={() => {
                setSelectedRow(data);
                setOpenEdit(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete category">
            <img
              src={deleteIcon}
              onClick={async () => {
                await setDeleteCategoryID(data?.id);
                deleteCateogry(data?.id);
              }}
              className="marginLeft20 cursorPointer"
              alt="Delete"
            />
          </Tooltip>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const RenderHeader = ({ columns, onSorting }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  const classes = useRowStyles();
  return (
    <TableHead className={classes.header}>
      <TableRow>
        {columns?.map(({ name, field, sortable, image }) => (
          <TableCell
            align={columns.align}
            key={name}
            className={classes.cursorDefault}
          >
            <div className="dividerFlex">
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
                {/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function NewsTable({ data, showLoader, setShowLoader }) {
  const classes = useRowStyles();
  // const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
  const [totalCount, setTotalCount] = useState(0);
  const [newsList, setNewsList] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const token = JSON.parse(localStorage.getItem("token"));
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const tableColumnWidth = [25, 25, 25, 25];
  const columns = [
    {
      name: "Title",
      field: "Title",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Publish date",
      field: "publishDate",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Action",
      field: "action",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
  ];
  useEffect(() => {
    getNewsList();
  }, []);
  useEffect(() => {
    getNewsList();
  }, [pageNo]);
 
  const handleChangePage = (event, nextPageNo) => {
    setPageNo(nextPageNo);
    window.scrollTo(0, 0);
  };
//?page=${pageNo + 1}&limit=10
  const getNewsList = async () => {
    const result = await axiosInstance
      .get(`/v1/admin/news/fetch-all?page=${pageNo + 1}&limit=10`, header)
      .then((result) => {
        setNewsList(result?.data?.result?.data);
        setTotalCount(result?.data?.result?.totalItems);
      })
      .catch((err) => {
        if (
          err?.response &&
          err?.response?.data?.errors &&
          err?.response?.data?.errors?.length !== 0
        ) {
          Swal.fire({
            title: "Oops!",
            text: err?.response?.data?.errors.map((err) => err.msg),
            icon: "error",
          });
        } else {
          Swal.fire("Oops!", err?.response?.data?.message, "error");
        }
      });
  };
  return (
    <div className="table_div tableWidth100">
      {showLoader == true ? <Loader /> : null}
      {newsList?.length !== 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" className={classes.root}>
              <RenderHeader
                columns={columns}
                // onSorting={(field, order) => setSorting({ field, order })}
              />
              <TableBody style={{ width: "100%" }}>
                {newsList?.map((row, index) => (
                  <Row
                    aria-label="expand row"
                    size="small"
                    key={row?.id}
                    row={row}
                    setSelectedRow={(value) => setSelectedRow(value)}
                    selectedRow={selectedRow}
                    data={newsList[index]}
                    tableColumnWidth={tableColumnWidth}
                    setOpenEdit={setOpenEdit}
                    // setShowLoader={setShowLoader}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            // backIconButtonProps={{ disabled: false }}
            component="div"
            count={totalCount}
            rowsPerPage={10}
            page={pageNo}
            onChangePage={handleChangePage}
            // onChange={(e) => handleChangePage(e, page)}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => (
              <>
                <span className="paginationLabel">Showing </span>
                {`${from}-${to}`}
                <span className="paginationLabel"> out of </span>
                {`${count}`}
              </>
            )}
          />
        </>
      ) : (
        <div className="noResultFound flexColumnCenter">
          <NoRecordsFound message="No records found." />
        </div>
      )}
      <UiDrawer
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        showLoader={showLoader}
        setShowLoader={(e) => setShowLoader(e)}
        isEdit={true}
        selectedRow={selectedRow}
      />
      {/* <UiDrawer
        open={open}
        onClose={() => setOpen(false)}
        showLoader={showLoader}
        setShowLoader={(e) => setShowLoader(e)}
        isEdit={isEdit}
      /> */}
    </div>
  );
}
