import React from "react";
import Done from "../../../assets/images/other-images/done.png";
import "./RemoveFileUploaded.css";

const RemoveFileUploaded = ({ disable, state, handleRemoveFile, fileType }) => (
  <div className={`fileUploadActionItems " + ${disable ? "opacity3 cursorDefault" : ""}`}>
    <div
      className={`fileUploadMainActionBox fileUploadMainFlex ${
        state.isEdit && "cursorDefault"
      } ${fileType != "mainFile" ? "width98px" : ""}`}
    >
      <div className="removeUploaded">
        <span
          className={`closeButtonRemoveUpload ${disable ? "cursorDefault" : "cursorPointer"}`}
          onClick={(e) => {
            e.stopPropagation();
            if (fileType != "mainFile" && !disable) {
              handleRemoveFile(fileType);
            } else if (!state.isEdit && !disable) {
              handleRemoveFile(fileType);
            }
          }}
        >
          x
        </span>
      </div>
      <img src={Done} alt="filePreview" height="30px" />
    </div>
  </div>
);

export default RemoveFileUploaded;
