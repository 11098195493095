import React from 'react'

const DynamicPageIcons = () => {
    return (
        <svg version="1.1" id="Capa_1" x="0px" y="0px"
             viewBox="0 0 463 463" style={{enableBackground:"new 0 0 463 463",strokeWidth:'14px'}}>
        <g>
            <path d="M232.026,176c-30.603,0-55.5,24.897-55.5,55.5s24.897,55.5,55.5,55.5s55.5-24.897,55.5-55.5S262.628,176,232.026,176z
                 M232.026,272c-22.332,0-40.5-18.168-40.5-40.5s18.168-40.5,40.5-40.5s40.5,18.168,40.5,40.5S254.358,272,232.026,272z"/>
            <path d="M232.026,208c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5c4.687,0,8.5,3.813,8.5,8.5c0,4.143,3.357,7.5,7.5,7.5
                s7.5-3.357,7.5-7.5C255.526,218.542,244.984,208,232.026,208z"/>
            <path d="M388.061,231.5c6.575-6.863,12.633-13.755,18.114-20.633c30.384-38.126,39.736-71.906,26.335-95.117
                c-13.4-23.21-47.329-32.003-95.541-24.752c-8.713,1.31-17.729,3.115-26.977,5.384c-5.481-18.897-12.136-35.802-19.819-49.969
                c3.342-4.06,5.352-9.256,5.352-14.913c0-12.958-10.542-23.5-23.5-23.5c-3.851,0-7.485,0.937-10.696,2.586
                C251.954,3.563,242.116,0,232.026,0c-26.802,0-51.38,24.989-69.206,70.365c-3.216,8.185-6.156,16.879-8.812,26.004
                c-9.23-2.262-18.229-4.063-26.926-5.371C78.873,83.75,44.942,92.539,31.54,115.75c-13.4,23.211-4.048,56.991,26.336,95.117
                c5.492,6.891,11.564,13.797,18.153,20.673c-8.116,8.455-15.415,16.925-21.773,25.305C52.27,256.298,50.183,256,48.026,256
                c-12.958,0-23.5,10.542-23.5,23.5c0,6.448,2.612,12.297,6.832,16.547c-7.722,19.95-7.677,37.587,0.183,51.203
                c10.338,17.905,32.886,27.229,64.788,27.228c9.453,0,19.734-0.819,30.754-2.477c8.696-1.308,17.695-3.108,26.926-5.371
                c2.656,9.125,5.596,17.818,8.812,26.004C180.646,438.01,205.224,463,232.026,463s51.38-24.99,69.206-70.366
                c3.221-8.199,6.166-16.908,8.825-26.05c12.229,3.009,24.007,5.198,35.142,6.49c2.51,10.276,11.789,17.927,22.827,17.927
                c11.237,0,20.652-7.93,22.956-18.487c19.723-3.6,34.036-12.287,41.528-25.263c13.401-23.211,4.049-56.991-26.335-95.117
                C400.694,245.255,394.636,238.362,388.061,231.5z M339.199,105.832c41.085-6.182,70.361,0.169,80.32,17.418
                c9.958,17.248,0.818,45.776-25.075,78.269c-5.142,6.452-10.823,12.924-16.987,19.374c-15.384-14.784-33.069-29.311-52.48-43.114
                c-2.276-23.711-6.038-46.286-11.114-66.908C322.554,108.751,331.021,107.062,339.199,105.832z M272.276,301.215
                c-13.373,7.721-26.836,14.775-40.218,21.113c-13.433-6.346-26.929-13.404-40.282-21.113c-13.383-7.727-26.234-15.866-38.423-24.294
                c-1.204-14.769-1.827-29.968-1.827-45.421c0-15.439,0.622-30.624,1.824-45.38c12.221-8.471,25.085-16.633,38.426-24.336
                c13.383-7.727,26.857-14.786,40.25-21.128c13.393,6.341,26.867,13.401,40.25,21.128c13.375,7.722,26.218,15.855,38.399,24.277
                c1.215,14.78,1.851,29.993,1.851,45.439c0,15.453-0.623,30.652-1.827,45.421C298.51,285.349,285.659,293.488,272.276,301.215z
                 M308.752,296.33c-2.19,18.263-5.31,35.688-9.299,51.901c-16.001-4.652-32.673-10.667-49.597-17.894
                c9.986-5.009,19.981-10.394,29.919-16.131C289.73,308.458,299.406,302.484,308.752,296.33z M214.275,330.348
                c-16.936,7.247-33.611,13.266-49.668,17.923c-3.993-16.224-7.117-33.663-9.308-51.942c9.346,6.154,19.022,12.129,28.977,17.876
                C194.215,319.943,204.242,325.328,214.275,330.348z M137.518,265.531c-14.716-11.024-28.241-22.434-40.284-33.99
                c12.029-11.53,25.574-22.961,40.28-33.999c-0.654,11.147-0.988,22.489-0.988,33.958
                C136.526,242.994,136.861,254.361,137.518,265.531z M155.294,166.708c2.192-18.292,5.317-35.744,9.313-51.979
                c16.047,4.654,32.712,10.668,49.637,17.909c-10.002,5.016-20.015,10.409-29.969,16.156
                C174.342,154.529,164.664,160.522,155.294,166.708z M249.807,132.638c16.916-7.237,33.572-13.249,49.611-17.901
                c3.961,16.151,7.09,33.594,9.294,51.907c-9.333-6.145-18.996-12.11-28.936-17.849C269.821,143.047,259.809,137.654,249.807,132.638
                z M326.509,197.45c14.743,11.042,28.291,22.473,40.352,34.05c-12.054,11.57-25.594,22.995-40.328,34.032
                c0.657-11.17,0.993-22.537,0.993-34.031C327.526,220.011,327.178,208.635,326.509,197.45z M280.526,31.5c0,4.687-3.813,8.5-8.5,8.5
                s-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5S280.526,26.813,280.526,31.5z M176.78,75.851C191.973,37.179,212.109,15,232.026,15
                c6.239,0,12.529,2.173,18.755,6.467c-1.444,3.045-2.255,6.445-2.255,10.033c0,12.958,10.542,23.5,23.5,23.5
                c1.855,0,3.658-0.223,5.391-0.631c6.989,13.04,13.078,28.54,18.132,45.882c-20.478,5.926-41.882,13.971-63.522,23.868
                c-21.644-9.899-43.05-17.944-63.53-23.87C170.999,91.685,173.764,83.53,176.78,75.851z M44.53,123.25
                c9.958-17.249,39.235-23.597,80.321-17.418c8.159,1.227,16.604,2.911,25.272,5.023c-5.111,20.715-8.849,43.294-11.098,67.007
                c-19.344,13.794-37.03,28.345-52.385,43.081c-6.182-6.467-11.879-12.955-17.034-19.424C43.712,169.026,34.572,140.498,44.53,123.25
                z M48.026,271c4.687,0,8.5,3.813,8.5,8.5s-3.813,8.5-8.5,8.5s-8.5-3.813-8.5-8.5S43.339,271,48.026,271z M124.852,357.168
                c-41.083,6.177-70.362-0.169-80.321-17.418c-6.524-11.302-3.874-25.618,0.316-36.971c1.041,0.141,2.101,0.221,3.179,0.221
                c12.958,0,23.5-10.542,23.5-23.5c0-5.349-1.8-10.283-4.821-14.237c5.845-7.651,12.521-15.387,19.926-23.121
                c15.364,14.761,33.021,29.265,52.4,43.048c2.249,23.693,5.985,46.254,11.093,66.954
                C141.456,354.257,133.01,355.941,124.852,357.168z M287.27,387.149C272.077,425.82,251.942,448,232.026,448
                c-19.917,0-40.053-22.18-55.245-60.851c-3.017-7.679-5.781-15.835-8.285-24.398c20.493-5.93,41.915-13.982,63.573-23.89
                c21.631,9.868,43.068,17.91,63.503,23.838C293.063,371.281,290.293,379.454,287.27,387.149z M368.026,376
                c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5s8.5,3.813,8.5,8.5S372.712,376,368.026,376z M419.52,339.75
                c-5.18,8.972-15.342,14.946-30.207,17.81c-3.752-8.002-11.881-13.56-21.287-13.56c-9.626,0-17.915,5.82-21.543,14.125
                c-10.313-1.215-21.218-3.242-32.547-6.013c5.104-20.691,8.837-43.24,11.085-66.921c19.394-13.794,37.065-28.311,52.437-43.083
                c6.164,6.45,11.845,12.922,16.987,19.374C420.338,293.974,429.478,322.502,419.52,339.75z"/>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        </svg>
        
    )
}

export default DynamicPageIcons
