import React, { useState } from "react";
// import "./index.css";
import UiDrawer from "./AddAuction/addAuctionDrawer";
import AuctionList from "./AuctionList";

export default function AuctionIndex() {
  const [selectedTab, setSelectedTab] = useState("category");
  const [open, setOpen] = useState(false);
  return (
    <div>
      <div class="products-wrapper">
        <div class="container-fluide">
          <div class="page-title">
            <div class="row">
              <div class="col-6">
                <h3>Auction Listing</h3>
              </div>

              {/* <div class="col-6">
                <nav class="" aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="/products/products/:layout">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>
                      </a>
                    </li>
                    <li class="active breadcrumb-item" aria-current="page">
                      Auction
                    </li>
                  </ol>
                </nav>
              </div> */}
              {/* <div class="col-12 mt-4" style={{ justifySelf: "flexgit -end" }}>
                <button
                  className={"btn btn-primary"}
                  onClick={() => setOpen(true)}
                >
                  + Add Auction
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="auctionContent">
        {/* <div className="boleroTabsSection">
          <div
            className={
              "UsersTabOption " +
              (selectedTab == "category" ? "userTabSelected" : "")
            }
            onClick={() => {
              setSelectedTab("category");
            }}
          >
            Auction
          </div>
          <div
            className={
              "boleroTabCurve" +
              (selectedTab == "category" ? "userTabSelected" : "")
            }
            onClick={() => {
              setSelectedTab("category");
            }}
          ></div> */}

          {/* <div
            className={
              "UsersTabOption " +
              (selectedTab == "subCategory" ? "userTabSelected" : "")
            }
            onClick={() => {
              setSelectedTab("subCategory");
            }}
          >
            Auction Config
          </div>
          <div
            className={
              "boleroTabCurve " +
              (selectedTab == "subCategory" ? "userTabSelected" : "")
            }
            onClick={() => {
              setSelectedTab("subCategory");
            }}
          ></div> */}
        {/* </div> */}
        {/* {/* {selectedTab == "category" && <AuctionList />} */}
        {selectedTab == "category" && <AuctionList />}
      </div>

    </div>
  );
}
