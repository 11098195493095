import React, { Fragment, useState, useEffect } from "react"
import styles from './AirDropTable.module.css'
import ReactPlayer from "react-player";
import decryptFunction from "../../common/decryptURL";
import Swal from "sweetalert2";
import { axiosInstance } from "../../../utility/api";
import Loader from "../../common/Loader/Loader";
const ConfirmPopup = ({ assetData, userData, setPopupOpen }) => {
  const [confirm, setconfirm] = useState(false)
  const [edition, setEdition] = useState("")
  const [greaterMsg, setGreaterMsg] = useState(false)
  const [loader, setLoader] = useState(false)
  // console.log(assetData)
  // console.log(userData)
  const formatInput = (e) => {
    // console.log(e.keyCode)
    let checkIfNum;
    // if(e.keyCode > 70){
    //   console.log("yeeha")
    // }

    if (e.key !== undefined) {
      if ((e.key === "e") || (e.key === "+") || (e.key === "-")) {
        checkIfNum = true
      }
      else if (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189 || e.keyCode === 190) {
        checkIfNum = true
      }
      else if ((e.keyCode > 64 && e.keyCode < 91) || (e.keyCode > 96 && e.keyCode < 123)) {
        checkIfNum = true
      }
      else if (e.shiftKey == 1 || (e.key == "'" || e.key === ";" || e.key === "," || e.key === "/" || e.key === "[" || e.key === "]" || e.key === "`" || e.key === "\\")) {
        checkIfNum = true
      }
    }


    // if (e.key != undefined) {
    //   checkIfNum = e.key === "e" || e.key === "+" || e.key === "-";
    // } else if (e.key != undefined) {
    //   checkIfNum = e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189;
    // }
    // else if(e.key!= undefined){
    //   if ((e.keyCode > 64 && e.keyCode <91) || (e.keyCode >96 && e.keyCode < 123) || (e.keyCode==8) ){
    //     checkIfNum= true
    //   }
    // }
    // console.log(checkIfNum)
    return checkIfNum && e.preventDefault();
  };

  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };
  const editionValue = () => {
    // console.log("check")
    if (edition.length != 0) {
      setconfirm(true)
    }
    else {
      setconfirm(false)
      setGreaterMsg(false)
    }

  }

  useEffect(() => {
    editionValue()
  }, [edition])

  const confirmDrop = () => {
    if (parseInt(edition) > assetData.totalEdition) {
      setGreaterMsg(true)
    }
    else {
      setGreaterMsg(false)
      setLoader(true)
      axiosInstance.post(`/v1/admin/airdrop/${assetData?.id}`, {
        editionNo: parseInt(edition),
        userId: userData?.id
      }, config).then((res) => {
        setLoader(false)
        setPopupOpen(false)
        Swal.fire("Success!", "Airdrop Successful!!", "success").then(() => {

          window.location.reload()
        });


      }).catch((err) => {
        setLoader(false)
        // console.log(err?.response?.data)
        Swal.fire("Alert!", err?.response?.data?.message, "error");
      })


    }

  }

  return (
    <>

      <div className={styles.modalpopup} >
        <div className={styles.customUI}>
          {loader && <Loader></Loader>}
          <div className={styles.customHeader}>
            <h5>Confirm AirDrop</h5>
          </div>
          <div className={styles.image}>
            <div
              class="imgCard"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {assetData?.ipfsAudioUrl && (
                <ReactPlayer
                  url={decryptFunction(assetData?.ipfsAudioUrl)}
                  width="120px"
                  height="120px"
                  controls={true}
                  style={{
                    backgroundImage: "url(" + assetData?.audioThumbnail + ")",
                    backgroundSize: "cover",
                  }}
                  onReady={true}
                  light={assetData?.audioThumbnail ? assetData?.audioThumbnail : true}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                      },
                    },
                  }}
                // playIcon="true"
                />
              )}
              {assetData?.ipfsLongVideoUrl && !assetData?.ipfsAudioUrl && (
                <>

                  <ReactPlayer
                    url={decryptFunction(assetData?.ipfsLongVideoUrl)}
                    width="120px"
                    height="120px"
                    controls={true}
                    onReady={true}
                    config={{
                      file: {
                        attributes: {
                          controlsList: "nodownload",
                        },
                      },
                    }}
                  // playIcon="true"
                  />
                </>

              )}
              {/* <img src={videoThumbnail && videoThumbnail} alt="Image" /> */}
              {assetData?.ipfsImageUrl &&
                !assetData?.ipfsLongVideoUrl &&
                assetData?.ipfsImageUrl.split(".").pop() != "fbx" && (
                  <img
                    src={assetData?.ipfsImageUrl ? `${decryptFunction(assetData?.ipfsImageUrl)}` : null}
                    alt="Image"
                  />
                )}
              {assetData?.ipfsImageUrl &&
                !assetData?.ipfsLongVideoUrl &&
                assetData?.ipfsImageUrl.split(".").pop() == "fbx" && (
                  <img src={assetData["3DURL"] ? decryptFunction(assetData["3DURL"]) : null} alt="Image" />
                )}
              {/* {assetData?.ipfsAudioUrl && <img src={AudioImg} alt="Image" />} */}
            </div>

          </div>
          <br />
          <div className={styles.dropmsg}>
            Select the Edition Of the Asset to AirDrop

          </div>
          <br />
          <div className={styles.editionLabel}>
            <label>Edition:<sup> * </sup></label>
            <input type="text" name="edition" placeholder="Enter the edition" className={styles.editionInput} onChange={(e) => { setEdition(e.target.value) }} onKeyDown={(e) => { formatInput(e) }} />

          </div>
          <div>
            {greaterMsg && <p style={{ color: "red" }}>Please enter edition less than total edition({assetData?.totalEdition})</p>}
          </div>


          <div className={styles.dropconfirm}>
            <button onClick={() => { setPopupOpen(false) }} className={styles.no}>Cancel</button>
            <button
              // onClick={() => {
              //   this.handleClickDelete();
              //   onClose();
              // }}
              disabled={!confirm}
              onClick={() => { confirmDrop() }}
              className={confirm ? styles.yes : styles.disabled}

            >
              Confirm
            </button>

          </div>

          {/* <h3>Confirm Drop</h3>
              <p>You want to delete this file?</p>
              <button onClick={onClose}>No</button>
              <button
                onClick={() => {
                  this.handleClickDelete();
                  onClose();
                }}
              >
                Yes, Delete it!
              </button> */}
        </div>


      </div>


    </>

  );
}

export default ConfirmPopup;