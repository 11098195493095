import { ACTION_TOGGLE } from "../redux/actionTypes";

const initial_state = {
  created: true,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case ACTION_TOGGLE:
      return { ...state, created: !state.created };

    default:
      return { ...state };
  }
};
