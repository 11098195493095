/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./AirDropTable.module.css";
// import Box from "@material-ui/core/Box";
// import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import { confirmAlert } from "react-confirm-alert";
import SearchIcon from "@material-ui/icons/Search";
// import TextField from "@material-ui/core/TextField";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import Loader from "../../common/Loader/Loader";
import editIcon from "../../../assets/images/icons/edit.svg";
import deleteIcon from "../../../assets/images/icons/delete.svg";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import { axiosInstance } from "../../../utility/api";
// import SearchIcon from "@material-ui/icons/Search";
// import FilterListIcon from "@material-ui/icons/FilterList";
// import ExpandedRow from "./Table/Components/tableExpandedRow-MaterialUI";
import { InputAdornment, Switch } from "@material-ui/core";
import NoRecordsFound from "../../common/Error/NoRecordsFound";
import UiDrawer from "../../categories/components/UiDrawer";
import decryptFunction from "../../common/decryptURL";
import ConfirmPopup from "./ConfirmPopup";
import { formatAPIError } from "../../../utility/helper";
const useRowStyles = makeStyles({
  root: {
    marginTop: "0px",
    border: "none",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5FC",
  },
  rowDatas: {
    "& td,th": {
      border: "none",
      height: "90px",
    },
  },
  border__right: {
    borderRight: "2px solid white",
    height: "70%",
  },
  cursorDefault: {
    cursor: "default",
  },
});

//Each row of table
function Row(props) {
  const {
    row,
    data,
    tableColumnWidth,
    setShowLoader,
    showLoader,
    setOpenEdit,
    selectedRow,
    setSelectedRow,
    rowIndex,
    setCategoryList,
    categoryList,
    setAssetData,
    setPopupOpen,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [deleteCategoryID, setDeleteCategoryID] = useState(null);
  let config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };
  const classes = useRowStyles();
  const chosenAssetValue = (e, data) => {
    // console.log(data)
    setAssetData(data);
    setPopupOpen(true);
  };
  const deleteCateogry = (id) => {
    if (id) {
      confirmAlert({
        title: "Delete Drop?",
        message: "Are you sure to delete.",
        buttons: [
          {
            label: "Yes",
            onClick: () => handleDelete(id),
          },
          {
            label: "No",
            onClick: () => console.log("Clicked No"),
          },
        ],
      });
    }
  };

  const handleDelete = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };
    axiosInstance
      .delete(`/v1/admin/drops/${id}`, config)
      .then((res) => {
        Swal.fire("Success!", res?.data?.message, "success");
      })
      .then(() => {
        window.location.reload(true);
      })
      .catch((err) => {
        let errMsg = formatAPIError(err);
        Swal.fire("Alert!", errMsg, "error");
      });
  };
  const dropStatusFn = (statusId) => {
    if (statusId == 0) {
      return <span className="upcoming-drop">Upcoming</span>;
    } else if (statusId == 1) {
      return <span className="current-drop">Current</span>;
    } else {
      return <span className="completed-drop">Completed</span>;
    }
  };

  function handleBannerImageStatusChange(e, id) {
    axiosInstance
      .patch(`/v1/admin/drops/${id}`, null, config)
      .then((res) => {
        let tData = [...categoryList];
        tData[rowIndex].isCarousel = !tData[rowIndex].isCarousel;
        setCategoryList([...tData]);
      })
      .catch((err) => {
        let errMsg = formatAPIError(err);
        Swal.fire("Alert!", errMsg, "error");
      });

    // } else {
    //   axiosInstance
    //   .patch(`/admin/creators/toggle/:id`, null, config).then((res)=>{
    //     let tData=[...categoryList]
    //     tData[rowIndex].status="inactive"
    //     setCategoryList([...tData])

    //   }).catch((err)=>{
    //     Swal.fire("Alert!", err?.response?.data?.message,"error");
    //   })

    // }
  }
  return (
    <React.Fragment>
      <TableRow
        onClick={() => {
          setOpen(!open);
        }}
        className={classes.rowDatas}
        style={{ borderBottom: "12px solid #F5F5FC", cursor: "default" }}
      >
        <TableCell
          style={{ width: `${tableColumnWidth[0]}%` }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {/* <div className="tableCellContainer"> */}
          <div
            class="imgCard"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            {data?.ipfsAudioUrl && (
              <ReactPlayer
                url={decryptFunction(data?.ipfsAudioUrl)}
                width="120px"
                height="120px"
                controls={true}
                style={{
                  backgroundImage: "url(" + data?.audioThumbnail + ")",
                  backgroundSize: "cover",
                }}
                onReady={true}
                light={data?.audioThumbnail ? data?.audioThumbnail : true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
                // playIcon="true"
              />
            )}
            {data?.ipfsLongVideoUrl && !data?.ipfsAudioUrl && (
              <>
                <ReactPlayer
                  url={decryptFunction(data?.ipfsLongVideoUrl)}
                  width="120px"
                  height="120px"
                  controls={true}
                  onReady={true}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                      },
                    },
                  }}
                  // playIcon="true"
                />
              </>
            )}
            {/* <img src={videoThumbnail && videoThumbnail} alt="Image" /> */}
            {data?.ipfsImageUrl &&
              !data?.ipfsLongVideoUrl &&
              data?.ipfsImageUrl.split(".").pop() != "fbx" && (
                <img
                  src={
                    data?.ipfsImageUrl
                      ? `${decryptFunction(data?.ipfsImageUrl)}`
                      : null
                  }
                  alt="Image"
                />
              )}
            {data?.ipfsImageUrl &&
              !data?.ipfsLongVideoUrl &&
              data?.ipfsImageUrl.split(".").pop() == "fbx" && (
                <img
                  src={data["3DURL"] ? decryptFunction(data["3DURL"]) : null}
                  alt="Image"
                />
              )}
            {/* {data?.ipfsAudioUrl && <img src={AudioImg} alt="Image" />} */}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
          <div className="columnStyles">{data?.name}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
          <div className="columnStyles">
            {data?.regularPrice ? data?.regularPrice : "-"}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div className="columnStyles">
            {data?.totalEdition ? data?.totalEdition : "-"}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div className="columnStyles">
            {/* {data?.availableEdition ? data?.availableEdition : "-"} */}
            {data?.availableStock ? data?.availableStock : "No stock available"}
          </div>
        </TableCell>

        <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div className="columnStyles">
            <button
              className="airdrop-btn"
              onClick={(e) => {
                chosenAssetValue(e, data);
              }}
            >
              Confirm AirDrop
            </button>
          </div>
        </TableCell>
        {/* <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <div className="columnStyles">{dropStatusFn(data?.dropStatus)}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[5]}%` }}>
          <Switch
            checked={data?.isCarousel}
            onChange={(e)=>handleBannerImageStatusChange(e,data?.id)}
            color="primary"
            name="isCarousel"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[6]}%` }}>
          <Tooltip title="Edit Drop">
            <img
              src={editIcon}
              className="cursorPointer"
              alt="Edit"
              onClick={() => {
                setSelectedRow(data);
                setOpenEdit(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete Drop">
            <img
              src={deleteIcon}
              onClick={async () => {
                await setDeleteCategoryID(data?.id);
                deleteCateogry(data?.id);
              }}
              className="marginLeft20 cursorPointer"
              alt="Delete"
            />
          </Tooltip>
        </TableCell> */}
      </TableRow>
    </React.Fragment>
  );
}

const RenderHeader = ({ columns, onSorting }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  const classes = useRowStyles();
  return (
    <TableHead className={classes.header}>
      <TableRow>
        {columns?.map(({ name, field, sortable, image }) => (
          <TableCell
            align={columns.align}
            key={name}
            className={classes.cursorDefault}
          >
            <div className="dividerFlex">
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
                {/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function AirDropTable({
  data,
  showLoader,
  setShowLoader,
  userData,
}) {
  const classes = useRowStyles();
  // const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
  const [totalCount, setTotalCount] = useState(0);
  const [categoryList, setCategoryList] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchParam, setSearchParam] = React.useState("");
  const token = JSON.parse(localStorage.getItem("token"));
  const [assetData, setAssetData] = useState();
  const [popupOpen, setPopupOpen] = useState(false);

  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const tableColumnWidth = [20, 10, 10, 10, 20, 30];
  const columns = [
    {
      name: "Asset",
      field: "asset",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Name",
      field: "name",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Price (€)",
      field: "price",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Total Edition",
      field: "count",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    // {
    //   name: "Asset Count",
    //   field: "count",
    //   // image: Divider,
    //   numeric: true,
    //   sortable: false,
    //   align: "left",
    // },
    {
      name: "Available Stock",
      field: "availableStock",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    // {
    //   name: "Banner Image (Hide/Show)",
    //   field: "count",
    //   // image: Divider,
    //   numeric: true,
    //   sortable: false,
    //   align: "left",
    // },
    {
      name: "Action",
      field: "action",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
  ];

  useEffect(() => {
    getCategoryList();
  }, [pageNo, searchParam]);

  // useEffect(()=>{

  // },[assetData])

  const handleChangePage = (event, nextPageNo) => {
    setPageNo(nextPageNo);
    window.scrollTo(0, 0);
  };
  const handleSearchParam = (e) => {
    setSearchParam(e.target.value);
    setPageNo(0);
    // axiosInstance
    // .get(`/v1/admin/drops`, header)
    // ?type=category&page=${pageNo + 1}&limit=10&search=${e.target.value}
    // .then((result) => {
    //   setCategoryList(result?.data?.result);
    //   setTotalCount(result?.data?.result);
    // })
    // .catch((err) => {
    //   if (
    //     err?.response &&
    //     err?.response?.data?.errors &&
    //     err?.response?.data?.errors?.length !== 0
    //   ) {
    //     Swal.fire({
    //       title: "Oops!",
    //       // text: err?.response?.data?.errors?.map((err) => err.msg),
    //       icon: "error",
    //     });
    //   } else {
    //     Swal.fire("Oops!", err?.response?.data?.message, "error");
    //   }
    // });
  };

  const getCategoryList = async () => {
    const result = await axiosInstance
      .get(
        `/v1/admin/airdropAsset?page=${
          pageNo + 1
        }&limit=10&search=${searchParam}`,
        header
      )
      // ?type=category&page=${pageNo + 1}&limit=10&
      .then((result) => {
        console.log(result?.data?.result?.data?.rows);
        setCategoryList(result?.data?.result?.data?.rows);
        setTotalCount(result?.data?.result?.data?.count);
      })
      .catch((err) => {
        if (
          err?.response &&
          err?.response?.data?.errors &&
          err?.response?.data?.errors?.length !== 0
        ) {
          Swal.fire({
            title: "Oops!",
            // text: err?.response?.data?.errors?.map((err) => err.msg),
            icon: "error",
          });
        } else {
          Swal.fire("Oops!", err?.response?.data?.message, "error");
        }
      });
  };

  return (
    <>
      <div className="table_div tableWidth100" style={{ position: "relative" }}>
        {popupOpen && (
          <ConfirmPopup
            assetData={assetData}
            userData={userData}
            setPopupOpen={setPopupOpen}
          ></ConfirmPopup>
        )}
        {showLoader == true ? <Loader /> : null}
        <>
          <TextField
            value={searchParam}
            onChange={handleSearchParam}
            // onKeyDown={(e) => handleSearchBoxKeyDown(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            label="Search Asset"
            variant="outlined"
            placeholder="Search"
            className="cat-search-box-align"
            id="sports-search"
          />

          {/* <TextField
             
               className="cat-search-box-align"
               id="sports-search"
               label="Search"
               variant="outlined"
               value={searchParam}
               onChange={handleSearchParam}
             /> */}
          {categoryList?.length !== 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table" className={classes.root}>
                  <RenderHeader
                    columns={columns}
                    // onSorting={(field, order) => setSorting({ field, order })}
                  />
                  <TableBody style={{ width: "100%" }}>
                    {categoryList?.map((row, index) => (
                      <Row
                        aria-label="expand row"
                        size="small"
                        key={row?.id}
                        row={row}
                        setSelectedRow={(value) => setSelectedRow(value)}
                        selectedRow={selectedRow}
                        data={categoryList[index]}
                        tableColumnWidth={tableColumnWidth}
                        setOpenEdit={setOpenEdit}
                        rowIndex={index}
                        setCategoryList={setCategoryList}
                        categoryList={categoryList}
                        setAssetData={setAssetData}
                        setPopupOpen={setPopupOpen}
                        // setShowLoader={setShowLoader}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[]}
                // backIconButtonProps={{ disabled: false }}
                component="div"
                count={totalCount}
                rowsPerPage={10}
                page={pageNo}
                onChangePage={handleChangePage}
                // onChange={(e) => handleChangePage(e, page)}
                // onChangeRowsPerPage={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => (
                  <>
                    <span className="paginationLabel">Showing </span>
                    {`${from}-${to}`}
                    <span className="paginationLabel"> out of </span>
                    {`${count}`}
                  </>
                )}
              />
              {/* <TablePagination
               rowsPerPageOptions={[]}
               // backIconButtonProps={{ disabled: false }}
               component="div"
               count={totalCount}
               rowsPerPage={10}
               page={pageNo}
               onChangePage={handleChangePage}
               // onChange={(e) => handleChangePage(e, page)}
               // onChangeRowsPerPage={handleChangeRowsPerPage}
               labelDisplayedRows={({ from, to, count }) => (
                 <>
                   <span className="paginationLabel">Showing </span>
                   {`${from}-${to}`}
                   <span className="paginationLabel"> out of </span>
                   {`${count}`}
                 </>
               )}
             /> */}
            </>
          ) : (
            <div className="noResultFound flexColumnCenter">
              <NoRecordsFound message="No records found." />
            </div>
          )}
        </>
      </div>
    </>
  );
}
