import React, { Fragment, useState, useEffect } from "react";
import './analytics.css';
import Breadcrumb from '../../layout/breadcrumb';
import Chart from "react-apexcharts";
import PopoverPopupState from "../Orders/components/dateRanagePopOver";
import CategoryTable from '../Orders/components/OrderTable';
import { axiosInstance } from '../../utility/api';


const AnalyticsDetail = (props) => {

    // const history = useHistory();
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();
    const remainingDays = today.getDay();
    const date1MonthBefore = new Date(year, month - 1, day);
    const weekBefore = new Date(year, month, day-7);
    const weekToDate = new Date(year, month, day-remainingDays);
    const monthToDate = new Date(year, month, day-(day-2));
    

    const token = JSON.parse(localStorage.getItem("token"));
    const header = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const [selectionRange, setSelectionRange] = useState('lastWeek');
    const [dates, setDates] = useState({ fromDate: weekBefore, toDate: today, period: "day" });
    
   
    const [options, setOptions] = React.useState(
        {
            chart: {
                id: "basic-bar",
            },
            markers: {
                size: 5,
            },
            stroke: {
                curve: 'straight',
            },
            xaxis: {
                categories: []
            }
        },
    )

    const [series, setSeries] = useState(
        [
            {
                name: "series",
                data: []
            }
        ]
    )

    const getGraphData = async () => {
        const result = await axiosInstance
            .get(`/v1/admin/dashboard/analytics/asset`, { ...header, params: { ...dates } })
        const mapdata = result.data?.result?.orders;
        const time = mapdata.map(item => item.period + " " + item.month).reverse();
        const data = mapdata.map(item=>item.totalSale.toFixed(5)).reverse();
        setOptions({...options, xaxis:{categories:time}});
        setSeries([{name:"series", data}]);
    }

    React.useEffect(() => {
        getGraphData();
    }, [dates])

    const handleRange = (e) => {
        const value = e.target.value;
        
        if (value === "lastWeek") {
            setDates({...dates, fromDate:weekBefore});
        }
        if (value === "lastMonth") {
            setDates({...dates, fromDate:date1MonthBefore});
        }
        if (value === "weekly") {
            setDates({...dates, fromDate:weekToDate});
        }
        if (value === "monthly") {
            setDates({...dates, fromDate:monthToDate});
        }
    }

    return (
        <div style={{ width: "100%" }} className="chart-container">
            <div style={{ width: "100%", padding: "10px 0px", borderBottom: "1px solid #aaa" }}>
                <h4 style={{ textAlign: "left" }}>Total Sales</h4>
            </div>

            {/* <div style={{ width: "95%", margin:"auto", marginTop:30 }} className="table-container">
                <CategoryTable />
            </div> */}

            <div style={{ width: "100%", margin: "auto", marginTop: 30, fontSize: 22 }} >
                <span style={{ fontSize: 23, marginRight: 20 }}> Select Range:</span>
                <select onChange={handleRange}>
                    <option value="lastWeek"> Last Week </option>
                    <option value="lastMonth"> Last Month </option>
                    <option value="monthly"> Month to Date </option>
                    <option value="weekly"> Week to Date </option>
                </select>
            </div>

            <div style={{ width: "100%", marginTop: 35 }} className="single-chart">
                <Chart
                    options={options}
                    series={series}
                    type="line"
                    width="1000"
                />
            </div>
        </div>
    )
}

export default AnalyticsDetail;