const formatDateTime = (dateinput) => {
  let date;
  if (dateinput) {
    date = new Date(dateinput);
  } else {
    return null;
  }
  const utcDate =
    date.getUTCDate() > 9 ? date.getUTCDate() : "0" + date.getUTCDate();
  const utcMonth =
    date.getUTCMonth() > 8
      ? date.getUTCMonth() + 1
      : "0" + (date.getUTCMonth() + 1);
  const utcFullYear = date.getUTCFullYear();
  // > 9 ? date.getUTCFullYear() : "0"+date.getUTCFullYear();
  const utcHours =
    date.getUTCHours() > 9 ? date.getUTCHours() : "0" + date.getUTCHours();
  const utcMins =
    date.getUTCMinutes() > 9
      ? date.getUTCMinutes()
      : "0" + date.getUTCMinutes();
  const utcSecs =
    date.getUTCSeconds() > 9
      ? date.getUTCSeconds()
      : "0" + date.getUTCSeconds();
  let formatted_date =
    utcDate +
    "/" +
    utcMonth +
    "/" +
    utcFullYear +
    " " +
    utcHours +
    ":" +
    utcMins +
    ":" +
    utcSecs +
    " UTC";
  return formatted_date;
};

export { formatDateTime };
