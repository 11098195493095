// dashbaord
import Default from "../components/dashboard/default";
import Ecommerce from "../components/dashboard/ecommerce";
import Products from "../components/products/products";
import Orders from "../components/Orders/Orders";
import Commission from "../components/commission";
import TransactionHistory from "../components/TransactionHistory";
import Users from "../components/users";
import Auction from "../components/Auction/index";
import News from "../components/News/index";
import ArtistPage from "../components/Artists";
import CollectionPage from "../components/Collection/index";
import InstitutionsPage from "../components/Institutions";
import InstitutionsDetailsPage from "../components/Institutions/details";
import AirDropsPage from "../components/AirDrops";
// starter kits
// import Starterkits from "../components/starter-kits";
import Analytics from "../components/analytics/Analytics";
import AnalyticsDetail from "../components/analytics/AnalyticsDetail";
import UserAnalyticsDetail from "../components/analytics/UserAnalytics";
import VisitorAnalyticsDetail from "../components/analytics/VisitorAnalytics";
import TotalAssets from "../components/analytics/TotalAssets";
import TwofactorAuth from "../components/Login/TwoFactorScreen";
import Test from "../components/test";
import AboutUs from "../components/About-Us/AboutUs";
import TermsCondition from "../components/Terms-condition/TermsCondition";
import PrivacyPolicy from "../components/Privacy-Policy/PrivacyPolicy";
import LandingPage from "../components/Landing-Page/LandingPage";
import AccountSetting from "../components/AccountSetting/AccountSetting";
import CreatorModule from "../components/CreatorModule/CreatorModule";
import UserQueryComponent from "../components/UserQueries";
import ManageSubAdmin from "../../src/components/SubAdmin/index";
import Welcome from "../components/common/Welcome";
import ReleasesPage from "../components/Releases";
import ReleasesDetailsPage from "../components/Releases/details";

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/dashboard/default/:layout`,
    Component: Default,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/ecommerce/:layout`,
    Component: Ecommerce,
  },
  {
    path: `${process.env.PUBLIC_URL}/login/step2/:layout`,
    Component: TwofactorAuth,
  },
  {
    path: `${process.env.PUBLIC_URL}/products/products/:layout`,
    Component: Products,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/news/:layout`,
    Component: News,
  },
  {
    path: `${process.env.PUBLIC_URL}/orders/orders/:layout`,
    Component: Orders,
  },
  {
    path: `${process.env.PUBLIC_URL}/commission/commission/:layout`,
    Component: Commission,
  },
  {
    path: `${process.env.PUBLIC_URL}/orders/transactionhistory/:layout`,
    Component: TransactionHistory,
  },
  {
    path: `${process.env.PUBLIC_URL}/air-drops/:layout`,
    Component: AirDropsPage,
  },
  {
    path: `${process.env.PUBLIC_URL}/test/test/:layout`,
    Component: Test,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/users/:layout`,
    Component: Users,
  },
  {
    path: `${process.env.PUBLIC_URL}/auction/auction/:layout`,
    Component: Auction,
  },
  {
    path: `${process.env.PUBLIC_URL}/analytics/dashboard/:layout`,
    Component: Analytics,
  },
  {
    path: `${process.env.PUBLIC_URL}/analytics/dashboard/totalorders/:layout`,
    Component: AnalyticsDetail,
  },
  {
    path: `${process.env.PUBLIC_URL}/analytics/dashboard/totalUsers/:layout`,
    Component: UserAnalyticsDetail,
  },
  {
    path: `${process.env.PUBLIC_URL}/analytics/dashboard/totalSiteVisitors/:layout`,
    Component: VisitorAnalyticsDetail,
  },
  {
    path: `${process.env.PUBLIC_URL}/analytics/dashboard/totalAssets/:layout`,
    Component: TotalAssets,
  },
  {
    path: `${process.env.PUBLIC_URL}/dynamic-page/about-us/:layout`,
    Component: AboutUs,
  },
  {
    path: `${process.env.PUBLIC_URL}/dynamic-page/terms-condition/:layout`,
    Component: TermsCondition,
  },
  {
    path: `${process.env.PUBLIC_URL}/dynamic-page/privacy-policy/:layout`,
    Component: PrivacyPolicy,
  },
  {
    path: `${process.env.PUBLIC_URL}/dynamic-page/landing-page/:layout`,
    Component: LandingPage,
  },
  {
    path: `${process.env.PUBLIC_URL}/creator/:layout`,
    Component: CreatorModule,
  },
  {
    path: `${process.env.PUBLIC_URL}/user-queries/:layout`,
    Component: UserQueryComponent,
  },
  {
    path: `${process.env.PUBLIC_URL}/account-settings`,
    Component: AccountSetting,
  },
  {
    path: `${process.env.PUBLIC_URL}/manage-subadmin/subadmin/:layout`,
    Component: ManageSubAdmin,
  },
  {
    path: `${process.env.PUBLIC_URL}/welcome/:layout`,
    Component: Welcome,
  },
  {
    path: `${process.env.PUBLIC_URL}/artists/:layout`,
    Component: ArtistPage,
  },
  {
    path: `${process.env.PUBLIC_URL}/collection/:layout`,
    Component: CollectionPage,
  },
  {
    path: `${process.env.PUBLIC_URL}/institutions/:layout`,
    Component: InstitutionsPage,
  },
  {
    path: `${process.env.PUBLIC_URL}/institutions-details/:id`,
    Component: InstitutionsDetailsPage,
  },
  {
    path: `${process.env.PUBLIC_URL}/releases/:layout`,
    Component: ReleasesPage,
  },
  {
    path: `${process.env.PUBLIC_URL}/releases-details/:id`,
    Component: ReleasesDetailsPage,
  },
];
