import React, { useEffect, useState } from "react";
import ProductTable from "../common/tableExpandable.jsx";
import Swal from "sweetalert2";

import { getProductList as getProduct } from "../../APIActions/Product";

import UiDrawer from "./../Sidedrawer/Uidrawer";

import API from "../../APIActions/api";
import { formatAPIError } from "../../utility/helper.js";

export default function Products() {
  const [productList, setProductList] = useState([]);
  const [pageNo, setpageNo] = useState(0);
  const [selectedTab, setSelectedTab] = useState("assets");
  const [totalDoc, setTotalDoc] = useState(0);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filterSelected, setFilterSelected] = useState("");
  const [networkType, setNetworkType] = useState("0");
  const [showLoader, setShowLoader] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const [collections, setCollections] = useState([]);
  const [artists, setArtists] = useState([]);

  const columns = [
    {
      name: "Asset image",
      field: "image",
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Asset name",
      field: "name",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Price(€)",
      field: "price",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Available Stock",
      field: "stock",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    // {
    //   name: "Remaining Balance in Edition",
    //   field: "stock",
    //   // image: Divider,
    //   numeric: false,
    //   sortable: true,
    //   align: "left",
    // },
    {
      name: "Total Edition",
      field: "stock",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Network Type",
      field: "networkType",
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "Sales Type",
      field: "rate",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    // {
    //   name: "Action",
    //   field: "activate",
    //   // image: Divider,
    //   numeric: true,
    //   sortable: true,
    //   align: "left",
    // },
    // {
    //   name: "Category",
    //   field: "category",
    //   // image: Divider,
    //   numeric: false,
    //   sortable: true,
    //   align: "right",
    // },
    {
      name: "Status (Inactive/Active)",
      field: "status",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "right",
    },
  ];

  const soldColumns = [
    {
      name: "Asset",
      field: "image",
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Name",
      field: "name",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Price(€)",
      field: "price",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Available Stock",
      field: "stock",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    // {
    //   name: "Remaining Balance in Edition",
    //   field: "stock",
    //   // image: Divider,
    //   numeric: false,
    //   sortable: true,
    //   align: "left",
    // },
    {
      name: "Total Edition",
      field: "stock",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Network Type",
      field: "networkType",
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "Sales Type",
      field: "rate",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    // {
    //   name: "Action",
    //   field: "activate",
    //   // image: Divider,
    //   numeric: true,
    //   sortable: true,
    //   align: "left",
    // },
    // {
    //   name: "Category",
    //   field: "category",
    //   // image: Divider,
    //   numeric: false,
    //   sortable: true,
    //   align: "right",
    // },
  ];

  const resaleColumns = [
    {
      name: "Asset",
      field: "image",
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Name",
      field: "name",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Seller Name",
      field: "name",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Price(€)",
      field: "price",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Available Stock",
      field: "stock",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    // {
    //   name: "Remaining Balance in Edition",
    //   field: "stock",
    //   // image: Divider,
    //   numeric: false,
    //   sortable: true,
    //   align: "left",
    // },
    {
      name: "Total Edition",
      field: "stock",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Sales Type",
      field: "rate",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    // {
    //   name: "Action",
    //   field: "activate",
    //   // image: Divider,
    //   numeric: true,
    //   sortable: true,
    //   align: "left",
    // },
    // {
    //   name: "Category",
    //   field: "category",
    //   // image: Divider,
    //   numeric: false,
    //   sortable: true,
    //   align: "right",
    // },
  ];

  const tableColumnWidth = [15, 15, 15, 10, 15, 10, 10, 10];
  const [tableTotalCount, setTableTotalCount] = useState(10);
  const token = JSON.parse(localStorage.getItem("token"));
  // const header = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };
  // const token =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pblVzZXIiOnsiaWQiOjEsImVtYWlsIjoiYWRtaW5AZXhhbXBsZS5jb20iLCJyb2xlIjoiYWRtaW4ifSwiaWF0IjoxNjMxMTA3MTk0LCJleHAiOjE2NjI2NjQ3OTR9.nvl8lpBQEipDADIZBwbiWfj1oB1sB9ddzsuLmaTYjAk";

  useEffect(() => {
    getProductList();
    setShowLoader(false);
    if (!institutions?.length) {
      getInstitutionList();
    }
    if (!collections?.length) {
      getCollectionListData();
    }
    if (!artists?.length) {
      getArtistList();
    }
  }, [pageNo, searchText, filterSelected, selectedTab, networkType]);

  // To fetch product list
  const getProductList = async () => {
    setShowLoader(true);
    if (selectedTab != "resaleassets") {
      let queryParam = {
        search: searchText,
        page: pageNo + 1,
        limit: 10,
        saleType: filterSelected == 0 ? "" : filterSelected,
        isSold: selectedTab == "assets" ? false : true,
        networkType: networkType == 0 ? "" : networkType,
      };
      API.getAssets(queryParam)
        .then((result) => {
          setProductList(result?.data?.result?.assets);
          setTotalDoc(result?.data?.result?.totalItems);
          setShowLoader(false);
        })
        .catch((err) => {
          setShowLoader(false);
          let errMsg = formatAPIError(err);
          Swal.fire("Alert!", errMsg, "error").then(() => {
            if (
              err?.response?.status === 401 &&
              err?.response?.data?.message?.includes("authenticated")
            ) {
              console.log("err.response", err.response);
              window.location.href = "/welcome/:layout";
            }
          });
        });
    } else {
      let queryParams = {
        search: searchText,
        page: pageNo + 1,
        limit: 10,
        saleType: filterSelected == 0 ? "" : filterSelected,
        type: "resale",
      };
      API.getAssets(queryParams)
        .then((result) => {
          setProductList(result?.data?.result?.assets);
          setTotalDoc(result?.data?.result?.totalItems);
          setShowLoader(false);
        })
        .catch((err) => {
          setShowLoader(false);
          let errMsg = formatAPIError(err);
          Swal.fire("Alert!", errMsg, "error");
        });
    }
    // const result = await axiosInstance.get(
    //   `${baseURL}v1/admin/asset?search=${searchText}&page=${
    //     pageNo + 1
    //   }&limit=10&category=${filterSelected == 0 ? "" : filterSelected}`,
    //   header
    // );
  };

  const getInstitutionList = () => {
    API.getInstitutions("")
      .then((res) => {
        if (res?.data?.result?.institutions?.length) {
          let data = [];
          res.data.result.institutions.forEach((el) => {
            let obj = { ...el, label: el?.name };
            data.push(obj);
          });
          setInstitutions(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCollectionListData = () => {
    API.getCollectionList("")
      .then((res) => {
        if (res?.data?.result?.collections?.length) {
          let data = [];
          res.data.result.collections.forEach((el) => {
            let obj = { ...el, label: el?.name };
            data.push(obj);
          });
          setCollections(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getArtistList = () => {
    API.getArtists("")
      .then((res) => {
        if (res?.data?.result?.data?.length) {
          let data = [];
          res.data.result.data.forEach((el) => {
            let obj = { ...el, label: el?.artistName };
            data.push(obj);
          });
          setArtists(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div class="products-wrapper">
        <div class="container-fluid">
          <div class="page-title">
            <div class="row">
              <div class="col-6">
                <h3>Assets</h3>
              </div>

              {/* <div class="col-6">
                <nav class="" aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="/products/products/:layout">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>
                      </a>
                    </li>
                    <li class="active breadcrumb-item" aria-current="page">
                      Asset
                    </li>
                  </ol>
                </nav>
              </div> */}
              <div class="col-12 mt-4" style={{ justifySelf: "flexgit -end" }}>
                {selectedTab !== "resaleassets" && (
                  <button
                    className={"btn btn-primary"}
                    style={{ textTransform: "uppercase" }}
                    onClick={() => setOpen(true)}
                  >
                    + Add Asset
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="products-content">
          <ProductTable
            data={productList}
            columns={columns}
            soldColumns={soldColumns}
            resaleColumns={resaleColumns}
            tableColumnWidth={tableColumnWidth}
            totalDoc={totalDoc}
            pageNo={pageNo}
            setPageNo={(pageNo) => setpageNo(pageNo)}
            showLoader={showLoader}
            setShowLoader={(e) => setShowLoader(e)}
            searchText={searchText}
            setSearchText={setSearchText}
            filterSelected={filterSelected}
            setFilterSelected={setFilterSelected}
            networkType={networkType}
            setNetworkType={setNetworkType}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            institutions={institutions}
            collections={collections}
            artists={artists}
          />
        </div>
      </div>

      {open && (
        <UiDrawer
          open={open}
          institutions={institutions}
          collections={collections}
          artists={artists}
          onClose={() => setOpen(false)}
          showLoader={showLoader}
          setShowLoader={(e) => setShowLoader(e)}
          isEdit={false}
          maxWidth="xs"
        />
      )}
    </>
  );
}
