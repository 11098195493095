/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from "../../components/common/Loader/Loader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SearchIcon from "@material-ui/icons/Search";
import Swal from "sweetalert2";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from 'moment'
import "./userTable.css";
import { axiosInstance } from "../../utility/api";
import NoRecordsFound from "../common/Error/NoRecordsFound";
import DataRangePopOver from "../common/DateRange/DateRangePopOver";
import Edit from "../../assets/images/icons/edit.svg";
import UserActivate from "../../assets/images/UserActivate.svg";
import UserBlock from "../../assets/images/UserBlock.svg";
import optionsDots from "../../assets/images/optionsDots.svg";
import UiDrawer from "./UiDrawerEditUser";
import AirDropDrawer from "./AirDropDrawer";

import ProfilePlaceholder from '../../assets/images/profile-placeholder.png'
import { formatAPIError } from "../../utility/helper";

const useRowStyles = makeStyles({
  root: {
    marginTop: "0px",
    border: "none",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5FC",
  },
  rowDatas: {
    "& td,th": {
      border: "none",
      height: "90px",
    },
  },
  border__right: {
    borderRight: "2px solid white",
    height: "70%",
  },
  cursorDefault: {
    cursor: "default",
  },
  height24: {
    height: "24px",
  },
});

//Each row of table
function Row(props) {
  const {
    data,
    tableColumnWidth,
    selectedRowIndex,
    setselectedRowIndex,
    index,
    setOpenEdit,
    // selectedRow,
    setSelectedRow,
    setSelectedUserData,
    setAirDropOpen,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [sumsubFailReason, setSumsubFailReason] = useState(null)

  useEffect(() => {
    try{
      let sumdata = JSON.parse(data?.sumsubNotes)
      setSumsubFailReason(sumdata)
    }
    catch{
      return;
    }
  },[])

console.log('sumsubFailReason :>> ', sumsubFailReason);

  const currentUserSelectHandle = (e,userdata)=>{
    setSelectedUserData(userdata)
    setAirDropOpen(true)
  }

  // const [openPreview, setOpenPreview] = useState({ open: false, id: data?.id });
  // const token = JSON.parse(localStorage.getItem("token"));
  const classes = useRowStyles();

  const handleChangeForDots = (index) => {
    setselectedRowIndex(index);
  };

  const blockUser = (id) => {
    console.log("deleted 2", id);
    if (id) {
      confirmAlert({
        title: "Block User?",
        message: "Are you sure to block.",
        buttons: [
          {
            label: "Yes",
            onClick: () => handleBlock(id),
          },
          {
            label: "No",
            onClick: () => console.log("Clicked No"),
          },
        ],
      });
    }
  };
  const activateUser = async (id) => {
    console.log("Activate", id);
    if (id) {
      await handleActivate(id);
      // confirmAlert({
      //   title: "Block User?",
      //   message: "Are you sure to block.",
      //   buttons: [
      //     {
      //       label: "Yes",
      //       onClick: () => handleBlock(id),
      //     },
      //     {
      //       label: "No",
      //       onClick: () => console.log("Clicked No"),
      //     },
      //   ],
      // });
    }
  };

  const handleBlock = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };

    const payload = {
      isBlock: true,
    };
    axiosInstance
      .put(`/v1/admin/user/block/${id}`, payload, config)
      .then((res) => {
        Swal.fire("Success!", res?.data?.message, "success");
      })
      .then(() => {
        window.location.reload(true);
      })
      .catch((err) => {
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error");
      });
  };

  const handleActivate = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };

    const payload = {
      isBlock: false,
    };

    axiosInstance
      .put(`/v1/admin/user/block/${id}`, payload, config)
      .then((res) => {
     
        Swal.fire("Success!", res?.data?.message, "success");
      })
      .then(() => {
        window.location.reload(true);
      })
      .catch((err) => {
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error");
      });
  };

  const HandleOutSideClickClose = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setselectedRowIndex("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const BlockDisableContainerRef = React.useRef(null);
  HandleOutSideClickClose(BlockDisableContainerRef);



  return (
    <React.Fragment>
      <TableRow
        onClick={() => {
          setOpen(!open);
        }}
        className={classes.rowDatas}
        style={{ borderBottom: "12px solid #F5F5FC", cursor: "default" }}
      >
        <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
          <div className="columnStyles user-profile-img">
              <img
              src={data?.profilePicUrl ? data?.profilePicUrl : ProfilePlaceholder}
              className="pharmaIconAvatar"
              height="50px"
              // alt="avatar"
            />
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
          <div className="columnStyles">{data?.userName || 'Incomplete'}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div className="columnStyles">{data?.email || 'Incomplete'}</div>
        </TableCell>
              {/* button for airdrop */}
              <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
                {/* <button className="airdropbutton" onClick={(e)=>{currentUserSelectHandle(e,data)}} >AirDrop</button> */}
                {data?.isBlock===false?  <button className="airdrop-btn" onClick={(e)=>{currentUserSelectHandle(e,data)}} >AirDrop</button> : ""}
              </TableCell>
        {/* <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <OrderDetailsModal orderId={data?.id} />
        </TableCell> */}
        {/* <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div className="columnStyles">{data?.role && data?.role}</div>
        </TableCell> */}
        <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div
            class={`columnStyles ${
              data?.isBlock === true ? "userBlockStyle" : "userActiveStyle"
            }`}
          >
            {data?.isBlock === true ? "Blocked" : "Active"}
          </div>
        </TableCell>
        {/* <TableCell style={{ width: `${tableColumnWidth[5]}%` }}>
          <div className="columnStyles">{data?.asset && data?.asset?.name}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[6]}%` }}>
          <div className="columnStyles">{data?.asset && data?.asset?.name}</div>
        </TableCell> */}
          <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <div className="columnStyles whitespace-nowrap">
            {moment(data?.updatedAt).format('MMMM Do YYYY, h:mm a')}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[5]}%` }}>
          <div className="columnStyles">
            {data?.publicAddress && data?.publicAddress}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[6]}%` }}>
          <div className="columnStyles">
            {data?.sumsubStatus ? data?.sumsubStatus : "-"}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[7]}%` }}>
        {
            data?.applicantId ? 
            <div className="columnStyles" style={{width:"70px"}}>
              <a href={process.env.REACT_APP_SUMSUB_STATUS_URL+data?.applicantId} target="_blank" rel="noopener noreferrer">Go to link</a>
            </div> : <div>-</div>
          }
          
          {/* {
            data?.applicantId ? 
            <div className="columnStyles" style={{cursor:"pointer"}} onClick={() => window.open(process.env.REACT_APP_SUMSUB_STATUS_URL+data?.applicantId, '_blank').focus()}>
              {process.env.REACT_APP_SUMSUB_STATUS_URL+data?.applicantId}
            </div> : <div>-</div>
          } */}
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[8]}%` }}>
          <div className="columnStyles">
            {
              sumsubFailReason ? 
              <button className="airdrop-btn" style={{backgroundColor: "#D8000C"}} onClick={(e)=> Swal.fire({
                title:"Failure Reason",
                // text: sumsubFailReason.moderationComment,
                html: `<p style="text-align:left; font-size:14px;">1. ${sumsubFailReason.moderationComment}</p><p style="text-align:left; font-size:14px;">2. ${sumsubFailReason.clientComment}</p>`
                
              })} >Reason</button>
              :
              "-"
            }

            {/* {sumsubFailReason?.moderationComment || data?.sumsubNotes?.clientComment || "-"} */}
          </div>
        </TableCell>

        <TableCell
          align="left"
          style={{ width: `${tableColumnWidth[9]}%` }}
          onClick={(event) => event.preventDefault()}
        >
          <div
            className={"voiletTickImage alignItemStart hcpLastColumn"}
            style={{ position: "relative" }}
          >
            {selectedRowIndex === index && (
              <div
                className="userTableActions threeDotContainer threeDotContainerAlignment"
                ref={BlockDisableContainerRef}
              >
                {/* <div
                  className="editTableRow userTableActionBtn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedRow(data);
                    setOpenEdit(true);
                  }}
                >
                  <img src={Edit} alt="edit" className="paddingRight4px" />
                  Edit
                </div> */}
                <div
                  className="blockTableRow userTableActionBtn"
                  onClick={(e) => {
                    e.stopPropagation();
                    data?.isBlock === true
                      ? activateUser(data?.id)
                      : blockUser(data?.id);
                  }}
                  
                >
                  <img
                    src={data?.isBlock === true ? UserActivate : UserBlock}
                    style={{marginRight:"7px"}}
                    alt="disable"
      
                    className="paddingRight4px"
                    
                  />
                 {data?.isBlock === true ? "Activate" : "Block"}
                
             
                  
                </div>
              </div>
            )}
          </div>
          <img
            className="optionsDotsCss cursorPointer"
            src={optionsDots}
            onClick={(e) => {
              e.stopPropagation();
              handleChangeForDots(index);
            }}
            alt="dots"
          />
        </TableCell>
      </TableRow>
      {/* {openPreview?.open ? (
        <OrderDetailsModal orderId={openPreview?.id} />
      ) : null} */}
    </React.Fragment>
  );
}

const RenderHeader = ({ columns, tableColumnWidth, onSorting }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  const classes = useRowStyles();
  return (
    <TableHead className={classes.header}>
      <TableRow>
        {columns?.map(({ name, field, sortable, image },i) => (
          <TableCell
            align={columns.align}
            key={name}
            className={classes.cursorDefault}
            style={{ 'width' : `${tableColumnWidth[i]}%` }}
          >
            <div className="dividerFlex">
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
                {/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function UserTable({ data, showLoader, setShowLoader }) {
  const classes = useRowStyles();
  // const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();
  let day = today.getDate();
  let date1MonthBefore = new Date(year, month - 1, day);
  const [userList, setUserList] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectionRange, setSelectionRange] = useState({
    // startDate: date1MonthBefore,
    // endDate: new Date(),
    startDate: "",
    endDate: "",
    key: "selection",
  });
  const [searchText, setSearchText] = useState("");
   /*eslint-disable */
  const [searchSuggestions, setSearchSuggestions] = useState([]);
   /*eslint-enable */
  const [statusFilterSelected, setStatusFilterSelected] = useState("");
  const [kycFilterSelected, setKycFilterSelected] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const token = JSON.parse(localStorage.getItem("token"));
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState()
  const [airDropOpen, setAirDropOpen] = useState(false)
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const tableColumnWidth = [20, 15, 15, 15, 20, 15, 15, 20, 20, 15];
  const columns = [

    {
      name: "Profile Picture",
      field: "profilepicture",
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Username",
      field: "username",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Email",
      field: "email",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "AirDrop",
      field: "airdrop",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Status",
      field: "status",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Updated At",
      field: "updatedAt",
      numeric: true,
      sortable: false,
      align: "left",
    },
    // {
    //   name: "Transaction History",
    //   field: "transactionHistory",
    //   numeric: true,
    //   sortable: false,
    //   align: "left",
    // },
    {
      name: "Wallet Address",
      field: "walletAddress",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Kyc status",
      field: "sumsubStatus",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Kyc status link",
      field: "sumsubNotes",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Kyc failure reason",
      field: "sumsubNotes",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Action",
      field: "action",
      numeric: false,
      sortable: false,
      align: "left",
    },
  ];
/*eslint-disable */
  useEffect(() => {
    getUserDetails();
  }, [statusFilterSelected, searchText, pageNo, selectionRange, kycFilterSelected]);
/*eslint-enable */
  const handleSelect = (ranges) => {
    setSelectionRange(ranges?.selection);
  };

  const handleChangePage = (event, nextPageNo) => {
    setPageNo(nextPageNo);
    window.scrollTo(0, 0);
  };

  const getUserDetails = async () => {
    let startDate = selectionRange?.startDate ? new Date(
      selectionRange?.startDate?.getTime() -
        selectionRange?.startDate?.getTimezoneOffset() * 60000
    ).toISOString() : ""
    let endDate = selectionRange?.endDate ? new Date(
      selectionRange?.endDate?.getTime() -
        selectionRange?.endDate?.getTimezoneOffset() * 60000
    ).toISOString() : ""
    let statusFilter = "";
    if (statusFilterSelected === "blocked") {
      statusFilter = true;
    } else if (statusFilterSelected === "active") {
      statusFilter = false;
    }
    console.log('kycFilterSelected', typeof(kycFilterSelected))

    // const result = 
    await axiosInstance
      .get(
        `/v1/admin/user?page=${
          pageNo + 1
        }&limit=10&search=${searchText}&isBlock=${statusFilter}&fromDate=${startDate}&toDate=${endDate}&kyc=${kycFilterSelected !== "-1"  ? kycFilterSelected : ""}`,
        header
      )
      .then((result) => {
        setUserList(result?.data?.result?.users);
        setTotalCount(result?.data?.result?.totalItems);
      })
      .catch((err) => {
        let errMsg = formatAPIError(err)
        Swal.fire("Alert!", errMsg, "error").then(() => {
          if(err?.response?.status === 401 && err?.response?.data?.message?.includes("authenticated")){
            console.log('err.response', err.response)
            window.location.href = "/welcome/:layout"
          }
        })
      });
  };

  const resetFilter = () => {
    setSelectionRange({
      startDate: "",
      endDate: "",
      key: "selection",
    });
    setStatusFilterSelected(-1);
    setKycFilterSelected("-1")
    setSearchText("");
  }

  return (
    <div className="table_div tableWidth100">
      {showLoader === true ? <Loader /> : null}
      <div className="searchBoxProduct">
        <Button variant="outlined" onClick={() => resetFilter()}>
          Reset Filter
        </Button>
        <DataRangePopOver
          selectionRange={selectionRange}
          setSelectionRange={setSelectionRange}
          handleSelect={handleSelect}
        />
        {airDropOpen && <AirDropDrawer data={selectedUserData} setAirDropOpen={setAirDropOpen}></AirDropDrawer>}
        <FormControl variant="outlined" className={classes.height24}>
          <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
          {console.log(airDropOpen)}
          <Select
            native
            value={statusFilterSelected}
            onChange={(e) =>  {
              setPageNo(0)
              setStatusFilterSelected(e.target.value)}}
            label="Filter"
            inputProps={{
              name: "Filter",
              id: "outlined-age-native-simple",
            }}
            IconComponent={FilterListIcon}
            displayEmpty={true}
          >
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                clipRule="evenodd"
              />
            </svg>
            <optgroup label="Status">
              <option value={-1}>All</option>
              <option value={"blocked"}>Blocked</option>
              <option value={"active"}>Active</option>
            </optgroup>
          </Select>
        </FormControl>

        <FormControl variant="outlined" className={classes.height24}>
          <InputLabel htmlFor="outlined-age-native-simple">Kyc Filter</InputLabel>
          <Select
            native
            value={kycFilterSelected}
            onChange={(e) =>  {
              setPageNo(0)
              setKycFilterSelected(e.target.value)}}
            label="KycFilter"
            inputProps={{
              name: "KycFilter",
              id: "outlined-age-native-simple",
            }}
            IconComponent={FilterListIcon}
            displayEmpty={true}
          >
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                clipRule="evenodd"
              />
            </svg>
            <optgroup label="Kyc Status">
              <option value={-1}>All</option>
              <option value={"initiated"}>Initiated</option>
              <option value={"pending"}>Pending</option>
              <option value={"success"}>Success</option>
              <option value={"failed"}>Failed</option>
            </optgroup>
          </Select>
        </FormControl>

        <TextField
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            setPageNo(0);
          }}
          // onKeyDown={(e) => handleSearchBoxKeyDown(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          label="Search User"
          variant="outlined"
          placeholder="Search"
        />
        {/* <Autocomplete
          id="search-box-product"
          options={searchSuggestions}
          disableClearable={true}
          clearOnBlur={false}
          getOptionLabel={(option) => {
            console.log('option', option)
            return option.name || "";
          }}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setPageNo(0);
              }}
              // onKeyDown={(e) => handleSearchBoxKeyDown(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              label="Search User"
              variant="outlined"
            />
          )}
        /> */}
      </div>
      {userList?.length !== 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" className={classes.root}>
              <RenderHeader
                columns={columns}
                tableColumnWidth={tableColumnWidth}
                // onSorting={(field, order) => setSorting({ field, order })}
              />
              <TableBody style={{ width: "100%" }}>
                {userList?.map((row, index) => (
                  <Row
                    aria-label="expand row"
                    size="small"
                    key={row?.id}
                    row={row}
                    setSelectedRow={(value) => setSelectedRow(value)}
                    selectedRow={selectedRow}
                    index={index}
                    data={userList[index]}
                    tableColumnWidth={tableColumnWidth}
                    selectedRowIndex={selectedRowIndex}
                    setselectedRowIndex={setSelectedRowIndex}
                    openEdit={openEdit}
                    setOpenEdit={setOpenEdit}
                    setSelectedUserData = {setSelectedUserData}
                    setAirDropOpen = {setAirDropOpen}
                    // setShowLoader={setShowLoader}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            // backIconButtonProps={{ disabled: false }}
            component="div"
            count={totalCount}
            rowsPerPage={10}
            page={pageNo}
            onChangePage={handleChangePage}
            // onChange={(e) => handleChangePage(e)}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => (
              <>
                <span className="paginationLabel">Showing </span>
                {`${from}-${to}`}
                <span className="paginationLabel"> out of </span>
                {`${count}`}
              </>
            )}
          />
        </>
      ) : (
        <div className="noResultFound flexColumnCenter">
          <NoRecordsFound message="No records found." />
        </div>
      )}

      {openEdit && <UiDrawer
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        showLoader={showLoader}
        setShowLoader={(e) => setShowLoader(e)}
        isEdit={true}
        selectedRow={selectedRow}
      />}

  


    
    </div>
  );
}
